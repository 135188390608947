// CustomTooltip.tsx
import React, { useMemo } from "react";
import { mapCheckKeyToMetricKey } from "@pages/Dashboard/mocks/mockMetricsData";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { WidgetClass } from "@services/widget.service";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";

interface CustomTooltipProps {
  active?: boolean;
  payload?: Payload<ValueType, NameType>[];
  colorMetric?: { [key: string]: string };
  label: string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  colorMetric,
  label,
}) => {
  const widgetService = new WidgetClass();
  if (!active || !payload || !payload.length) {
    return null;
  }

  const uniquePayload = useMemo(() => {
    const visited: { [key: string]: boolean } = {};
    const result = [];

    payload.forEach((p) => {
      if (!visited[p.name]) {
        visited[p.name] = true;
        result.push(p);
      }
    });
    return result;
  }, [payload]);

  const payloadJSX = uniquePayload.map((p, i) => (
    <div key={i} className="performance-tooltip__row">
      <div className="performance-tooltip__rowLabel body-small">
        <div
          className="sales-performance__legendCircle"
          style={{ backgroundColor: colorMetric?.[p.dataKey] }}
        />
        {WIDGET_METRICS_CONFIG[p.dataKey]?.label || p.name}
      </div>
      <div className="performance-tooltip__rowValue body-small">
        {widgetService.formatValue(p.dataKey, p.value)}
      </div>
    </div>
  ));

  return (
    <div className="performance-tooltip">
      <div className="performance-tooltip__date">{label}</div>
      {payloadJSX}
    </div>
  );
};
