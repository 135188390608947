import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import Header from "./components/Header";
import ProductInfo from "./components/ProductInfo";
import TopProblems from "./components/TopProblems";
import Reviews from "./components/Reviews";
import ReviewScore from "./components/ReviewScore";
import AspectsAndPrases from "./components/AspectsAndPrases";

import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import { loadInsideProductData } from "src/utils/insides";
import { AmazonService } from "@services/amazon/amazon.service";
import { SpiderService } from "@services/spider/spider.services";
import { CategoriesService } from "@services/amazon/categories/categories.service";

import styles from "./styles.module.scss";

export interface Item {
  asin?: string;
  title?: string;
  score?: number | null;
  number_in_BSR?: number;
  image?: string;
}

interface Category {
  Department: string;
  Category: string;
  Sub_category: string;
  Sub_category_1: string;
  Sub_category_2: string;
  Sub_category_3: string;
  items: Item[];
  levels: number;
}

type CategoriesResponse = Category[];

const formattedKeys = (spiderDots: Record<string, number>) =>
  Object.keys(spiderDots).map((key) =>
    key
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
  );

export type OverviewType = "my-product" | "show-room";

export const Overview: React.FC<{ type?: OverviewType }> = ({
  type = "my-product",
}) => {
  const [visibleReviews, setVisibleReviews] = useState<number>(10);
  const [score, setScore] = useState<number | null>(null);
  const [stats, setStats] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState<boolean>(false);

  const { selectedProduct } = useProductStore();

  const {
    productOne,
    setProductOneAspects,
    setProductOnePhrases,
    setProductOneProblems,
    setProductOneReviews,
    setProductOneSpiderTitles,
    setProductOneSpiderValues,
    clearProductOne,
    setProductOneHypotheses,
  } = useInsightsStore();

  const loadMoreReviews = async () => {
    setIsLoadMoreLoading(true);
    try {
      const resReviews = await AmazonService.reviews.getOwnProducts(
        selectedProduct.asin,
        visibleReviews,
        10,
      );
      setProductOneReviews([...productOne.reviews, ...resReviews]);
      setVisibleReviews((prev) => prev + 10);
    } catch (error) {
      console.error("Error loading more reviews:", error);
    } finally {
      setIsLoadMoreLoading(false);
    }
  };

  const handleReviews = async () => {
    try {
      const spider = new CategoriesService();
      const resReviews = await spider.getCategories(selectedProduct.asin);
      if (
        Array.isArray(resReviews) &&
        resReviews.every((item) => "items" in item)
      ) {
        const matchingItem = resReviews
          .flatMap((category) => category.items)
          .find((item) => item.asin === selectedProduct.asin);

        if (matchingItem) {
          setScore(matchingItem.score || null);
        }
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      handleReviews();
    }
  }, [selectedProduct]);

  const handleStats = async () => {
    try {
      const spider = new CategoriesService();
      const resStats = await spider.getStats(selectedProduct.asin);
      setStats(resStats);
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      handleStats();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct?.asin) {
      clearProductOne();

      const fetchInsights = async () => {
        try {
          const { hypotheses, phrases, problems, reviews } =
            await loadInsideProductData(selectedProduct);
          setProductOneHypotheses(hypotheses);
          setProductOnePhrases(phrases);
          setProductOneProblems(problems);
          setProductOneReviews(reviews);
        } catch (error) {
          console.error("Error fetching insights:", error);
        } finally {
          setIsLoading(false);
        }
      };

      const fetchSpiderData = async () => {
        try {
          const spider = new SpiderService();
          const spiderDots = await spider.getSpiderInfo(
            selectedProduct.asin,
            type,
          );
          if (spiderDots && Object.keys(spiderDots).length > 0) {
            setProductOneSpiderTitles(formattedKeys(spiderDots));
            setProductOneSpiderValues(
              Object.values(spiderDots).map((value) =>
                parseFloat(value.toFixed(1)),
              ),
            );
          } else {
            setProductOneSpiderTitles([]);
            setProductOneSpiderValues([]);
          }
        } catch (error) {
          console.error("Error fetching spider info:", error);
        }
      };

      fetchInsights();
      fetchSpiderData();
    }
  }, [
    selectedProduct,
    type,
    clearProductOne,
    setProductOneHypotheses,
    setProductOnePhrases,
    setProductOneProblems,
    setProductOneReviews,
    setProductOneSpiderTitles,
    setProductOneSpiderValues,
  ]);

  const hasAspectsOrPhrases =
    (productOne.spiderTitles?.length || 0) > 0 ||
    (productOne.phrases?.length || 0) > 0;

  const hasProblems = (productOne.problems?.length || 0) > 0;
  const hasReviews = (productOne.reviews?.length || 0) > 0;

  return (
    <PageWrapper>
      <Header title={type === "my-product" ? "My product" : "Overview mode"} />
      <ProductInfo type={type} />

      {selectedProduct && <ReviewScore score={score} stats={stats} />}

      <div className={styles.section}>
        {!isLoading &&
          (hasAspectsOrPhrases ? (
            <AspectsAndPrases
              spiderTitles={productOne.spiderTitles}
              spiderValues={productOne.spiderValues}
              phrases={productOne.phrases}
            />
          ) : (
            <div className={styles.noData}>No data</div>
          ))}
      </div>

      <div className={styles.section}>
        {!isLoading &&
          (hasProblems ? (
            <TopProblems problems={productOne.problems} />
          ) : (
            <div className={styles.noData}>No data</div>
          ))}
      </div>

      {isLoading && (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}

      {/* Reviews section */}
      <div className={styles.section}>
        {selectedProduct &&
          !isLoading &&
          (hasReviews ? (
            <Reviews
              reviews={productOne.reviews}
              visibleReviews={visibleReviews}
              loadMoreReviews={loadMoreReviews}
              isLoadingMore={isLoadMoreLoading}
            />
          ) : (
            <div className={styles.noData}>No data</div>
          ))}
      </div>
    </PageWrapper>
  );
};

export default Overview;
