export const CHART_LABELS = [
  "Design",
  "Brand",
  "Usability",
  "Quality",
  "Price",
  "Noise",
];

export const METRIC_ICONS = {
  impressions: "👁️",
  clicks: "🖱️",
  orders: "📦",
  sales: "💰",
  spend: "💸",
  acos: "📊",
  roas: "📈",
  cpc: "💵",
  cvr: "🎯",
  ctr: "🔄",
  default: "📊",
} as const;

export const CHART_DATASET_CONFIG = {
  backgroundColor: "#FFFFFF",
  borderColor: "#5295E0",
  pointBackgroundColor: "transparent",
  pointHoverBackgroundColor: "#5295E0",
  pointBorderColor: "transparent",
  pointHoverBorderColor: "#5295E0",
  pointRadius: 10,
  pointHoverRadius: 6,
  borderWidth: 1,
};
