import { useState } from "react";
import { ItemData } from "../../mock";
import { categoryMock } from "./mock";
import styles from "./styles.module.scss";

interface FormProps {
  setAddNewItem: () => void;
  newItem: ItemData;
  setNewItem: (value: ItemData) => void;
}

export const FormProduct = ({
  setAddNewItem,
  newItem,
  setNewItem,
}: FormProps) => {
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewItem({ ...newItem, [name]: value });

    if (name === "category") {
      if (value.length >= 3) {
        const filtered = categoryMock.filter((category) =>
          category.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredCategories(filtered);
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    }
  };

  const handleCategorySelect = (category: string) => {
    setNewItem({ ...newItem, category });
    setShowPopup(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setAddNewItem();
  };

  return (
    <form onSubmit={handleSubmit} className={styles.newProduct}>
      <label className="input">
        Product name
        <input
          className="input__input"
          type="text"
          name="title"
          placeholder="Enter product name"
          value={newItem.title}
          onChange={handleChange}
        />
      </label>
      <label className="input">
        Category
        <input
          className="input__input"
          type="text"
          name="category"
          placeholder="Enter product category"
          value={newItem.category}
          onChange={handleChange}
        />
        {showPopup && (
          <div className={styles.popup}>
            {filteredCategories.map((category) => (
              <div
                key={category}
                className={styles.popupItem}
                onClick={() => handleCategorySelect(category)}
              >
                {category}
              </div>
            ))}
          </div>
        )}
      </label>
      <div className={styles.formRow}>
        <label className="input">
          Price
          <input
            className="input__input"
            type="text"
            name="price"
            placeholder="Enter product price"
            value={newItem.value}
            onChange={handleChange}
          />
        </label>
        <label className="input">
          Count
          <input
            className="input__input"
            type="text"
            name="count"
            placeholder="Enter product count"
            onChange={handleChange}
          />
        </label>
      </div>
      <button
        className="button button--sm button--gap button--m-0 button--spacing"
        type="submit"
      >
        Add
      </button>
    </form>
  );
};
