import styles from "./styles.module.scss";
import { Actions } from "./components/Actions";
import { Range } from "./components/Range";
import { List } from "./components/List";
import { DataProps, mock } from "./mock";
import React, { useState } from "react";
import { NewProduct } from "./components/NewProduct";
import TilesIcon from "@assets/icons/tiles.tsx";
import TableIcon from "@assets/icons/table.tsx";
import classNames from "classnames";
import { Table } from "./components/Table";

type TabKey = "charts" | "table";

interface TabObject {
  code: TabKey;
  icon: React.ReactNode;
}

interface ProductsProps {
  fullSize?: boolean;
  useOnly?: TabKey;
}

export const Products: React.FC<ProductsProps> = ({
  fullSize = false,
  useOnly,
}) => {
  const [data, setData] = useState<DataProps>(mock);
  const [addNewItem, setAddNewItem] = useState(false);
  const [activeTab, setActiveTab] = useState<TabKey>(useOnly || "charts");

  const tabList: TabObject[] = [
    {
      code: "charts",
      icon: <TilesIcon color="red" />,
    },
    {
      code: "table",
      icon: <TableIcon />,
    },
  ];

  const handleCloseModal = () => {
    setAddNewItem(false);
  };

  const toggleTab = (code: TabKey) => {
    setActiveTab(code);
  };

  return (
    <div className={classNames(styles.container, fullSize && styles.fullSize)}>
      <div className={styles.header}>
        <div className={styles.header__title}>
          <h2>Products</h2>
          {!useOnly && (
            <div className={classNames(styles["tabContainer__tab"])}>
              {tabList.map((item) => (
                <button
                  key={item.code}
                  onClick={() => toggleTab(item.code)}
                  className={classNames(styles.element, {
                    [styles["element__active"]]: activeTab === item.code,
                  })}
                >
                  {item.icon}
                </button>
              ))}
            </div>
          )}
        </div>
        <Actions setAddNewItem={setAddNewItem} />
      </div>
      {activeTab === "charts" ? (
        <>
          <Range />
          <List data={data} />
        </>
      ) : (
        <Table data={data} />
      )}
      <NewProduct
        isOpen={addNewItem}
        onClose={handleCloseModal}
        data={data}
        setData={setData}
      />
    </div>
  );
};
