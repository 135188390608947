import { WhatIfTab } from "@pages/AdsAnalitics/components/Campaigns/components/Tables/WhatIfTable/Tabs";
import { create } from "zustand";

export type Goals = {
  acos: number;
  dailySpend: number;
  monthlySales: number;
};

export type AdSlides = {
  adSpend: number;
  adBids: number;
  adTime: number;
  adTop: number;
};

export interface IAdsAnaliticsState {
  isWhatIfEnabled: boolean;
  keys: {
    performance: string[];
    campaign: string[];
    targeting: string[];
  };
  sankeyTab: WhatIfTab;
  goals: Goals;
  adSlides: AdSlides;
  compareWith: {
    startDate: Date;
    endDate: Date;
  };
  compareWithString: string;
  setCompareWithString: (compareWithString: string) => void;
  setCompareWith: (range: { startDate: Date; endDate: Date }) => void;
  setAdSlides: (adSlides: AdSlides) => void;
  setIsWhatIfEnabled: (isWhatIfEnabled: boolean) => void;
  setKeys: ({
    performance,
    campaign,
    targeting,
  }: {
    performance: string[];
    campaign: string[];
    targeting: string[];
  }) => void;
  setSankeyTab: (tab: WhatIfTab) => void;
  setGoals: (goal: Goals) => void;
}

export const useAdsAnaliticsStore = create<IAdsAnaliticsState>((set) => ({
  compareWith: {
    startDate: new Date(),
    endDate: new Date(),
  },
  isWhatIfEnabled: false,
  keys: {
    performance: [],
    campaign: [],
    targeting: [],
  },
  sankeyTab: "Campaigns",
  goals: {
    acos: 0,
    dailySpend: 0,
    monthlySales: 0,
  },
  adSlides: {
    adSpend: 0,
    adBids: 0,
    adTime: 0,
    adTop: 0,
    week: 0,
  },
  compareWithString: "Custom",
  setCompareWithString: (compareWithString: string) => {
    set(() => ({ compareWithString }));
  },
  setCompareWith: (range: { startDate: Date; endDate: Date }) => {
    set(() => ({ compareWith: range }));
  },
  setAdSlides: (adSlides: AdSlides) => {
    set(() => ({ adSlides }));
  },
  setIsWhatIfEnabled: (isWhatIfEnabled: boolean) => {
    set(() => ({ isWhatIfEnabled }));
  },
  setKeys: ({
    performance,
    campaign,
    targeting,
  }: {
    performance: string[];
    campaign: string[];
    targeting: string[];
  }) => {
    set(() => ({ keys: { performance, campaign, targeting } }));
  },
  setSankeyTab: (sankeyTab: WhatIfTab) => {
    set(() => ({ sankeyTab }));
  },
  setGoals: (goals: Goals) => {
    set(() => ({ goals }));
  },
}));
