import { create } from "zustand";

interface TimeRangeState {
  timeRange: "week" | "month" | "daily";
  setTimeRange: (value: "week" | "month" | "daily") => void;
  computedTimeRanges: any[];
  setComputedTimeRanges: (ranges: any[]) => void;
}

export const useTimeRangeStore = create<TimeRangeState>((set) => ({
  timeRange: "week",
  setTimeRange: (value) => set({ timeRange: value }),
  computedTimeRanges: [],
  setComputedTimeRanges: (ranges) => set({ computedTimeRanges: ranges }),
}));
