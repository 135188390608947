import { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Setting from "@assets/icons/setting";
import BottomArrow from "@assets/icons/bottomArrow";
import { CollapseInfo } from "@pages/MarketIntelligence/components/CollapseInfo";
import { statuses, capitalize } from "./statuses";

interface ProductActionsProps {
  setIsOpenModal?: (value: boolean) => void;
  status: string;
  isDetails: boolean;
  setIsDetails?: (value: boolean) => void;
  isTable?: boolean;
}

export const ProductActions = ({
  setIsOpenModal,
  status,
  isDetails,
  setIsDetails,
  isTable,
}: ProductActionsProps) => {
  const [currentStatus, setCurrentStatus] = useState(status.toLowerCase());
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (status.toLowerCase() !== currentStatus) {
      setCurrentStatus(status.toLowerCase());
    }
  }, [status]);

  const handleStatusChange = (newStatus: string) => {
    setCurrentStatus(newStatus);
    setIsStatusOpen(false);
  };

  return (
    <div className={styles.productActions}>
      {!isTable && (
        <div
          className={styles.setting__button}
          onClick={() => setIsOpenModal && setIsOpenModal(true)}
        >
          <Setting />
        </div>
      )}
      <div className={styles.additionalActions}>
        <div
          ref={dropdownRef}
          className={classNames(styles.track, styles[currentStatus], {
            [styles.open]: isStatusOpen,
          })}
          onClick={() => setIsStatusOpen((prev) => !prev)}
        >
          <div className={styles.trackWrapper}>
            <span className={styles.trackText}>
              <span className={styles.trackIcon}></span>
              {capitalize(currentStatus)}
            </span>
            <span className={styles.arrow}>
              <BottomArrow fill={currentStatus === "active" ? "#80C67A" : ""} />
            </span>
          </div>
        </div>
        {isStatusOpen && (
          <div className={styles.statusDropdown}>
            {statuses.map((statusItem) => (
              <div
                key={statusItem.code}
                className={classNames(
                  styles.statusOption,
                  styles[statusItem.code],
                )}
                onClick={() => handleStatusChange(statusItem.code)}
              >
                <span className={styles.trackIcon}></span>
                {statusItem.label}
              </div>
            ))}
          </div>
        )}
        {!isTable && (
          <CollapseInfo
            label="Details"
            onClick={() => setIsDetails(!isDetails)}
            rotateArrow={isDetails}
          />
        )}
      </div>
    </div>
  );
};
