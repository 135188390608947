import { SVGProps } from "react";

export default (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1316 9.5C10.7828 9.5 10.5 9.21723 10.5 8.86842V2.63158C10.5 2.28277 10.2172 2 9.86842 2H3.63158C3.28277 2 3 1.71723 3 1.36842V1.13158C3 0.782768 3.28277 0.5 3.63158 0.5H11.3684C11.7172 0.5 12 0.782768 12 1.13158V8.86842C12 9.21723 11.7172 9.5 11.3684 9.5H11.1316ZM0 4.13158C0 3.78277 0.282768 3.5 0.631579 3.5H8.36842C8.71723 3.5 9 3.78277 9 4.13158V11.8684C9 12.2172 8.71723 12.5 8.36842 12.5H0.631579C0.282767 12.5 0 12.2172 0 11.8684V4.13158ZM2.13158 5C1.78277 5 1.5 5.28277 1.5 5.63158V10.3684C1.5 10.7172 1.78277 11 2.13158 11H6.86842C7.21723 11 7.5 10.7172 7.5 10.3684V5.63158C7.5 5.28277 7.21723 5 6.86842 5H2.13158Z"
        fill="currentColor"
      />
    </svg>
  );
};
