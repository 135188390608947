export const CustomTooltip = ({ active, payload, label }: any) => {
  if (!active || !payload || !payload.length) return null;

  return (
    <div
      style={{
        backgroundColor: "#1F1F20",
        border: "1px solid #7C7C78",
        padding: "8px 12px",
        borderRadius: "4px",
      }}
    >
      <p style={{ color: "#fff", margin: 0 }}>
        {`Spend: ${payload[0].payload.category}%`}
      </p>
      <p style={{ color: "#fff", margin: "4px 0 0" }}>
        {`Value: $${Math.floor(payload[0].value).toLocaleString()}`}
      </p>
    </div>
  );
};
