export const redRows = [
  "Cost of Goods Sold",
  "COGS",
  "Shipping & Handling",
  "Amazon Fees",
  "Amazon Spend",
  "Overhead Cost",
  "OPEX",
];

export const financeData = [
  {
    key: "1",
    pnlType: "Total Sales",
    jan1: "{$XXX}",
    jan2: "{$XXX}",
    jan3: "{$XXX}",
    jan4: "{$XXX}",
    jan5: "{$XXX}",
    jan6: "{$XXX}",
    jan7: "{$XXX}",
    children: [
      {
        key: "1-1",
        pnlType: "Amazon Sales",
        jan1: "{$XXX}",
        jan2: "{$XXX}",
        jan3: "{$XXX}",
        jan4: "{$XXX}",
        jan5: "{$XXX}",
        jan6: "{$XXX}",
        jan7: "{$XXX}",
        children: [
          {
            key: "1-1-1",
            pnlType: "Ad",
            jan1: "{$XXX}",
            jan2: "{$XXX}",
            jan3: "{$XXX}",
            jan4: "{$XXX}",
            jan5: "{$XXX}",
            jan6: "{$XXX}",
            jan7: "{$XXX}",
          },
          {
            key: "1-1-2",
            pnlType: "Organic",
            jan1: "{$XXX}",
            jan2: "{$XXX}",
            jan3: "{$XXX}",
            jan4: "{$XXX}",
            jan5: "{$XXX}",
            jan6: "{$XXX}",
            jan7: "{$XXX}",
          },
        ],
      },
      {
        key: "1-2",
        pnlType: "Other sales",
        jan1: "{$XXX}",
        jan2: "{$XXX}",
        jan3: "{$XXX}",
        jan4: "{$XXX}",
        jan5: "{$XXX}",
        jan6: "{$XXX}",
        jan7: "{$XXX}",
        children: [
          {
            key: "1-2-1",
            pnlType: "Organic Social",
            jan1: "{$XXX}",
            jan2: "{$XXX}",
            jan3: "{$XXX}",
            jan4: "{$XXX}",
            jan5: "{$XXX}",
            jan6: "{$XXX}",
            jan7: "{$XXX}",
          },
          {
            key: "1-2-2",
            pnlType: "DSP",
            jan1: "{$XXX}",
            jan2: "{$XXX}",
            jan3: "{$XXX}",
            jan4: "{$XXX}",
            jan5: "{$XXX}",
            jan6: "{$XXX}",
            jan7: "{$XXX}",
          },
        ],
      },
    ],
  },
  {
    key: "2",
    pnlType: "Cost of Goods Sold",
    jan1: "{$XXX}",
    jan2: "{$XXX}",
    jan3: "{$XXX}",
    jan4: "{$XXX}",
    jan5: "{$XXX}",
    jan6: "{$XXX}",
    jan7: "{$XXX}",
    children: [
      {
        key: "2-1",
        pnlType: "COGS",
        jan1: "{$XXX}",
        jan2: "{$XXX}",
        jan3: "{$XXX}",
        jan4: "{$XXX}",
        jan5: "{$XXX}",
        jan6: "{$XXX}",
        jan7: "{$XXX}",
      },
      {
        key: "2-2",
        pnlType: "Shipping & Handling",
        jan1: "{$XXX}",
        jan2: "{$XXX}",
        jan3: "{$XXX}",
        jan4: "{$XXX}",
        jan5: "{$XXX}",
        jan6: "{$XXX}",
        jan7: "{$XXX}",
      },
    ],
  },
  {
    key: "3",
    pnlType: "Overhead Cost",
    jan1: "{$XXX}",
    jan2: "{$XXX}",
    jan3: "{$XXX}",
    jan4: "{$XXX}",
    jan5: "{$XXX}",
    jan6: "{$XXX}",
    jan7: "{$XXX}",
    children: [
      {
        key: "3-1",
        pnlType: "Amazon Fees",
        jan1: "{$XXX}",
        jan2: "{$XXX}",
        jan3: "{$XXX}",
        jan4: "{$XXX}",
        jan5: "{$XXX}",
        jan6: "{$XXX}",
        jan7: "{$XXX}",
      },
      {
        key: "3-2",
        pnlType: "Amazon Spend",
        jan1: "{$XXX}",
        jan2: "{$XXX}",
        jan3: "{$XXX}",
        jan4: "{$XXX}",
        jan5: "{$XXX}",
        jan6: "{$XXX}",
        jan7: "{$XXX}",
      },
    ],
  },
  {
    key: "4",
    pnlType: "Contribution Profit",
    jan1: "{$XXX}",
    jan2: "{$XXX}",
    jan3: "{$XXX}",
    jan4: "{$XXX}",
    jan5: "{$XXX}",
    jan6: "{$XXX}",
    jan7: "{$XXX}",
  },
  {
    key: "5",
    pnlType: "Contribution Margin",
    jan1: "{$XXX}",
    jan2: "{$XXX}",
    jan3: "{$XXX}",
    jan4: "{$XXX}",
    jan5: "{$XXX}",
    jan6: "{$XXX}",
    jan7: "{$XXX}",
  },
  {
    key: "6",
    pnlType: "OPEX",
    jan1: "{$XXX}",
    jan2: "{$XXX}",
    jan3: "{$XXX}",
    jan4: "{$XXX}",
    jan5: "{$XXX}",
    jan6: "{$XXX}",
    jan7: "{$XXX}",
  },
  {
    key: "7",
    pnlType: "Cash Flow",
    jan1: "{$XXX}",
    jan2: "{$XXX}",
    jan3: "{$XXX}",
    jan4: "{$XXX}",
    jan5: "{$XXX}",
    jan6: "{$XXX}",
    jan7: "{$XXX}",
  },
];

export const financeColumns = [
  {
    title: "Type",
    dataIndex: "pnlType",
    key: "pnlType",
    render: (value: string, record: any) => {
      if (record.pnlType === "Total Sales") {
        return <div className="total-sales-cell-pnl">{value}</div>;
      }
      if (
        record.pnlType === "Amazon Sales" ||
        record.pnlType === "Other sales"
      ) {
        return <div className="Sales-sales-cell-pnl">{value}</div>;
      }
      return value;
    },
  },
  {
    title: "1 Jan",
    dataIndex: "jan1",
    key: "jan1",
    render: (val: string, record: any) => {
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  },
  {
    title: "2 Jan",
    dataIndex: "jan2",
    key: "jan2",
    render: (val: string, record: any) => {
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  },
  {
    title: "3 Jan",
    dataIndex: "jan3",
    key: "jan3",
    render: (val: string, record: any) => {
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  },
  {
    title: "4 Jan",
    dataIndex: "jan4",
    key: "jan4",
    render: (val: string, record: any) => {
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  },
  {
    title: "5 Jan",
    dataIndex: "jan5",
    key: "jan5",
    render: (val: string, record: any) => {
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  },
  {
    title: "6 Jan",
    dataIndex: "jan6",
    key: "jan6",
    render: (val: string, record: any) => {
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  },
  {
    title: "7 Jan",
    dataIndex: "jan7",
    key: "jan7",
    render: (val: string, record: any) => {
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  },
];

export const generateFinanceColumns = (computedTimeRange: any[]): any[] => {
  const typeColumn = {
    title: "",
    dataIndex: "pnlType",
    key: "pnlType",
    render: (value: string, record: any) => {
      if (record.pnlType === "Total Sales") {
        return <div className="total-sales-cell-pnl">{value}</div>;
      }
      if (
        record.pnlType === "Amazon Sales" ||
        record.pnlType === "Other sales"
      ) {
        return <div className="Sales-sales-cell-pnl">{value}</div>;
      }
      return value;
    },
  };

  const dynamicColumns = computedTimeRange.map((range, index) => ({
    title: range,
    dataIndex: `jan${index + 1}`,
    key: `jan${index + 1}`,
    render: (val: string, record: any) => {
      if (val == null) {
        val = "{$XXX}";
      }
      if (redRows.includes(record.pnlType)) {
        return <span style={{ color: "red" }}>{val}</span>;
      }
      return val;
    },
  }));

  return [typeColumn, ...dynamicColumns];
};
