import styles from "./styles.module.scss";
import {
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
  Area,
  AreaChart,
} from "recharts";
import { staticData, chartColors, formatCurrency } from "./mock";

export const ProductChart = () => {
  return (
    <div className={styles.chart}>
      <h3 className={styles.title}>
        <span className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: chartColors.sales }}
          ></span>
          Total Sales
        </span>
      </h3>
      <ResponsiveContainer width="100%" height={216}>
        <AreaChart
          data={staticData}
          margin={{ top: 20, right: 30, left: 20, bottom: 0 }}
        >
          <defs>
            <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor={chartColors.sales}
                stopOpacity={0.6}
              />
              <stop offset="100%" stopColor="#FFFFFF" stopOpacity={0} />
            </linearGradient>
          </defs>

          <CartesianGrid stroke="#1F1F20" />
          <YAxis
            tickFormatter={formatCurrency}
            tick={{ fill: "#aaa" }}
            stroke="transparent"
          />
          <Area
            type="monotone"
            dataKey="sales"
            stroke={chartColors.sales}
            fill="url(#areaGradient)"
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
