import classNames from "classnames";
import "./index.css";
import Star from "./Star.tsx";
import { FC } from "react";

interface IProps {
  rating?: number;
  showRating?: boolean;
  oneRating?: boolean;
  starSize?: "sm" | "md" | "lg";
}

export const Rating: FC<IProps> = ({
  rating,
  showRating = true,
  oneRating = false,
  starSize,
}: IProps) => {
  const computedWidth = `${(rating / 5) * 100}%`;

  return oneRating ? (
    <div className="ratingStarContainer">
      <div className={classNames("rating__stars", starSize)}>
        <div className={classNames("rating-underlay", starSize)}>
          <div className={classNames("rating__star", starSize)}>
            <Star />
          </div>
        </div>
        <div
          className={classNames("rating-overlay", starSize)}
          style={{ width: computedWidth }}
        >
          <div className={classNames("rating__star", starSize)}>
            <Star />
          </div>
        </div>
      </div>
      {showRating && <p className="rating__value heading-3">{rating}</p>}
    </div>
  ) : (
    <div className="rating">
      <div className={classNames("rating__stars", starSize)}>
        <div className={classNames("rating-underlay", starSize)}>
          {[...Array(5)].map((_, i) => (
            <div key={i} className={classNames("rating__star", starSize)}>
              <Star />
            </div>
          ))}
        </div>
        <div
          className={classNames("rating-overlay", starSize)}
          style={{ width: computedWidth }}
        >
          {[...Array(5)].map((_, i) => (
            <div key={i} className={classNames("rating__star", starSize)}>
              <Star />
            </div>
          ))}
        </div>
      </div>
      {showRating && <p className="rating__value heading-3">{rating}</p>}
    </div>
  );
};
