const rawDataNoForecast = [
  {
    date: "1 Jan",
    OPEX: 300,
    Commission: 300,
    TransportCost: 900,
    AdSpend: 600,
    COGS: 3200,
    Sales: 3000,
    CumulativeSF: -4800,
  },
  {
    date: "2 Jan",
    OPEX: 200,
    Commission: 100,
    TransportCost: 300,
    AdSpend: 300,
    COGS: 1000,
    Sales: 3200,
    CumulativeSF: -2000,
  },
  {
    date: "3 Jan",
    OPEX: 700,
    Commission: 300,
    TransportCost: 950,
    AdSpend: 400,
    COGS: 2800,
    Sales: 1500,
    CumulativeSF: 0,
  },
  {
    date: "4 Jan",
    OPEX: 200,
    Commission: 200,
    TransportCost: 500,
    AdSpend: 200,
    COGS: 750,
    Sales: 4700,
    CumulativeSF: 500,
  },
  {
    date: "5 Jan",
    OPEX: 200,
    Commission: 200,
    TransportCost: 500,
    AdSpend: 200,
    COGS: 750,
    Sales: 4700,
    CumulativeSF: -1000,
  },
  {
    date: "6 Jan",
    OPEX: 400,
    Commission: 200,
    TransportCost: 980,
    AdSpend: 800,
    COGS: 4000,
    Sales: 800,
    CumulativeSF: 0,
  },
  {
    date: "7 Jan",
    OPEX: 100,
    Commission: 100,
    TransportCost: 200,
    AdSpend: 20,
    COGS: 300,
    Sales: 400,
    CumulativeSF: -2000,
  },
];

const plusKeysNoForecast = ["Sales", "OrganicSocialSales"];
const minusKeysNoForecast = [
  "OPEX",
  "Commission",
  "TransportCost",
  "AdSpend",
  "COGS",
];

const rawDataBaseForecast = [
  {
    date: "1 Jan",
    OPEX: 300,
    Commission: 300,
    TransportCost: 900,
    AdSpend: 600,
    COGS: 3200,
    Sales: 3000,
  },
  {
    date: "2 Jan",
    OPEX: 200,
    Commission: 100,
    TransportCost: 300,
    AdSpend: 300,
    COGS: 1000,
    Sales: 3200,
  },
  {
    date: "3 Jan",
    OPEX: 700,
    Commission: 300,
    TransportCost: 950,
    AdSpend: 400,
    COGS: 2800,
    Sales: 1500,
  },
  {
    date: "4 Jan",
    OPEX: 200,
    Commission: 200,
    TransportCost: 500,
    AdSpend: 200,
    COGS: 750,
    Sales: 4700,
  },
  {
    date: "5 Jan",
    OPEX: 200,
    Commission: 200,
    TransportCost: 500,
    AdSpend: 200,
    COGS: 750,
    Sales: 4700,
  },
  {
    date: "6 Jan",
    OPEX: 400,
    Commission: 200,
    TransportCost: 980,
    AdSpend: 800,
    COGS: 4000,
    Sales: 800,
  },
  {
    date: "7 Jan",
    OPEX: 100,
    Commission: 100,
    TransportCost: 200,
    AdSpend: 20,
    COGS: 300,
    Sales: 400,
  },
];

const plusKeysForecast = ["Sales", "OrganicSocialSales"];
const minusKeysForecast = [
  "OPEX",
  "Commission",
  "TransportCost",
  "AdSpend",
  "COGS",
];

function transformItem(
  item: Record<string, any>,
  plusKeys: string[],
  minusKeys: string[],
) {
  const newItem: Record<string, number | string> = { date: item.date };

  plusKeys.forEach((key) => {
    newItem[key] = item[key];
  });
  minusKeys.forEach((key) => {
    newItem[key] = -Math.abs(item[key]);
  });

  if ("CumulativeSF" in item) {
    newItem["CumulativeSF"] = item["CumulativeSF"];
  }

  return newItem;
}

export function getTransformedData(showForecast: boolean) {
  if (!showForecast) {
    const rawDataNoForecastWithOSS = rawDataNoForecast.map((item) => ({
      ...item,
      OrganicSocialSales: Math.round(item.Sales * 0.3),
    }));
    return rawDataNoForecastWithOSS.map((item) =>
      transformItem(item, plusKeysNoForecast, minusKeysNoForecast),
    );
  } else {
    const rawDataForecast = rawDataBaseForecast.map((item) => ({
      ...item,
      OrganicSocialSales: Math.round(item.Sales * 0.3),
    }));
    return rawDataForecast.map((item) =>
      transformItem(item, plusKeysForecast, minusKeysForecast),
    );
  }
}

export const nameMapping: Record<string, string> = {
  AdSpend: "Amazon Ad Sales",
  Commission: "Amazon Fees",
  Sales: "Amazon Organic Sales",
  TransportCost: "Amazon Spend",
  COGS: "COGS",
  OPEX: "OPEX",
  OrganicSocialSales: "Organic Social Sales",
  CumulativeSF: "Cumulative SF",
};

export const legendOrder = [
  "AdSpend",
  "Commission",
  "Sales",
  "TransportCost",
  "COGS",
  "OPEX",
  "OrganicSocialSales",
];

export const legendColorMapping: Record<string, string> = {
  AdSpend: "#80C67A",
  Commission: "#FE5858",
  Sales: "#1BB08D",
  TransportCost: "#F19867",
  COGS: "#6B4DBA",
  OPEX: "#E667F1",
  OrganicSocialSales: "#AEEFA8",
};

export const rawData = [
  {
    date: "1 Jan",
    OPEX: 300,
    Commission: 300,
    TransportCost: 900,
    AdSpend: 600,
    COGS: 3200,
    Sales: 3000,
    CumulativeSF: -4800,
  },
  {
    date: "2 Jan",
    OPEX: 200,
    Commission: 100,
    TransportCost: 300,
    AdSpend: 300,
    COGS: 1000,
    Sales: 3200,
    CumulativeSF: -2000,
  },
  {
    date: "3 Jan",
    OPEX: 700,
    Commission: 300,
    TransportCost: 950,
    AdSpend: 400,
    COGS: 2800,
    Sales: 1500,
    CumulativeSF: 0,
  },
  {
    date: "4 Jan",
    OPEX: 200,
    Commission: 200,
    TransportCost: 500,
    AdSpend: 200,
    COGS: 750,
    Sales: 4700,
    CumulativeSF: 500,
  },
  {
    date: "5 Jan",
    OPEX: 200,
    Commission: 200,
    TransportCost: 500,
    AdSpend: 200,
    COGS: 750,
    Sales: 4700,
    CumulativeSF: -1000,
  },
  {
    date: "6 Jan",
    OPEX: 400,
    Commission: 200,
    TransportCost: 980,
    AdSpend: 800,
    COGS: 4000,
    Sales: 800,
    CumulativeSF: 0,
  },
  {
    date: "7 Jan",
    OPEX: 100,
    Commission: 100,
    TransportCost: 200,
    AdSpend: 20,
    COGS: 300,
    Sales: 400,
    CumulativeSF: -2000,
  },
];

export const plusKeys = ["Sales"];
export const minusKeys = [
  "OPEX",
  "Commission",
  "TransportCost",
  "AdSpend",
  "COGS",
];

export const transformedData = rawData.map((item) => {
  const newItem: any = { date: item.date };

  plusKeys.forEach((key) => {
    newItem[key] = item[key];
  });

  minusKeys.forEach((key) => {
    newItem[key] = -Math.abs(item[key]);
  });

  return newItem;
});

export const nameMappingNoForecast: Record<string, string> = {
  Sales: "Sales",
  COGS: "COGS",
  AdSpend: "AdSpend",
  TransportCost: "TransportCost",
  Commission: "Commission",
  OPEX: "OPEX",
};

export const legendOrderNoForecast = [
  "Sales",
  "COGS",
  "AdSpend",
  "TransportCost",
  "Commission",
  "OPEX",
];

export const legendColorMappingNoForecast: Record<string, string> = {
  Sales: "#80C67A",
  COGS: "#FE5858",
  AdSpend: "#F19867",
  TransportCost: "#6B4DBA",
  Commission: "#C8A857",
  OPEX: "#E667F1",
};
