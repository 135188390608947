import styles from "./styles.module.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";
import { data, colors, formatCurrency, sliderLabels } from "./mock";
import { CustomSlider } from "./components/CustomSlider";
import { AsinSlider } from "./components/AsinSlider";
import { Select } from "@components/Select";
import classNames from "classnames";
import { useState } from "react";

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <p>{payload[0].payload.date}</p>
        {payload.map((entry: any, index: number) => (
          <p key={index} style={{ color: entry.fill }}>
            {entry.name}: ${entry.value}M
          </p>
        ))}
      </div>
    );
  }
  return null;
};

export const DetailsChart = () => {
  const [view, setView] = useState("Week View");

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartHeader}>
        <div className={styles.chartLegend}>
          <h2 className={styles.chartTitle}>Sales velocity</h2>
          <AsinSlider />
        </div>
        <Select
          options={["Week View", "Month View"]}
          value={view}
          onChange={(value: string) => setView(value)}
          placeholder="Week View"
          className={classNames(styles.select__dropdown, styles.lg)}
        />
      </div>
      <div className={styles.dateSlider}>
        <span>Launch date</span>
        <CustomSlider
          min={0}
          max={6}
          value={4}
          onChange={(value) => console.log(value)}
          labels={sliderLabels}
        />
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 10, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
          <XAxis dataKey="date" tick={{ fill: "#5C5C5A" }} />
          <YAxis tickFormatter={formatCurrency} tick={{ fill: "white" }} />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />

          <ReferenceLine x="Jan 4" stroke="white" strokeDasharray="4 4">
            <Label
              value="Product 1 Launched"
              position="top"
              fill="white"
              fontSize={12}
            />
          </ReferenceLine>
          <ReferenceLine x="Jan 6" stroke="white" strokeDasharray="4 4">
            <Label
              value="Product 2 Launched"
              position="top"
              fill="white"
              fontSize={12}
            />
          </ReferenceLine>

          {["p1", "p2", "p3", "p4", "p5", "p6"].map((key, index) => (
            <Bar key={key} dataKey={key} stackId="a" fill={colors[index]} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
