import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Modal } from "antd";

interface ModalBoxProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  className?: string;
  maskClosable?: boolean;
}

const ModalBox: React.FC<ModalBoxProps> = ({
  isOpen,
  onClose,
  title,
  children,
  className,
  maskClosable = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <Modal
      className={classNames(styles.modalOverlay, "modal")}
      open={isOpen}
      onCancel={onClose}
      maskClosable={maskClosable}
      footer={null}
    >
      <div className={classNames(styles.modalContent, className)}>
        <h2 className={styles.modalTitle}>{title}</h2>
        {children}
      </div>
    </Modal>
  );
};

export default ModalBox;
