import React, { useState } from "react";
import styles from "./styles.module.scss";
import { useScoreAndStatsStore } from "src/store/review-stats.store";
import { useProductsStore } from "src/store/product.state";
import { useProductStore } from "src/store/overviewProduct.state";
import { calculateStars } from "@pages/Overview/components/ReviewScore/ReviewScore/ratingUtils";
import { mockData } from "@pages/Compare/components/Products/mock";
import { Rating } from "@pages/Dashboard/features/Products/Rating";

interface ProductDetailsProps {
  name: string | null;
  points: string[] | null;
  isUpdated: boolean;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  name,
  points,
  isUpdated,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { stats, score } = useScoreAndStatsStore();
  const { selectedProduct } = useProductStore();

  const { productDataByAsin } = useProductsStore();

  const reviews = parseFloat(
    (typeof score === "object" && selectedProduct?.asin
      ? score[selectedProduct.asin] || mockData[0]?.rating || 0
      : mockData[0]?.rating || 0
    ).toFixed(1),
  );

  const stars = calculateStars(reviews);
  return (
    <div className={styles.productDetails}>
      <h1 className={styles.title}>{isUpdated ? <mark>{name}</mark> : name}</h1>
      <div className={styles.rating}>
        <Rating rating={reviews} showRating={false} />
      </div>

      <p className={styles.price}>
        ${productDataByAsin?.price} <span>($0.50/Count)</span>
      </p>
      <div className={styles.itemDetails}>
        <p>About this item</p>
        <ul className={styles.detailsList}>
          {points && points.length > 0 ? (
            points.map((point, index) => (
              <li key={index}>{isUpdated ? <mark>{point}</mark> : point}</li>
            ))
          ) : (
            <li>No bullet points available.</li>
          )}
        </ul>
        {showMore && (
          <div className={styles.moreDetails}>
            <p>
              Gone are the days of dreading taking vitamins with our delicious
              and convenient gummies.
            </p>
          </div>
        )}
      </div>
      <button
        className={styles.showMoreButton}
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "Show Less" : "Show More"}
      </button>
    </div>
  );
};

export default ProductDetails;
