import { useState, useEffect, useCallback } from "react";

export const useZoom = (initialZoom: number = 0.2) => {
  const [zoom, setZoom] = useState(initialZoom);

  const handleWheel = useCallback((e: WheelEvent) => {
    if (e.ctrlKey) {
      e.preventDefault();
      setZoom((prevZoom) => {
        const delta = -e.deltaY * 0.0005;
        return Math.min(Math.max(prevZoom + delta, 0.2), 2);
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => window.removeEventListener("wheel", handleWheel);
  }, [handleWheel]);

  const zoomIn = () => setZoom((prev) => Math.min(prev + 0.05, 2));
  const zoomOut = () => setZoom((prev) => Math.max(prev - 0.05, 0.2));

  return { zoom, zoomIn, zoomOut, setZoom };
};
