import React, { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import ProductCode from "@components/ProductCode";
import ProductInput from "@components/ProductInput";
import { Product } from "@services/amazon/products/types";
import { OverviewType } from "@pages/Overview";
import { useProductStore } from "src/store/overviewProduct.state";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import { GraphsService } from "@services/amazon/graph/graph.service";
import { mockDataMultySelect } from "../mock";
import classNames from "classnames";
import {
  getAsin,
  getDateRange,
  getProductsToRender,
  getRat,
  getReviews,
  getStarReviews,
  getUniqueProductsToRender,
} from "./constants";
import { Rating } from "@pages/Dashboard/features/Products/Rating";

interface MockDataType {
  type: string;
  name: string;
  price: string;
  rating: number;
  reviews: Record<string, number>;
  image: string;
  code: string;
}

interface ProductCardProps {
  productTwo?: boolean;
  product: Product | null;
  label: string;
  selectType: "your" | "competitor";
  mockData: MockDataType;
  type: OverviewType;
  showInput?: boolean;
  isSmallSize?: boolean;
  rating?: number;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productTwo = false,
  product,
  label,
  selectType,
  mockData,
  type,
}) => {
  const { selectedProduct, selectedProductToCompare } = useProductStore();
  const [spiders, setSpiders] = useState<any>(null);
  const [ratings, setRatings] = useState<{ [key: string]: number | null }>({});
  const spiderData = product?.spiderDots || product;
  const productPrice = spiderData?.price ?? 0;
  const productImage =
    spiderData?.picture_url ?? product?.image ?? mockData.image;
  const productAsin = spiderData?.asin ?? product?.asin ?? mockData.code;
  const [totalReviews, setTotalReviews] = useState<number>(0);
  const [scoreMain, setScoreMain] = useState<{ [key: string]: number | null }>(
    {},
  );
  const [totalReviewsSecondProduct, setTotalReviewsSecondProduct] = useState<{
    [key: string]: number;
  }>({});
  const asin = getAsin(selectedProduct);
  const rat = getRat(product, mockData);
  const reviews = getReviews(mockData);
  const productsToRender = getProductsToRender(product, mockData);
  const uniqueProductsToRender = getUniqueProductsToRender(productsToRender);

  const fetchCategories = async () => {
    const spider = new CategoriesService();
    try {
      if (selectedProduct?.asin.length > 1) {
        const spidersData = await spider.getCategories(selectedProduct?.asin);
        setSpiders(spidersData);
        setScoreMain(spidersData);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedProductToCompare) {
      fetchCategories();
    }
  }, [selectedProductToCompare]);

  const getScoresForAsins = (asins: string[]) => {
    const scores: { [key: string]: number | null } = {};

    asins.forEach((asin) => {
      let found = false;
      for (const spider of spiders) {
        const item = spider.items.find((item) => item.asin === asin);
        if (item) {
          scores[asin] = item.score;
          found = true;
          break;
        }
      }
      if (!found) {
        scores[asin] = null;
      }
    });

    return scores;
  };

  useEffect(() => {
    if (
      spiders &&
      Array.isArray(spiders) &&
      selectedProductToCompare &&
      selectedProductToCompare.length > 0
    ) {
      const asins = selectedProductToCompare.flat().map((p) => p.asin);
      const newRatings = getScoresForAsins(asins);
      setRatings((prevRatings) => ({
        ...prevRatings,
        ...newRatings,
      }));
    }

    if (selectedProduct && selectedProduct.asin && spiders) {
      const score = getScoresForAsins([selectedProduct.asin]);

      setRatings((prevRatings) => ({
        ...prevRatings,
        ...score,
      }));
    }
  }, [selectedProductToCompare, selectedProduct, spiders]);

  const handleReviewScore = async () => {
    const spider = new GraphsService();
    const { date_start, date_end } = getDateRange();
    const spiderDot = await spider.getGraph(asin, date_start, date_end);
    setTotalReviews(spiderDot.length);
  };

  const handleSecondProductReviewScore = async () => {
    if (selectedProductToCompare && selectedProductToCompare.length > 0) {
      const spider = new GraphsService();
      const { date_start, date_end } = getDateRange();

      const allAsin = selectedProductToCompare
        .flat()
        .map((product) => product.asin)
        .filter(Boolean);

      try {
        const results = await Promise.all(
          allAsin.map(async (asin) => {
            const spiderDot = await spider.getGraph(asin, date_start, date_end);
            return { asin, totalReviews: spiderDot.length };
          }),
        );
        const reviewsMap = results.reduce((acc, { asin, totalReviews }) => {
          acc[asin] = totalReviews;
          return acc;
        }, {});

        setTotalReviewsSecondProduct(reviewsMap);
      } catch (error) {
        console.error("Error fetching reviews for the products:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedProductToCompare) {
      handleSecondProductReviewScore();
    }
  }, [selectedProductToCompare]);

  useEffect(() => {
    if (selectedProduct) {
      handleReviewScore();
    }
  }, [selectedProduct]);

  return (
    <div className={styles.product}>
      {selectType === "your" ? (
        <>
          <div className={styles.productCode}>
            <ProductInput
              label={label}
              selectType={selectType}
              productTwo={productTwo}
              type={type}
              onProductSelect={selectedProduct}
            />
            <ProductCode code={product?.asin || mockData.code} />
          </div>
          <div
            className={classNames(
              styles.imgRateContainer,
              styles["product-borderedOne"],
            )}
          >
            <div className={styles.image}>
              <img
                src={
                  product?.image_link ?? product?.picture_url ?? mockData.image
                }
                alt={product?.title ?? mockData.name}
              />
            </div>
            <div className={styles.details}>
              <span className={styles.price}>
                ${product?.price ?? mockData.price}
              </span>
              <div className={styles.rating}>
                <Rating
                  rating={ratings[selectedProduct?.asin] || rat}
                  showRating={false}
                />
                <span className={styles.ratingValue}>
                  {ratings[selectedProduct?.asin]
                    ? ratings[selectedProduct.asin]?.toFixed(1)
                    : rat.toFixed(1)}
                </span>
              </div>
              <span className={styles.ratingsCount}>
                Rating: {totalReviews}
              </span>

              <ul className={styles.reviews}>
                {getStarReviews(reviews).map((review, index) => (
                  <li key={index} className={styles.reviewItem}>
                    <span className={styles.reviewLabel}>{review.label}</span>
                    <div className={styles.reviewBar}>
                      <div
                        className={styles.reviewFill}
                        style={{ width: `${review.value}%` }}
                      ></div>
                    </div>
                    <span className={styles.reviewPercentage}>
                      {review.value}%
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            {(uniqueProductsToRender.length > 0
              ? uniqueProductsToRender
              : mockDataMultySelect
            ).map((item, index) => {
              const asin = item.asin || productAsin || mockData.code;
              const totalReviews =
                totalReviewsSecondProduct &&
                totalReviewsSecondProduct[asin] !== undefined
                  ? totalReviewsSecondProduct[asin]
                  : ".";

              return (
                <div
                  key={index}
                  className={classNames(
                    styles.comptitorProduct,
                    styles["product-bordered"],
                  )}
                >
                  <div
                    className={classNames({
                      [styles[
                        `productVisualRow${uniqueProductsToRender.length || mockDataMultySelect.length}`
                      ]]: true,
                    })}
                  >
                    <img
                      src={
                        item?.image_link ??
                        item?.picture_url ??
                        productImage ??
                        mockData.image
                      }
                      className={classNames(
                        styles.competitorImage,
                        uniqueProductsToRender.length !== 1 &&
                          styles[
                            `competitorImage${uniqueProductsToRender.length}`
                          ],
                      )}
                    />
                    <div className={styles.productInfo}>
                      <div
                        className={classNames(styles.productBetween, {
                          [styles.productBetweenOne]:
                            uniqueProductsToRender.length === 1,
                        })}
                      >
                        <div className={styles.priceAndCode}>
                          <span
                            className={classNames({
                              [styles.priceCompetitor]:
                                uniqueProductsToRender.length === 1,
                              [styles[
                                `priceCompetitor${uniqueProductsToRender.length}`
                              ]]: uniqueProductsToRender.length !== 1,
                            })}
                          >
                            ${item.price ?? productPrice ?? mockData.price}
                          </span>
                          <ProductCode
                            code={item.asin || productAsin || mockData.code}
                          />
                        </div>
                        <div className={styles.rating}>
                          <Rating
                            rating={ratings[item.asin] || rat}
                            showRating={false}
                          />

                          <div className={styles.ratingContainer}>
                            <span className={styles.ratingValue}>
                              {(ratings[item?.asin] || rat).toFixed(1)}
                            </span>
                          </div>
                        </div>

                        <span className={styles.ratingScore}>
                          Rating: {totalReviews}
                        </span>
                        {uniqueProductsToRender.length === 1 && (
                          <>
                            <ul className={styles.reviews}>
                              {getStarReviews(reviews).map((review, index) => (
                                <li key={index} className={styles.reviewItem}>
                                  <span className={styles.reviewLabel}>
                                    {review.label}
                                  </span>
                                  <div className={styles.reviewBar}>
                                    <div
                                      className={styles.reviewFill}
                                      style={{ width: `${review.value}%` }}
                                    ></div>
                                  </div>
                                  <span className={styles.reviewPercentage}>
                                    {review.value}%
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductCard;
