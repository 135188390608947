import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const Range = () => {
  const months = [
    "Dec 2024",
    "Jul 2024",
    "Aug 2024",
    "Sep 2024",
    "Oct 2024",
    "Nov 2024",
    "Dec 2024",
    "Jan 2025",
    "Feb 2025",
    "Mar 2025",
  ];
  const [active, setActive] = useState(4);

  return (
    <div className={styles.range}>
      <div className={styles.monthsLine}>
        {months.map((month, i) => (
          <div
            key={i}
            className={classNames(styles.month, {
              [styles.active]: i === active,
            })}
            onClick={() => setActive(i)}
          >
            {month}
          </div>
        ))}
      </div>
    </div>
  );
};
