import { Table } from "antd";
import "./table.scss";
import classNames from "classnames";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { useEffect, useMemo } from "react";
import { useAntdColumnResize } from "react-antd-column-resize";

interface ExpandableTableProps {
  columns: any[];
  data: any[];
  rowSelection?: any;
  className?: string;
  isLoading?: boolean;
  withCustomScroll?: boolean;
  scroll?: { x?: number | string; y?: number | string };
  withoutBottomPadding?: boolean;
  onRowClick?: (record: any) => void;
  expandable?: {
    expandedRowKeys: React.Key[];
    onExpand: (expanded: boolean, record: any) => void;
    rowExpandable: (record: any) => boolean;
  };
  rowKey?: string;
  adSalesTab?: TypeTab;
  autoHeight?: boolean;
  headerClassName?: boolean;
}
const removeExpand = (adSalesTab?: TypeTab) => {
  document.querySelectorAll(".ant-table-cell-with-append").forEach((el) => {
    if (el.querySelector(".total-sales-cell-pnl")) {
      return;
    }
    if (el.querySelector(".Sales-sales-cell-pnl")) {
      return;
    }
    if (el.textContent?.includes("Total")) {
      el.querySelector("button")?.remove();
    }
  });

  if (
    adSalesTab === "Performance" ||
    adSalesTab === "Ad Type" ||
    adSalesTab === "Funnel" ||
    adSalesTab === "Match type"
  ) {
    document
      .querySelectorAll(".ant-table-row-level-2 .ant-table-cell-with-append")
      .forEach((el) => {
        if (el.querySelector(".total-sales-cell-pnl")) {
          return;
        }
        if (el.querySelector(".Sales-sales-cell-pnl")) {
          return;
        }
        if (el.textContent?.includes("Total")) {
          el.querySelector("button")?.remove();
        }
      });
  } else {
    document
      .querySelectorAll(".ant-table-row-level-1 .ant-table-cell-with-append")
      .forEach((el) => {
        if (el.querySelector(".total-sales-cell-pnl")) {
          return;
        }
        if (el.querySelector(".Sales-sales-cell-pnl")) {
          return;
        }
        if (el.textContent?.includes("Total")) {
          el.querySelector("button")?.remove();
        }
      });
  }
};

export const ExpandableTable = ({
  columns,
  data,
  rowSelection,
  className,
  isLoading,
  withCustomScroll,
  withoutBottomPadding,
  onRowClick,
  scroll = { x: 1000 },
  expandable,
  adSalesTab,
  autoHeight,
  headerClassName,
}: ExpandableTableProps) => {
  const isCheckboxDisabled = (record) => {
    if (typeof record.campaign === "string") {
      return (
        record.campaign?.startsWith("Total") ||
        record.campaign?.startsWith("Grand")
      );
    }
    if (typeof record.performance === "object") {
      return record.performance?.props.children.startsWith("Grand");
    }
    if (typeof record.targeting === "string") {
      return record.targeting?.startsWith("Total");
    }
    if (typeof record.performance === "string") {
      return record.performance?.startsWith("Total");
    }
    if (typeof record.ad_type === "string") {
      return record.ad_type?.startsWith("Total");
    }
    if (typeof record.match_type === "string") {
      return record.match_type?.startsWith("Total");
    }
    if (typeof record.funnel === "string") {
      return record.funnel?.startsWith("Total");
    }
    if (typeof record.campaign_name === "string") {
      return record.campaign_name?.startsWith("Total");
    }
    if (typeof record.target === "string") {
      return record.target?.startsWith("Total");
    }
    if (typeof record.typeAd === "object") {
      return (
        record.typeAd?.props.children.startsWith("Grand") ||
        record.typeAd?.props.children.startsWith("Total")
      );
    }
    if (typeof record.category === "string") {
      return record.category?.startsWith("Total");
    }
    return false;
  };

  const isExpandable = (record) => {
    if (typeof record.campaign === "string") {
      return !(
        record.campaign.startsWith("Total") ||
        record.campaign.startsWith("Grand")
      );
    }
    return true;
  };

  const modifiedExpandable = expandable
    ? {
        ...expandable,
        onExpand: (expanded, record) => {
          if (isExpandable(record)) {
            expandable.onExpand(expanded, record);
          }
          removeExpand(adSalesTab);
        },
        rowExpandable: (record) => isExpandable(record),
      }
    : undefined;

  useEffect(() => {
    removeExpand(adSalesTab);
  }, [adSalesTab, data]);

  const modifiedColumns = useMemo(() => {
    return columns.map((col) => {
      if (col.key === "delta") {
        return {
          ...col,
          className: "delta-column",
          render: (text: any) => <div className="delta-cell">{text}</div>,
        };
      }
      return col;
    });
  }, [columns]);

  const { resizableColumns, components } = useAntdColumnResize(
    () => ({ columns: modifiedColumns }),
    [modifiedColumns],
  );

  const customComponents = {
    ...components,
    header: {
      cell: (headerProps: any) => {
        const defaultStyle = headerClassName
          ? { backgroundColor: "#141414", color: "#ffffff" }
          : {};
        return (
          <th
            {...headerProps}
            style={{ ...headerProps.style, ...defaultStyle }}
            className={classNames(
              headerProps.className,
              headerClassName && "customHeader",
            )}
          >
            {headerProps.children}
          </th>
        );
      },
    },
  };

  return (
    <Table
      key={"key"}
      columns={resizableColumns}
      dataSource={data}
      loading={isLoading}
      expandable={modifiedExpandable}
      components={customComponents}
      rowSelection={
        rowSelection && {
          ...rowSelection,
          checkStrictly: false,
          getCheckboxProps: (record) => ({
            disabled: isCheckboxDisabled(record),
          }),
        }
      }
      scroll={scroll}
      pagination={false}
      className={classNames(
        "expandable-table",
        withCustomScroll && "custom-scroll",
        withoutBottomPadding && "without-bottom-padding",
        headerClassName === true && "custom-scroll-bg",
        className,
        autoHeight && "auto-height",
      )}
      onRow={(record) => ({
        onClick: () => {
          onRowClick?.(record);
        },
      })}
    />
  );
};
