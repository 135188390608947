import React from "react";
import classNames from "classnames";
import { renderElement } from "../../utils";
import styles from "../../styles.module.scss";
import { CanvaElement } from "src/store/canva/canva.types";
import Dots from "@assets/icons/Dots";
import { GridStack } from "gridstack";
import { ELEMENT_ID } from "src/store/canva/element-id";

interface GridElementProps {
  element: CanvaElement;
  onRemove: (id: string) => void;
  gridRef: React.RefObject<GridStack>;
  asin: string[];
  isWhatIf: boolean;
  onDoubleClick?: (id: string) => void;
  zoom?: number;
}

export const GridElement: React.FC<GridElementProps> = ({
  element,
  onRemove,
  gridRef,
  asin,
  isWhatIf,
  onDoubleClick,
  zoom,
}) => (
  <div
    id={`grid-item-${element.id}`}
    className={classNames(styles.gridItem, "grid-stack-item")}
    data-id={element.id}
    data-gs-id={element.id}
    gs-y={element.y}
    gs-x={element.x}
    gs-w={element.size?.width}
    gs-h={element.size?.height}
    onDoubleClick={() => onDoubleClick?.(element.id)}
  >
    <div
      className={classNames(
        styles.item,
        "grid-stack-item-content",
        element.elementId === ELEMENT_ID.LAUNCH_PRODUCT && styles.showOverflowX,
      )}
    >
      <div className={styles.itemHeader}>
        <div className={classNames(styles.icon, "drag-handle")}>
          <Dots />
        </div>
        <button
          className={styles.deleteButton}
          onClick={() => onRemove(element.id)}
        >
          x
        </button>
      </div>
      {renderElement(
        element.elementId,
        asin,
        isWhatIf,
        element.id,
        gridRef,
        zoom,
      )}
    </div>
  </div>
);
