export const categoryMock = [
  "Gummy",
  "Gummy Bears",
  "Gummy Candy",
  "Gummies",
  "Gummy Molds",
  "Gum Soft Picks",
  "Gummy Vitamins",
  "Gumball Machine",
  "Gummy Worms",
  "Gym Bag",
  "Gym Towels",
];
