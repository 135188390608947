import { create } from "zustand";

interface ComputedTimeRangesState {
  computedTimeRange: any[];
  setComputedTimeRanges: (ranges: any[]) => void;
}

export const useComputedTimeRangesStore = create<ComputedTimeRangesState>(
  (set) => ({
    computedTimeRange: [],
    setComputedTimeRanges: (ranges) => set({ computedTimeRange: ranges }),
  }),
);
