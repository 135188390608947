export const data = [
  {
    rank: 1,
    sales: 50000.0,
    ctr: 25.0,
    cvr: 23.0,
    adsCTR: 15.23,
    adsCVR: 18.45,
    adsSales: 18.45,
  },
  {
    rank: 2,
    sales: 48511.36,
    ctr: 24.75,
    cvr: 22.72,
    adsCTR: 14.75,
    adsCVR: 17.89,
    adsSales: 15.0,
  },
  {
    rank: 3,
    sales: 47027.8,
    ctr: 24.5,
    cvr: 22.45,
    adsCTR: 14.5,
    adsCVR: 17.5,
    adsSales: 12.0,
  },
  {
    rank: 4,
    sales: 45549.14,
    ctr: 24.26,
    cvr: 22.17,
    adsCTR: 14.26,
    adsCVR: 17.17,
    adsSales: 10.0,
  },
  {
    rank: 5,
    sales: 44075.21,
    ctr: 24.02,
    cvr: 21.9,
    adsCTR: 14.02,
    adsCVR: 16.9,
    adsSales: 9.0,
  },
  {
    rank: 6,
    sales: 42605.86,
    ctr: 23.78,
    cvr: 21.63,
    adsCTR: 13.78,
    adsCVR: 16.63,
    adsSales: 8.0,
  },
  {
    rank: 7,
    sales: 41140.92,
    ctr: 23.54,
    cvr: 21.36,
    adsCTR: 13.54,
    adsCVR: 16.36,
    adsSales: 7.0,
  },
  {
    rank: 8,
    sales: 39680.21,
    ctr: 23.31,
    cvr: 21.1,
    adsCTR: 13.31,
    adsCVR: 16.1,
    adsSales: 6.0,
  },
  {
    rank: 9,
    sales: 38223.56,
    ctr: 23.07,
    cvr: 20.84,
    adsCTR: 13.07,
    adsCVR: 15.84,
    adsSales: 5.0,
  },
  {
    rank: 10,
    sales: 36770.82,
    ctr: 22.84,
    cvr: 20.57,
    adsCTR: 12.84,
    adsCVR: 15.57,
    adsSales: 4.0,
  },
  {
    rank: 11,
    sales: 35321.82,
    ctr: 22.61,
    cvr: 20.31,
    adsCTR: 12.61,
    adsCVR: 15.31,
    adsSales: 3.0,
  },
  {
    rank: 12,
    sales: 33876.41,
    ctr: 22.39,
    cvr: 20.06,
    adsCTR: 12.39,
    adsCVR: 15.06,
    adsSales: 2.0,
  },
  {
    rank: 13,
    sales: 32434.42,
    ctr: 22.16,
    cvr: 19.8,
    adsCTR: 12.16,
    adsCVR: 14.8,
    adsSales: 1.0,
  },
  {
    rank: 14,
    sales: 30995.7,
    ctr: 21.94,
    cvr: 19.55,
    adsCTR: 11.94,
    adsCVR: 14.55,
    adsSales: 20.0,
  },
  {
    rank: 15,
    sales: 29560.09,
    ctr: 21.72,
    cvr: 19.3,
    adsCTR: 11.72,
    adsCVR: 14.3,
    adsSales: 19.0,
  },
  {
    rank: 16,
    sales: 28127.43,
    ctr: 21.5,
    cvr: 19.05,
    adsCTR: 11.5,
    adsCVR: 14.05,
    adsSales: 18.0,
  },
  {
    rank: 17,
    sales: 26697.57,
    ctr: 21.28,
    cvr: 18.8,
    adsCTR: 11.28,
    adsCVR: 13.8,
    adsSales: 17.0,
  },
  {
    rank: 18,
    sales: 25270.36,
    ctr: 21.06,
    cvr: 18.55,
    adsCTR: 11.06,
    adsCVR: 13.55,
    adsSales: 16.0,
  },
  {
    rank: 19,
    sales: 23845.64,
    ctr: 20.85,
    cvr: 18.31,
    adsCTR: 10.85,
    adsCVR: 13.31,
    adsSales: 15.0,
  },
  {
    rank: 20,
    sales: 22423.25,
    ctr: 20.63,
    cvr: 18.06,
    adsCTR: 10.63,
    adsCVR: 13.06,
    adsSales: 14.0,
  },
  {
    rank: 21,
    sales: 21003.05,
    ctr: 20.42,
    cvr: 17.82,
    adsCTR: 10.42,
    adsCVR: 12.82,
    adsSales: 13.0,
  },
  {
    rank: 22,
    sales: 19584.87,
    ctr: 20.21,
    cvr: 17.58,
    adsCTR: 10.21,
    adsCVR: 12.58,
    adsSales: 12.0,
  },
  {
    rank: 23,
    sales: 18168.56,
    ctr: 20.0,
    cvr: 17.34,
    adsCTR: 10.0,
    adsCVR: 12.34,
    adsSales: 11.0,
  },
  {
    rank: 24,
    sales: 16753.96,
    ctr: 19.8,
    cvr: 17.11,
    adsCTR: 9.8,
    adsCVR: 12.11,
    adsSales: 10.0,
  },
  {
    rank: 25,
    sales: 15340.92,
    ctr: 19.59,
    cvr: 16.87,
    adsCTR: 9.59,
    adsCVR: 11.87,
    adsSales: 9.0,
  },
  {
    rank: 26,
    sales: 13929.28,
    ctr: 19.39,
    cvr: 16.64,
    adsCTR: 9.39,
    adsCVR: 11.64,
    adsSales: 8.0,
  },
  {
    rank: 27,
    sales: 12518.89,
    ctr: 19.18,
    cvr: 16.4,
    adsCTR: 9.18,
    adsCVR: 11.4,
    adsSales: 7.0,
  },
  {
    rank: 28,
    sales: 11109.6,
    ctr: 18.98,
    cvr: 16.17,
    adsCTR: 8.98,
    adsCVR: 11.17,
    adsSales: 6.0,
  },
  {
    rank: 29,
    sales: 9701.25,
    ctr: 18.78,
    cvr: 15.94,
    adsCTR: 8.78,
    adsCVR: 10.94,
    adsSales: 5.0,
  },
  {
    rank: 30,
    sales: 8293.7,
    ctr: 18.58,
    cvr: 15.71,
    adsCTR: 8.58,
    adsCVR: 10.71,
    adsSales: 4.0,
  },
  {
    rank: 31,
    sales: 6886.78,
    ctr: 18.39,
    cvr: 15.49,
    adsCTR: 8.39,
    adsCVR: 10.49,
    adsSales: 3.0,
  },
  {
    rank: 32,
    sales: 5480.35,
    ctr: 18.19,
    cvr: 15.26,
    adsCTR: 8.19,
    adsCVR: 10.26,
    adsSales: 2.0,
  },
  {
    rank: 33,
    sales: 4074.26,
    ctr: 18.0,
    cvr: 15.04,
    adsCTR: 8.0,
    adsCVR: 10.04,
    adsSales: 1.0,
  },
  {
    rank: 34,
    sales: 2668.36,
    ctr: 17.81,
    cvr: 14.81,
    adsCTR: 7.81,
    adsCVR: 9.81,
    adsSales: 20.0,
  },
  {
    rank: 35,
    sales: 1262.5,
    ctr: 17.62,
    cvr: 14.59,
    adsCTR: 7.62,
    adsCVR: 9.59,
    adsSales: 19.0,
  },
  {
    rank: 36,
    sales: 856.55,
    ctr: 17.43,
    cvr: 14.37,
    adsCTR: 7.43,
    adsCVR: 9.37,
    adsSales: 18.0,
  },
  {
    rank: 37,
    sales: 450.35,
    ctr: 17.24,
    cvr: 14.15,
    adsCTR: 7.24,
    adsCVR: 9.15,
    adsSales: 17.0,
  },
  {
    rank: 38,
    sales: 44.06,
    ctr: 17.05,
    cvr: 13.93,
    adsCTR: 7.05,
    adsCVR: 8.93,
    adsSales: 16.0,
  },
  {
    rank: 39,
    sales: 23.55,
    ctr: 16.87,
    cvr: 13.71,
    adsCTR: 6.87,
    adsCVR: 8.71,
    adsSales: 15.0,
  },
  {
    rank: 40,
    sales: 12.99,
    ctr: 16.68,
    cvr: 13.5,
    adsCTR: 6.68,
    adsCVR: 8.5,
    adsSales: 14.0,
  },
  {
    rank: 41,
    sales: 7.16,
    ctr: 16.5,
    cvr: 13.28,
    adsCTR: 6.5,
    adsCVR: 8.28,
    adsSales: 13.0,
  },
  {
    rank: 42,
    sales: 3.95,
    ctr: 16.32,
    cvr: 13.07,
    adsCTR: 6.32,
    adsCVR: 8.07,
    adsSales: 12.0,
  },
  {
    rank: 43,
    sales: 2.11,
    ctr: 16.14,
    cvr: 12.86,
    adsCTR: 6.14,
    adsCVR: 7.86,
    adsSales: 11.0,
  },
  {
    rank: 44,
    sales: 1.14,
    ctr: 15.96,
    cvr: 12.65,
    adsCTR: 5.96,
    adsCVR: 7.65,
    adsSales: 10.0,
  },
  {
    rank: 45,
    sales: 0.62,
    ctr: 15.78,
    cvr: 12.44,
    adsCTR: 5.78,
    adsCVR: 7.44,
    adsSales: 9.0,
  },
  {
    rank: 46,
    sales: 0.34,
    ctr: 15.6,
    cvr: 12.23,
    adsCTR: 5.6,
    adsCVR: 7.23,
    adsSales: 8.0,
  },
  {
    rank: 47,
    sales: 0.17,
    ctr: 15.43,
    cvr: 12.03,
    adsCTR: 5.43,
    adsCVR: 7.03,
    adsSales: 7.0,
  },
  {
    rank: 48,
    sales: 0.09,
    ctr: 15.25,
    cvr: 11.82,
    adsCTR: 5.25,
    adsCVR: 6.82,
    adsSales: 6.0,
  },
  {
    rank: 49,
    sales: 0.05,
    ctr: 15.08,
    cvr: 11.62,
    adsCTR: 5.08,
    adsCVR: 6.62,
    adsSales: 5.0,
  },
  {
    rank: 50,
    sales: 0.02,
    ctr: 14.9,
    cvr: 11.42,
    adsCTR: 4.9,
    adsCVR: 6.42,
    adsSales: 4.0,
  },
];
