import styles from "./styles.module.scss";
import { FormEventHandler, useState } from "react";
import TemplateItem, { Template } from "./Template";
import { Spin } from "antd";
import classNames from "classnames";

interface SelectTemplateWidgetsProps {
  loading: boolean;
  templates: Template[];
  onGenerateImage: (prompt: string[]) => void;
  applyImg: boolean;
  setCustomPrompt: (prompt: string) => void;
  activeImage: string;
  selectedPrompt: string;
  setSelectedPrompt: (prompt: string) => void;
}

const SelectTemplateWidgets = ({
  loading,
  templates,
  onGenerateImage,
  applyImg,
  activeImage,
  setCustomPrompt,
  selectedPrompt,
  setSelectedPrompt,
}: SelectTemplateWidgetsProps) => {
  const handleTemplateSelect = (prompt: string) => {
    setSelectedPrompt(prompt);
    setCustomPrompt(prompt);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const target = event.nativeEvent.target;

    if (!(target instanceof HTMLFormElement)) {
      return;
    }

    const inputs = target.querySelectorAll(
      "input:checked",
    ) as NodeListOf<HTMLInputElement>;

    const checkedTemplates = Array.from(inputs).map((el) => el.value);

    if (checkedTemplates.length === 0) {
      return;
    }

    onGenerateImage(checkedTemplates);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.block}>
      {loading ? (
        <div className={styles.block__loading}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.accordion}>
            {templates.map((template, i) => (
              <TemplateItem
                key={i}
                template={template}
                onSelect={handleTemplateSelect}
              />
            ))}
          </div>
        </>
      )}
    </form>
  );
};

export default SelectTemplateWidgets;
