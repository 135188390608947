import { IWidget } from "@pages/Dashboard/types/dashboard.types";

export const metrics: IWidget[] = [
  {
    id: "1",
    key: "acos",
    name: "ACOS",
    value: "0.24%",
    originalValue: 0.24,
    difference: "-0.0534",
  },
  {
    id: "2",
    key: "monthlySales",
    name: "Monthly Ad Sales",
    value: "$64,090",
    originalValue: 12,
    difference: "-5.34",
  },
];

export const getInputLabel = (metric: IWidget) => {
  switch (metric?.name) {
    case "ACOS":
      return "Enter your target ACOS, %";
    case "Daily Ad Spend":
      return "Enter your daily Ad Spend, $";
    case "Daily Ad Sales":
      return "Enter your monthly Ad Sales, $";
    default:
      return "";
  }
};

export const formatDate = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}.${month}.${year}`;
};
