import { Line, YAxis } from "recharts";

export interface LegendItem {
  title: string;
  squareStyle: React.CSSProperties;
}

export function getLegendData(
  isMarket: boolean,
  metrics: string[],
  metricsColors: { [key: string]: string },
): LegendItem[] {
  if (isMarket) {
    const legendItems: LegendItem[] = [
      {
        title: "Total",
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          backgroundColor: metricsColors["Total"] || "#FE5858",
        },
      },
      {
        title: "Total Forecast",
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          border: `2px dashed ${metricsColors["TotalForecast"] || "#FE5858"}`,
          backgroundColor: metricsColors["TotalForecast"]
            ? metricsColors["TotalForecast"] + "3D"
            : "#FE58583D",
        },
      },
      {
        title: "ROI",
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          backgroundColor: metricsColors["ROI"] || "#5295E0",
        },
      },
      {
        title: "ROI Forecast",
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          border: `2px dashed ${metricsColors["ROIForecast"] || "#5295E0"}`,
          backgroundColor: metricsColors["ROIForecast"]
            ? metricsColors["ROIForecast"] + "3D"
            : "#5295E03D",
        },
      },
    ];

    return legendItems;
  }
  return metrics.map((metric) => ({
    title: metric,
    squareStyle: { backgroundColor: metricsColors[metric] },
  }));
}

export const marketDataWithBrand = [
  {
    date: "1 Jun",
    Total: 3,
    TotalForecast: null,
    ROI: 0.5,
    ROIForecast: null,
    Brand: 2.2,
    BrandForecast: null,
    Asin: 2.5,
    AsinForecast: null,
  },
  {
    date: "2 Jun",
    Total: 2.5,
    TotalForecast: null,
    ROI: 1.2,
    ROIForecast: null,
    Brand: 2.0,
    BrandForecast: null,
    Asin: 2.3,
    AsinForecast: null,
  },
  {
    date: "3 Jun",
    Total: 2.9,
    TotalForecast: null,
    ROI: 0.55,
    ROIForecast: null,
    Brand: 2.8,
    BrandForecast: null,
    Asin: 2.6,
    AsinForecast: null,
  },
  {
    date: "4 Jun",
    Total: 3.2,
    TotalForecast: 3.2,
    ROI: 0.6,
    ROIForecast: 0.6,
    Brand: 3.0,
    BrandForecast: 3.0,
    Asin: 3.1,
    AsinForecast: 3.1,
  },
  {
    date: "5 Jun",
    Total: null,
    TotalForecast: 3.6,
    ROI: null,
    ROIForecast: 0.65,
    Brand: null,
    BrandForecast: 3.4,
    Asin: null,
    AsinForecast: 3.5,
  },
  {
    date: "6 Jun",
    Total: null,
    TotalForecast: 4.0,
    ROI: null,
    ROIForecast: 1.7,
    Brand: null,
    BrandForecast: 3.8,
    Asin: null,
    AsinForecast: 3.9,
  },
  {
    date: "7 Jun",
    Total: null,
    TotalForecast: 3.8,
    ROI: null,
    ROIForecast: 2.68,
    Brand: null,
    BrandForecast: 3.6,
    Asin: null,
    AsinForecast: 3.7,
  },
  {
    date: "8 Jun",
    Total: null,
    TotalForecast: 4.2,
    ROI: null,
    ROIForecast: 0.72,
    Brand: null,
    BrandForecast: 4.0,
    Asin: null,
    AsinForecast: 4.1,
  },
];

export function getExtendedLegend(
  filteredLegend: LegendItem[],
  asinSelected: string,
  brandSelected: string,
): LegendItem[] {
  const legend = [...filteredLegend];
  if (asinSelected && asinSelected.length > 0) {
    legend.push(
      {
        title: asinSelected,
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          backgroundColor: "#6B4DBA",
        },
      },
      {
        title: `${asinSelected} Forecast`,
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          border: "2px dashed #6B4DBA",
          backgroundColor: "#6B4DBA3D",
        },
      },
    );
  }
  if (brandSelected && brandSelected.length > 0) {
    legend.push(
      {
        title: brandSelected,
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          backgroundColor: "#5295E0",
        },
      },
      {
        title: `${brandSelected} Forecast`,
        squareStyle: {
          width: "32px",
          height: "12px",
          borderRadius: "2px 0 0 0",
          border: "2px dashed #5295E0",
          backgroundColor: "#5295E03D",
        },
      },
    );
  }
  return legend;
}

export function renderMarketLines(
  metricsColors: { [key: string]: string },
  isMobile: boolean,
  asinSelected: string,
  brandSelected: string,
  showForecast: boolean,
) {
  const dash = showForecast ? "5 5" : undefined;

  return (
    <>
      <Line
        type="monotone"
        dataKey="Total"
        stroke={metricsColors["Total"] || "#FC6768"}
        strokeWidth={isMobile ? 1 : 2}
        dot={false}
        connectNulls
      />
      <Line
        type="monotone"
        dataKey="TotalForecast"
        stroke={metricsColors["TotalForecast"] || "#FC6768"}
        strokeDasharray={dash}
        strokeWidth={isMobile ? 1 : 2}
        dot={false}
        connectNulls
      />
      {asinSelected && asinSelected.length > 0 ? (
        <>
          <Line
            type="monotone"
            dataKey="Asin"
            stroke="#6B4DBA"
            strokeWidth={isMobile ? 1 : 2}
            dot={false}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey="AsinForecast"
            stroke="#6B4DBA"
            strokeDasharray={dash}
            strokeWidth={isMobile ? 1 : 2}
            dot={false}
            connectNulls
          />
        </>
      ) : brandSelected && brandSelected.length > 0 ? (
        <>
          <Line
            type="monotone"
            dataKey="Brand"
            stroke="#5295E0"
            strokeWidth={isMobile ? 1 : 2}
            dot={false}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey="BrandForecast"
            stroke="#5295E0"
            strokeDasharray={dash}
            strokeWidth={isMobile ? 1 : 2}
            dot={false}
            connectNulls
          />
        </>
      ) : (
        <>
          <Line
            type="monotone"
            dataKey="ROI"
            stroke={metricsColors["ROI"] || "#5295E0"}
            strokeWidth={isMobile ? 1 : 2}
            dot={false}
            connectNulls
          />
          <Line
            type="monotone"
            dataKey="ROIForecast"
            stroke={metricsColors["ROIForecast"] || "#5295E0"}
            strokeDasharray={dash}
            strokeWidth={isMobile ? 1 : 2}
            dot={false}
            connectNulls
          />
        </>
      )}
    </>
  );
}
