import { format } from "date-fns";
import { CustomBar } from "@components/BarChart";
interface RenderProps {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: any;
  dataKey: string;
  activeLabel: string | null;
  showForecast: boolean;
  colorMapping: { [key: string]: { [key: string]: string } };
  index: number;
}
interface DataItem {
  date: string;
  crestSales?: number;
  lumineuxSales?: number;
  inoProSales?: number;
  guruNandaSales?: number;
  keidocSales?: number;
}

export const dataMetrics: DataItem[] = [
  {
    date: "1 Jan",
    crestSales: 1600,
    lumineuxSales: 650,
    inoProSales: 500,
    guruNandaSales: 0,
    keidocSales: 0,
  },
  {
    date: "2 Jan",
    crestSales: 1800,
    lumineuxSales: 420,
    inoProSales: 500,
    guruNandaSales: 0,
    keidocSales: 0,
  },
  {
    date: "3 Jan",
    crestSales: 1650,
    lumineuxSales: 250,
    inoProSales: 900,
    guruNandaSales: 0,
    keidocSales: 0,
  },
  {
    date: "4 Jan",
    crestSales: 1200,
    lumineuxSales: 500,
    inoProSales: 700,
    guruNandaSales: 300,
    keidocSales: 300,
  },
  {
    date: "5 Jan",
    crestSales: 900,
    lumineuxSales: 360,
    inoProSales: 200,
    guruNandaSales: 440,
    keidocSales: 440,
  },
  {
    date: "6 Jan",
    crestSales: 1400,
    lumineuxSales: 450,
    inoProSales: 600,
    guruNandaSales: 500,
    keidocSales: 500,
  },
  {
    date: "7 Jan",
    crestSales: 1300,
    lumineuxSales: 400,
    inoProSales: 800,
    guruNandaSales: 400,
    keidocSales: 400,
  },
];

export const data = [
  {
    date: "1 Jan",
    availableCapital: 1600,
    reorderPoint: 650,
    unitsSold: 500,
  },
  {
    date: "2 Jan",
    availableCapital: 1800,
    reorderPoint: 420,
    unitsSold: 500,
  },
  {
    date: "3 Jan",
    availableCapital: 1650,
    reorderPoint: 250,
    unitsSold: 900,
  },
  {
    date: "4 Jan",
    availableCapital: 1200,
    reorderPoint: 500,
    inTransit: 300,
    unitsSold: 700,
  },
  {
    date: "5 Jan",
    availableCapital: 900,
    reorderPoint: 360,
    inTransit: 440,
    unitsSold: 200,
  },
  {
    date: "6 Jan",
    availableCapital: 1400,
    reorderPoint: 450,
    inTransit: 500,
    unitsSold: 600,
  },
  {
    date: "7 Jan",
    availableCapital: 1300,
    reorderPoint: 400,
    inTransit: 400,
    unitsSold: 800,
  },
];

export const colorMapping: Record<string, Record<string, string>> = {
  "1 Jan": {
    availableCapital: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
  },
  "2 Jan": {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
  },
  "3 Jan": {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
  },
  "4 Jan": {
    availableCapital: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#F19867",
    unitsSold: "#5C5C5A",
  },
  "5 Jan": {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#6B4DBA",
    unitsSold: "#5C5C5A",
  },
  "6 Jan": {
    availableCapital: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#F19867",
    unitsSold: "#5C5C5A",
  },
  "7 Jan": {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#6B4DBA",
    unitsSold: "#5C5C5A",
  },
};

export const nameChoosedMapping: Record<string, boolean> = {
  availableCapital: true,
  reorderPoint: true,
  inTransit: true,
  receiving: true,
  safetyStock: true,
  unitsSold: true,
};

export const legendColorMapping: Record<string, string> = {
  availableCapital: "#5295E0",
  reorderPoint: "#255FA0",
  inTransit: "#F19867",
  receiving: "#6B4DBA",
  safetyStock: "#7AC57B",
  unitsSold: "#5C5C5A",
};

export const brandLegendColorMapping: Record<string, string> = {
  crestSales: "#5295E0",
  lumineuxSales: "#6B4DBA",
  inoProSales: "#80C67A",
  guruNandaSales: "#00F9F4",
  keidocSales: "#FE5858",
};

export function generateDateRangeArray(
  startDate: string | Date,
  endDate: string | Date,
) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];

  const current = new Date(start);

  while (current <= end) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }

  return dates;
}

export const generateData = (
  startDate: Date,
  endDate: Date,
  activeChecks: string[],
) => {
  const dates = generateDateRangeArray(startDate, endDate);

  return dates.map((date) => {
    const obj: any = {
      date: format(date, "d MMM"),
    };

    activeChecks.forEach((check) => {
      obj[check] = Math.floor(Math.random() * 1000);
    });

    return obj;
  });
};

const renderCustomizedBar = ({
  x,
  y,
  width,
  height,
  payload,
  dataKey,
  activeLabel,
  showForecast,
  index,
}: RenderProps) => {
  const lastDate = new Date(data[data.length - 1].date);
  const forecastDate = new Date(lastDate);
  forecastDate.setDate(lastDate.getDate() + index);

  const color =
    brandLegendColorMapping[dataKey] ||
    legendColorMapping[dataKey] ||
    "#5C5C5A";

  return (
    <CustomBar
      x={x}
      y={y}
      width={width}
      height={height}
      fill={color}
      showForecast={showForecast}
      activeLabel={activeLabel}
      payload={payload}
      dataKey={dataKey}
    />
  );
};

export default renderCustomizedBar;
