export type TableTab =
  | "Organic Keyword Performance"
  // | "Channel"
  | "Current/Missing Target";

export const TableTabs: TableTab[] = [
  "Organic Keyword Performance",
  // "Channel",
  "Current/Missing Target",
];
