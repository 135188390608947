import React, { useMemo } from "react";
import {
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  YAxis,
  Line,
} from "recharts";
import styles from "./styles.module.scss";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { WidgetClass } from "@services/widget.service";
import { useIsMobile } from "src/utils/isMobile";
import {
  getExtendedLegend,
  getLegendData,
  LegendItem,
  marketDataWithBrand,
  renderMarketLines,
} from "./utils";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useForecastStore } from "src/store/forecast";

interface ChartDimensions {
  margin: { top: number; right: number; left: number; bottom: number };
  tickPadding: { left: number; right: number };
  height: number;
  xAxisDy: number;
}

const getResponsiveDimensions = (isMobile: boolean): ChartDimensions => ({
  margin: isMobile
    ? { top: 20, right: -20, left: -20, bottom: 20 }
    : { top: 20, right: 30, left: 20, bottom: 20 },
  tickPadding: {
    left: isMobile ? 10 : 0,
    right: isMobile ? -10 : 0,
  },
  height: isMobile ? 250 : 350,
  xAxisDy: isMobile ? 5 : 10,
});

interface ChartProps {
  data: any[];
  metrics: string[];
  metricsColors: { [key: string]: string };
  isForecasted: boolean;
  isMarket?: boolean;
  isMarketHeight?: boolean;
}

export const LineChart: React.FC<ChartProps> = ({
  data,
  metrics,
  metricsColors,
  isForecasted,
  isMarket = false,
  isMarketHeight,
}) => {
  const widgetClass = new WidgetClass();
  const isMobile = useIsMobile();
  const dimensions = useMemo(
    () => getResponsiveDimensions(isMobile),
    [isMobile],
  );
  const { brandSelected, asinSelected } = useDashboardStore();
  const chartData = isMarket ? marketDataWithBrand : data;
  const { showForecast } = useForecastStore();

  const dataToRender: LegendItem[] = useMemo(
    () => getLegendData(isMarket, metrics, metricsColors),
    [isMarket, metrics, metricsColors],
  );

  const filteredLegend = useMemo(() => {
    if (isMarket) {
      return dataToRender.filter((item) => {
        if (
          !showForecast &&
          (item.title === "Total Forecast" || item.title === "ROI Forecast")
        ) {
          return false;
        }
        return true;
      });
    } else if (
      (asinSelected && asinSelected.length > 0) ||
      (brandSelected && brandSelected.length > 0)
    ) {
      return dataToRender.filter((item) => {
        if (item.title === "ROI") return false;
        if (!showForecast && item.title === "ROI Forecast") return false;
        return true;
      });
    }
    return dataToRender;
  }, [dataToRender, brandSelected, asinSelected, isMarket, showForecast]);

  const extendedLegend = useMemo(
    () => getExtendedLegend(filteredLegend, asinSelected, brandSelected),
    [filteredLegend, asinSelected, brandSelected],
  );

  return (
    <div className={styles["sales-performance__chart"]}>
      {(isMarket || isMarketHeight) && (
        <div className={styles.salesTitlesContainer}>
          {isMarket && (
            <div className={styles.salesTitleRow}>
              {extendedLegend.map((item) => (
                <div key={item.title} className={styles.salesTitleItem}>
                  <div
                    className={styles.salesTitleBox}
                    style={item.squareStyle}
                  />
                  <span className={styles.salesTitle}>{item.title}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <ResponsiveContainer
        width={isMarketHeight ? 650 : "100%"}
        height={isMarketHeight ? 415 : "100%"}
        debounce={50}
        style={isMarketHeight ? { marginTop: 80 } : {}}
      >
        <ComposedChart data={chartData} margin={dimensions.margin}>
          <CartesianGrid
            stroke="#1F1F20"
            vertical={false}
            horizontalCoordinatesGenerator={(props) => {
              const { height } = props;
              return Array.from(
                { length: isMobile ? 5 : 10 },
                (_, i) => height * (i / (isMobile ? 4 : 9)),
              );
            }}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            width={60}
            interval={isMobile ? "preserveStartEnd" : 0}
            tick={{
              fill: "#FFFFFF",
              dy: dimensions.xAxisDy,
              fontSize: isMobile ? 10 : 12,
            }}
            tickLine={false}
          />
          <Tooltip
            content={({ active, payload, label }) => (
              <CustomTooltip
                active={active}
                payload={payload}
                colorMetric={metricsColors}
                label={label}
              />
            )}
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
          />
          {isMarket
            ? renderMarketLines(
                metricsColors,
                isMobile,
                asinSelected,
                brandSelected,
                showForecast,
              )
            : metrics.map((metric, index) => {
                const yAxisId = metric;
                const orientation = index % 2 === 0 ? "left" : "right";
                const tickColor = metricsColors[metric];
                return (
                  <React.Fragment key={metric}>
                    <YAxis
                      yAxisId={yAxisId}
                      orientation={orientation}
                      stroke={tickColor}
                      tickFormatter={(value: number) =>
                        widgetClass.formatValue(metric, value)
                      }
                      axisLine={false}
                      tickLine={false}
                      domain={["auto", "auto"]}
                      tickCount={isMobile ? 5 : 11}
                      tick={{
                        fill: tickColor,
                        dx: dimensions.tickPadding[orientation],
                        fontSize: isMobile ? 10 : 12,
                      }}
                      width={isMobile ? 35 : 60}
                    />
                    <Line
                      yAxisId={metric}
                      type="monotone"
                      dataKey={metric}
                      stroke={metricsColors[metric]}
                      strokeWidth={isMobile ? 1 : 2}
                      strokeDasharray={isForecasted ? "5 5" : "0"}
                      dot={false}
                      isAnimationActive={false}
                    />
                  </React.Fragment>
                );
              })}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
