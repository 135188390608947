import { generateDays } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { SalesPerformanceApi } from "@services/sales-performance/sales-performance.api";
import { format } from "date-fns";

export type Checks = {
  sales: boolean;
  spend: boolean;
  impressions: boolean;
  ctr: boolean;
  cvr: boolean;
  cpc: boolean;
  acos: boolean;
  roas: boolean;
  orders: boolean;
  unitSolds: boolean;
  clicks: boolean;
  tacos: boolean;
  organicRank?: boolean;
  searchTermAdImpressionRank?: boolean;
  timeInBudget?: boolean;
  tosAdImpressionShare?: boolean;
  organicCTR?: boolean;
  organicCVR?: boolean;
  organicSales?: boolean;
};

export const lineStyle = {
  strokeWidth: 2,
  dot: false,
  activeDot: false,
  xAxisId: "lineAxis",
};

export const areaStyle = {
  strokeWidth: 2,
  strokeDasharray: "3 3",
};

export const getInterval = (length: number) => {
  if (length > 50) return Math.ceil(length / 25);
  if (length > 25) return 1;
  return 0;
};

export const getTickProps = (length: number) => {
  if (length > 20) {
    return { angle: -45, textAnchor: "end" };
  }
  return {};
};
export const formatThousands = (tick) => {
  if (tick < 1000) return tick;
  return `${(tick / 1000).toFixed(1)}k`;
};

export const getData = async (
  dateRange: { startDate: Date; endDate: Date },
  checks: Partial<Checks>,
) => {
  const { startDate, endDate } = dateRange;
  const startDateString = startDate.toISOString().split("T")[0];
  const endDateString = endDate.toISOString().split("T")[0];

  const salesPerformanceApi = new SalesPerformanceApi();

  const data = {
    sales: null,
    spend: null,
    impressions: null,
    ctr: null,
    cvr: null,
    cpc: null,
    acos: null,
    roas: null,
    unitSolds: null,
    clicks: null,
    //organic
    organicCTR: null,
    organicCVR: null,
    organicSales: null,
  };

  const promises: Promise<any>[] = [];
  const keys: string[] = [];

  for (const key of Object.keys(checks)) {
    if (checks[key]) {
      let promise: Promise<any> | null = null;
      switch (key) {
        case "sales":
          promise = salesPerformanceApi.getTotalSales(
            startDateString,
            endDateString,
          );
          break;
        case "spend":
          promise = salesPerformanceApi.getAdSpend(
            startDateString,
            endDateString,
          );
          break;
        case "impressions":
          promise = salesPerformanceApi.getAdImpressions(
            startDateString,
            endDateString,
          );
          break;
        case "ctr":
          promise = salesPerformanceApi.getAdCtr(
            startDateString,
            endDateString,
          );
          break;
        case "cvr":
          promise = salesPerformanceApi.getAdCvr(
            startDateString,
            endDateString,
          );
          break;
        case "cpc":
          promise = salesPerformanceApi.getAdCpc(
            startDateString,
            endDateString,
          );
          break;
        case "acos":
          promise = salesPerformanceApi.getAdAcos(
            startDateString,
            endDateString,
          );
          break;
        case "roas":
          promise = salesPerformanceApi.getAdRoas(
            startDateString,
            endDateString,
          );
          break;
        case "unitSolds":
          promise = salesPerformanceApi.getAdUnitsSold(
            startDateString,
            endDateString,
          );
          break;
        case "clicks":
          promise = salesPerformanceApi.getAdClicks(
            startDateString,
            endDateString,
          );
          break;
        //organic
        case "organicCTR":
          promise = salesPerformanceApi.getAdClicks(
            startDateString,
            endDateString,
          );
          break;
        case "organicCVR":
          promise = salesPerformanceApi.getAdClicks(
            startDateString,
            endDateString,
          );
          break;
        case "organicSales":
          promise = salesPerformanceApi.getAdClicks(
            startDateString,
            endDateString,
          );
          break;
        default:
          break;
      }
      if (promise) {
        promises.push(promise);
        keys.push(key);
      }
    }
  }

  const results = await Promise.all(promises);

  keys.forEach((key, index) => {
    data[key] = results[index];
  });

  return {
    date: generateDays(startDate, endDate),
    ...data,
  };
};

export const getMockDataRank = () => {
  let adSales = 200;
  let sales = 30;
  let spend = 50;
  let impressions = 500;
  let ctr = 5;
  let cvr = 3;
  let cpc = 2;
  let acos = 10;
  let roas = 3;
  let orders = 5;
  let unitSolds = 7;
  let clicks = 50;
  let adsCvr = 5;

  let organicCTR = 3;
  let organicCVR = 8;
  let organicSales = 20;

  return Array.from({ length: 50 }, (_, i) => {
    const rank = 50 - i;
    adSales += Math.floor(Math.random() * 40 - 20);
    if (adSales < 0) adSales = 0;

    sales += Math.floor(Math.random() * 10 - 5);
    if (sales < 0) sales = 0;

    spend += Math.floor(Math.random() * 10 - 5);
    if (spend < 0) spend = 0;

    impressions += Math.floor(Math.random() * 50 - 25);
    if (impressions < 0) impressions = 0;

    ctr += Math.random() * 0.4 - 0.2;
    if (ctr < 0) ctr = 0;

    cvr += Math.random() * 0.3 - 0.15;
    if (cvr < 0) cvr = 0;

    cpc += Math.random() * 0.3 - 0.15;
    if (cpc < 0) cpc = 0;

    acos += Math.random() * 2 - 1;
    if (acos < 0) acos = 0;

    roas += Math.random() * 0.2 - 0.1;
    if (roas < 0) roas = 0;

    orders += Math.floor(Math.random() * 5 - 2);
    if (orders < 0) orders = 0;

    unitSolds += Math.floor(Math.random() * 5 - 2);
    if (unitSolds < 0) unitSolds = 0;

    clicks += Math.floor(Math.random() * 10 - 5);
    if (clicks < 0) clicks = 0;

    adsCvr += Math.random() * 0.3 - 0.15;
    if (adsCvr < 0) adsCvr = 0;

    organicCTR += Math.random() * 0.4 - 0.2;
    if (organicCTR < 0) organicCTR = 0;
    organicCVR += Math.random() * 0.3 - 0.15;
    if (organicCVR < 0) organicCVR = 0;
    organicSales += Math.floor(Math.random() * 2);
    if (organicSales < 0) organicSales = 0;

    return {
      rank,
      adSales,
      sales,
      spend,
      impressions,
      ctr: Number(ctr.toFixed(2)),
      cvr: Number(cvr.toFixed(2)),
      cpc: Number(cpc.toFixed(2)),
      acos: Number(acos.toFixed(2)),
      roas: Number(roas.toFixed(2)),
      orders,
      unitSolds,
      clicks,
      adsCvr: Number(adsCvr.toFixed(2)),
      organicCTR: Number(organicCTR.toFixed(2)),
      organicCVR: Number(organicCVR.toFixed(2)),
      organicSales,
      isForecast: false,
      isPastData: false,
    };
  });
};

export const getMockDataDate = () => {
  const today = new Date();
  const dataPoints = 14;

  let adSales = 10;
  let sales = 30;
  let spend = 50;
  let impressions = 500;
  let ctr = 5;
  let cvr = 13;
  let cpc = 2;
  let acos = 10;
  let roas = 3;
  let orders = 5;
  let unitSolds = 7;
  let clicks = 50;
  let adsCvr = 5;
  let organicCTR = 3;
  let organicCVR = 8;
  let organicSales = 20;

  const updateValue = (
    value: number,
    deltaFn: () => number,
    min = 0,
    max?: number,
  ): number => {
    const newValue = value + deltaFn();
    if (newValue < min) return min;
    return max !== undefined ? Math.min(newValue, max) : newValue;
  };

  const mockData: any[] = [];

  for (let i = 0; i < dataPoints; i++) {
    const currentDate = new Date(today);
    currentDate.setDate(today.getDate() - (dataPoints - 1 - i));

    adSales = updateValue(adSales, () => Math.random() * 2 - 1, 0, 25);
    sales = updateValue(sales, () => Math.floor(Math.random() * 2), 0);
    spend = updateValue(spend, () => Math.floor(Math.random() * 10 - 5), 0);
    impressions = updateValue(
      impressions,
      () => Math.floor(Math.random() * 50 - 25),
      0,
    );
    ctr = updateValue(ctr, () => Math.random() * 0.4 - 0.2, 0);
    cvr = updateValue(cvr, () => Math.random() * 2, 0);
    cpc = updateValue(cpc, () => Math.random() * 0.3 - 0.15, 0);
    acos = updateValue(acos, () => Math.random() * 2 - 1, 0);
    roas = updateValue(roas, () => Math.random() * 0.2 - 0.1, 0);
    orders = updateValue(orders, () => Math.floor(Math.random() * 5 - 2), 0);
    unitSolds = updateValue(
      unitSolds,
      () => Math.floor(Math.random() * 5 - 2),
      0,
    );
    clicks = updateValue(clicks, () => Math.floor(Math.random() * 10 - 5), 0);
    adsCvr = updateValue(adsCvr, () => Math.random() * 0.3 - 0.15, 0);
    organicCTR = updateValue(organicCTR, () => Math.random() * 0.4 - 0.2, 0);
    organicCVR = updateValue(organicCVR, () => Math.random() * 2, 0);
    organicSales = updateValue(
      organicSales,
      () => Math.floor(Math.random() * 2),
      0,
    );

    mockData.push({
      date: currentDate.toISOString().split("T")[0],
      adSales: Number(adSales.toFixed(2)),
      sales,
      spend,
      impressions,
      ctr: Number(ctr.toFixed(2)),
      cvr: Number(cvr.toFixed(2)),
      cpc: Number(cpc.toFixed(2)),
      acos: Number(acos.toFixed(2)),
      roas: Number(roas.toFixed(2)),
      orders,
      unitSolds,
      clicks,
      adsCvr: Number(adsCvr.toFixed(2)),
      organicCTR: Number(organicCTR.toFixed(2)),
      organicCVR: Number(organicCVR.toFixed(2)),
      organicSales,
      isForecast: false,
      isPastData: false,
    });
  }

  return mockData;
};
