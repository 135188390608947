import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import SupplyBlocks from "@pages/SupplyChain/components/SupplyChain";
import {
  normalizeTitle,
  Widgets,
} from "@pages/AdsAnalitics/components/Campaigns/components/Widgets";
import {
  generateSecondaryWidgets,
  getWidgetData,
  WidgetData,
} from "@pages/AdsAnalitics/components/Campaigns/utils";
import styles from "./styles.module.scss";
import {
  Checks,
  initialChecksForHideExtra,
} from "@pages/Dashboard/features/SalesPerformance/types";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { getRankFromPercentage } from "../TotalAsinBlock/utils";
import { MarketLevelChart } from "../MarketLevelChart";
import classNames from "classnames";

export const COLORSCHART = ["#fc6762", "#6B4DBA", "#5295E0"];

interface MarketLevelSalesDistributionProps {
  isFullSize?: boolean;
}

const MarketLevelSalesDistribution: React.FC<
  MarketLevelSalesDistributionProps
> = ({ isFullSize = false }) => {
  const [marketShareRank, setMarketShareRank] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(true);
  const [widgetChecks, setWidgetChecks] = useState<Checks>(
    initialChecksForHideExtra,
  );
  const [dataW, setDataW] = useState<WidgetData>();
  const { dateRange, compareType } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    compareType: state.compareType,
  }));
  const { compareWith } = useAdsAnaliticsStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isChecksFixed, setIsChecksFixed] = useState(false);
  const handleCheck = (key: string) => {
    const formattedKey = normalizeTitle(key);
    setWidgetChecks((prev) => ({
      ...prev,
      [formattedKey]: !prev[formattedKey],
    }));
  };
  const handleFixChecks = () => {
    if (isChecksFixed) {
      setIsChecksFixed(false);
      return;
    }
    if (activeChecks > 6) {
      setWidgetChecks({
        sales: true,
        spend: true,
        impressions: true,
        ctr: true,
        cvr: true,
        cpc: true,
        acos: true,
        roas: true,
        unitSolds: true,
        clicks: true,
        organicCTR: true,
        organicCVR: true,
        organicSales: true,
      });
      setIsChecksFixed(true);
    }
  };
  useEffect(() => {
    const fetchWidgetData = async () => {
      setIsLoading(true);
      const widgetData = await getWidgetData(dateRange, compareWith);
      setDataW(widgetData);
      setIsLoading(false);
    };

    fetchWidgetData();
  }, [dateRange, compareWith, widgetChecks]);
  const activeChecks = Object.keys(widgetChecks).filter(
    (key) => widgetChecks[key],
  ).length;
  useEffect(() => {
    const colorMapping: { [key: string]: string } = {};
    Object.keys(widgetChecks).forEach((key, index) => {
      colorMapping[key] = COLORSCHART[index % COLORSCHART.length];
    });
  }, [widgetChecks]);
  return (
    <div
      className={classNames(styles.marketBlock, isFullSize && styles.fullSize)}
    >
      <div className={styles.marketSizeBlockChart}>
        <h1 className={styles.titleMarketLevel}>
          Market Level Sales Distribution
        </h1>
        <div className={styles.supplyBlock}>
          <SupplyBlocks
            blockCount={1}
            customBlockTitle={"Market Share "}
            onMarketShareChange={(percentValue: number) => {
              const rank = getRankFromPercentage(percentValue);
              setMarketShareRank(rank);
            }}
          />
        </div>
        <div className={styles.campaignsChart}>
          {!isLoading ? (
            <Widgets
              checks={widgetChecks}
              setChecks={handleCheck}
              widgetsData={generateSecondaryWidgets(dataW, compareType)}
              hideExtra={true}
              open={isOpenModal}
            />
          ) : (
            <div className={styles.loader}>
              <Spin size="large" />
            </div>
          )}
          <div>
            <MarketLevelChart
              checks={widgetChecks}
              handleFixChecks={handleFixChecks}
              hideLine={true}
              useMockData={true}
              marketShareRank={marketShareRank}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketLevelSalesDistribution;
