import asinSvg from "./images/asin.svg";
import generateSvg from "./images/generate.svg";
import assetsSvg from "./images/assets.svg";
import settingsSvg from "./images/settings.svg";

export const navItems = [
  {
    label: "Your Product Picture",
    icon: asinSvg,
  },
  {
    label: "Generate",
    icon: generateSvg,
  },
  {
    label: "Assets",
    icon: assetsSvg,
  },
  {
    label: "Settings",
    icon: settingsSvg,
  },
];

export const SIZE_LARGE_IMAGE = 600;
