export class NotificationService {
  private baseUrl: string;
  private pollingInterval: number;
  private pollingTimer: NodeJS.Timeout | null = null;

  constructor(pollingInterval: number = 5000) {
    this.baseUrl = import.meta.env.BUILD_API_BASE_URL;
    this.pollingInterval = pollingInterval;
  }

  private async getUserToken(): Promise<string> {
    const token = localStorage.getItem("access_token");
    if (!token) {
      throw new Error("No access token found");
    }
    return token;
  }

  private async request<T>(endpoint: string, options: RequestInit): Promise<T> {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      ...options,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await this.getUserToken()}`,
        ...options.headers,
      },
    });

    if (response.status === 401) {
      window.location.href = "/auth/login";
      throw new Error("Unauthorized");
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  private async get<T>(endpoint: string): Promise<T> {
    return this.request<T>(endpoint, { method: "GET" });
  }

  private async post<T>(endpoint: string, body: any): Promise<T> {
    return this.request<T>(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
    });
  }

  public async waitForTasks(
    taskIds: string[],
    onTaskComplete?: (task: any) => void,
    maxRetries: number = 75,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let attempts = 0;
      const completedTasks = new Set<string>();

      const pollTasks = async () => {
        try {
          let tasks: any[] | null = null;
          try {
            tasks = await this.getNotifications();
          } catch (error) {
            if (error instanceof Error && error.message.includes("404")) {
              console.warn("Notifications endpoint returned 404, retrying...");
            } else {
              console.error("Error polling getNotifications:", error);
            }
          }

          if (Array.isArray(tasks)) {
            taskIds.forEach(async (taskId) => {
              const task = tasks.find((t) => t.payload?.task_id === taskId);

              if (!task) {
                console.log(`Task ${taskId} not found in getNotifications.`);
              } else if (task.kind === "TASK_READY") {
                console.log(`Task ${taskId} completed.`);
                completedTasks.add(taskId);

                try {
                  await this.setNotificationAck(task.uuid);
                  console.log(`Acknowledged notification for task ${taskId}`);
                } catch (ackError) {
                  console.error(
                    `Failed to acknowledge notification for task ${taskId}:`,
                    ackError,
                  );
                }

                if (onTaskComplete) onTaskComplete(task);
              } else if (task.kind === "FAILED") {
                reject(new Error(`Task ${taskId} failed.`));
                return;
              }
            });
          }

          if (completedTasks.size === taskIds.length) {
            console.log("All tasks completed successfully.");
            resolve();
            return;
          }

          if (attempts < maxRetries) {
            attempts++;
            setTimeout(pollTasks, 1000);
          } else {
            reject(new Error(`Polling timed out after ${maxRetries} attempts`));
          }
        } catch (error) {
          console.error("Unexpected error in polling:", error);
          reject(error);
        }
      };

      pollTasks();
    });
  }

  public async getNotifications(): Promise<any[]> {
    return this.get<any[]>(
      "/cng/v1/notifications?limit=10&skip=0&is_ack=false",
    );
  }

  public async setNotificationAck(notificationId: string): Promise<void> {
    try {
      await this.post<void>(`/cng/v1/notifications/${notificationId}/ack`, {});
      console.log(`Successfully acknowledged notification ${notificationId}`);
    } catch (error) {
      console.error(
        `Failed to acknowledge notification ${notificationId}:`,
        error,
      );
    }
  }

  // public async getNotificationById(notificationId: string): Promise<any> {
  //   return this.get<any>(`/cng/v1/notifications/${notificationId}`);
  // }

  // public async countUnreadNotifications(): Promise<number> {
  //   const response = await this.get<{ count: number }>(
  //     "/cng/v1/notifications/count/unread",
  //   );
  //   return response.count;
  // }

  // public startPolling(callback: (notifications: any[]) => void): void {
  //   if (this.pollingTimer) {
  //     clearInterval(this.pollingTimer);
  //   }

  //   this.pollingTimer = setInterval(async () => {
  //     try {
  //       const notifications = await this.getNotifications();
  //       callback(notifications);
  //     } catch (error) {
  //       console.error("Error fetching notifications:", error);
  //     }
  //   }, this.pollingInterval);
  // }

  // public stopPolling(): void {
  //   if (this.pollingTimer) {
  //     clearInterval(this.pollingTimer);
  //     this.pollingTimer = null;
  //   }
  // }
}
