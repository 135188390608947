export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1707 2.26631V3.68431L9.88851 3.68421C11.0875 4.7364 11.8224 6.27625 11.821 7.95405C11.8188 10.5523 10.0515 12.8169 7.53161 13.4503C5.01173 14.0837 2.38363 12.924 1.15314 10.6355C-0.0773204 8.34709 0.404455 5.51513 2.32245 3.76232C2.59342 4.19346 2.97548 4.54368 3.42851 4.77622C2.94741 5.1649 2.55948 5.65641 2.29323 6.21466C2.02699 6.77291 1.88918 7.38372 1.88993 8.00221C1.92483 10.3267 3.81926 12.1928 6.14401 12.1925C8.27345 12.2276 10.0893 10.6572 10.3617 8.54497C10.5653 6.96687 9.85873 5.46275 8.62568 4.59176L8.62558 7.2294H7.20754V2.26631H12.1707ZM4.72601 0.848267C5.50916 0.848267 6.14401 1.48316 6.14401 2.26631C6.14401 3.04946 5.50916 3.68431 4.72601 3.68431C3.94286 3.68431 3.30797 3.04946 3.30797 2.26631C3.30797 1.48316 3.94286 0.848267 4.72601 0.848267Z"
      fill="#5295E0"
    />
  </svg>
);
