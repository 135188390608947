import styles from "./styles.module.scss";
import {
  Line,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import classNames from "classnames";
import { staticData, chartColors, formatCurrency } from "./mock";

export const BrandPerformance = () => {
  return (
    <div className={classNames("chart", "box", styles.container)}>
      <h2 className="chart-title">Brand Performance</h2>
      <div className="chart-legend">
        <div className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: chartColors.unitsSold }}
          ></span>
          <span>Units Sold</span>
        </div>
        <div className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: chartColors.revenue }}
          ></span>
          <span>Revenue</span>
        </div>
      </div>
      <div className="chart-wrapper">
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            width={500}
            height={300}
            data={staticData}
            margin={{ top: 20, right: 30, left: 20, bottom: 0 }}
          >
            <CartesianGrid vertical={false} stroke="#333" opacity={0.1} />
            <XAxis
              dataKey="date"
              stroke="#7C7C78"
              padding={{ left: 20, right: 20 }}
            />
            <Tooltip cursor={{ stroke: "white", strokeDasharray: "4 4" }} />
            <YAxis
              yAxisId="left"
              stroke={chartColors.unitsSold}
              axisLine={false}
              tickLine={false}
              tickCount={10} // 0-10
              domain={[0, 10]}
              tick={{ fill: chartColors.unitsSold }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              stroke="transparent"
              tickCount={11}
              domain={[0, 20000]}
              tickFormatter={formatCurrency}
              tick={{ fill: chartColors.revenue }}
            />
            <Line
              yAxisId="left"
              type="monotone"
              dataKey="unitsSold"
              stroke={chartColors.unitsSold}
              strokeWidth={2}
              dot={false}
              activeDot={{
                r: 5,
                fill: "#fff",
                stroke: chartColors.unitsSold,
                strokeWidth: 2,
              }}
            />
            <Line
              yAxisId="right"
              type="monotone"
              dataKey="revenue"
              stroke={chartColors.revenue}
              strokeWidth={2}
              dot={false}
              activeDot={{
                r: 5,
                fill: "#fff",
                stroke: chartColors.revenue,
                strokeWidth: 2,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
