import { useState, useEffect } from "react";
import { SalesPerformanceService } from "@services/sales-performance/sales-performance.service";
import {
  ChartDataPoint,
  MetricsState,
  ColorMapping,
  COLORS_CHART,
} from "./sales-performance.types";

export const useSalesPerformanceChart = (
  dateRange: { startDate: Date; endDate: Date },
  checks: MetricsState,
) => {
  const [data, setData] = useState<ChartDataPoint[]>([]);
  const [metricsColors, setMetricsColors] = useState<ColorMapping>({});
  const salesPerformanceService = new SalesPerformanceService();

  useEffect(() => {
    const fetchData = async () => {
      if (!dateRange.startDate || !dateRange.endDate) return;

      const activeChecks = Object.entries(checks)
        .filter(([_, isChecked]) => isChecked)
        .map(([key]) => key);

      const chartData = await salesPerformanceService.getChartData(
        activeChecks,
        dateRange.startDate,
        dateRange.endDate,
      );
      setData(chartData);
    };

    fetchData();
  }, [dateRange, checks]);

  useEffect(() => {
    const colorMapping: ColorMapping = {};
    Object.keys(checks).forEach((check, index) => {
      colorMapping[check] = COLORS_CHART[index % COLORS_CHART.length];
    });
    setMetricsColors(colorMapping);
  }, [checks]);

  return { data, metricsColors };
};
