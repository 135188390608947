import { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { CheckWidget } from "@components/CheckWidget";
import { AdsWidget } from "../../utils";
import { Checks } from "../CampaignChart/utils";
import Arrow from "@assets/icons/Arrow";

export const METRICS_OPTIONS = [
  "TACOS",
  "Organic Rank",
  "Search Term Ad Impression Rank",
  "Time in Budget",
  "TOS Ad Impression Share",
];

interface WidgetsProps {
  checks: Checks;
  setChecks: (metricName: string) => void;
  widgetsData?: AdsWidget[];
  hideExtra?: true;
  options?: string[];
  onReplaceWidget?: (title: string, titleToDelete: string) => void;
  open?: boolean;
}

export const normalizeTitle = (title: string) => {
  switch (title) {
    case "Units Sold":
      return "unitSolds";
    case "Search Term Ad Impression Rank":
      return "searchTermAdImpressionRank";
    case "Time in Budget":
      return "timeInBudget";
    case "TOS Ad Impression Share":
      return "tosAdImpressionShare";
    case "Organic CVR":
      return "organicCVR";
    case "Organic CTR":
      return "organicCTR";
    case "Organic Sales":
      return "organicSales";
    default:
      return title.toLowerCase().replace(/\s+/g, "");
  }
};

export const Widgets = ({
  checks,
  setChecks,
  widgetsData,
  hideExtra,
  onReplaceWidget,
  options,
  open,
}: WidgetsProps) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const updateArrowVisibility = () => {
    if (!containerRef.current) return;
    const container = containerRef.current;
    const scrollLeft = container.scrollLeft;
    const clientWidth = container.clientWidth;
    const scrollWidth = container.scrollWidth;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    updateArrowVisibility();
    container.addEventListener("scroll", updateArrowVisibility);
    window.addEventListener("resize", updateArrowVisibility);
    return () => {
      container.removeEventListener("scroll", updateArrowVisibility);
      window.removeEventListener("resize", updateArrowVisibility);
    };
  }, []);

  const scrollRight = () => {
    if (!containerRef.current) return;
    const container = containerRef.current;
    const totalScrollable = container.scrollWidth - container.clientWidth;
    if (totalScrollable <= 0) return;
    const step = totalScrollable / 3;
    container.scrollBy({ left: step, behavior: "smooth" });
  };

  const scrollLeft = () => {
    if (!containerRef.current) return;
    const container = containerRef.current;
    const totalScrollable = container.scrollWidth - container.clientWidth;
    if (totalScrollable <= 0) return;
    const step = totalScrollable / 3;
    container.scrollBy({ left: -step, behavior: "smooth" });
  };

  return (
    <div className={styles.containerWrapper}>
      <div
        ref={containerRef}
        className={classNames(hideExtra ? styles.overlay : styles.grid)}
      >
        {widgetsData.map((card) => (
          <CheckWidget
            key={card.title}
            title={card.title}
            value={card.value.toLocaleString()}
            increase={card.increase.toLocaleString()}
            increaseDirection={card.increaseDirection as "up" | "down"}
            isChecked={checks[normalizeTitle(card.title)]}
            onChange={setChecks}
            color={card.color}
            options={options}
            onReplace={onReplaceWidget}
            hideExtra={hideExtra}
          />
        ))}
      </div>

      {hideExtra && showLeftArrow && !open && (
        <button className={styles.arrowWrapperLeft} onClick={scrollLeft}>
          <Arrow />
        </button>
      )}

      {hideExtra && showRightArrow && !open && (
        <button className={styles.arrowWrapperRight} onClick={scrollRight}>
          <Arrow />
        </button>
      )}
    </div>
  );
};
