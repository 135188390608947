import { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import CustomInput from "@components/СustomInput";
import { useCanvaStore } from "src/store/canva/canva.state";
import classNames from "classnames";
import { useGlobalResetStore } from "src/store/global-reset.store";

export const ReviewsOptimization: React.FC = () => {
  const { isWhatIf } = useCanvaStore();
  const [reviewsScore, setReviewsScore] = useState<number>(0);
  const [reviewsCount, setReviewsCount] = useState<number>(0);
  const { resetTriggered, resetComplete, setHasChanges } =
    useGlobalResetStore();

  const initialValues = useRef({
    score: 0,
    count: 0,
  });

  useEffect(() => {
    if (!isWhatIf) return;

    const hasChanges =
      reviewsScore !== initialValues.current.score ||
      reviewsCount !== initialValues.current.count;

    setHasChanges(hasChanges);
  }, [reviewsScore, reviewsCount, isWhatIf, setHasChanges]);

  useEffect(() => {
    if (resetTriggered && isWhatIf) {
      resetValues();
      resetComplete();
    }
  }, [resetTriggered, isWhatIf, resetComplete]);

  const resetValues = () => {
    setReviewsScore(initialValues.current.score);
    setReviewsCount(initialValues.current.count);
  };

  return (
    <div className={classNames(styles.container, isWhatIf && styles.whatIf)}>
      <h3>Reviews Optimization</h3>
      <div className={styles.input}>
        <CustomInput
          label="Reviews Score"
          value={reviewsScore}
          onChange={(e) => setReviewsScore(+e.target.value)}
          max={5}
          step={0.1}
          blue
          hideActions={!isWhatIf}
        />
      </div>
      <div className={styles.input}>
        <CustomInput
          label="Reviews Count"
          value={reviewsCount}
          onChange={(e) => setReviewsCount(+e.target.value)}
          max={1000}
          step={10}
          blue
          hideActions={!isWhatIf}
        />
      </div>
    </div>
  );
};
