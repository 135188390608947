import classNames from "classnames";
import { TableTab, TableTabs } from "./models";
import styles from "./styles.module.scss";

interface TabsProps {
  activeTab: TableTab;
  setActiveTab: (tab: TableTab) => void;
}

export const Tabs: React.FC<TabsProps> = ({ activeTab, setActiveTab }) => {
  return (
    <div className={styles.tabs}>
      {TableTabs.map((tab) => (
        <button
          key={tab}
          className={classNames(activeTab === tab && styles.active, styles.tab)}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};
