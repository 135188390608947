export enum ELEMENT_ID {
  PRODUCT_DETAILS = "PRODUCT_DETAILS",
  EXTERNAL_PRODUCT_DETAILS = "EXTERNAL_PRODUCT_DETAILS",
  SCORE_REVIEWS = "SCORE_REVIEWS",
  ASPECT_OPTIMIZATION = "ASPECT_OPTIMIZATION",
  CONTENT_OPTIMIZATION = "CONTENT_OPTIMIZATION",
  PRICE_OPTIMIZATION = "PRICE_OPTIMIZATION",
  REVIEWS_OPTIMIZATION = "REVIEWS_OPTIMIZATION",
  EXECUTIVE_SUMMARY = "EXECUTIVE_SUMMARY",
  ADS_SALES_SLIDERS = "ADS_SALES_SLIDERS",
  ADS_SALES = "ADS_SALES",
  ADS_SANKEY = "ADS_SANKEY",
  ORGANIC_SALES = "ORGANIC_SALES",
  FUNNEL = "FUNNEL",
  SUMMARY_AUDIT = "SUMMARY_AUDIT",
  CAMPAIGN_METRICS = "CAMPAIGN_METRICS",
  ORGANIC_KEYWORD_PERFORMANCE = "ORGANIC_KEYWORD_PERFORMANCE",
  ORGANIC_MISSING_TARGET = "ORGANIC_MISSING_TARGET",
  WHAT_IF_CHART = "WHAT_IF_CHART",
  DEMOGRAPHIC_TABLE = "DEMOGRAPHIC_TABLE",
  DEMOGRAPHIC_TYPE = "DEMOGRAPHIC_TYPE",
  TARGITING_PROFILES = "TARGITING_PROFILES",
  PNL_BLOCK = "PNL_BLOCK",
  DEMOGRAPHIC_TYPE_SHARE = "DEMOGRAPHIC_TYPE_SHARE",
  BRAND = "BRAND",
  PRODUCT_PLACEMENT = "PRODUCT_PLACEMENT",
  DATA_ENTRY = "DATA_ENTRY",
  SATURATION_CURVE = "SATURATION_CURVE",
  MARKET_LEVEL_SALES_DISTRIBUTION = "MARKET_LEVEL_SALES_DISTRIBUTION",
  MARKET_SHARE = "MARKET_SHARE",
  SALES_VELICITY = "SALES_VELICITY",
  MARKET_OVERVIEW_BUBBLE = "MARKET_OVERVIEW_BUBBLE",
  MARKET_OVERVIEW = "MARKET_OVERVIEW",
  LAUNCH_PRODUCT = "LAUNCH_PRODUCT",
  LAUNCH_PRODUCT_TABLE = "LAUNCH_PRODUCT_TABLE",
  FUNNEL_MARKET = "FUNNEL_MARKET",
  SALES_PERFORMANCE = "SALES_PERFORMANCE",
  GOAL_SETTING = "GOAL_SETTING",
  FINANCIAL_OVERVIEW = "FINANCIAL_OVERVIEW",
  CASH_FLOW = "CASH_FLOW",
  PRODUCT_COST = "PRODUCT_COST",
  WATERFALL = "WATERFALL",
  TOTAL_SALES = "TOTAL_SALES",
  CONTRIBUTION_PROFIT = "CONTRIBUTION_PROFIT",
  MARKET_SALES_PERFORMANCE = "MARKET_SALES_PERFORMANCE",
  BRAND_SALES_PERFORMANCE = "BRAND_SALES_PERFORMANCE",
}
