import { useEffect, useState } from "react";
import { Button, Card } from "antd";
import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ComposedChart,
  Bar,
  Line,
} from "recharts";
import styles from "./styles.module.scss";
import renderCustomizedBar, {
  colorMapping,
  data as dataMock,
  generateData,
  legendColorMapping,
  nameChoosedMapping,
  brandLegendColorMapping,
  asinLegendColorMapping,
  getInitialData,
  getUsedKeys,
} from "./utils";
import { CustomTooltip } from "@pages/Dashboard/features/SalesPerformance/Chart/CustomTooltip";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import classNames from "classnames";
import { InventoryPerformanceService } from "@services/inventory-performance/inventory-performance.service";
import { DatePeriod } from "@services/inventory-performance/inventory-performance.api";
import PoSalesTable from "@pages/SupplyChain/components/PoSalesTable";
import { SettingsModal } from "@pages/SupplyChain/components/InventoryPerformance/modal";
import { initialChecksBrand } from "@components/SalesPerformance/utils";

const asinKeys = [
  "B07QB8T5SF",
  "B08QWW6V8T",
  "B09M5PXJ86",
  "B0B5HN65QQ",
  "B0BK2SC18T",
  "B0CRDJ8J5P",
  "B0DBQG28PV",
  "B082TPDTM2",
  "B093TSRPQM",
];

interface InventoryPerformanceProps {
  title?: string;
  line?: boolean;
  checks?: { [key: string]: boolean };
  checksBrand?: { [key: string]: boolean };
  dataBrand?: any;
  hideBorder?: boolean;
}

const BrandAsinPerformance: React.FC<InventoryPerformanceProps> = ({
  title = "Market Sales Performance",
  line,
  checks = initialChecksBrand,
  checksBrand,
  dataBrand,
  hideBorder,
}) => {
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [dataPoints, setDataPoints] = useState(nameChoosedMapping);
  const [showForecast, setShowForecast] = useState(false);
  const [isPoModal, setIsPoModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [period, setPeriod] = useState<DatePeriod>("DAY");

  const {
    dateRange,
    asinSelected,
    brandSelected,
    brandName,
    isAsinSelect,
    asinName,
    isBrandSelect,
  } = useDashboardStore();
  const [isLoading, setIsLoading] = useState(false);

  const inventoryService = new InventoryPerformanceService();

  const activeChecks = line ? checksBrand : checks;
  const finalChecks = activeChecks || dataPoints;

  const [data, setData] = useState(() =>
    getInitialData(title, isAsinSelect, line),
  );

  useEffect(() => {
    if (title) {
      setData(getInitialData(title, isAsinSelect, line));
      return;
    }

    if (!dateRange.startDate || !dateRange.endDate) return;

    const fetchData = async () => {
      setIsLoading(true);
      const activeChecksKeys = Object.entries(finalChecks)
        .filter(([_, isChecked]) => isChecked)
        .map(([key]) => key);

      try {
        let newData;
        if (showForecast) {
          const startDate = new Date(dateRange.endDate);
          startDate.setDate(startDate.getDate() + 1);

          const endDate = new Date(dateRange.endDate);
          const datesCount = Math.abs(
            new Date(dateRange.endDate).getDate() -
              new Date(dateRange.startDate).getDate(),
          );
          endDate.setDate(endDate.getDate() + datesCount + 1);

          newData = await inventoryService.getChartData(
            activeChecksKeys,
            startDate,
            endDate,
            period,
            asinSelected,
            brandSelected,
          );
          setData(newData);
        } else {
          newData = await inventoryService.getChartData(
            activeChecksKeys,
            dateRange.startDate,
            dateRange.endDate,
            period,
            asinSelected,
            brandSelected,
          );
          setData(newData);
        }
      } catch (error) {
        console.error("Error fetching inventory data:", error);
        const generated = generateData(
          showForecast ? new Date(dateRange.endDate) : dateRange.startDate,
          showForecast ? new Date(dateRange.endDate) : dateRange.endDate,
          activeChecksKeys,
        );
        setData(generated);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [
    title,
    showForecast,
    dateRange,
    finalChecks,
    asinSelected,
    brandSelected,
    isAsinSelect,
    line,
    period,
  ]);

  const usedKeys = getUsedKeys({
    title,
    line,
    finalChecks,
    isBrandSelect,
    brandSelected,
    isAsinSelect,
    asinSelected,
    brandName,
    asinName,
  });

  return (
    <Card className={classNames(styles.card, hideBorder && styles.hideBorder)}>
      <div className={styles.headerBetween}>
        <h1 className={styles.title}>
          {title ||
            (isPoModal
              ? "PO Sales, Margin & ROI Dynamics"
              : "Inventory Performance")}
        </h1>
      </div>

      {/* Легенда */}
      <div className={styles.legendContainer}>
        {usedKeys.map((key) => (
          <div className={styles.legendItem} key={key}>
            <span
              className={styles.colorDot}
              style={{
                backgroundColor:
                  finalChecks && finalChecks[key]
                    ? "orange"
                    : isAsinSelect
                      ? asinLegendColorMapping[key] ||
                        legendColorMapping[key] ||
                        "#5C5C5A"
                      : brandLegendColorMapping[key] ||
                        legendColorMapping[key] ||
                        "#5C5C5A",
              }}
            />
            <span className={styles.legendText}>
              {WIDGET_METRICS_CONFIG[key]?.label || key}
            </span>
          </div>
        ))}
      </div>

      {!isPoModal ? (
        <div className={styles.chartContainer}>
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart
              data={data}
              margin={{ top: 20, right: 30, left: 0, bottom: 5 }}
              onMouseMove={(state) => {
                if (state.isTooltipActive) {
                  setActiveLabel(state.activeLabel as string);
                } else {
                  setActiveLabel(null);
                }
              }}
              onMouseLeave={() => setActiveLabel(null)}
            >
              <CartesianGrid stroke="#1F1F20" vertical={false} />
              <YAxis
                yAxisId="left"
                tick={{ fill: "white" }}
                tickFormatter={(value) => {
                  if (isAsinSelect) {
                    return value >= 1_000_000
                      ? `$${Math.round(value / 1_000_000)}M`
                      : value;
                  }
                  if (line) {
                    if (value >= 1_000_000) {
                      return `$${Math.round(value / 1_000_000)}M`;
                    } else if (value >= 1_000) {
                      return `$${Math.round(value / 1_000)}k`;
                    }
                    return value;
                  }
                  if (value >= 1_000_000) {
                    return `$${Math.round(value / 1_000_000)}M`;
                  } else if (value >= 1_000) {
                    return `$${Math.round(value / 1_000)}k`;
                  }
                  return value;
                }}
                axisLine={{ stroke: "#1F1F20" }}
                ticks={
                  isAsinSelect
                    ? [0, 5000000, 10000000, 15000000]
                    : line
                      ? [0, 100000, 300000, 500000, 700000]
                      : [0, 10000000, 20000000, 30000000]
                }
                domain={
                  isAsinSelect ? [0, 30000] : line ? [0, 200] : [0, 20000000]
                }
              />
              <YAxis yAxisId="right" hide={true} />
              <XAxis
                dataKey="date"
                tick={{ fill: "white" }}
                axisLine={{ stroke: "#1F1F20" }}
              />
              <Tooltip
                content={({ active, payload, label }) => (
                  <CustomTooltip
                    active={active}
                    payload={payload}
                    colorMetric={legendColorMapping}
                    label={label}
                  />
                )}
                cursor={{ fill: "transparent" }}
              />
              {usedKeys
                .filter((key) => key !== "Reviews Count")
                .map((key) => (
                  <Bar
                    key={key}
                    yAxisId="left"
                    dataKey={key}
                    stackId="a"
                    name={WIDGET_METRICS_CONFIG[key]?.label || key}
                    shape={(props) =>
                      renderCustomizedBar({
                        ...props,
                        activeLabel,
                        showForecast,
                        colorMapping,
                      })
                    }
                    barSize={55}
                  />
                ))}
              {line && (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey="Reviews Count"
                  stroke="#FF0000"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>

          {usedKeys.includes("safetyStock") && (
            <div
              className={classNames(
                styles.borderOverlay,
                showForecast && styles.borderOverlayForecast,
              )}
            />
          )}
        </div>
      ) : (
        <PoSalesTable />
      )}

      <SettingsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        setDataPoints={setDataPoints}
        dataPoints={dataPoints}
        showForecast={showForecast}
        setShowForecast={setShowForecast}
        isPoModal={isPoModal}
        setIsPoModal={setIsPoModal}
      />
    </Card>
  );
};

export default BrandAsinPerformance;
