import { useCallback, useEffect, useRef, useState } from "react";

type SizeEvent = {
  size: string;
  listener: () => void;
};

interface UseContainerSize {
  events?: SizeEvent[];
  zoom?: number;
}

export const useContainerSize = (
  { events, zoom }: UseContainerSize = { events: [], zoom: 1 },
) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number>(0);

  const handleResize = useCallback(() => {
    if (!ref.current) return;

    const currentWidth = ref.current.offsetWidth;
    setWidth(currentWidth);

    if (events) {
      events.forEach(({ size, listener }) => {
        if (size === currentWidth.toString()) {
          listener();
        }
      });
    }
  }, [events]);

  useEffect(() => {
    handleResize();

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, zoom]);

  return { ref, width };
};
