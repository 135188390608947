import { Checkbox, Modal, Switch, Tooltip } from "antd";
import styles from "./styles.module.scss";
import "../modal.scss";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import info from "@assets/icons/i.svg";
import { WidgetMetricKey } from "@services/math/math.service";

interface SettingsModalProps {
  open: boolean;
  title: string;
  availableKeys: WidgetMetricKey[];
  selectedKeys: WidgetMetricKey[];
  onClose: () => void;
  onChange: (keys: WidgetMetricKey[]) => void;
  resetToDefault: () => void;
  onApply: () => void;
  showHistorical?: boolean;
  setShowHistorical?: (show: boolean) => void;
}

export const SettingsModal: React.FC<SettingsModalProps> = ({
  open,
  title,
  availableKeys,
  selectedKeys,
  onClose,
  onChange,
  resetToDefault,
  onApply,
  showHistorical,
  setShowHistorical,
}) => {
  const toggleKey = (key: WidgetMetricKey) => {
    onChange(
      selectedKeys.includes(key)
        ? selectedKeys.filter((k) => k !== key)
        : [...selectedKeys, key],
    );
  };

  return (
    <Modal open={open} onCancel={onClose} className="modal modal-small">
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Choose Metrics</h2>
          <span>Choose metrics for «{title}»</span>
        </div>
        <div className={styles.switchers}>
          <div className={styles.switch}>
            <Switch
              checked={showHistorical}
              onChange={() => setShowHistorical?.(!showHistorical)}
            />
            <span className={styles.switchLabel}>Historical Preview</span>
          </div>
        </div>
        <div className={styles.keys}>
          {availableKeys.map((key) => (
            <div key={key} className={styles.key}>
              <Checkbox
                onChange={() => toggleKey(key)}
                checked={selectedKeys.includes(key)}
              />
              <span>{WIDGET_METRICS_CONFIG[key].label}</span>
              <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info || ""}>
                <img src={info} alt="info" />
              </Tooltip>
            </div>
          ))}
        </div>
        <div className={styles.footer}>
          <button onClick={resetToDefault}>reset to default</button>
          <button onClick={onApply}>Apply</button>
        </div>
      </div>
    </Modal>
  );
};
