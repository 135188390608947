import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface ModalBoxProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  className?: string;
}

const ModalBox: React.FC<ModalBoxProps> = ({
  isOpen,
  onClose,
  title,
  children,
  hideCloseButton = false,
  className,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  if (!isOpen) return null;

  const modalContent = (
    <div
      className={styles.modalOverlay}
      onClick={handleOverlayClick}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className={classNames(styles.modalContent, className)}>
        {!hideCloseButton && (
          <button className={styles.modalCloseButton} onClick={onClose}>
            <span className={styles.closeIcon}></span>
          </button>
        )}
        <h2 className={styles.modalTitle}>{title}</h2>
        {children}
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default ModalBox;
