import styles from "./styles.module.scss";
import { Rating } from "@pages/Dashboard/features/Products/Rating";
import { AccentValue } from "../AccentValue";
import { ItemData } from "../../mock";
import { CollapseInfo } from "@pages/MarketIntelligence/components/CollapseInfo";
import { AsinBtn } from "../asinBtn";

export const Card = ({
  asin,
  title,
  img,
  rating,
  value,
  isVariants,
  setIsVariants,
  isVariant,
  variantsCount,
}: ItemData) => {
  return (
    <div className={styles.productCard}>
      <img src={img} alt="img" />
      <div className={styles.content}>
        <h3>{title}</h3>
        {asin && <AsinBtn asin={asin} />}
        {rating > 0 && <Rating rating={rating} starSize="sm" />}
        {value > 0 && <AccentValue value={value} />}
        {!isVariant && variantsCount && (
          <CollapseInfo
            label="Variants"
            count={variantsCount}
            onClick={() => setIsVariants(!isVariants)}
            rotateArrow={isVariants}
          />
        )}
      </div>
    </div>
  );
};
