import { useRef, useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { AsinsMock } from "../../mock";
import { ChevronLeft, ChevronRight } from "lucide-react";

export const AsinSlider = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const checkScrollPosition = () => {
    if (!scrollContainerRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
  };

  const handleScroll = (direction: "left" | "right") => {
    if (!scrollContainerRef.current) return;

    const scrollAmount = 150;
    const newScrollPosition =
      direction === "left"
        ? scrollContainerRef.current.scrollLeft - scrollAmount
        : scrollContainerRef.current.scrollLeft + scrollAmount;

    scrollContainerRef.current.scrollTo({
      left: newScrollPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    container.addEventListener("scroll", checkScrollPosition);
    return () => container.removeEventListener("scroll", checkScrollPosition);
  }, []);

  return (
    <div className={styles.asinsScroller}>
      {showLeftArrow && (
        <button
          className={styles.scrollArrow}
          onClick={() => handleScroll("left")}
        >
          <ChevronLeft size={20} />
        </button>
      )}
      <div ref={scrollContainerRef} className={styles.chartAsins}>
        {AsinsMock.map((asin) => (
          <div key={asin.name} className={styles.asin}>
            <div
              className={styles.asinColor}
              style={{ backgroundColor: asin.color }}
            ></div>
            <span>{asin.name}</span>
          </div>
        ))}
      </div>
      {showRightArrow && (
        <button
          className={styles.scrollArrow}
          onClick={() => handleScroll("right")}
        >
          <ChevronRight size={20} />
        </button>
      )}
    </div>
  );
};
