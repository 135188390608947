export const data = [
  {
    title: "Total",
    color: "#FF5A5A",
    metrics: [
      { label: "Impressions", value: "3,713,068" },
      { label: "Clicks", value: "3,713,068" },
      { label: "Add to cart", value: "561,311", blockColor: "#FF5A5A" },
      { label: "Units Sold", value: "81,367", blockColor: "#FF5A5A" },
      { label: "", valueEnd: "2.2%" },
    ],
  },
  {
    title: "ASIN",
    color: "#5295E0",
    metrics: [
      { label: "Impressions", value: "10,398" },
      { label: "Clicks", value: "10,398" },
      {
        label: "Add to cart",
        value: "1,159",
        blockColor: "#5295E0",
        leftValue: "1,159",
      },
      {
        label: "Units Sold",
        value: "202",
        blockColor: "#5295E0",
        leftValue: "202",
      },
      { label: "", valueEnd: "1.9%" },
    ],
  },
];

export function getRankFromPercentage(percentage: number): number {
  if (percentage >= 8) return 1;
  if (percentage > 6 && percentage < 7) return 2;
  if (percentage > 5 && percentage <= 6) return 3;
  if (percentage > 4 && percentage <= 5) return 4;
  if (percentage > 3 && percentage <= 4) return 5;
  if (percentage > 2 && percentage <= 3) return 6;
  if (percentage > 1 && percentage <= 2) return 7;
  if (percentage >= 8) return 1;
  return 10;
}
