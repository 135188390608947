import { format } from "date-fns";
import { CustomBar } from "@components/BarChart";

interface RenderProps {
  x: number;
  y: number;
  width: number;
  height: number;
  payload: any;
  dataKey: string;
  activeLabel: string | null;
  showForecast: boolean;
  colorMapping: { [key: string]: { [key: string]: string } };
  index: number;
}

interface DataItem {
  date: string;
  crestSales?: number;
  lumineuxSales?: number;
  InoPro?: number;
  guruNandaSales?: number;
  keidocSales?: number;
  "Amazon Basics"?: number;
  AURAGLOW?: number;
  Eccomas?: number;
  Milumber?: number;
  Mornature?: number;
  Pdoo?: number;
  PERSMAX?: number;
  SNOW?: number;
  "Waving Palms"?: number;
  Zimba?: number;
  "Reviews Count"?: number;
  [asin: string]: number | string | undefined;
}

export const dataMetrics: DataItem[] = [
  {
    date: "Jan",
    "Amazon Basics": 228006,
    crestSales: 3374193,
    lumineuxSales: 2573179,
    InoPro: 228006,
    guruNandaSales: 0,
    keidocSales: 0,
    AURAGLOW: 0,
    Eccomas: 0,
    Milumber: 0,
    Mornature: 0,
    Pdoo: 0,
    PERSMAX: 0,
    SNOW: 0,
    "Waving Palms": 0,
    Zimba: 113482,
    "Reviews Count": 69,
  },
  {
    date: "Feb",
    "Amazon Basics": 17494,
    crestSales: 850355,
    lumineuxSales: 2958116,
    InoPro: 485719,
    guruNandaSales: 0,
    keidocSales: 0,
    AURAGLOW: 0,
    Eccomas: 0,
    Milumber: 0,
    Mornature: 0,
    Pdoo: 0,
    PERSMAX: 0,
    SNOW: 0,
    "Waving Palms": 0,
    Zimba: 78412,
    "Reviews Count": 81,
  },
  {
    date: "Mar",
    "Amazon Basics": 17263,
    crestSales: 1602231,
    lumineuxSales: 4060581,
    InoPro: 815174,
    guruNandaSales: 0,
    keidocSales: 0,
    AURAGLOW: 0,
    Eccomas: 0,
    Milumber: 0,
    Mornature: 0,
    Pdoo: 0,
    PERSMAX: 0,
    SNOW: 0,
    "Waving Palms": 0,
    Zimba: 119465,
    "Reviews Count": 91,
  },
  {
    date: "Apr",
    "Amazon Basics": 17448,
    crestSales: 5362304,
    lumineuxSales: 3882780,
    InoPro: 580914,
    guruNandaSales: 0,
    keidocSales: 0,
    AURAGLOW: 0,
    Eccomas: 0,
    Milumber: 0,
    Mornature: 0,
    Pdoo: 0,
    PERSMAX: 0,
    SNOW: 0,
    "Waving Palms": 0,
    Zimba: 72775,
    "Reviews Count": 144,
  },
  {
    date: "May",
    "Amazon Basics": 18542,
    crestSales: 7697747,
    lumineuxSales: 2713762,
    InoPro: 572656,
    guruNandaSales: 0,
    keidocSales: 0,
    AURAGLOW: 0,
    Eccomas: 0,
    Milumber: 0,
    Mornature: 228374,
    Pdoo: 0,
    PERSMAX: 15627,
    SNOW: 0,
    "Waving Palms": 0,
    Zimba: 84587,
    "Reviews Count": 124,
  },
  {
    date: "Jun",
    "Amazon Basics": 14199,
    crestSales: 6127449,
    lumineuxSales: 2590106,
    InoPro: 563849,
    guruNandaSales: 0,
    keidocSales: 0,
    AURAGLOW: 0,
    Eccomas: 0,
    Milumber: 0,
    Mornature: 286788,
    Pdoo: 0,
    PERSMAX: 31805,
    SNOW: 0,
    "Waving Palms": 0,
    Zimba: 85972,
    "Reviews Count": 93,
  },
  {
    date: "Jul",
    "Amazon Basics": 109894,
    crestSales: 19735968,
    lumineuxSales: 8581047,
    InoPro: 775568,
    guruNandaSales: 188630,
    keidocSales: 35,
    AURAGLOW: 2867,
    Eccomas: 0,
    Milumber: 0,
    Mornature: 278033,
    Pdoo: 0,
    PERSMAX: 34220,
    SNOW: 0,
    "Waving Palms": 0,
    Zimba: 120138,
    "Reviews Count": 98,
  },
];

export const dataMetricsAsin: DataItem[] = [
  {
    date: "Jan",
    B00336EUTK: 45567,
    B0045XE3AG: 193421,
    B00AHAWWO0: 0,
    B01INDUT6A: 40482,
    B077YCC84H: 2198267,
    B07QB8T5RL: 33187,
    B07QB8T5SF: 0,
    B08QWW6V8T: 25780,
    B09M5PXJ86: 0,
    B0B5HN65QQ: 0,
    B0BK2SC18T: 0,
    B0CRDJ8J5P: 0,
    B0DBQG28PV: 0,
    B082TPDTM2: 1083764,
    B093TSRPQM: 1186629,
  },
  {
    date: "Feb",
    B00336EUTK: 85770,
    B0045XE3AG: 115148,
    B00AHAWWO0: 0,
    B01INDUT6A: 35579,
    B077YCC84H: 293812,
    B07QB8T5RL: 19860,
    B07QB8T5SF: 11477,
    B08QWW6V8T: 17494,
    B09M5PXJ86: 94893,
    B0B5HN65QQ: 0,
    B0BK2SC18T: 0,
    B0CRDJ8J5P: 0,
    B0DBQG28PV: 0,
    B082TPDTM2: 1481791,
    B093TSRPQM: 1243598,
  },
  {
    date: "Mar",
    B00336EUTK: 105083,
    B0045XE3AG: 168838,
    B00AHAWWO0: 0,
    B01INDUT6A: 30317,
    B077YCC84H: 597071,
    B07QB8T5RL: 21518,
    B07QB8T5SF: 168518,
    B08QWW6V8T: 17263,
    B09M5PXJ86: 174880,
    B0B5HN65QQ: 205063,
    B0BK2SC18T: 0,
    B0CRDJ8J5P: 0,
    B0DBQG28PV: 0,
    B082TPDTM2: 2385190,
    B093TSRPQM: 1527640,
  },
  {
    date: "Apr",
    B00336EUTK: 123211,
    B0045XE3AG: 136572,
    B00AHAWWO0: 0,
    B01INDUT6A: 20961,
    B077YCC84H: 2927774,
    B07QB8T5RL: 18162,
    B07QB8T5SF: 212827,
    B08QWW6V8T: 17448,
    B09M5PXJ86: 693467,
    B0B5HN65QQ: 610552,
    B0BK2SC18T: 0,
    B0CRDJ8J5P: 0,
    B0DBQG28PV: 0,
    B082TPDTM2: 1960664,
    B093TSRPQM: 1624312,
  },
  {
    date: "May",
    B00336EUTK: 137555,
    B0045XE3AG: 118192,
    B00AHAWWO0: 2404598,
    B01INDUT6A: 14349,
    B077YCC84H: 2622146,
    B07QB8T5RL: 12088,
    B07QB8T5SF: 185474,
    B08QWW6V8T: 18542,
    B09M5PXJ86: 299619,
    B0B5HN65QQ: 661461,
    B0BK2SC18T: 0,
    B0CRDJ8J5P: 94353,
    B0DBQG28PV: 0,
    B082TPDTM2: 1431537,
    B093TSRPQM: 1104003,
  },
  {
    date: "Jun",
    B00336EUTK: 102220,
    B0045XE3AG: 151618,
    B00AHAWWO0: 2594075,
    B01INDUT6A: 8344,
    B077YCC84H: 1365115,
    B07QB8T5RL: 20180,
    B07QB8T5SF: 195431,
    B08QWW6V8T: 14199,
    B09M5PXJ86: 188911,
    B0B5HN65QQ: 432278,
    B0BK2SC18T: 0,
    B0CRDJ8J5P: 116242,
    B0DBQG28PV: 0,
    B082TPDTM2: 1517558,
    B093TSRPQM: 976408,
  },
  {
    date: "Jul",
    B00336EUTK: 88764,
    B0045XE3AG: 239399,
    B00AHAWWO0: 11963371,
    B01INDUT6A: 11492,
    B077YCC84H: 1178187,
    B07QB8T5RL: 24296,
    B07QB8T5SF: 237221,
    B08QWW6V8T: 109894,
    B09M5PXJ86: 1077552,
    B0B5HN65QQ: 1084980,
    B0BK2SC18T: 114047,
    B0CRDJ8J5P: 115062,
    B0DBQG28PV: 161,
    B082TPDTM2: 6156252,
    B093TSRPQM: 2658150,
  },
];

export const brandDataMetricsAsin: DataItem[] = [
  {
    date: "Jan",
    B0D3VHMR3Z: 100000,
    B0CXYSDC2H: 190000,
    B0D3VF1GW2: 3110000,
    B0D3VFXCC2: 595000,
    B0B5HN65QQ: 7150000,
    "Reviews Count": 65,
  },
  {
    date: "Feb",
    B0D3VHMR3Z: 120000,
    B0CXYSDC2H: 700000,
    B0D3VF1GW2: 3130000,
    B0D3VFXCC2: 3905000,
    B0B5HN65QQ: 760000,
    "Reviews Count": 51,
  },
  {
    date: "Mar",
    B0D3VHMR3Z: 190000,
    B0CXYSDC2H: 150000,
    B0D3VF1GW2: 170000,
    B0D3VFXCC2: 7135000,
    B0B5HN65QQ: 9205063,
    "Reviews Count": 91,
  },
  {
    date: "Apr",
    B0D3VHMR3Z: 2110000,
    B0CXYSDC2H: 580000,
    B0D3VF1GW2: 190000,
    B0D3VFXCC2: 5460000,
    B0B5HN65QQ: 8220000,
    "Reviews Count": 81,
  },
  {
    date: "May",
    B0D3VHMR3Z: 230000,
    B0CXYSDC2H: 2500000,
    B0D3VF1GW2: 210000,
    B0D3VFXCC2: 2380000,
    B0B5HN65QQ: 6540000,
    "Reviews Count": 71,
  },
  {
    date: "Jun",
    B0D3VHMR3Z: 250000,
    B0CXYSDC2H: 220000,
    B0D3VF1GW2: 3230000,
    B0D3VFXCC2: 200000,
    B0B5HN65QQ: 4260000,
    "Reviews Count": 48,
  },
  {
    date: "Jul",
    B0D3VHMR3Z: 270000,
    B0CXYSDC2H: 3240000,
    B0D3VF1GW2: 250000,
    B0D3VFXCC2: 1220000,
    B0B5HN65QQ: 7180000,
    "Reviews Count": 81,
  },
];

export const data = [
  {
    date: "Jan",
    availableCapital: 1600,
    reorderPoint: 650,
    unitsSold: 500,
    "Reviews Count": 8,
  },
  {
    date: "Feb",
    availableCapital: 1800,
    reorderPoint: 420,
    unitsSold: 500,
    "Reviews Count": 11,
  },
  {
    date: "Mar",
    availableCapital: 1650,
    reorderPoint: 250,
    unitsSold: 900,
    "Reviews Count": 7,
  },
  {
    date: "Apr",
    availableCapital: 1200,
    reorderPoint: 500,
    inTransit: 300,
    unitsSold: 700,
    "Reviews Count": 15,
  },
  {
    date: "May",
    availableCapital: 900,
    reorderPoint: 360,
    inTransit: 440,
    unitsSold: 200,
    "Reviews Count": 9,
  },
  {
    date: "Jun",
    availableCapital: 1400,
    reorderPoint: 450,
    inTransit: 500,
    unitsSold: 600,
    "Reviews Count": 13,
  },
  {
    date: "Jul",
    availableCapital: 1300,
    reorderPoint: 400,
    inTransit: 400,
    unitsSold: 800,
    "Reviews Count": 17,
  },
];

export const allBrandKeys = [
  "crestSales",
  "lumineuxSales",
  "InoPro",
  "guruNandaSales",
  "keidocSales",
  "Amazon Basics",
  "Milumber",
  "Mornature",
  "PERSMAX",
  "Zimba",
];
export const colorMapping: Record<string, Record<string, string>> = {
  Jan: {
    availableCapital: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
    "Reviews Count": "#FF0000",
  },
  Feb: {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
    "Reviews Count": "#FF0000",
  },
  Mar: {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#5C5C5A",
    unitsSold: "#5C5C5A",
    "Reviews Count": "#FF0000",
  },
  Apr: {
    availableCapital: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#F19867",
    unitsSold: "#5C5C5A",
    "Reviews Count": "#FF0000",
  },
  May: {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#6B4DBA",
    unitsSold: "#5C5C5A",
    "Reviews Count": "#FF0000",
  },
  Jun: {
    availableCapital: "#5295E0",
    reorderPoint: "#255FA0",
    inTransit: "#F19867",
    unitsSold: "#5C5C5A",
    "Reviews Count": "#FF0000",
  },
  Jul: {
    availableCapital: "#5295E0",
    reorderPoint: "#F19867",
    inTransit: "#6B4DBA",
    unitsSold: "#5C5C5A",
    "Reviews Count": "#FF0000",
  },
};

export const nameChoosedMapping: Record<string, boolean> = {
  availableCapital: true,
  reorderPoint: true,
  inTransit: true,
  receiving: true,
  safetyStock: true,
  unitsSold: true,
  "Reviews Count": true,
};

export const legendColorMapping: Record<string, string> = {
  availableCapital: "#5295E0",
  reorderPoint: "#255FA0",
  inTransit: "#F19867",
  receiving: "#6B4DBA",
  safetyStock: "#7AC57B",
  unitsSold: "#5C5C5A",
  "Reviews Count": "#FF0000",
};

export const brandLegendColorMapping: Record<string, string> = {
  crestSales: "#5295E0",
  lumineuxSales: "#6B4DBA",
  InoPro: "#80C67A",
  guruNandaSales: "#00F9F4",
  keidocSales: "#FE5858",
  "Reviews Count": "#FF0000",
  "Amazon Basics": "#7FB3D5",
  AURAGLOW: "#6B4DBA",
  Eccomas: "#B388EB",
  Milumber: "#00C853",
  Mornature: "#D500F9",
  Pdoo: "#FFD600",
  PERSMAX: "#00BCD4",
  SNOW: "#8E24AA",
  "Waving Palms": "#F06292",
  Zimba: "#4CAF50",
};

export const asinLegendColorMapping: Record<string, string> = {
  B00336EUTK: "#FF5733",
  B0045XE3AG: "#33FF57",
  B00AHAWWO0: "#3357FF",
  B01INDUT6A: "#F1C40F",
  B077YCC84H: "#9B59B6",
  B07QB8T5RL: "#E67E22",
  B07QB8T5SF: "#2ECC71",
  B08QWW6V8T: "#3498DB",
  B09M5PXJ86: "#E74C3C",
  B0B5HN65QQ: "#1ABC9C",
  B0BK2SC18T: "#8E44AD",
  B0CRDJ8J5P: "#34495E",
  B0DBQG28PV: "#16A085",
  B082TPDTM2: "#27AE60",
  B093TSRPQM: "#2980B9",
  B0D3VHMR3Z: "#66BB6A",
  B0CXYSDC2H: "#81C784",
  B0D3VF1GW2: "#9CCC65",
  B0D3VFXCC2: "#7CB342",
};

export function generateDateRangeArray(
  startDate: string | Date,
  endDate: string | Date,
) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];
  const current = new Date(start);
  while (current <= end) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }
  return dates;
}

export const generateData = (
  startDate: Date,
  endDate: Date,
  activeChecks: string[],
) => {
  const dates = generateDateRangeArray(startDate, endDate);
  return dates.map((date) => {
    const obj: any = { date: format(date, "MMM") };
    activeChecks.forEach((check) => {
      obj[check] = Math.floor(Math.random() * 1000);
    });
    return obj;
  });
};

const renderCustomizedBar = ({
  x,
  y,
  width,
  height,
  payload,
  dataKey,
  activeLabel,
  showForecast,
  index,
}: RenderProps) => {
  const lastDate = new Date(data[data.length - 1].date);
  const forecastDate = new Date(lastDate);
  forecastDate.setDate(lastDate.getDate() + index);
  const color =
    asinLegendColorMapping[dataKey] ||
    brandLegendColorMapping[dataKey] ||
    legendColorMapping[dataKey] ||
    "#5C5C5A";
  return (
    <CustomBar
      x={x}
      y={y}
      width={width}
      height={height}
      fill={color}
      showForecast={showForecast}
      activeLabel={activeLabel}
      payload={payload}
      dataKey={dataKey}
    />
  );
};

export default renderCustomizedBar;

export const asinKeys = [
  "B07QB8T5SF",
  "B08QWW6V8T",
  "B09M5PXJ86",
  "B0B5HN65QQ",
  "B0BK2SC18T",
  "B0CRDJ8J5P",
  "B0DBQG28PV",
  "B082TPDTM2",
  "B093TSRPQM",
];

export function getInitialData(
  title: string | undefined,
  isAsinSelect: boolean,
  line?: boolean,
) {
  if (!title) {
    return data;
  }

  return isAsinSelect
    ? line
      ? brandDataMetricsAsin
      : dataMetricsAsin
    : dataMetrics;
}

export function getUsedKeys({
  title,
  line,
  finalChecks,
  isBrandSelect,
  brandSelected,
  isAsinSelect,
  asinSelected,
  brandName,
  asinName,
}: {
  title?: string;
  line?: boolean;
  finalChecks: { [key: string]: boolean };
  isBrandSelect: boolean;
  brandSelected: string[];
  isAsinSelect: boolean;
  asinSelected: string[];
  brandName: string[];
  asinName: string[];
}): string[] {
  let usedKeys: string[] = [];

  if (title) {
    if (line) {
      if (isBrandSelect && brandSelected?.length) {
        usedKeys = [...new Set(brandSelected), "Reviews Count"];
      } else if (isAsinSelect && asinSelected?.length) {
        usedKeys = [...new Set(asinSelected), "Reviews Count"];
      } else if (isBrandSelect) {
        usedKeys = [
          ...brandName.map((b) => (b === "InoPro" ? "InoPro" : b)),
          "Reviews Count",
        ];
      } else {
        usedKeys = [...new Set(asinName), "Reviews Count"];
      }
      const metricKeys = Object.keys(finalChecks).filter((k) => finalChecks[k]);
      usedKeys = [...usedKeys, ...metricKeys];
    } else {
      if (isAsinSelect) {
        usedKeys = [...asinKeys];
      } else {
        usedKeys = [...allBrandKeys];
      }
      const metricKeys = Object.keys(finalChecks).filter((k) => finalChecks[k]);
      usedKeys = [...usedKeys, ...metricKeys];
    }
  } else {
    usedKeys = Object.keys(finalChecks).filter(
      (k) => finalChecks[k] && k !== "Reviews Count",
    );
  }

  return usedKeys;
}
