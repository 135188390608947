import { channelData, performanceData, targetData } from "./mock";
import { TableTab } from "./Tabs/models";

export const columns = [
  {
    title: "Search Volume",
    dataIndex: "searchVolume",
    key: "searchVolume",
    width: 140,
  },
  {
    title: "Organic Rank AVG",
    dataIndex: "organicRank",
    key: "organicRank",
    width: 140,
  },
  {
    title: "Relevancy Score",
    dataIndex: "relevancyScore",
    key: "relevancyScore",
    width: 140,
  },
  {
    title: "Impressions",
    dataIndex: "organicImpressions",
    key: "organicImpressions",
    width: 160,
  },
  {
    title: "TOS Impressions share",
    dataIndex: "impressionsShare",
    key: "impressionsShare",
    width: 160,
  },
  {
    title: "Clicks",
    dataIndex: "organicClicks",
    key: "organicClicks",
    width: 140,
  },
  {
    title: "CTR",
    dataIndex: "organicCTR",
    key: "organicCTR",
    width: 140,
  },
  {
    title: "Ad Spend",
    dataIndex: "spend",
    key: "spend",
    width: 140,
  },
  {
    title: "Ad Spend What If",
    dataIndex: "spend_with_nyle",
    key: "spend_with_nyle",
    width: 140,
  },
  {
    title: "CPC",
    dataIndex: "cpc",
    key: "cpc",
    width: 140,
  },
  {
    title: "Units Sold",
    dataIndex: "organicUnitsSold",
    key: "organicUnitsSold",
    width: 180,
  },
  {
    title: "CVR",
    dataIndex: "organicCVR",
    key: "organicCVR",
    width: 140,
  },
  {
    title: "Sales",
    dataIndex: "organicSales",
    key: "organicSales",
    width: 140,
  },
  {
    title: "Sales Share",
    dataIndex: "salesShare",
    key: "salesShare",
    width: 140,
  },
  {
    title: "Sales What If",
    dataIndex: "organicSales_with_nyle",
    key: "organicSales_with_nyle",
    width: 140,
  },
  {
    title: "ACOS",
    dataIndex: "acos",
    key: "acos",
    width: 140,
  },
  {
    title: "ACOS What If",
    dataIndex: "acos_with_nyle",
    key: "acos_with_nyle",
    width: 140,
  },
  {
    title: "TACOS",
    dataIndex: "tacos",
    key: "tacos",
    width: 140,
  },
  {
    title: "TACOS What If",
    dataIndex: "tacos_with_nyle",
    key: "tacos_with_nyle",
    width: 140,
  },
  {
    title: "SVR",
    dataIndex: "svr",
    key: "svr",
    width: 140,
  },
];

export const generateColumns = (tab: TableTab, isWhatIf: boolean) => {
  const columnTitle = {
    title: tab,
    dataIndex: "category",
    key: "category",
    fixed: "left" as const,
    width: 264,
  };

  if (!isWhatIf) {
    return [
      columnTitle,
      ...columns.filter((column) => !column.key.includes("with_nyle")),
    ];
  }

  return [columnTitle, ...columns];
};

export const getData = (tab: TableTab) => {
  switch (tab) {
    case "Organic Keyword Performance":
      return performanceData;
    // case "Channel":
    //   return channelData;
    case "Current/Missing Target":
      return targetData;
    default:
      return [];
  }
};
