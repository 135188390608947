import styles from "./styles.module.scss";

export const SummaryAudit = () => {
  return (
    <div className={styles.container}>
      <h3>Summary of Audit</h3>
      <div className={styles.audit}>
        <ul>
          <li>
            By implementing our optimizations and AI automations, we can achieve
          </li>
          <li>
            ~$1.5M+ of additional annual Ad Sales. As a result of Ad
            optimizations, we can achieve ~ 0.5M of additional annual Organic
            Sales.
          </li>
          <li>I can reduce the current ACOS value from 36% to 31%.</li>
          <li>
            Monthly Ad Spend savings are projected to exceed $38 551 or $0 462
            606 annually - which I would allocate on TOF marketing campaigns to
            generate even more sales.
          </li>
        </ul>
        <h4>Audit Details:</h4>
        <h4>Ad Performance: </h4>
        <div className={styles.audit__details}>
          Based on the selected period <strong> (04 Nov - 07 Jan)</strong> of ad
          reports there are following observations:
          <ul>
            <li>
              Ad Spend of $97 640 representing 31% of your Total Ad Spend, has
              resulted in zero sales.
            </li>
            <li>
              Ad Spend of $129 139 representing 41% of your Total Ad Spend, has
              generated unprofitable Ad Sales of $229 935 with an average ACOS
              of 56%.
            </li>
            <li>
              In contrast, only Ad Spend of $88 345 or 28% of your Total As
              Spend, has generated profitable Ad Sales $657 369 with an ACOS of
              13%.
            </li>
            <li>
              Around 91% of your Sponsored Products Ad Campaigns are
              experiencing ineffective budget management, resulting in depletion
              of funds by the middle of the day. Average Time in Budget 79%
              Estimated Monthly Lost Ad Sales between $119 963 and $299 908 with
              an average ACOS of 31%.
            </li>
            <li>
              There are at least 204 keywords from BROAD, PHRASE and AUTO
              campaigns with ACOS at 13% that had not been utilized in EXACT
              match before. Estimated Monthly Lost Sales $14 565.
            </li>
            <li>
              Estimated Monthly Lost Sales from Brand Tailored Promotion $21
              589.
            </li>
            <li>
              Moreover, there are over 2,500 Missing Keywords which we
              researched as being relevant, but not used in your Ad campaigns,
              which have a total monthly search volume of 2,282,000. The
              estimated monthly Lost Ad Sales from these Missing Keywords is $75
              090.
            </li>
          </ul>
        </div>
        <div>
          <h4>Organic Performance:</h4>
          <ul>
            <li> Estimated Monthly Lost Organic Sales $84 365.</li>
          </ul>
        </div>
        <div>
          <h4>Total Performance:</h4>
          <ul>
            <li>
              Estimated Monthly Lost Total Sales between $204 328 and $384 273.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
