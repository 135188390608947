import { Line, ResponsiveContainer, Area, AreaChart, Tooltip } from "recharts";
import { useId } from "react";
import style from "./styles.module.scss";
import { ToolType } from "@components/ChatBot/types";
import { formatMetricValue } from "@components/ChatBot/ui/message/formatters";

export interface CardWithChartProps {
  title?: string;
  value?: string;
  chartData?: CardChartData[];
  toolType?: ToolType;
}

export type CardChartData = {
  name: string;
  value: number;
};

const mockChartData = [
  { name: "Day 1", value: 1000 },
  { name: "Day 2", value: 1100 },
  { name: "Day 3", value: 1200 },
  { name: "Day 4", value: 1600 },
  { name: "Day 5", value: 1700 },
  { name: "Day 6", value: 1700 },
  { name: "Day 7", value: 1800 },
  { name: "Day 8", value: 1800 },
  { name: "Day 9", value: 1600 },
  { name: "Day 10", value: 1650 },
  { name: "Day 11", value: 1650 },
  { name: "Day 12", value: 2280 },
];

const CustomTooltip = ({ active, payload, toolType }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;
    return (
      <div className={style.tooltip}>
        <p>{`${name}: ${formatMetricValue(toolType, value)}`}</p>
      </div>
    );
  }
  return null;
};

export const CardWithChart = ({
  title,
  value,
  chartData = mockChartData,
  toolType,
}: CardWithChartProps) => {
  const gradientId = `colorGradient-${useId()}`;

  return (
    <div className={style.container}>
      <div className={style.header}>
        {title && <p className={style.title}>{title}</p>}
        {value && <p className={style.value}>{value}</p>}
      </div>
      {chartData && (
        <div className={style.chart}>
          <ResponsiveContainer width="100%" height={80}>
            <AreaChart
              data={chartData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#5295E0" stopOpacity={0.15} />
                  <stop offset="100%" stopColor="#5295E0" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="value"
                stroke="#5295E0"
                fill={`url(#${gradientId})`}
              />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#5295E0"
                strokeWidth={2}
              />
              <Tooltip
                content={(props) => CustomTooltip({ ...props, toolType })}
                cursor={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
