import React, { createContext, useContext, useState } from "react";

interface BackgroundContextType {
  isBlurred: boolean;
  setIsBlurred: (value: boolean) => void;
}

const BackgroundContext = createContext<BackgroundContextType | undefined>(
  undefined,
);

export const BackgroundProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isBlurred, setIsBlurred] = useState(false);

  return (
    <BackgroundContext.Provider value={{ isBlurred, setIsBlurred }}>
      {children}
      {isBlurred && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backdropFilter: "blur(19px)",
            zIndex: "101",
          }}
        />
      )}
    </BackgroundContext.Provider>
  );
};

export const useBackground = () => {
  const context = useContext(BackgroundContext);
  if (!context) {
    throw new Error("useBackground must be used within BackgroundProvider");
  }
  return context;
};
