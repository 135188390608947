import { useState, useEffect, useMemo } from "react";
import { Checkbox, Switch, Tooltip } from "antd";
import classNames from "classnames";
import ModalBox from "@components/ModalBox";
import { nameChoosedMapping } from "../utils";
import { WidgetClass } from "@services/widget.service";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { nameMapping } from "../../FinanceFlowChart/utils";
import styles from "./styles.module.scss";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useTimeRangeStore } from "src/store/daily-week";
import { useComputedTimeRangesStore } from "src/store/useComputedTimeRangesStore";
import { computeTimeRanges } from "../../CashBlock/utils";

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  setDataPoints: (data: Record<string, boolean>) => void;
  dataPoints: Record<string, boolean>;
  showForecast: boolean;
  setShowForecast: (showForecast: boolean) => void;
  isPoModal: boolean;
  setIsPoModal: (isPoModal: boolean) => void;
  isCash?: boolean;
  isTableView?: boolean;
  setIsTableView?: (val: boolean) => void;
}

export const SettingsModal = ({
  open,
  onClose,
  setDataPoints,
  dataPoints,
  showForecast,
  setShowForecast,
  isPoModal,
  setIsPoModal,
  isCash,
  isTableView,
  setIsTableView,
}: SettingsModalProps) => {
  const [forecastChecked, setForecastChecked] = useState(showForecast);
  const [poModalChecked, setPoModalChecked] = useState(isPoModal);
  const [choosedDataPoints, setChoosedDataPoints] = useState(dataPoints);
  const [tableViewLocal, setTableViewLocal] = useState(isTableView);
  const { dateRange } = useDashboardStore();
  const { timeRange, setTimeRange } = useTimeRangeStore();
  const { setComputedTimeRanges } = useComputedTimeRangesStore();

  const computedTimeRanges = useMemo(
    () => computeTimeRanges(dateRange, timeRange, Boolean(isCash)),
    [dateRange, timeRange, isCash],
  );
  useEffect(() => {
    setComputedTimeRanges(computedTimeRanges);
  }, [computedTimeRanges, setComputedTimeRanges]);
  const keys = isCash
    ? Object.keys(nameMapping)
    : Object.keys(nameChoosedMapping);
  const handleApply = () => {
    setShowForecast(forecastChecked);
    setIsPoModal(poModalChecked);
    setDataPoints(choosedDataPoints);
    if (isCash && setIsTableView) {
      setIsTableView(tableViewLocal || false);
    }
    onClose();
  };
  const handleReset = () => {
    if (isCash) {
      const resetData: Record<string, boolean> = {};
      keys.forEach((key) => {
        resetData[key] = true;
      });
      setDataPoints(resetData);
      setTableViewLocal(false);
      setTimeRange("week");
    } else {
      setDataPoints(nameChoosedMapping);
      onClose();
    }
    setForecastChecked(false);
    setPoModalChecked(false);
  };
  return (
    <ModalBox
      title={isCash ? "Cashflow" : "Inventory Performance"}
      isOpen={open}
      onClose={onClose}
      className={classNames(styles.modal, { [styles.cash]: isCash })}
    >
      <p>
        Choose view and metrics for «
        {isCash ? "Cashflow" : "Inventory Performance"}»
      </p>
      {isCash && (
        <>
          <div className={styles.tabContainer}>
            <div
              className={classNames(
                styles.tabItem,
                timeRange === "week" && styles.active,
              )}
              onClick={() => setTimeRange("week")}
            >
              Week
            </div>
            <div
              className={classNames(
                styles.tabItem,
                timeRange === "month" && styles.active,
              )}
              onClick={() => setTimeRange("month")}
            >
              Month
            </div>
            <div
              className={classNames(
                styles.tabItem,
                timeRange === "daily" && styles.active,
              )}
              onClick={() => setTimeRange("daily")}
            >
              Daily
            </div>
          </div>
        </>
      )}
      <div className={styles.switchRow}>
        <div className={styles.switchContainer}>
          <Switch
            checked={forecastChecked}
            onChange={(checked) => setForecastChecked(checked)}
          />
          <span className={styles.switchLabel}>Show Forecast</span>
        </div>
        {isCash && (
          <div className={styles.switchContainer}>
            <Switch
              checked={!!tableViewLocal}
              onChange={(checked) => setTableViewLocal(checked)}
            />
            <span className={styles.switchLabel}>Table View</span>
          </div>
        )}
      </div>
      <div className={styles.points}>
        {keys.map((key) => (
          <div key={key} className={styles.row}>
            <Checkbox
              checked={!!choosedDataPoints[key]}
              onChange={(e) =>
                setChoosedDataPoints((prev) => ({
                  ...prev,
                  [key]: e.target.checked,
                }))
              }
            />
            <span>
              {isCash ? nameMapping[key] : WidgetClass.convertKeyToName(key)}
            </span>
            <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info} placement="right">
              <button>i</button>
            </Tooltip>
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <button className={styles.reset} onClick={handleReset}>
          reset to default
        </button>
        <button className={styles.apply} onClick={handleApply}>
          Apply
        </button>
      </div>
    </ModalBox>
  );
};
