import { OptimisationItem } from "../OptimisationItem";
import { optimizationData } from "./mock";
import LiquidFillGauge from "react-liquid-gauge";
import styles from "./styles.module.scss";
import CustomInput from "@components/СustomInput";
import { OptimisationButton } from "../../OptimisationButton";
import classNames from "classnames";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { updateCroInfo } from "src/utils/updateCROInfo";
import { CROInfoType } from "@services/amazon/products/types";
import { OptimizationService } from "@services/optimization/optimization.services";
import { useGlobalResetStore } from "src/store/global-reset.store";

interface ListingOptimisationProps {
  croInfo?: CROInfoType;
  isWhatIf?: boolean;
  defaultOpen?: boolean;
  disableHide?: boolean;
  asin?: string;
}

export const ListingOptimisation = ({
  isWhatIf,
  croInfo,
  defaultOpen,
  disableHide,
  asin,
}: ListingOptimisationProps) => {
  const nav = useNavigate();
  const [data, setData] = useState(optimizationData);
  const optimizationService = new OptimizationService();
  const { resetTriggered, resetComplete, setHasChanges } =
    useGlobalResetStore();

  const initialData = useRef(optimizationData);

  useEffect(() => {
    if (!isWhatIf) return;

    const hasDataChanged = data.some((currentItem, index) => {
      const initialItem = initialData.current[index];
      return currentItem.percentage !== initialItem.percentage;
    });

    setHasChanges(hasDataChanged);
  }, [data, isWhatIf, setHasChanges]);

  useEffect(() => {
    if (resetTriggered && isWhatIf) {
      setData(optimizationData);
      resetComplete();
    }
  }, [resetTriggered, isWhatIf, resetComplete]);

  useEffect(() => {
    const fetchScore = async () => {
      if (!asin) return;
      try {
        const res = await optimizationService.getCROInfo(asin);
        const updatedData = updateCroInfo([...data], res);
        setData(updatedData);

        if (!isWhatIf) {
          initialData.current = [...updatedData];
        }
      } catch (error) {
        console.error("Error fetching CRO Info:", error);
      }
    };

    fetchScore();
  }, [asin]);

  useEffect(() => {
    if (!croInfo) {
      return;
    }

    const updatedData = updateCroInfo([...data], croInfo);
    setData(updatedData);

    if (!isWhatIf) {
      initialData.current = [...updatedData];
    }
  }, [croInfo, isWhatIf]);

  return (
    <OptimisationItem
      title="Content optimization"
      defaultOpen={defaultOpen}
      disableHide={disableHide}
    >
      <div className={styles.card__container}>
        {data.map((item) =>
          !isWhatIf ? (
            <div className={styles.card} key={item.label}>
              <div className={styles.header}>
                <span className={styles.label}>{item.label}</span>
              </div>
              {item.percentage ? (
                <LiquidFillGauge
                  value={item.percentage}
                  style={{ marginLeft: "-250px" }}
                  height={80}
                  width={800}
                  textSize={1}
                  offsetX={20}
                  textOffsetX={-110}
                  textOffsetY={20}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={3}
                  gradient
                  circleStyle={{ display: "none" }}
                  waveStyle={{
                    fill: item.color,
                    width: "400%",
                    r: "600",
                  }}
                  textStyle={{
                    className: styles.text,
                    fill: "#fff",
                    fontFamily: "Arial",
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAnchor: "start",
                    dominantBaseline: "middle",
                  }}
                  waveTextStyle={{
                    className: styles.text,
                    fill: "#fff",
                    fontFamily: "Arial",
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAnchor: "start",
                    dominantBaseline: "middle",
                  }}
                />
              ) : (
                <span className={styles.notOptimized}>{item.text}</span>
              )}
            </div>
          ) : (
            <div
              className={classNames(styles.card, styles.whatIf)}
              key={item.label}
            >
              <CustomInput
                label={item.label + " score, %"}
                value={item.percentage}
                onChange={(e) => {
                  setData(
                    data.map((el) =>
                      el.label === item.label
                        ? { ...el, percentage: Number(e.target.value) }
                        : el,
                    ),
                  );
                }}
                className={styles.input}
                blue
              />
              <OptimisationButton
                onClick={() => {
                  nav(item.route);
                }}
              />
            </div>
          ),
        )}
      </div>
    </OptimisationItem>
  );
};
