import React, { useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Legend } from "@pages/Dashboard/features/SalesPerformance/Legend";
import { Chart } from "@pages/Dashboard/features/SalesPerformance/Chart";
import MetricModal from "./MetricModal";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { initialChecks } from "./types";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { useIsMobile } from "src/utils/isMobile";
import classNames from "classnames";
import { generateDateRangeArray } from "@components/SalesPerformance/utils";
import { BarChart } from "@components/SalesPerformance/components/BarChart";

export const COLORS = [
  "#80C67A",
  "#1BB08D",
  "#fc6762",
  "#3a3b7c",
  "#6B4DBA",
  "#C8A857",
  "#5295E0",
];

export const SalesPerformance = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeLabel, setActiveLabel] = useState<string | null>(null);
  const [showBarChart, setShowBarChart] = useState(false);
  const [dataSet, setDataSet] = useState<any[]>([]);
  const [isForecasted, setIsForecasted] = useState(false);

  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const [metricsColors, setMetricsColors] = useState<{
    [key: string]: string;
  }>({});
  const [checks, setChecks] = useState(initialChecks);
  const [data, setData] = useState<any>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      const data = await getData(dateRange, checks);
      const transformedData = transformDataToObject(data);
      setData(transformedData);
      setIsDataLoading(false);
    };

    if (Object.values(checks).some((value) => value)) {
      fetchData();
    }
  }, [dateRange, checks]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const colorMapping: { [key: string]: string } = {};
    Object.keys(checks).forEach((check, index) => {
      colorMapping[check] = COLORS[index % COLORS.length];
    });
    setMetricsColors(colorMapping);
  }, [checks]);

  const dateFormatter = useMemo(() => {
    return new Intl.DateTimeFormat("en", {
      day: "numeric",
      month: "short",
    });
  }, []);

  const datesPeriod = useMemo(() => {
    const start = new Date(dateRange.startDate);
    const end = new Date(dateRange.endDate);
    const datesCount =
      Math.abs(end.getTime() - start.getTime()) / (1000 * 3600 * 24);
    if (datesCount <= 7) return "day";
    if (datesCount <= 30) return "week";
    return "month";
  }, [dateRange]);

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const fetchedData = await getData(dateRange, checks);

        const transformedData = transformDataToObject(fetchedData);
        setData(transformedData);
        const salesData: number[] = fetchedData.sales || [];

        const allDates = generateDateRangeArray(
          dateRange.startDate,
          dateRange.endDate,
        );
        const mappedData = allDates.map((dateObj, index) => {
          const dateStr = dateFormatter.format(dateObj);

          return {
            date: dateStr,
            AdsSales: salesData[index] || 0,
            OrganicSales: Array.isArray(fetchedData.OrganicSales)
              ? fetchedData.OrganicSales[index] || 0
              : 0,
          };
        });
        setDataSet(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsDataLoading(false);
      }
    };

    if (
      dateRange &&
      dateRange.startDate &&
      dateRange.endDate &&
      Object.values(checks).some((value) => value)
    ) {
      fetchData();
    }
  }, [dateRange, checks, dateFormatter]);

  const activeChecks = Object.keys(checks).filter((key) => checks[key]);

  const renderChart = () => {
    if (isDataLoading) {
      return (
        <div className={"sales-performance__loader"}>
          <Spin size="large" />
        </div>
      );
    }

    if (showBarChart) {
      return (
        <BarChart
          dataSet={data}
          activeLabel={activeLabel}
          setActiveLabel={setActiveLabel}
          metrics={activeChecks}
          metricsColors={metricsColors}
          isForecasted={isForecasted}
        />
      );
    } else {
      return (
        <Chart
          checks={checks}
          metricsColors={metricsColors}
          data={data}
          isMobile={isMobile}
        />
      );
    }
  };

  return (
    <div className="sales-performance">
      <header className="sales-performance__header">
        <h3 className="sales-performance__title heading-2">
          {t("label.sales_performance")}
        </h3>
      </header>

      <div
        className={classNames("sales-performance__body", {
          performance__body: true,
        })}
      >
        {!isMobile && (
          <Legend
            handleOpenModal={handleOpenModal}
            checks={checks}
            metricsColors={metricsColors}
            buttonIcon={true}
          />
        )}

        {renderChart()}

        {isMobile && (
          <Legend
            handleOpenModal={handleOpenModal}
            checks={checks}
            metricsColors={metricsColors}
          />
        )}
        <MetricModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setChecks={setChecks}
          checks={checks}
          isSwitch={true}
          showBarChart={showBarChart}
          setShowBarChart={setShowBarChart}
        />
      </div>
    </div>
  );
};
