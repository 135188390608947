import { create } from "zustand";

export interface AsinState {
  asins: string[];
  setAsins: (asins: string[]) => void;
}

export const useAsinStore = create<AsinState>((set) => ({
  asins: [],
  setAsins: (asins: string[]) => set({ asins }),
}));
