export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.9422 15.8078C13.0003 15.8659 13.0463 15.9348 13.0777 16.0107C13.1092 16.0866 13.1254 16.1679 13.1254 16.25C13.1254 16.3321 13.1092 16.4134 13.0777 16.4893C13.0463 16.5652 13.0003 16.6341 12.9422 16.6922C12.8841 16.7503 12.8152 16.7963 12.7393 16.8277C12.6634 16.8592 12.5821 16.8753 12.5 16.8753C12.4179 16.8753 12.3366 16.8592 12.2607 16.8277C12.1848 16.7963 12.1159 16.7503 12.0578 16.6922L5.80782 10.4422C5.74971 10.3841 5.70361 10.3152 5.67215 10.2393C5.6407 10.1635 5.62451 10.0821 5.62451 10C5.62451 9.91786 5.6407 9.83653 5.67215 9.76066C5.70361 9.68478 5.74971 9.61585 5.80782 9.55781L12.0578 3.30781C12.1751 3.19053 12.3342 3.12465 12.5 3.12465C12.6659 3.12465 12.8249 3.19053 12.9422 3.30781C13.0595 3.42508 13.1254 3.58414 13.1254 3.75C13.1254 3.91585 13.0595 4.07491 12.9422 4.19218L7.1336 10L12.9422 15.8078Z"
        fill="white"
      />
    </svg>
  );
};
