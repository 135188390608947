import { Tab } from "@pages/Dashboard/features/Widgets/Tab";
import { useTranslation } from "react-i18next";
import { useSuplyStore } from "@pages/Dashboard/store/suplyChain.state";
import styles from "./styles.module.scss";
import { useCanvaStore } from "src/store/canva/canva.state";
import { useSectionStore } from "src/store/croModule.state";
import SupplyBlocks from "./SupplyChain";
import { ExecutiveSummary } from "@components/ExecutiveSummary";
import InventoryPerformance from "./InventoryPerformance";
import Scheduler from "./Scheduler";
import FinanceFlowChart from "./FinanceFlowChart";
import { useCfoStore } from "src/store/cfo.state";
import { BrandAsinSelect } from "@components/brand-asin-select";
import SalesPerformance from "@components/SalesPerformance";

export function SupplyChainBlock() {
  const { t } = useTranslation();
  const isRestrictedRoute = location.pathname.includes("supply-chain");
  const { customSuplyWidgets } = useSuplyStore((state) => ({
    customSuplyWidgets: state.customSuplyWidgets,
  }));
  const { isWhatIf, setIsWhatIf } = useCfoStore();
  const { changeWhatIf: setCroWhatIf } = useSectionStore();
  const { setIsWhatIf: setCanvaWhatIf } = useCanvaStore();

  const handleToggle = () => {
    const newValue = !isWhatIf;
    setIsWhatIf(newValue);
    setCroWhatIf(newValue);
    setCanvaWhatIf(newValue);
  };

  return (
    <div>
      <header className="dashboard-widgets__header">
        <h1 className={styles.headerTitle}>{t("Supply chain (COO)")}</h1>
        <div className={styles.containerTab}>
          <BrandAsinSelect />
          <Tab
            customSelectedWidgets={customSuplyWidgets}
            isRestrictedRoute={isRestrictedRoute}
            isConfigRoute={true}
          />
          <button className={styles.whatIf} onClick={handleToggle}>
            {isWhatIf ? "Save Changes" : "switch to What IF"}
          </button>
        </div>
      </header>

      {isWhatIf ? (
        <>
          <div className={styles.supplyBlock}>
            <h1 className={styles.titleChain}>Supply Chain</h1>
            <SupplyBlocks />
          </div>
          <div className={styles.executiveBlock}>
            <ExecutiveSummary
              showWhatIf={isWhatIf}
              hideSwitch={true}
              alwaysDefault={true}
              showCOO={true}
            />
            <div className={styles.perfomanceBlock}>
              <SalesPerformance choosedGroups={"all"} />
            </div>
            <div className={styles.perfomanceBlock}>
              <InventoryPerformance />
            </div>
            <div>
              <Scheduler />
            </div>
            <div className={styles.financeBlock}>
              <FinanceFlowChart />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.executiveBlock}>
            <ExecutiveSummary
              showWhatIf={isWhatIf}
              hideSwitch={true}
              alwaysDefault={true}
              showCOO={true}
            />
          </div>
          <div className={styles.perfomanceBlock}>
            <SalesPerformance choosedGroups={"all"} />
          </div>
          <div className={styles.perfomanceBlock}>
            <InventoryPerformance />
          </div>
          <div>
            <Scheduler />
          </div>
          <div className={styles.financeBlock}>
            <FinanceFlowChart />
          </div>
        </>
      )}
    </div>
  );
}
