import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUserAmazonCheckerStore from "src/store/userAmazonCheacker.store";
import { useUserStore } from "src/store/user.state";
import { ROUTES } from "src/router/routes";

const useAmazonRedirect = () => {
  const { isAmazonConnectedOrSeller, checkAmazonStatus } =
    useUserAmazonCheckerStore();
  const { user } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  const [userLoaded, setUserLoaded] = useState(false);
  const falseCountRef = useRef(0);
  const lastStatusRef = useRef<boolean | null>(null);

  const exceptions = [
    "/CRO-module/cro-photo-update/generator",
    "/referral-page",
    "/connect-amazon",
    "/amazon-onboarding",
    "/settings",
    "/notification-settings",
    "/redirect-to-seller",
    "/redirect-to-amazon",
    "/congratulations-connect",
    "/amazon-onboarding",
    "/amazon-onboarding-complete",
    "/my-product",
    "/ads-analytics/campaigns",
    "/customers",
  ];

  const isException = exceptions.some((path) =>
    location.pathname.startsWith(path),
  );

  useEffect(() => {
    if (user !== null) {
      setUserLoaded(true);
    }
  }, [user]);

  useEffect(() => {
    if (!userLoaded) return;

    const checkStatusAndRedirect = async () => {
      try {
        if (user?.is_staff) {
          return;
        }

        await checkAmazonStatus();

        if (lastStatusRef.current === null) {
          lastStatusRef.current = isAmazonConnectedOrSeller;
          return;
        }

        if (
          lastStatusRef.current === false &&
          isAmazonConnectedOrSeller === false
        ) {
          falseCountRef.current += 1;
        } else {
          falseCountRef.current = 0;
        }

        if (falseCountRef.current >= 2 && !isException) {
          console.log("Redirecting to Amazon Connect...");
          navigate(ROUTES.CONNECT_AMAZON);
        } else {
          console.log(
            "Redirect canceled: Amazon status updated to",
            isAmazonConnectedOrSeller,
          );
        }

        lastStatusRef.current = isAmazonConnectedOrSeller;
      } catch (error) {
        console.error("Error during Amazon redirect check:", error);
      }
    };

    checkStatusAndRedirect();
    setTimeout(checkStatusAndRedirect, 1000);
    setTimeout(checkStatusAndRedirect, 2000);
  }, [
    userLoaded,
    user,
    checkAmazonStatus,
    isAmazonConnectedOrSeller,
    navigate,
    location.pathname,
    isException,
  ]);
};

export default useAmazonRedirect;
