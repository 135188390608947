import { format } from "date-fns";

export interface Stats {
  total: number;
  five_stars: number;
  four_stars: number;
  three_stars: number;
  two_stars: number;
  one_star: number;
}

export const calculateRatingPercentages = (stats: Stats | undefined) => {
  if (!stats) return [];

  const total =
    stats.five_stars +
    stats.four_stars +
    stats.three_stars +
    stats.two_stars +
    stats.one_star;

  if (total === 0) return [];

  return [
    {
      star: "5 STAR",
      percentage: ((stats.five_stars / total) * 100).toFixed(1),
      width: `${(stats.five_stars / total) * 100}%`,
    },
    {
      star: "4 STAR",
      percentage: ((stats.four_stars / total) * 100).toFixed(1),
      width: `${(stats.four_stars / total) * 100}%`,
    },
    {
      star: "3 STAR",
      percentage: ((stats.three_stars / total) * 100).toFixed(1),
      width: `${(stats.three_stars / total) * 100}%`,
    },
    {
      star: "2 STAR",
      percentage: ((stats.two_stars / total) * 100).toFixed(1),
      width: `${(stats.two_stars / total) * 100}%`,
    },
    {
      star: "1 STAR",
      percentage: ((stats.one_star / total) * 100).toFixed(1),
      width: `${(stats.one_star / total) * 100}%`,
    },
  ];
};

export const calculateStars = (
  reviews: number,
): ("full" | "half" | "empty")[] => {
  return [1, 2, 3, 4, 5].map((index) => {
    if (reviews >= index - 0.24) return "full";
    else if (reviews >= index - 0.74) return "half";
    else return "empty";
  });
};

export const convertDate = (timestamp: number) => {
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    return "";
  }
  return format(date, "dd MMM");
};

export const formatReviewScore = (value: number): string => {
  const oneDecimal = Number(value.toFixed(1));
  if (Math.abs(value - oneDecimal) > 0.04) {
    const truncated = Math.floor(value * 100) / 100;
    return truncated.toFixed(2);
  } else {
    return oneDecimal.toFixed(1);
  }
};

export const generateTicks = (start: Date, end: Date): number[] => {
  const ticks: number[] = [];
  const current = new Date(start);
  while (current <= end) {
    ticks.push(current.getTime());
    current.setDate(current.getDate() + 1);
  }
  return ticks;
};

export const getDateRangeForPeriod = (period) => {
  const today = new Date();
  let startDate = today;
  let endDate = today;

  if (period === "day") {
    startDate = today;
    endDate = today;
  } else if (period === "week") {
    startDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    endDate = today;
  } else if (period === "month") {
    startDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate(),
    );
    endDate = today;
  }

  return { startDate, endDate };
};
