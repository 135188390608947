export const calculateLabelPositions = (
  pieData,
  centerX,
  centerY,
  radius,
  radialOffset,
  horizontalOffset,
) => {
  return pieData.map((slice) => {
    let midAngle = (slice.startAngle + slice.endAngle) / 2;
    midAngle = (midAngle + 360) % 360;

    const angleRad = (midAngle * Math.PI) / 180;

    const startX = centerX + radius * Math.cos(angleRad);
    const startY = centerY - radius * Math.sin(angleRad);

    const midX = startX + radialOffset * Math.cos(angleRad);
    const midY = startY - radialOffset * Math.sin(angleRad);

    let endX, endY, textX, textY, textAnchor;

    if (slice.name === "InoPro" || slice.name === "GuruNanda") {
      if (midAngle < 180) {
        endX = midX - 55;
        endY = midY;
        textX = endX - 75;
        textY = endY;
        textAnchor = "start";
      } else {
        endX = midX;
        endY = midY;
        textX = endX;
        textY = endY;
        textAnchor = "end";
      }
    } else if (slice.name === "Amazon") {
      endX = midX - 20;
      endY = midY;
      textX = endX - 5;
      textY = endY;
      textAnchor = "end";
    } else if (slice.name === "Organic Social") {
      endX = midX + 30;
      endY = midY;
      textX = endX + 5;
      textY = endY;
      textAnchor = "start";
    } else if (midAngle >= 70 && midAngle <= 210) {
      endX = midX;
      endY = midY - 40;
      textX = endX;
      textY = endY - 10;
      textAnchor = "middle";
    } else if (midAngle < 180) {
      endX = midX + horizontalOffset;
      endY = midY;
      textX = endX + 5;
      textY = endY + 5;
      textAnchor = "start";
    } else {
      endX = midX - horizontalOffset;
      endY = midY;
      textX = endX - 5;
      textY = endY + 5;
      textAnchor = "end";
    }

    return {
      startX,
      startY,
      midX,
      midY,
      endX,
      endY,
      textX,
      textY,
      textAnchor,
      value: slice.value,
      name: slice.name,
    };
  });
};
