import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import SwiperNavigation from "@components/ProductSwiper";
import { RadarChart } from "@components/Spider/RadarChart";
import { useInsightsStore } from "src/store/insights/insights.state";
import { setRadarData } from "src/utils/setRadarData";
import DynamicComparisonBlock from "@pages/CROModule/components/CROPage/components/Sections/AspectOptimisation/ComparisonBlock";

export const AspectOptimization = ({ isWhatIf }) => {
  const { productOne } = useInsightsStore();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const defaultSpiderTitles = [
    "Aspect 1",
    "Aspect 2",
    "Aspect 3",
    "Aspect 4",
    "Aspect 5",
  ];
  const defaultSpiderValues = [0, 0, 0, 0, 0];

  const spiderTitles = productOne.spiderTitles?.length
    ? productOne.spiderTitles
    : defaultSpiderTitles;
  const spiderValues = productOne.spiderValues?.length
    ? productOne.spiderValues
    : defaultSpiderValues;

  const [currentDataSet, setCurrentDataSet] = useState(
    setRadarData(productOne.spiderValues),
  );
  const [product, setProduct] = useState([]);
  const [_, forceUpdate] = useState(false);
  const [isNewData, setIsNewData] = useState(false);
  const [yourSet, competitorsSet] = currentDataSet;

  useEffect(() => {
    handleReset();
  }, [productOne.spiderValues]);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < productOne.spiderTitles.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : productOne.spiderTitles.length - 1,
    );
  };

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
    if (isTooltipVisible) {
      setIsTooltipVisible(false);
    }
  };

  const handleReset = () => {
    setCurrentDataSet(setRadarData(productOne.spiderValues));
    setIsNewData(false);
    setActiveIndex(0);
  };

  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);
  const singleDataSet = [currentDataSet[0]];

  return (
    <div className={styles.container}>
      <div className={styles.radarBox}>
        <RadarChart
          datasets={singleDataSet}
          activeIndex={activeIndex}
          onDotClick={handleDotClick}
          isDragEnabled={true}
          showTooltipWithArrow={isTooltipVisible}
          blockChanging={false}
          width={506}
          height={416}
          labelFontSize={11}
          update={() => {
            if (!isWhatIf) return;
            setIsNewData(true);
            forceUpdate((p) => !p);
          }}
          productOneValues={spiderValues}
          productTwoValues={[]}
          productOneTitles={spiderTitles}
          productTwoTitles={[]}
        />
      </div>
      <div className={styles.headContent}>
        <div className={styles.comparation}>
          {isWhatIf && (
            <SwiperNavigation
              headerText={productOne.spiderTitles[activeIndex]}
              onNext={handleNext}
              onPrev={handlePrev}
              showText={true}
              improvementValue={improvementValue}
            />
          )}
          <DynamicComparisonBlock
            productScore={yourSet?.data[activeIndex] ?? 0}
            competitorScore={competitorsSet?.data[activeIndex] ?? 0}
            competitorsCount={product.length}
          />
        </div>
      </div>
    </div>
  );
};
