import create from "zustand";
import { useUserStore } from "./user.state";

interface UserAmazonCheckerState {
  isAmazonConnectedOrSeller: boolean;
  checkAmazonStatus: () => void;
}

const useUserAmazonCheckerStore = create<UserAmazonCheckerState>((set) => ({
  isAmazonConnectedOrSeller: false,
  checkAmazonStatus: () => {
    const { user } = useUserStore.getState();
    const isAmazonConnectedOrSeller =
      user.is_amazon_connected || user.is_amazon_seller;
    set({ isAmazonConnectedOrSeller });
  },
}));

export default useUserAmazonCheckerStore;
