import { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "./calendar.scss";
import { DateRangePicker, RangeKeyDict, Range } from "react-date-range";
import { useOutsideClick } from "src/utils/useOutsideClick";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { presets, Preset } from "./calendarPreset";
import { useIsMobile } from "src/utils/isMobile";
import { useBackground } from "@components/BackfroundContext";
import { useWindowWidth } from "src/utils/useWindowWidth";

interface CalendarModalProps {
  buttonWidth: number;
  closeModal: () => void;
  onDateChange: (startDate: Date, endDate: Date) => void;
  initialRange: { startDate: Date; endDate: Date };
}

const previousMonthDate = new Date();
previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

export const CalendarModal = ({
  closeModal,
  onDateChange,
  initialRange,
}: CalendarModalProps) => {
  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: initialRange.startDate,
    endDate: initialRange.endDate,
    key: "selection",
  });
  const isMobile = useIsMobile();
  const windowWidth = useWindowWidth();
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  const today = new Date();

  const modalRef = useOutsideClick(() => {
    closeModal();
  }, "click");

  useEffect(() => {
    document.querySelectorAll(".rdrWeekDays").forEach((weekDays) => {
      weekDays.querySelectorAll(".rdrWeekDay").forEach((day, index) => {
        const days = ["S", "M", "T", "W", "T", "F", "S"];
        day.textContent = days[index];
      });
    });
  }, []);

  const handleSelect = (ranges: RangeKeyDict) => {
    setSelectionRange(ranges.selection);
    setSelectedPreset(null);
  };

  const applyPreset = (preset: Preset) => {
    setSelectionRange(preset.range);
    setSelectedPreset(preset.label);
  };

  const applyDateSelection = () => {
    onDateChange(selectionRange.startDate!, selectionRange.endDate!);
    closeModal();
  };
  const monthsToShow = windowWidth < 1920 ? 1 : 2;
  return (
    <>
      <div ref={modalRef} className="modalContainer">
        <div className="calendarContainer">
          {isMobile && (
            <div className="presetsContainer">
              <div className="presetsBox">
                <div className="title">Presets</div>
                <div className="buttons">
                  {presets.map((preset, index) => (
                    <button
                      className={`presetsBtn ${selectedPreset === preset.label ? "selected" : ""}`}
                      key={index}
                      onClick={() => applyPreset(preset)}
                    >
                      {preset.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="container">
            <DateRangePicker
              shownDate={previousMonthDate}
              ranges={[selectionRange]}
              onChange={handleSelect}
              months={isMobile ? 1 : monthsToShow}
              direction={isMobile ? "vertical" : "horizontal"}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              className="calendar"
              maxDate={today}
              preventSnapRefocus={true}
            />
            <button className="applyButton" onClick={applyDateSelection}>
              APPLY
            </button>
          </div>

          {!isMobile && (
            <div className="presetsContainer">
              <div className="presetsBox">
                <div className="title">Presets</div>
                <div className="buttons">
                  {presets.map((preset, index) => (
                    <button
                      className={`presetsBtn ${selectedPreset === preset.label ? "selected" : ""}`}
                      key={index}
                      onClick={() => applyPreset(preset)}
                    >
                      {preset.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
