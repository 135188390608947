export const staticData = [
  { date: "Feb 25", unitsSold: 7, revenue: 14000 },
  { date: "Feb 26", unitsSold: 8, revenue: 16000 },
  { date: "Feb 27", unitsSold: 6, revenue: 12000 },
  { date: "Feb 28", unitsSold: 7, revenue: 15000 },
  { date: "Mar 01", unitsSold: 9, revenue: 18000 },
  { date: "Mar 02", unitsSold: 8, revenue: 16000 },
  { date: "Mar 03", unitsSold: 10, revenue: 20000 },
  { date: "Mar 04", unitsSold: 7, revenue: 14000 },
  { date: "Mar 05", unitsSold: 8, revenue: 17000 },
  { date: "Mar 06", unitsSold: 9, revenue: 18500 },
  { date: "Mar 07", unitsSold: 9, revenue: 15500 },
];

export const chartColors = {
  unitsSold: "#5295E0",
  revenue: "#80C67A",
};

export const formatCurrency = (value: number) => `$${value.toLocaleString()}`;
