import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { ReviewChart } from "./ReviewScore";
import { useProductStore } from "src/store/overviewProduct.state";
import product1 from "@assets/img/noImage.jpg";
import { mockData } from "@pages/Compare/components/Products/mock";
import { calculateRatingPercentages } from "./ReviewScore/ratingUtils";
import { Rating } from "@pages/Dashboard/features/Products/Rating";
import { CategoriesService } from "@services/amazon/categories/categories.service";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import PeriodSelector from "@pages/AdsAnalitics/components/Header/ui/SelectDays";
import { format } from "date-fns";

interface ReviewScoreProps {
  score: number;
  stats?: {
    total: number;
    five_stars: number;
    four_stars: number;
    three_stars: number;
    two_stars: number;
    one_star: number;
  };
}

const ReviewScore: React.FC<ReviewScoreProps> = ({ score, stats }) => {
  const { selectedProduct } = useProductStore();
  const [chartData, setChartData] = useState([]);

  const reviews = parseFloat((score || mockData[0]?.rating || 0).toFixed(1));
  const { dateRangeSelect } = useDashboardStore((state) => ({
    dateRangeSelect: state.dateRangeSelect,
  }));

  const ratingPercentages = calculateRatingPercentages(stats);

  const handleStats = async () => {
    if (!selectedProduct || !dateRangeSelect) return;
    try {
      const spider = new CategoriesService();
      const date_start = format(dateRangeSelect.startDate, "yyyy-MM-dd");
      const date_end = format(dateRangeSelect.endDate, "yyyy-MM-dd");

      const resReviews = await spider.getGraphsReview(
        selectedProduct.asin,
        date_start,
        date_end,
      );
      const startDateObj = new Date(date_start);
      const endDateObj = new Date(date_end);
      const days = [];
      for (
        let d = new Date(startDateObj);
        d <= endDateObj;
        d.setDate(d.getDate() + 1)
      ) {
        days.push(new Date(d));
      }
      const transformedData = days.map((day) => {
        const dayString = format(day, "yyyy-MM-dd");
        const matchingItem = resReviews.find(
          (item: any) => item.date === dayString,
        );
        return {
          date: day.getTime(),
          reviewScore: matchingItem ? matchingItem.average : 0,
          positiveReviews: matchingItem ? matchingItem.positive : 0,
          negativeReviews: matchingItem ? matchingItem.negative : 0,
          neutralReviews: matchingItem ? matchingItem.neutral : 0,
        };
      });
      setChartData(transformedData);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct && dateRangeSelect) {
      handleStats();
    }
  }, [selectedProduct, dateRangeSelect]);

  return (
    <div className={styles.reviewScoreContainer}>
      <div className={styles.productBox}>
        <div className={styles.imageContainer}>
          <img
            src={
              selectedProduct?.image_link ||
              selectedProduct?.picture_url ||
              product1
            }
            alt="choose your product"
            className={styles.productImage}
          />
        </div>
        <div className={styles.ratingContainer}>
          <div className={styles.ratingWrapper}>
            <div className={styles.startBox}>
              <Rating rating={reviews} />
            </div>
            <div className={styles.ratingBreakdown}>
              {ratingPercentages.length > 0 ? (
                ratingPercentages.map((rating, index) => (
                  <div key={index} className={styles.ratingRow}>
                    <div className={styles.star}>{rating.star}</div>
                    <div className={styles.progressBar}>
                      <div
                        className={styles.progressFill}
                        style={{ width: rating.width }}
                      ></div>
                      <span>{rating.percentage}%</span>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading ...</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <div className={styles.chartHeader}>
          <h3>Review score</h3>
          <PeriodSelector />
        </div>
        <div>
          <ReviewChart
            startDate={dateRangeSelect.startDate}
            endDate={dateRangeSelect.endDate}
            data={chartData}
          />
        </div>
      </div>
    </div>
  );
};

export default ReviewScore;
