import { useOutsideClick } from "src/utils/useOutsideClick";
import styles from "./styles.module.scss";
import { useState, useCallback, useMemo } from "react";
import BottomArrow from "@assets/icons/bottomArrow";
import classNames from "classnames";
import { Checkbox } from "antd";

export type Option = {
  id: string;
  text: string;
  img?: string;
  asin?: string;
};

export type SelectValue = string | string[];

export interface SelectProps {
  value: SelectValue;
  placeholder: string | React.ReactNode;
  options: Option[] | string[] | React.ReactNode[];
  onChange: (value: SelectValue) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isMultiSelect?: boolean;
  className?: string;
  children?: React.ReactNode;
  onLoadMore?: () => void;
  hasMore?: boolean;
  isOptionsOutside?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  value,
  placeholder,
  options,
  onChange,
  onOpen,
  onClose,
  isMultiSelect,
  className,
  children,
  onLoadMore,
  hasMore,
  isOptionsOutside,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const selectRef = useOutsideClick(() => {
    setOpen(false);
    onClose?.();
  });

  const handleToggleSelect = useCallback(() => {
    const newState = !open;
    setOpen(newState);
    if (newState) {
      onOpen?.();
    }
  }, [open, onOpen]);

  const handleOptionClick = useCallback(
    (optionValue: string) => {
      if (isMultiSelect && Array.isArray(value)) {
        const newValue = value.includes(optionValue)
          ? value.filter((val) => val !== optionValue)
          : [...value, optionValue];
        onChange(newValue);
      } else {
        onChange(optionValue);
        setOpen(false);
        onClose?.();
      }
    },
    [isMultiSelect, value, onChange, onClose],
  );

  const handleLoadMore = useCallback(() => {
    onLoadMore?.();
  }, [onLoadMore]);

  const displayValue = useMemo(() => {
    if (Array.isArray(value) && value.length > 0) {
      return value.join(", ");
    }
    if (!Array.isArray(value) && value) {
      return value;
    }
    return placeholder;
  }, [value, placeholder]);

  const renderOption = useCallback(
    (option: any, index: number) => {
      if (
        typeof option === "object" &&
        (option.id !== undefined || option.img !== undefined)
      ) {
        if (!isMultiSelect && option.id === value) return null;

        return (
          <button
            key={option.id || index}
            onClick={() => handleOptionClick(option.id)}
            className={styles.option}
          >
            {isMultiSelect && (
              <Checkbox
                checked={Array.isArray(value) && value.includes(option.id)}
              />
            )}
            {option.img && <img src={option.img} alt="product" />}
            <div className={styles.option__text}>
              <span>
                {option.asin || option.id
                  ? `(${option.asin || option.id}) `
                  : ""}
              </span>
              <span>{option.text}</span>
            </div>
          </button>
        );
      }

      // Handle string options
      if (!isMultiSelect && option === value) return null;

      return (
        <button
          key={typeof option === "string" ? option : index}
          onClick={() => handleOptionClick(option)}
          className={styles.option}
        >
          {isMultiSelect && (
            <Checkbox
              checked={Array.isArray(value) && value.includes(option)}
            />
          )}
          {option}
        </button>
      );
    },
    [handleOptionClick, isMultiSelect, value],
  );

  return (
    <div
      className={classNames(
        styles.selector,
        className,
        open && styles.open,
        isOptionsOutside && styles.optionsOutside,
      )}
      ref={selectRef}
    >
      <button
        onClick={handleToggleSelect}
        className={classNames(
          styles.value,
          (!value || (Array.isArray(value) && value.length === 0)) &&
            styles.placeholder,
        )}
      >
        <input
          value={displayValue as string}
          readOnly
          placeholder={placeholder as string}
        />
        <span className={styles.arrow}>
          <BottomArrow />
        </span>
      </button>

      {open && (
        <div className={styles.options}>
          {children}

          {options.length > 0 ? (
            <>
              {options.map(renderOption)}

              {hasMore && onLoadMore && (
                <button className={styles.loadMore} onClick={handleLoadMore}>
                  Load More
                </button>
              )}
            </>
          ) : (
            <p className={styles.noOptions}>No options</p>
          )}
        </div>
      )}
    </div>
  );
};
