import { ProductText } from "@pages/CROModule/components/Product/ProductText";
import styles from "./styles.module.scss";
import { ReviewChart } from "@pages/Overview/components/ReviewScore/ReviewScore";
import { ListingOptimisation } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Listing";
import { PriceOptimisation } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Price";
import { AdSpend } from "@pages/AdsAnalitics/components/Campaigns/components/AdSpend";
import { CampaignsTable } from "@pages/AdsAnalitics/components/Campaigns/components/Tables";
import { SanKey } from "@pages/AdsAnalitics/components/SanKey";
import { ReviewsOptimization } from "../Elements/Collection/ReviewsOptimization";
import { OrganicSalesTable } from "@components/OrganicSales";
import { FunnelChart } from "@components/FunnelChart";
import { AspectOptimization } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Aspect";
import { SummaryAudit } from "@components/SummaryAudit";
import { OrganicKeywordTable } from "@components/OrganicKeyword";
import { OrganicMissingTargetTable } from "@components/OrganicMissingTarget";
import { WhatIfChart } from "@components/ChatBot/What-If";
import { ELEMENT_ID } from "src/store/canva/element-id";
import DemographicsTable from "../Elements/Collection/DemographicsTable";
import Brand from "../Elements/Collection/Brand";
import PNLBlock from "../Elements/Collection/PNL";
import TargetingProfiles from "../Elements/Collection/TargetingProfiles";
import DemographicTypeShare from "../Elements/Collection/DemographicTypeShare";
import { ProductPlacementTable } from "@components/ProductPlacement";
import { ExecutiveSummary } from "@components/ExecutiveSummary";
import DataEntry from "../Elements/Collection/DataEntry";
import { SaturationCurve } from "@components/SaturationCurve";
import DemographicDataOverviewBlock from "../Elements/Collection/DemographicDataOverview";
import { Campaigns } from "@pages/AdsAnalitics/components/Campaigns";
import { MutableRefObject } from "react";
import { GridStack } from "gridstack";
import SalesPerformance from "@components/SalesPerformance";
import MarketLevelSalesDistribution from "@pages/MarketIntelligence/components/MarketLevelBlock";
import BrandRevenueChart from "@pages/MarketIntelligence/components/BrandRevenueChart";
import MarketOverview from "@pages/MarketIntelligence/components/DotChart";
import PerformanceBlock from "@pages/MarketIntelligence/components/BrandBlock";
import { Products } from "@pages/MarketIntelligence/components/Products";

export const renderElement = (
  elementId: ELEMENT_ID,
  asin: string[],
  isWhatIf: boolean,
  id: string,
  gridRef: MutableRefObject<GridStack>,
  zoom?: number,
) => {
  switch (elementId) {
    case ELEMENT_ID.PRODUCT_DETAILS:
      return (
        <>
          <div className={styles.product}>
            <ProductText customAsin={asin[0]} showColumn={"right"} />
          </div>
        </>
      );
    case ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS:
      return (
        <>
          <div className={styles.product}>
            <ProductText customAsin={asin[0]} showColumn={"left"} />
          </div>
        </>
      );
    case ELEMENT_ID.SCORE_REVIEWS:
      return (
        <div className={styles.products}>
          <h3>Reviews Score</h3>
          <ReviewChart />
        </div>
      );
    case ELEMENT_ID.ASPECT_OPTIMIZATION:
      return (
        <div className={styles.optimisation}>
          <AspectOptimization
            isWhatIf={isWhatIf}
            defaultOpen
            disableHide
            customAsin={asin[0]}
          />
        </div>
      );
    case ELEMENT_ID.CONTENT_OPTIMIZATION:
      return (
        <div className={styles.optimisation}>
          <ListingOptimisation
            isWhatIf={isWhatIf}
            defaultOpen
            disableHide
            asin={asin[0]}
          />
        </div>
      );
    case ELEMENT_ID.PRICE_OPTIMIZATION:
      return (
        <div className={styles.optimisation}>
          <PriceOptimisation defaultOpen disableHide />
        </div>
      );
    case ELEMENT_ID.ADS_SALES_SLIDERS:
      return (
        <div className={styles.sliders}>
          <AdSpend isSmall />
        </div>
      );
    case ELEMENT_ID.GOAL_SETTING:
      return (
        <div className={styles.sliders}>
          <AdSpend onlyChart />
        </div>
      );
    case ELEMENT_ID.ADS_SALES:
      return (
        <div className={styles.products}>
          <h3>Ad Sales</h3>
          <CampaignsTable isWhatIf={isWhatIf} customAsin={asin} autoHeight />
        </div>
      );
    case ELEMENT_ID.ADS_SANKEY:
      return <SanKey canvaAsin={asin[0]} hideAsin />;
    case ELEMENT_ID.EXECUTIVE_SUMMARY:
      return <ExecutiveSummary id={id} gridRef={gridRef} />;
    case ELEMENT_ID.REVIEWS_OPTIMIZATION:
      return <ReviewsOptimization />;
    case ELEMENT_ID.ORGANIC_SALES:
      return (
        <OrganicSalesTable
          id={id}
          gridRef={gridRef}
          isWhatIf={isWhatIf}
          autoHeight
        />
      );
    case ELEMENT_ID.FUNNEL:
      return (
        <div className={styles.showOverflow}>
          <FunnelChart isWhatIf={isWhatIf} zoom={zoom} />
        </div>
      );
    case ELEMENT_ID.SUMMARY_AUDIT:
      return <SummaryAudit />;
    case ELEMENT_ID.CAMPAIGN_METRICS:
      return (
        <div className={styles.campaign}>
          <h3>Campaign Metrics</h3>
          <Campaigns canvas={true} />
        </div>
      );
    case ELEMENT_ID.ORGANIC_KEYWORD_PERFORMANCE:
      return <OrganicKeywordTable autoHeight />;
    case ELEMENT_ID.ORGANIC_MISSING_TARGET:
      return <OrganicMissingTargetTable autoHeight />;
    case ELEMENT_ID.WHAT_IF_CHART:
      return <WhatIfChart />;
    case ELEMENT_ID.DEMOGRAPHIC_TYPE:
      return <DemographicDataOverviewBlock />;
    case ELEMENT_ID.DEMOGRAPHIC_TABLE:
      return <DemographicsTable />;
    case ELEMENT_ID.BRAND:
      return <Brand />;
    case ELEMENT_ID.PNL_BLOCK:
      return <PNLBlock />;
    case ELEMENT_ID.TARGITING_PROFILES:
      return <TargetingProfiles />;
    case ELEMENT_ID.DEMOGRAPHIC_TYPE_SHARE:
      return <DemographicTypeShare />;
    case ELEMENT_ID.PRODUCT_PLACEMENT:
      return <ProductPlacementTable />;
    case ELEMENT_ID.DATA_ENTRY:
      return <DataEntry />;
    case ELEMENT_ID.SATURATION_CURVE:
      return <SaturationCurve />;
    case ELEMENT_ID.SALES_PERFORMANCE:
      return <SalesPerformance hideBg />;
    case ELEMENT_ID.MARKET_LEVEL_SALES_DISTRIBUTION:
      return <MarketLevelSalesDistribution isFullSize />;
    case ELEMENT_ID.MARKET_SHARE:
      return <BrandRevenueChart />;
    case ELEMENT_ID.BRAND_SALES_PERFORMANCE:
      return <PerformanceBlock onlyBrand hideBorder />;
    case ELEMENT_ID.MARKET_OVERVIEW:
      return <MarketOverview fullSize />;
    case ELEMENT_ID.MARKET_SALES_PERFORMANCE:
      return <PerformanceBlock onlyMarket hideBorder />;
    case ELEMENT_ID.LAUNCH_PRODUCT:
      return <Products fullSize useOnly="charts" />;
    case ELEMENT_ID.LAUNCH_PRODUCT_TABLE:
      return <Products useOnly="table" />;
    default:
      return elementId;
  }
};
