export const staticData = [
  { date: "Jan 01", sales: 500 },
  { date: "Jan 02", sales: 1500 },
  { date: "Jan 03", sales: 1000 },
  { date: "Jan 04", sales: 2500 },
  { date: "Jan 05", sales: 2000 },
  { date: "Jan 06", sales: 3000 },
  { date: "Jan 07", sales: 3500 },
];

export const chartColors = {
  sales: "#5295E0",
};

export const formatCurrency = (value: number) => `$${value.toLocaleString()}`;
