import classNames from "classnames";
import styles from "./styles.module.scss";
import { useState, useEffect } from "react";
import { useMaintenance } from "src/store/maintenance.state";

const Nav = ({
  onChange,
  items,
  defaultIndex = 0,
  UserGuide,
  step,
}: {
  onChange: (index: number) => void;
  items: { icon: string; label: string }[];
  defaultIndex?: number;
  UserGuide?: boolean;
  step?: number;
}) => {
  const [activeNavIndex, setActiveNavIndex] = useState(defaultIndex);

  const handleClick = (index: number) => {
    setActiveNavIndex(index);
    onChange(index);
  };

  useEffect(() => {
    if (UserGuide) {
      if (step === 3) {
        setActiveNavIndex(1);
      } else if (step === 6) {
        setActiveNavIndex(2);
      }
    } else {
      setActiveNavIndex(0);
    }
  }, [step, UserGuide]);

  const { maintenance } = useMaintenance();

  return (
    <ul className={styles.nav}>
      {items.map((widget, i) => {
        if (widget.label === "Settings") {
          return (
            maintenance && (
              <li
                key={i}
                className={classNames(
                  styles.nav__item,
                  UserGuide
                    ? i === activeNavIndex && styles["nav__item--active"]
                    : i === activeNavIndex && styles["nav__item--active"],
                )}
                onClick={() => handleClick(i)}
              >
                <img src={widget.icon} alt="" />
                <span>{widget.label}</span>
              </li>
            )
          );
        }

        return (
          <li
            key={i}
            className={classNames(
              styles.nav__item,
              UserGuide
                ? i === activeNavIndex && styles["nav__item--active"]
                : i === activeNavIndex && styles["nav__item--active"],
            )}
            onClick={() => handleClick(i)}
          >
            <img src={widget.icon} alt="" />
            <span>{widget.label}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default Nav;
