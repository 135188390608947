export class GenerateService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = import.meta.env.BUILD_API_BASE_URL;
  }

  private async get<T>(endpoint: string): Promise<T> {
    return this.request<T>(endpoint, {
      method: "GET",
    });
  }

  private async getUserToken(): Promise<string> {
    const token = localStorage.getItem("access_token");
    if (!token) {
      throw new Error("No access token found");
    }
    return token;
  }

  private async request<T>(endpoint: string, options: RequestInit): Promise<T> {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${await this.getUserToken()}`,
        ...options.headers,
      },
    });

    if (response.status === 401) {
      window.location.href = "/auth/login";
      throw new Error("Unauthorized");
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const text = await response.text();
    if (!text) {
      return {} as T;
    }

    return JSON.parse(text) as T;
  }

  private async post<T>(endpoint: string, body: any): Promise<T> {
    return this.request<T>(endpoint, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  public async fileUpload(file: File): Promise<{ file_id: string }> {
    const formData = new FormData();
    formData.append("user_file", file);

    const userToken = await this.getUserToken();

    try {
      const response = await fetch(`${this.baseUrl}/cng/v1/files/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
      });

      const data = await response.json();
      const fileId = data.file_id;
      localStorage.setItem("curr_file_id", fileId);

      return { file_id: fileId };
    } catch (error) {
      console.error("Error uploading file:", error);
      throw new Error("File upload failed");
    }
  }

  public async removeBackgroundByFileId(
    fileId: string,
  ): Promise<{ task_id: string }> {
    const payload = {
      file_id: fileId,
    };

    const response = await this.post<{ task_id: string }>(
      "/ai/v1/images/remove-background",
      payload,
    );

    const taskId = response.task_id;
    this.saveTaskIds([taskId]);

    return response;
  }

  public async getProductDescriptionByFileId(
    fileId: string,
  ): Promise<{ description?: string; task_id: string }> {
    const payload = {
      file_id: fileId,
      kind: "DESCRIBE_SHORT",
    };

    const response = await this.post<{ description?: string; task_id: string }>(
      "/ai/v1/images/product2text",
      payload,
    );

    const taskId = response.task_id;
    this.saveTaskIds([taskId]);

    return response;
  }

  public async generateImageByMaskAndProps(
    image_file_id: string,
    image_mask_file_id: string,
    prompt: string,
    negativePrompt: string,
    props_image_file_id: string,
    props_image_mask_file_id: string,
    numResults: number = 1,
  ): Promise<{ images: string[]; task_id: string }> {
    const payload = {
      image_file_id,
      image_mask_file_id,
      prompt,
      negative_prompt: negativePrompt,
      props_image_file_id,
      props_image_mask_file_id,
      num_results: numResults,
    };

    const response = await this.post<{ images: string[]; task_id: string }>(
      "/ai/v1/images/generate-by-mask-and-props",
      payload,
    );

    this.saveTaskIds([response.task_id]);

    return response;
  }

  private saveTaskIds(taskIds: string[]): void {
    const existingTaskIds = localStorage.getItem("curr_task_id");
    let taskIdsArray: string[] = [];

    if (existingTaskIds) {
      taskIdsArray = JSON.parse(existingTaskIds);
    }

    taskIdsArray = [...taskIdsArray, ...taskIds];
    localStorage.setItem("curr_task_id", JSON.stringify(taskIdsArray));
  }

  public async getPlatforms() {
    const res = await this.get(`/ai/v1/platforms`);
    return res;
  }
}
