import React, { useEffect, useState } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { Spin, Switch } from "antd";
import classNames from "classnames";
import styles from "./styles.module.scss";
import {
  Checks,
  formatThousands,
  getData,
  getInterval,
  getMockDataDate,
  getMockDataRank,
  getTickProps,
} from "./utils";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { CustomTooltip } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/Tooltip";
import {
  renderBar,
  renderLines,
} from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/ChartSeries";
import {
  generateForecastData,
  generatePastData,
} from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";

export interface CampaignsChartProps {
  checks?: Partial<Checks>;
  handleFixChecks?: () => void;
  chartWidth?: number;
  useDashed?: boolean;
  hideLine?: boolean;
  useMockData?: boolean;
  marketShareRank: number;
}

export const defaultChecks: Partial<Checks> = {
  sales: true,
  spend: true,
  impressions: true,
  ctr: true,
  cvr: true,
  cpc: true,
  acos: true,
  roas: true,
  orders: true,
  unitSolds: true,
  clicks: false,
  organicCTR: true,
  organicCVR: true,
  organicSales: true,
};

export const MarketLevelChart = ({
  checks = defaultChecks,
  handleFixChecks,
  chartWidth = 1302,
  useDashed,
  hideLine,
  useMockData,
  marketShareRank,
}: CampaignsChartProps) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const { isWhatIfEnabled } = useAdsAnaliticsStore((state) => ({
    isWhatIfEnabled: state.isWhatIfEnabled,
  }));
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));

  const [showForecast, setShowForecast] = useState(false);
  const [showPastData, setShowPastData] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (useMockData) {
        if (hideLine) {
          setData(getMockDataRank());
        } else {
          setData(getMockDataDate());
        }
        setIsLoading(false);
        return;
      }
      const startDate = new Date(dateRange.startDate);
      const daysToExtend = Math.ceil(
        (new Date(dateRange.endDate).getTime() -
          new Date(dateRange.startDate).getTime()) /
          (1000 * 60 * 60 * 24),
      );
      startDate.setDate(startDate.getDate() - daysToExtend);

      const requestDateRange =
        showPastData && isWhatIfEnabled
          ? {
              ...dateRange,
              startDate: startDate,
            }
          : { ...dateRange };
      const fetchedData = await getData(requestDateRange, checks);
      if (!fetchedData) {
        setIsLoading(false);
        return;
      }
      Object.keys(fetchedData).forEach((key) => {
        if (fetchedData[key] === null) {
          delete fetchedData[key];
        }
      });
      const transformedData = fetchedData.date.map(
        (date: string, index: number) => {
          const obj: any = { date, isForecast: false, isPastData: false };
          if (fetchedData.sales) obj.sales = fetchedData.sales[index] || 0;
          if (fetchedData.spend) obj.spend = fetchedData.spend[index] || 0;
          if (fetchedData.impressions)
            obj.impressions = fetchedData.impressions[index] || 0;
          if (fetchedData.ctr)
            obj.ctr = Number(fetchedData.ctr[index]) * 100 || 0;
          if (fetchedData.cvr)
            obj.cvr = Number(fetchedData.cvr[index]) * 100 || 0;
          if (fetchedData.cpc) obj.cpc = fetchedData.cpc[index] || 0;
          if (fetchedData.acos)
            obj.acos = Number(fetchedData.acos[index]) * 100 || 0;
          if (fetchedData.roas) obj.roas = fetchedData.roas[index] || 0;
          if (fetchedData.unitSolds)
            obj.unitSolds = fetchedData.unitSolds[index] || 0;
          if (fetchedData.clicks) obj.clicks = fetchedData.clicks[index] || 0;
          if (fetchedData.organicCTR)
            obj.organicCTR = Number(fetchedData.organicCTR[index]) || 0;
          if (fetchedData.organicCVR)
            obj.organicCVR = Number(fetchedData.organicCVR[index]) || 0;

          if (fetchedData.organicSales)
            obj.organicSales = fetchedData.organicSales[index] || 0;
          return obj;
        },
      );

      let finalData = [...transformedData];

      if (showPastData && isWhatIfEnabled) {
        const pastData = generatePastData(
          transformedData,
          daysToExtend,
          transformedData[daysToExtend],
          startDate,
        );
        finalData = [...pastData];
      }

      if (showForecast && isWhatIfEnabled) {
        const lastDate = transformedData[transformedData.length - 1];
        const forecastedData = generateForecastData(
          showPastData ? transformedData.length / 2 : transformedData.length,
          lastDate,
          transformedData[transformedData.length - 1].date,
        );
        finalData = [...finalData, ...forecastedData];
      }

      setData(finalData);
      setIsLoading(false);
    };
    fetchData();
  }, [
    dateRange,
    checks,
    showForecast,
    showPastData,
    isWhatIfEnabled,
    hideLine,
    useMockData,
  ]);

  const preparedData = Array.isArray(data)
    ? [
        { name: "Past", data: data.filter((item: any) => item.isPastData) },
        {
          name: "Actual",
          data: data.filter(
            (item: any) => !item.isForecast && !item.isPastData,
          ),
        },
        { name: "Forecast", data: data.filter((item: any) => item.isForecast) },
      ]
    : [];

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chart}>
        {isWhatIfEnabled && (
          <div className={styles.whatIf}>
            <div className={styles.toggle__container}>
              <Switch
                checked={showForecast}
                onChange={() => {
                  setShowForecast(!showForecast);
                }}
                className={classNames(
                  styles.toggle,
                  showForecast && styles.toggle__active,
                )}
                id="forecast"
                defaultChecked
              />
              <label htmlFor="forecast">Show Forecast</label>
            </div>
            <div className={styles.toggle__container}>
              <Switch
                checked={showPastData}
                onChange={() => {
                  if (!showPastData && handleFixChecks) {
                    handleFixChecks();
                  }
                  setShowPastData(!showPastData);
                }}
                className={classNames(
                  styles.toggle,
                  showPastData && styles.toggle__active,
                )}
                id="pastData"
                defaultChecked
              />
              <label htmlFor="pastData">Show Past Data</label>
            </div>
          </div>
        )}
        <ResponsiveContainer width="100%" height={323}>
          <ComposedChart
            data={preparedData.flatMap((s) => s.data)}
            margin={{
              top: 0,
              right: -10,
              left: -20,
              bottom: -10,
            }}
          >
            <CartesianGrid stroke="#1F1F20" />
            <Tooltip
              content={({ active, payload, label, coordinate }) => (
                <CustomTooltip
                  active={active}
                  payload={payload}
                  label={label}
                  coordinate={coordinate}
                  chartWidth={1302}
                  checks={checks}
                />
              )}
              cursor={{
                fill: "transparent",
                stroke: "white",
                strokeDasharray: 4,
              }}
            />

            {!hideLine ? (
              <>
                <XAxis
                  allowDuplicatedCategory={false}
                  dataKey="date"
                  type="category"
                  tickLine={false}
                  interval={getInterval(data.length)}
                  tick={getTickProps(data?.length)}
                />
                <XAxis
                  allowDuplicatedCategory={false}
                  dataKey="date"
                  type="category"
                  scale="point"
                  xAxisId="lineAxis"
                  hide={true}
                  interval={0}
                />
                <XAxis
                  allowDuplicatedCategory={false}
                  dataKey="date"
                  type="category"
                  scale="point"
                  xAxisId="centerAxis"
                  hide={true}
                  interval={0}
                />
              </>
            ) : (
              <>
                <XAxis
                  dataKey="rank"
                  type="number"
                  xAxisId="lineAxis"
                  reversed={true}
                  axisLine={{ stroke: "#fff" }}
                  tick={{ fill: "white" }}
                  domain={[0, 50]}
                  ticks={[50, 45, 40, 35, 30, 25, 20, 15, 10, 5, 1]}
                  tickLine={false}
                />

                <ReferenceLine
                  x={25}
                  xAxisId="lineAxis"
                  yAxisId="left1"
                  strokeDasharray="5 5"
                  stroke="#B5B5B5"
                  label={{
                    value: "Current Value",
                    position: "insideTop",
                    fill: "#B5B5B5",
                    offset: 10,
                    dx: 50,
                  }}
                />

                <ReferenceLine
                  x={marketShareRank}
                  xAxisId="lineAxis"
                  yAxisId="left1"
                  strokeDasharray="5 5"
                  stroke="#80C67A"
                  label={{
                    value: "Goal",
                    position: "insideTop",
                    fill: "#80C67A",
                    offset: 10,
                    dx: -30,
                  }}
                  strokeWidth={3}
                />
              </>
            )}

            {!hideLine &&
              renderBar(
                preparedData.flatMap((o) => o.data),
                checks,
              )}

            <YAxis
              yAxisId="left1"
              orientation="left"
              stroke="#6B4DBA"
              tickFormatter={formatThousands}
              tickLine={false}
              axisLine={{
                stroke: "#fff",
              }}
              domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
              includeHidden
              tickCount={11}
              tick={!hideLine}
              width={hideLine ? 0 : undefined}
            />
            {/* 2: Sales, Spend */}
            <YAxis
              yAxisId="left2"
              orientation="left"
              stroke="rgb(128, 198, 122)"
              tickFormatter={(tick) => `$${formatThousands(tick)}`}
              hide={!checks.sales && !checks.spend}
              axisLine={false}
              tickLine={false}
              domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
              includeHidden
              tickCount={11}
              tick={!hideLine}
              width={hideLine ? 0 : undefined}
            />
            {/* 3: NTB Orders, Orders */}
            <YAxis
              yAxisId="left3"
              orientation="left"
              stroke="#255FA0"
              tickFormatter={formatThousands}
              hide={!checks.orders && !checks.unitSolds}
              axisLine={false}
              tickLine={false}
              tickCount={11}
              tick={!hideLine}
              width={hideLine ? 0 : undefined}
            />
            {/* 4: CVR, CTR, ACOS */}
            <YAxis
              yAxisId="right1"
              orientation={hideLine ? "left" : "right"}
              stroke="#FFF"
              tickFormatter={(tick) => `${tick}%`}
              tickLine={false}
              domain={
                hideLine
                  ? [0, 25]
                  : [0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]
              }
              tickCount={11}
              width={hideLine ? 100 : undefined}
            />

            {/* 5: CPC */}
            <YAxis
              yAxisId="right2"
              orientation="right"
              stroke="#E6FF4B"
              tickFormatter={(tick) => `$${tick.toFixed(2)}`}
              hide={!checks.cpc}
              axisLine={false}
              tickLine={false}
              domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
              tickCount={11}
            />
            {/* 6: ROAS */}
            <YAxis
              yAxisId="right3"
              orientation="right"
              stroke="#F19867"
              tickFormatter={(tick) => `${tick.toFixed()}`}
              hide={!checks.roas}
              axisLine={false}
              tickLine={false}
              domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
              tickCount={11}
              tick={!hideLine}
            />

            {(checks.organicCTR ||
              checks.organicCVR ||
              checks.organicSales) && (
              <YAxis
                yAxisId="organic"
                orientation="right"
                stroke="#F19867"
                tickFormatter={(tick) => `${tick.toFixed()}`}
                axisLine={false}
                tickLine={false}
                domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]}
                tickCount={11}
                tick={!hideLine}
              />
            )}

            {renderLines(preparedData, checks, useDashed)}
          </ComposedChart>
        </ResponsiveContainer>
        {hideLine && <h5 className={styles.rank}>Rank</h5>}
      </div>
    </div>
  );
};
