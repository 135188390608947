export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const statuses = [
  { code: "active", label: "Active" },
  { code: "inactive", label: "Inactive" },
  { code: "search", label: "Search Suppressed" },
  { code: "out", label: "Out of Stock" },
  { code: "closed", label: "Closed" },
];
