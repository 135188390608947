import {
  adTypeColumnsWithoutNyle,
  funnelColumnsWithoutNyle,
  matchColumnsWithoutNyle,
  campaignsColumnsWithoutNyle,
  targetingColumnsWithoutNyle,
  performanceColumnsWithoutNyle,
  campaignsColumns,
  adTypeColumns,
  funnelColumns,
  matchColumns,
  performanceColumns,
  targetingColumns,
} from "../table.columns";
import { getStarColor } from "@components/ExpandebleTable/KeyTooltip";
import { TypeTab } from "@pages/AdsAnalitics/components/SanKey/Chart";
import { CanvasApi } from "@services/canvas/canvas.api";
import styles from "./styles.module.scss";
import { Checkbox } from "antd";
import StarKeyword from "@assets/icons/StarKeyword";
import { INestedData } from "../utils.types";

export const isTotalFirst = (data: any[]) => {
  if (!data || (data && data.length === 0)) return false;
  return (
    data[0].campaign_name === "Total" ||
    data[0].ad_type === "Total" ||
    data[0].match_type === "Total" ||
    data[0].target === "Total" ||
    data[0].funnel === "Total" ||
    data[0].performance === "Total"
  );
};

export const removeTotalChildren = (
  children: any[] | undefined,
  level: number = 1,
): any[] => {
  if (!children || children.length === 0) return [];

  return children
    .filter((child) => {
      if (level === 1) return true;

      return !(
        child.campaign_name === "Total" ||
        child.ad_type === "Total" ||
        child.match_type === "Total" ||
        child.target === "Total" ||
        child.funnel === "Total" ||
        child.performance === "Total"
      );
    })
    .map((child) => {
      if (child.children && child.children.length > 0) {
        return {
          ...child,
          children: removeTotalChildren(child.children, level + 1),
        };
      }
      return child;
    });
};

/** A small helper to format dates into YYYY-MM-DD. */
export const toIsoDate = (date: Date) => date.toISOString().split("T")[0];

/**
 * Returns columns based on the selected tab.
 */
export const getColumns = (tab: TypeTab, isWhatIf: boolean) => {
  switch (tab) {
    case "Campaigns":
      return isWhatIf ? campaignsColumns : campaignsColumnsWithoutNyle;
    case "Performance":
      return isWhatIf ? performanceColumns : performanceColumnsWithoutNyle;
    case "Ad Type":
      return isWhatIf ? adTypeColumns : adTypeColumnsWithoutNyle;
    case "Funnel":
      return isWhatIf ? funnelColumns : funnelColumnsWithoutNyle;
    case "Targeting":
      return isWhatIf ? targetingColumns : targetingColumnsWithoutNyle;
    case "Match type":
      return isWhatIf ? matchColumns : matchColumnsWithoutNyle;
  }
};

/**
 * Formats keyword options for the Select component.
 */
export const formattedKeys = (keys: string[]) => {
  return keys.map((key, index) => (
    <div className={styles.keys__container} key={key}>
      <Checkbox />
      <div className={styles.star}>
        <StarKeyword fill={getStarColor(index)} />
      </div>
      <span>{key}</span>
    </div>
  ));
};

/**
 * We extend the IPerformanceTableData to add fields for nested expansions.
 */

/**
 * A helper to update a node's `children` in a nested array by a matching `recordKey`.
 */
export function updateRecordChildren(
  records: INestedData[],
  recordKey: number,
  children: INestedData[],
): INestedData[] {
  return records.map((item) => {
    if (item.key == recordKey) {
      return {
        ...item,
        children,
        hasFetchedChildren: true,
      };
    } else if (item.children && item.children.length > 0) {
      return {
        ...item,
        children: updateRecordChildren(item.children, recordKey, children),
      };
    }
    return item;
  });
}

export const getSaturationKeyField = (selectedTab: string): string => {
  switch (selectedTab) {
    case "Performance":
      return "performance";
    case "Ad Type":
      return "ad_type";
    case "Match type":
      return "match_type";
    case "Targeting":
      return "target";
    case "Funnel":
      return "funnel";
    case "Campaigns":
      return "campaign_name";
    default:
      return "";
  }
};

export const updateNestedRecord = async (
  record: any,
  tableData: any[],
  params: {
    from: string;
    to: string;
    selectedTab: TypeTab;
    saturation?: number;
    asins?: string[];
    saturationKeys?: string[];
    expandedKeys?: string[];
  },
) => {
  const {
    from,
    to,
    selectedTab,
    saturation,
    asins,
    saturationKeys = [],
    expandedKeys = [],
  } = params;

  let updatedData;

  // Handle different levels
  if (Number(record.level) === 1) {
    const allData = await fetcherMap[selectedTab](
      from,
      to,
      saturation,
      asins,
      saturationKeys,
    );

    const keyField = getSaturationKeyField(selectedTab);
    updatedData = [allData.find((item) => item[keyField] === record[keyField])];
  } else {
    const getData =
      Number(record.level) === 2
        ? level1Map[selectedTab]
        : level2Map[selectedTab];

    if (!getData) return tableData;

    updatedData = await getData(
      from,
      to,
      record,
      saturation,
      asins,
      saturationKeys,
    );
  }

  if (!updatedData?.length) return tableData;

  // Function to recursively update children if row is expanded
  const updateExpandedChildren = async (item: any): Promise<any> => {
    if (!expandedKeys.includes(item.key)) {
      return item;
    }

    // If row is expanded but has no children, fetch them
    if (!item.children || item.children.length === 0) {
      const getChildrenData =
        Number(item.level) === 1
          ? level1Map[selectedTab]
          : level2Map[selectedTab];

      if (getChildrenData) {
        const childrenData = await getChildrenData(
          from,
          to,
          item,
          saturation,
          asins,
          saturationKeys,
        );

        item.children = childrenData.map((child: any, index: number) => ({
          ...child,
          key: `${item.key}-${index}`,
          level: Number(item.level) + 1,
          hasFetchedChildren: false,
        }));
      }
    }

    // Recursively update children
    if (item.children && item.children.length > 0) {
      item.children = await Promise.all(
        item.children.map(updateExpandedChildren),
      );
    }

    return item;
  };

  // Update records and their children
  const updateRecords = async (items: any[]): Promise<any[]> => {
    const updatedItems = await Promise.all(
      items.map(async (item) => {
        if (item.key === record.key) {
          const updatedItem = {
            ...item,
            impressions: updatedData[0].impressions,
            clicks: updatedData[0].clicks,
            ctr: updatedData[0].ctr,
            cpc: updatedData[0].cpc,
            spend: updatedData[0].spend,
            orders: updatedData[0].orders,
            units_sold: updatedData[0].units_sold,
            conversion_rate: updatedData[0].conversion_rate,
            acos: updatedData[0].acos,
            roas: updatedData[0].roas,
            sales: updatedData[0].sales,
            performance: item.performance,
            campaign_name: item.campaign_name,
            ad_type: item.ad_type,
            target: item.target,
            match_type: item.match_type,
            funnel: item.funnel,
            performanceKey: item.performanceKey,
            ad_typeKey: item.ad_typeKey,
            match_type_key: item.match_type_key,
            funnelKey: item.funnelKey,
            campaign_nameKey: item.campaign_nameKey,
            children: item.children,
          };
          return updateExpandedChildren(updatedItem);
        }

        if (item.children?.length) {
          const updatedChildren = await updateRecords(item.children);
          return {
            ...item,
            children: updatedChildren,
          };
        }

        return item;
      }),
    );

    return updatedItems;
  };

  return updateRecords(tableData);
};

const canvasApi = new CanvasApi();

export const fetcherMap: Record<
  string,
  (
    start: string,
    end: string,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
  ) => Promise<any[]>
> = {
  Performance: canvasApi.getSalesPerformance,
  "Ad Type": canvasApi.getSalesPerformanceAdType,
  "Match type": canvasApi.getSalesPerformanceMatchType,
  Targeting: canvasApi.getSalesPerformanceTargets,
  Funnel: canvasApi.getSalesPerformanceFunnel,
  Campaigns: canvasApi.getSalesPerformanceCampaigns,
};

export const level1Map: Record<
  string,
  (
    start: string,
    end: string,
    r: any,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
  ) => Promise<any>
> = {
  Performance: (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceCampaign(
      s,
      e,
      r.performanceKey || r.performance,
      saturation,
      asins,
      saturation_keys,
    ),
  "Ad Type": (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceAdTypeCampaign(
      s,
      e,
      r.ad_typeKey || r.ad_type,
      saturation,
      asins,
      saturation_keys,
    ),
  "Match type": (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceMatchTypeCampaign(
      s,
      e,
      r.match_type_key || r.match_type,
      saturation,
      asins,
      saturation_keys,
    ),
  Targeting: (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceTargetsCampaigns(
      s,
      e,
      r.target,
      saturation,
      asins,
      saturation_keys,
    ),
  Funnel: (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceFunnelCampaign(
      s,
      e,
      r.funnelKey || r.funnel,
      saturation,
      asins,
      saturation_keys,
    ),
  Campaigns: (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceCampaignsSearchTerms(
      s,
      e,
      r.campaign_nameKey || r.campaign_name,
      saturation,
      asins,
      saturation_keys,
    ),
};

export const level2Map: Record<
  string,
  (
    start: string,
    end: string,
    r: any,
    saturation?: number,
    asins?: string[],
    saturation_keys?: string[],
  ) => Promise<any>
> = {
  Performance: (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceTarget(
      s,
      e,
      r.performanceKey,
      r.performance,
      saturation,
      asins,
      saturation_keys,
    ),
  "Ad Type": (s, e, r, saturation, asins, saturation_keys) => {
    return canvasApi.getSalesPerformanceAdTypeSearchTerms(
      s,
      e,
      r.ad_typeKey,
      r.ad_type,
      saturation,
      asins,
      saturation_keys,
    );
  },
  "Match type": (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceMatchTypeTarget(
      s,
      e,
      r.match_type_key,
      r.match_type,
      saturation,
      asins,
      saturation_keys,
    ),
  Funnel: (s, e, r, saturation, asins, saturation_keys) =>
    canvasApi.getSalesPerformanceFunnelSearchTerms(
      s,
      e,
      r.funnelKey,
      r.funnel,
      saturation,
      asins,
      saturation_keys,
    ),
};

export const getSaturationKey = (record: any, selectedTab: string): string => {
  switch (selectedTab) {
    case "Performance":
      return record.performance;
    case "Ad Type":
      return record.ad_type;
    case "Match type":
      return record.match_type;
    case "Targeting":
      return record.target;
    case "Funnel":
      return record.funnel;
    case "Campaigns":
      return record.campaign_name;
    default:
      return "";
  }
};

export const getTableStringKey = (
  selectedTab: string,
  level?: number,
): string => {
  switch (selectedTab) {
    case "Performance":
      return level === 2 ? "performanceKey" : "performance";
    case "Ad Type":
      return level === 2 ? "campaign" : "ad_type";
    case "Match type":
      return level === 2 ? "match_type_key" : "match_type";
    case "Targeting":
      return "target";
    case "Funnel":
      return level === 2 ? "funnelKey" : "funnel";
    case "Campaigns":
      return level === 2 ? "search_term" : "campaign_name";
    default:
      return "";
  }
};
