import classNames from "classnames";
import styles from "../../styles.module.scss";
import React from "react";

interface WhatIfToggleProps {
  isWhatIf: boolean;
  onChange: (isWhatIf: boolean) => void;
}

export const WhatIfToggle: React.FC<WhatIfToggleProps> = ({
  isWhatIf,
  onChange,
}) => {
  return (
    <div className={styles.whatIf}>
      <button
        className={classNames(styles.whatIf__button, {
          [styles.whatIf__button_active]: isWhatIf,
        })}
        onClick={() => onChange(true)}
      >
        What If
      </button>
      <button
        className={classNames(styles.whatIf__button, {
          [styles.whatIf__button_active]: !isWhatIf,
        })}
        onClick={() => onChange(false)}
      >
        As Is
      </button>
    </div>
  );
};
