import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { ROUTES } from "src/router/routes.ts";
import Logo from "@assets/img/logo.tsx";
import { HeaderNav } from "@components/AppHeader/HeaderNav.tsx";
import { HeaderActions } from "@components/AppHeader/HeaderActions.tsx";
import { HeaderAvatar } from "@components/AppHeader/HeaderAvatar";
import MenuIcon from "@assets/icons/menu";
import CloseMenuIcon from "@assets/icons/closeMenu";
import { useIsMobile } from "src/utils/isMobile";
import useUserAmazonCheckerStore from "src/store/userAmazonCheacker.store";

export const AppHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useIsMobile();
  const { isAmazonConnectedOrSeller, checkAmazonStatus } =
    useUserAmazonCheckerStore();
  const [isAmazonConnectModalOpen, setAmazonConnectModalOpen] = useState(false);

  useEffect(() => {
    try {
      checkAmazonStatus();
    } catch (error) {
      console.error("Error checking Amazon status:", error);
    }
  }, [checkAmazonStatus]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <header className="app-header">
      <div className="app-header__inner">
        {isMobile && (
          <button className="app-header__menu-button" onClick={toggleMenu}>
            {menuOpen ? <CloseMenuIcon /> : <MenuIcon />}
          </button>
        )}
        <Link
          to={isAmazonConnectedOrSeller ? ROUTES.ROOT : "#"}
          className="app-header__logo-link"
          onClick={
            !isAmazonConnectedOrSeller
              ? () => setAmazonConnectModalOpen(true)
              : undefined
          }
        >
          <Logo />
        </Link>
        {isMobile ? (
          menuOpen && (
            <HeaderNav
              menuOpens={menuOpen}
              setMenuOpens={setMenuOpen}
              isAmazonConnectedOrSeller={isAmazonConnectedOrSeller}
              isAmazonConnectModalOpen={isAmazonConnectModalOpen}
              setAmazonConnectModalOpen={setAmazonConnectModalOpen}
            />
          )
        ) : (
          <HeaderNav
            menuOpens={true}
            setMenuOpens={() => {}}
            isAmazonConnectedOrSeller={isAmazonConnectedOrSeller}
            isAmazonConnectModalOpen={isAmazonConnectModalOpen}
            setAmazonConnectModalOpen={setAmazonConnectModalOpen}
          />
        )}
        <div className="app-header__right">
          <HeaderActions />
          <HeaderAvatar />
        </div>
      </div>
    </header>
  );
};
