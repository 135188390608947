import { useEffect, useState } from "react";
import "./index.css";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { Tab } from "../Widgets/Tab";
import { useSuplyStore } from "@pages/Dashboard/store/suplyChain.state";
import { Spin } from "antd";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WidgetClass } from "@services/widget.service";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { Widget } from "../Widgets/Widget";
import MarketplaceTable from "../MarketSalesTable";
import classNames from "classnames";
import { CampaignsChart } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart";
import { Checks } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";

export const SalesChannel = () => {
  const [widgets, setWidgets] = useState<IWidget[]>();
  const [isChecksFixed, setIsChecksFixed] = useState(false);
  const [checks, setChecks] = useState<Checks>({
    sales: true,
    spend: true,
    impressions: true,
    ctr: true,
    cvr: true,
    cpc: true,
    acos: true,
    roas: true,
    orders: true,
    unitSolds: true,
    clicks: false,
    tacos: false,
    organicRank: false,
    searchTermAdImpressionRank: false,
    timeInBudget: false,
    tosAdImpressionShare: false,
  });
  const { customSalesWidgets } = useSuplyStore((state) => ({
    customSalesWidgets: state.customSalesWidgets,
  }));
  const { compareWith } = useAdsAnaliticsStore();
  const { dateRange, compareType } = useDashboardStore();
  const isRestrictedRoute = location.pathname.includes(" ");
  const [isLoading, setIsLoading] = useState(true);
  const { startDate, endDate } = dateRange;
  const { selectedWidgetsSales } = useDashboardStore();

  const [showChart, setShowChart] = useState(false);

  const formatDifference = (difference: string): string => {
    if (difference.startsWith("+") || difference.startsWith("-")) {
      return difference;
    }
    return `+${difference}`;
  };

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        selectedWidgetsSales,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
        compareType,
      );

      let updatedWidgets = response;

      updatedWidgets = selectedWidgetsSales
        .map((key) => {
          const widget = response.find((w) => w.id === key);

          if (!widget) return null;
          const label = WIDGET_METRICS_CONFIG[key]?.label || key;
          return {
            ...widget,
            title: label.trim(),
            name: label.trim(),
            difference: formatDifference(widget.difference),
          };
        })
        .filter(Boolean)
        .slice(0, 10);

      setWidgets(updatedWidgets);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [selectedWidgetsSales, dateRange, compareWith, compareType]);

  const activeChecks = Object.keys(checks).filter((key) => checks[key]).length;

  const handleFixChecks = () => {
    if (isChecksFixed) {
      setIsChecksFixed(false);
      return;
    }
    if (activeChecks > 6) {
      setChecks({
        sales: true,
        spend: true,
        impressions: false,
        ctr: true,
        cvr: true,
        cpc: true,
        acos: false,
        roas: false,
        orders: false,
        unitSolds: true,
        clicks: false,
        tacos: false,
        organicRank: false,
        searchTermAdImpressionRank: false,
        timeInBudget: false,
        tosAdImpressionShare: false,
      });
      setIsChecksFixed(true);
    }
  };

  return (
    <>
      <div className="SalesChannels">
        <h1 className="tabContainer">All Sales Channels</h1>
        <Tab
          customSelectedWidgets={customSalesWidgets}
          isRestrictedRoute={true}
          hideCalendar={true}
        />
      </div>

      <div className="containerMetric">
        {isLoading ? (
          <div className="loading">
            <Spin />
          </div>
        ) : (
          <div className="widgets">
            {widgets?.map((widget, index) => (
              <Widget key={index} widget={widget} additionalClass="widget" />
            ))}
          </div>
        )}
      </div>
      {!showChart && (
        <button className="show" onClick={() => setShowChart(true)}>
          Show Chart
        </button>
      )}

      {showChart && (
        <div className={classNames("chart", "box")}>
          <CampaignsChart
            checks={checks}
            handleFixChecks={handleFixChecks}
            useDashed={true}
          />
        </div>
      )}

      <MarketplaceTable />
    </>
  );
};
