import { Element } from "./ElementsAccordion";
import { v4 as uuid } from "uuid";
import browser from "@assets/icons/canvas/browser.png";
import chat from "@assets/icons/canvas/chat.png";
import chart from "@assets/icons/canvas/chart.png";
import procent from "@assets/icons/canvas/procent.png";
import settings from "@assets/icons/canvas/settings.png";
import table from "@assets/icons/canvas/table.png";
import list from "@assets/icons/canvas/list.png";
import sankey from "@assets/icons/canvas/sankey.png";
import columns from "@assets/icons/canvas/columns.png";
import funnel from "@assets/icons/canvas/funnel.png";
import lightbulb from "@assets/icons/canvas/lightbulb.svg";
import browserThin from "@assets/icons/canvas/browser-thin.png";
import { ELEMENT_ID } from "src/store/canva/element-id";

export const croElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRODUCT_DETAILS,
    name: "Product details",
    active: false,
    icon: browser,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS,
    name: "External product page",
    active: false,
    icon: browserThin,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ASPECT_OPTIMIZATION,
    name: "Aspect optimization",
    active: false,
    icon: procent,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.SCORE_REVIEWS,
    name: "Reviews Score",
    active: false,
    icon: procent,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.CONTENT_OPTIMIZATION,
    name: "Content optimization",
    active: false,
    icon: settings,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRICE_OPTIMIZATION,
    name: "Price optimization",
    active: false,
    icon: settings,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.REVIEWS_OPTIMIZATION,
    name: "Reviews optimization",
    active: false,
    icon: chat,
  },
];

export const summaryElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.EXECUTIVE_SUMMARY,
    name: "Executive summary",
    active: false,
    icon: table,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.SALES_PERFORMANCE,
    name: "Sales performance",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.SUMMARY_AUDIT,
    name: "Summary of audit",
    active: false,
    icon: lightbulb,
  },
];

export const campaignElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SALES_SLIDERS,
    name: "Ad Spend",
    active: false,
    icon: settings,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.SATURATION_CURVE,
    name: "Saturation Curve",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.GOAL_SETTING,
    name: "Goal Setting",
    active: false,
    icon: settings,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SANKEY,
    name: "Ads Sankey",
    active: false,
    icon: sankey,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.FUNNEL,
    name: "Funnel Performance",
    active: false,
    icon: funnel,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRODUCT_PLACEMENT,
    name: "Product Placement",
    active: false,
    icon: columns,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.ADS_SALES,
    name: "Ad Sales",
    active: false,
    icon: list,
  },
];

export const organicElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.ORGANIC_SALES,
    name: "Keyword Performance",
    active: false,
    icon: columns,
  },
];

export const financeElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.FINANCIAL_OVERVIEW,
    name: "Financial Overview",
    active: false,
    icon: table,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.CASH_FLOW,
    name: "Cash Flow",
    active: false,
    icon: table,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.PRODUCT_COST,
    name: "Product Cost",
    active: false,
    icon: table,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.WATERFALL,
    name: "Waterfall",
    active: false,
    icon: table,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.TOTAL_SALES,
    name: "Total Sales",
    active: false,
    icon: table,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.CONTRIBUTION_PROFIT,
    name: "Contribution Profit",
    active: false,
    icon: table,
  },
];

export const marketElements: Element[] = [
  {
    id: uuid(),
    elementId: ELEMENT_ID.MARKET_LEVEL_SALES_DISTRIBUTION,
    name: "Market Level Sales Distribution",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.MARKET_OVERVIEW,
    name: "Market Overview (bubble)",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.MARKET_SHARE,
    name: "Market Share",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.MARKET_SALES_PERFORMANCE,
    name: "Market Sales Performance",
    active: false,
    icon: list,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.BRAND_SALES_PERFORMANCE,
    name: "Brand Sales Performance",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.LAUNCH_PRODUCT,
    name: "Product Launch",
    active: false,
    icon: chart,
  },
  {
    id: uuid(),
    elementId: ELEMENT_ID.LAUNCH_PRODUCT_TABLE,
    name: "Product Launch (table)",
    active: false,
    icon: table,
  },
];

export const supplyChainElements: Element[] = [];

export const reportsElements: Element[] = [];

const widgetSizeMap: Record<ELEMENT_ID, { width: number; height: number }> = {
  [ELEMENT_ID.SUMMARY_AUDIT]: { width: 8, height: 47 },
  [ELEMENT_ID.EXECUTIVE_SUMMARY]: { width: 8, height: 42 },
  [ELEMENT_ID.ADS_SALES]: { width: 8, height: 64 },
  [ELEMENT_ID.ORGANIC_KEYWORD_PERFORMANCE]: { width: 8, height: 72 },
  [ELEMENT_ID.ORGANIC_SALES]: { width: 8, height: 60 },
  [ELEMENT_ID.ADS_SANKEY]: { width: 8, height: 78 },
  [ELEMENT_ID.FUNNEL]: { width: 8, height: 76 },
  [ELEMENT_ID.SCORE_REVIEWS]: { width: 8, height: 70 },
  [ELEMENT_ID.ORGANIC_MISSING_TARGET]: { width: 8, height: 70 },
  [ELEMENT_ID.ADS_SALES_SLIDERS]: { width: 5, height: 50 },
  [ELEMENT_ID.CAMPAIGN_METRICS]: { width: 8, height: 80 },
  [ELEMENT_ID.PRODUCT_DETAILS]: { width: 5, height: 96 },
  [ELEMENT_ID.ASPECT_OPTIMIZATION]: { width: 3, height: 85 },
  [ELEMENT_ID.CONTENT_OPTIMIZATION]: { width: 3, height: 71 },
  [ELEMENT_ID.PRICE_OPTIMIZATION]: { width: 3, height: 71 },
  [ELEMENT_ID.REVIEWS_OPTIMIZATION]: { width: 3, height: 50 },
  [ELEMENT_ID.EXTERNAL_PRODUCT_DETAILS]: { width: 2, height: 51 },
  [ELEMENT_ID.DEMOGRAPHIC_TYPE]: { width: 10, height: 135 },
  [ELEMENT_ID.DEMOGRAPHIC_TABLE]: { width: 10, height: 43 },
  [ELEMENT_ID.PRODUCT_PLACEMENT]: { width: 8, height: 40 },
  [ELEMENT_ID.WHAT_IF_CHART]: { width: 10, height: 66 },
  [ELEMENT_ID.TARGITING_PROFILES]: { width: 12, height: 33 },
  [ELEMENT_ID.PNL_BLOCK]: { width: 8, height: 112 },
  [ELEMENT_ID.DEMOGRAPHIC_TYPE_SHARE]: { width: 15, height: 40 },
  [ELEMENT_ID.BRAND]: { width: 16, height: 100 },
  [ELEMENT_ID.DATA_ENTRY]: { width: 10, height: 52 },
  [ELEMENT_ID.SATURATION_CURVE]: { width: 8, height: 55 },
  [ELEMENT_ID.MARKET_LEVEL_SALES_DISTRIBUTION]: { width: 8, height: 68 },
  [ELEMENT_ID.MARKET_SHARE]: { width: 5, height: 70 },
  [ELEMENT_ID.SALES_VELICITY]: { width: 8, height: 55 },
  [ELEMENT_ID.MARKET_OVERVIEW_BUBBLE]: { width: 8, height: 55 },
  [ELEMENT_ID.MARKET_OVERVIEW]: { width: 5, height: 68 },
  [ELEMENT_ID.LAUNCH_PRODUCT]: { width: 8, height: 126 },
  [ELEMENT_ID.FUNNEL_MARKET]: { width: 8, height: 55 },
  [ELEMENT_ID.SALES_PERFORMANCE]: { width: 8, height: 55 },
  [ELEMENT_ID.GOAL_SETTING]: { width: 3, height: 25 },
  [ELEMENT_ID.LAUNCH_PRODUCT_TABLE]: { width: 8, height: 55 },
  [ELEMENT_ID.FINANCIAL_OVERVIEW]: { width: 8, height: 55 },
  [ELEMENT_ID.CASH_FLOW]: { width: 8, height: 55 },
  [ELEMENT_ID.PRODUCT_COST]: { width: 8, height: 55 },
  [ELEMENT_ID.WATERFALL]: { width: 8, height: 55 },
  [ELEMENT_ID.TOTAL_SALES]: { width: 8, height: 55 },
  [ELEMENT_ID.CONTRIBUTION_PROFIT]: { width: 8, height: 55 },
  [ELEMENT_ID.MARKET_SALES_PERFORMANCE]: { width: 8, height: 55 },
  [ELEMENT_ID.BRAND_SALES_PERFORMANCE]: { width: 8, height: 65 },
};

export const getWidgetSize = (
  elementId: ELEMENT_ID,
  brandsCount: number = 1,
): { width: number; height: number } => {
  if (elementId === ELEMENT_ID.BRAND) {
    const width = widgetSizeMap[elementId]?.width || 16;
    if (brandsCount > 0 && brandsCount < 3) {
      return { width, height: 27 * brandsCount };
    } else if (brandsCount >= 3) {
      return { width, height: 10 * brandsCount };
    }
  }

  return widgetSizeMap[elementId] || { width: 4, height: 60 };
};
