import { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import ProductDetails from "../ProductDetails";
import ProductImage from "../ProductImage";
import ProductCard from "../ProductCard";
import ProductHeaderTitle from "../components/ProductHeaderTitle";
import VideoSection from "../VideoSection";
import classNames from "classnames";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductsStore } from "src/store/product.state";
import { useProductStore } from "src/store/overviewProduct.state";
import { content } from "../components/AContent/mock";
import AContent from "../components/AContent";
import OptimisationArrow from "@assets/icons/optimisationArrow";
import { useLocation, useNavigate } from "react-router-dom";
import { useMaintenance } from "src/store/maintenance.state";

interface ProductTextProps {
  customAsin?: string;
  showColumn?: "left" | "right";
}

export const ProductText = ({ customAsin, showColumn }: ProductTextProps) => {
  const [showAContent, setShowAContent] = useState(false);
  const { productDataByAsin, productMaterials, setProductMaterials } =
    useProductsStore();
  const { selectedProduct } = useProductStore();
  const [isUpdated, setIsUpdated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { maintenance } = useMaintenance();

  useEffect(() => {
    const fetchProductData = async () => {
      const asin = customAsin || selectedProduct?.asin;

      if (!asin) {
        return;
      }

      try {
        if (!productDataByAsin || productDataByAsin.asin !== asin) {
          await AmazonService.products.getProductDataByAsin(asin);
        }

        if (!productMaterials || productMaterials.asin !== asin) {
          const [imagesData, videosData] = await Promise.all([
            AmazonService.products.getProductDataByAsinImgs([asin]),
            AmazonService.products.getProductDataByAsinVideos([asin]),
          ]);

          setProductMaterials({
            images: imagesData as any,
            videos: videosData as any,
            asin,
          });
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [customAsin, selectedProduct]);

  const handleAContent = () => {
    setShowAContent(!showAContent);
  };

  useEffect(() => {
    setIsUpdated(false);
  }, [customAsin, selectedProduct]);

  useEffect(() => {
    if (location.state && location.state.isUpdated !== undefined) {
      setIsUpdated(location.state.isUpdated);
    }
  }, [location.state]);

  useEffect(() => {
    if (isUpdated) {
      navigate(location.pathname, { replace: true });
    }
  }, [isUpdated, navigate, location.pathname]);

  return (
    <>
      {showColumn !== "right" && (
        <div
          className={classNames(
            styles.leftColumn,
            styles.sticky,
            showColumn === "left" && styles.fullWidth,
          )}
        >
          <ProductCard
            title={productDataByAsin?.item_name}
            price={productDataByAsin?.price}
            image={productDataByAsin?.image_link}
            isUpdated={isUpdated}
          />
        </div>
      )}

      {showColumn !== "left" && (
        <div className={classNames(styles.middleColumn, styles.sticky)}>
          <div className={styles.productDetailPageWrapper}>
            <ProductHeaderTitle title="Product details" />
            <div className={styles.productDetailPage}>
              <div className={styles.imageAndDetails}>
                <ProductImage images={productMaterials?.images} />
                <ProductDetails
                  name={productDataByAsin?.item_name}
                  points={productDataByAsin?.bullet_points}
                  isUpdated={isUpdated}
                />
              </div>
              <VideoSection videos={productMaterials?.videos} />
              {maintenance && (
                <div className={styles.aPlusContent}>
                  <button type="button" onClick={handleAContent}>
                    A+ Content
                    <div
                      className={`${styles.arrow} ${showAContent ? styles.rotated : ""}`}
                    >
                      <OptimisationArrow />
                    </div>
                  </button>
                  {showAContent && <AContent data={content} />}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
