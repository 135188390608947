export const BRAND_DATA = [
  { name: "Crest", value: 8000000 },
  { name: "Lumineux", value: 3700000 },
  { name: "InoPro", value: 1400000 },
  { name: "GuruNanda", value: 623000 },
  { name: "Keidoc", value: 1209000 },
];

export const BRAND_COLORS = [
  "#5295E0",
  "#6B4DBA",
  "#80C67A",
  "#00F9F4",
  "#FE5858",
];

export const brandLegendOrder = [
  "Crest",
  "Lumineux",
  "InoPro",
  "GuruNanda",
  "Keidoc",
];

export const DARK_YELLOW = "#DAA520";
export const renderInnerDots = (cx, cy, innerRadius) => {
  const dots = [];
  const dotCount = 30;
  const radius = innerRadius - 15;

  for (let i = 0; i < dotCount; i++) {
    const angle = (i / dotCount) * 2 * Math.PI;
    const x = cx + radius * Math.cos(angle);
    const y = cy + radius * Math.sin(angle);
    dots.push(<circle key={i} cx={x} cy={y} r={1} fill={DARK_YELLOW} />);
  }
  return <g>{dots}</g>;
};

export const getLegendValue = (item, hideTitle, hidePercentage, dataSales) => {
  if (hideTitle) {
    return "";
  }
  if (hidePercentage) {
    return item.name;
  }
  if (dataSales) {
    return `${item.name} $${(item.value / 1000).toFixed(1).replace(".", ",")}K`;
  }
  return `${item.name} $${(item.value / 1000000).toFixed(1)}M`;
};

export const getLegendColor = (item, index, hideTitle, dataSales) => {
  if (hideTitle) {
    return "transparent";
  }
  if (dataSales) {
    if (item.name === "Organic Social") {
      return "#5295E0";
    }
    if (item.name === "Amazon") {
      return "#FE5858";
    }
  }
  return BRAND_COLORS[index % BRAND_COLORS.length];
};
