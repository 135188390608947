import React from "react";
import styles from "./styles.module.scss";

const CustomLegend = ({ payload, hidePercentage }) => {
  return (
    <div className={styles.customLegendContainer}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className={styles.legendItem}>
          <span
            className={styles.legendDot}
            style={{ backgroundColor: entry.color }}
          />
          <span className={styles.legendText}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomLegend;
