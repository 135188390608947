import { Api } from "src/utils/api";
import { SALES_PERFORMANCE_API_BASE_URL } from "./sales-performance.constant";

export class SalesPerformanceApi extends Api {
  constructor() {
    super(SALES_PERFORMANCE_API_BASE_URL);
  }

  public getAdSpend = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("ad-spend", {
      date_start,
      date_end,
    });
  };

  public getTotalSales = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("total-sales", {
      date_start,
      date_end,
    });
  };
  public getAdSales = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("ad-sales", {
      date_start,
      date_end,
    });
  };
  public getTotalAcos = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("acos", {
      date_start,
      date_end,
    });
  };
  public getTotalRoas = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("roas", {
      date_start,
      date_end,
    });
  };
  public getTotalCpc = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("cpc", {
      date_start,
      date_end,
    });
  };
  public getAdClicks = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("clicks", {
      date_start,
      date_end,
    });
  };

  public getAdImpressions = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("impressions", {
      date_start,
      date_end,
    });
  };

  public getAdCtr = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("ctr", {
      date_start,
      date_end,
    });
  };

  public getAdCvr = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("cvr", {
      date_start,
      date_end,
    });
  };

  public getAdCpc = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("cpc", {
      date_start,
      date_end,
    });
  };

  public getAdAcos = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("acos", {
      date_start,
      date_end,
    });
  };

  public getAdRoas = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("roas", {
      date_start,
      date_end,
    });
  };

  public getAdUnitsSold = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return await this.get("unit-sold", {
      date_start,
      date_end,
    });
  };

  public getTotalUnitsSold = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return Promise.resolve([0]);
  };

  public getOrganicUnitsSold = async (
    date_start: string,
    date_end: string,
  ): Promise<number[]> => {
    return Promise.resolve([0]);
  };
}
