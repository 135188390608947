import { create } from "zustand";

interface GlobalResetStore {
  resetTriggered: boolean;
  hasChanges: boolean;
  hasSettingsChanges: boolean;
  setHasSettingsChanges: (hasChanges: boolean) => void;
  setHasChanges: (hasChanges: boolean) => void;
  triggerReset: () => void;
  resetComplete: () => void;
}

export const useGlobalResetStore = create<GlobalResetStore>((set) => ({
  resetTriggered: false,
  hasChanges: false,
  hasSettingsChanges: false,
  setHasSettingsChanges: (hasChanges) =>
    set({ hasSettingsChanges: hasChanges }),
  setHasChanges: (hasChanges) => set({ hasChanges }),
  triggerReset: () => set({ resetTriggered: true }),
  resetComplete: () => set({ resetTriggered: false }),
}));
