import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import styles from "./styles.module.scss";
import { convertDate, formatReviewScore, generateTicks } from "./ratingUtils";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.customTooltip}>
        <p className={styles.label}>{`Date: ${convertDate(label)}`}</p>
        {payload.map((entry, index) => {
          const displayValue =
            entry.name === "Review score"
              ? formatReviewScore(entry.value)
              : `${entry.value}%`;
          return (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${displayValue}`}
            </p>
          );
        })}
      </div>
    );
  }
  return null;
};

const CustomLegend = ({ payload }) => {
  return (
    <div className={styles.customLegend}>
      {payload.map((entry, index) => (
        <div key={`item-${index}`} className={styles.legendItem}>
          <span
            className={styles.legendColor}
            style={{ backgroundColor: entry.color }}
          />
          <span className={styles.legendText}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

interface ReviewChartProps {
  startDate?: string;
  endDate?: string;
  data?: Array<{
    date: string;
    reviewScore: number;
  }>;
}

export const ReviewChart: React.FC<ReviewChartProps> = ({
  startDate,
  endDate,
  data,
}) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const ticks = generateTicks(start, end);
  const diffDays = Math.ceil(
    (end.getTime() - start.getTime()) / (1000 * 3600 * 24),
  );
  const xAxisInterval = diffDays >= 28 ? 1 : 0;

  return (
    <div className={styles.reviewScoreChart}>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 0,
          }}
        >
          <Legend
            content={CustomLegend}
            verticalAlign="top"
            align="center"
            wrapperStyle={{ paddingBottom: 10 }}
          />
          <CartesianGrid stroke="#444" strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="date"
            domain={[start.getTime(), end.getTime()]}
            tickFormatter={convertDate}
            ticks={ticks}
            stroke="#7C7C78"
            interval={xAxisInterval}
            allowDataOverflow={true}
          />

          <YAxis type="number" domain={[1, 5]} stroke="#7C7C78" />
          <Tooltip content={CustomTooltip} />
          <Line
            type="monotone"
            dataKey="reviewScore"
            stroke="#8884d8"
            strokeWidth={2}
            dot={false}
            name="Review score"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
