import trendUp from "@assets/icons/trendUp.svg";
import trendDown from "@assets/icons/trendDown.svg";
import { WidgetClass } from "@services/widget.service";
import { WidgetMetricKey } from "@services/math/math.service";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";

const widgetService = new WidgetClass();
const metricsCache = new Map();

export const preloadTooltipMetrics = async (dateRange: {
  startDate: Date;
  endDate: Date;
}) => {
  if (!dateRange) {
    console.error("Date range is required for tooltip metrics");
    return;
  }

  const startDateString = dateRange.startDate.toISOString().split("T")[0];
  const endDateString = dateRange.endDate.toISOString().split("T")[0];

  const daysDiff = Math.ceil(
    (dateRange.endDate.getTime() - dateRange.startDate.getTime()) /
      (1000 * 60 * 60 * 24),
  );
  const compareStartDate = new Date(dateRange.startDate);
  compareStartDate.setDate(compareStartDate.getDate() - daysDiff);
  const compareEndDate = new Date(dateRange.startDate);
  compareEndDate.setDate(compareEndDate.getDate() - 1);

  const compareStartString = compareStartDate.toISOString().split("T")[0];
  const compareEndString = compareEndDate.toISOString().split("T")[0];

  try {
    const organicMetricKeys: WidgetMetricKey[] = [
      "organicImpressions",
      "organicClicks",
      "organicUnitsSold",
      "organicSales",
      "organicCtr",
      "organicCvr",
      "tacos",
      "organicPOS",
    ];

    const adMetricKeys: WidgetMetricKey[] = [
      "adImpressions",
      "adClicks",
      "adUnitsSold",
      "adSales",
      "adSpend",
      "adAcos",
      "adCtr",
      "adCvr",
      "adPOS",
    ];

    const [organicMetrics, adMetrics] = await Promise.all([
      widgetService.getWidgetsData(
        organicMetricKeys,
        startDateString,
        endDateString,
        compareStartString,
        compareEndString,
        "percent",
        false,
      ),
      widgetService.getWidgetsData(
        adMetricKeys,
        startDateString,
        endDateString,
        compareStartString,
        compareEndString,
        "percent",
        false,
      ),
    ]);

    const organicObj = organicMetrics.reduce((acc, metric) => {
      acc[metric.key] = {
        value: metric.value,
        difference: metric.difference,
        id: metric.id,
      };
      return acc;
    }, {});

    const adObj = adMetrics.reduce((acc, metric) => {
      acc[metric.key] = {
        value: metric.value,
        difference: metric.difference,
        id: metric.id,
      };
      return acc;
    }, {});

    const cacheKey = `${startDateString}_${endDateString}`;
    metricsCache.set(cacheKey, {
      "Amazon Organic": organicObj,
      "Amazon Ads": adObj,
      timestamp: Date.now(),
    });
  } catch (error) {
    console.error("Error preloading metrics:", error);
  }
};

const renderMetricItem = (label: string, metric: IWidget) => {
  if (!metric) return "";

  const { value, difference, id } = metric;
  const isPositive = !difference.startsWith("-");
  const trendIcon = isPositive ? trendUp : trendDown;
  const colorClass = isPositive ? "#80C67A" : "#FE5858";

  return `
    <div style="display: flex; flex-direction: column; gap: 4px;">
      <span style="color: #5C5C5A;">${label}</span>
      <div style="display: flex; flex-direction: row; gap: 4px; align-items: center;">
        <strong>${widgetService.formatValue(id, parseFloat(value))}</strong>
        ${
          difference !== "0"
            ? `
          <img src="${trendIcon}" alt="${isPositive ? "trend up" : "trend down"}" style="width: 16px; height: 16px;" />
          <span style="color: ${colorClass};">${difference.replace("-", "")}</span>
        `
            : ""
        }
      </div>
    </div>
  `;
};

export const tooltipFormatter = async (
  params: any,
  tooltipX?: number,
  isWhatIf?: boolean,
  dateRange?: { startDate: Date; endDate: Date },
) => {
  if (!dateRange) {
    console.error("Date range is required for tooltip metrics");
    return "";
  }

  const [timestamp, value, seriesName] = params.data;
  const startDateString = dateRange.startDate.toISOString().split("T")[0];
  const endDateString = dateRange.endDate.toISOString().split("T")[0];
  const cacheKey = `${startDateString}_${endDateString}`;

  let supTitle = "(Rest of Search)";
  let positionLabel = "POS";

  if (tooltipX < 420) {
    supTitle = "(First page Top of search)";
    positionLabel = "POS";
  }

  if (tooltipX > 420 && tooltipX < 840) {
    supTitle = "(First page Bottom of search)";
    positionLabel = "POS";
  }

  const cachedData = metricsCache.get(cacheKey);

  if (!cachedData || Date.now() - cachedData.timestamp > 3 * 60 * 1000) {
    return `
      <div style="font-size: 14px; color: #2E2E2E; padding: 10px;">
        Loading metrics data...
      </div>
    `;
  }

  try {
    if (seriesName === "Amazon Organic") {
      const metricsObj = cachedData["Amazon Organic"];

      return `
        <div style="font-size: 14px; color: #2E2E2E; display: flex; flex-direction: column; gap: 12px;">
          <div style="display: flex; flex-direction: row; gap: 4px;">
            <strong style="font-size: 16px;">${seriesName}</strong> 
            <span>${supTitle}</span>
          </div>
          <div style="display: flex; flex-direction: row; gap: 24px;">
            <div style="display: flex; flex-direction: row; gap: 24px; width: 600px; flex-wrap: wrap">
              ${renderMetricItem("Organic Impressions", metricsObj.organicImpressions)}
              ${renderMetricItem("Organic Clicks", metricsObj.organicClicks)}
              ${renderMetricItem("Organic Units Sold", metricsObj.organicUnitsSold)}
              ${renderMetricItem("Organic Sales", metricsObj.organicSales)}
              ${renderMetricItem("Organic CTR", metricsObj.organicCtr)}
              ${renderMetricItem("Organic CVR", metricsObj.organicCvr)}
              ${renderMetricItem("TACOS", metricsObj.tacos)}
              ${renderMetricItem(positionLabel, metricsObj.organicPOS)}
            </div>
          </div>
        </div>
      `;
    } else {
      const metricsObj = cachedData["Amazon Ads"];

      return `
        <div style="font-size: 14px; color: #2E2E2E; display: flex; flex-direction: column; gap: 12px;">
          <strong style="font-size: 16px;">${seriesName}${isWhatIf ? " (What If)" : ""}</strong> 
          <div style="display: flex; flex-direction: row; gap: 24px;">
            <div style="display: grid; grid-template-columns: repeat(5, 140px); gap: 24px; max-width: 780px;">
              ${
                isWhatIf
                  ? `
                      ${renderMetricItem("Ad Impressions What If", metricsObj.adImpressions)}
                      ${renderMetricItem("Ad Clicks What If", metricsObj.adClicks)}
                      ${renderMetricItem("Ad Units Sold What If", metricsObj.adUnitsSold)}
                      ${renderMetricItem("Ad Sales What If", metricsObj.adSales)}
                      ${renderMetricItem("Ad Spend What If", metricsObj.adSpend)}
                      ${renderMetricItem("ACOS What If", metricsObj.adAcos)}
                      ${renderMetricItem("ROAS What If", metricsObj.roas)}
                      ${renderMetricItem("Ad CTR What If", metricsObj.adCtr)}
                      ${renderMetricItem("Ad CVR What If", metricsObj.adCvr)}
                      ${renderMetricItem("POS What If", metricsObj.adPOS)}
                    `
                  : `
                      ${renderMetricItem("Ad Impressions", metricsObj.adImpressions)}
                      ${renderMetricItem("Ad Clicks", metricsObj.adClicks)}
                      ${renderMetricItem("Ad Units Sold", metricsObj.adUnitsSold)}
                      ${renderMetricItem("Ad Sales", metricsObj.adSales)}
                      ${renderMetricItem("Ad Spend", metricsObj.adSpend)}
                      ${renderMetricItem("ACOS", metricsObj.adAcos)}
                      ${renderMetricItem("Ad CTR", metricsObj.adCtr)}
                      ${renderMetricItem("Ad CVR", metricsObj.adCvr)}
                      ${renderMetricItem("POS", metricsObj.adPOS)}
                    `
              }
            </div>
          </div>
        </div>
      `;
    }
  } catch (error) {
    console.error("Error generating tooltip from cached data:", error);
    return `<div>Error displaying tooltip data</div>`;
  }
};
