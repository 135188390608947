// src/store/maintenance.state.ts
import create from "zustand";
import { getEnvironment } from "src/utils/getEnvironment";
import { Environment } from "src/constants/auth.constants";

interface MaintenanceState {
  maintenance: boolean;
  setMaintenance: (value: boolean) => void;
}

const environment = getEnvironment();

export const useMaintenance = create<MaintenanceState>((set) => ({
  maintenance:
    environment === Environment.LOCAL || environment === Environment.DEV,
  setMaintenance: (value) => set({ maintenance: value }),
}));
