import { Slider } from "antd";
import styles from "./styles.module.scss";
import classNames from "classnames";
import SliderReset from "@assets/icons/slider-reset";

interface RangeCardProps {
  title?: string;
  label?: string;
  value: number;
  onChange: (value: [number, number]) => void;
  min?: number;
  max?: number;
  startFrom?: number;
  isWhatIf?: boolean;
  isBlack?: boolean;
  children?: React.ReactNode;
  rowValue?: boolean;
  withoutRange?: boolean;
  onReset?: () => void;
}

export const RangeCard = ({
  title,
  label,
  value,
  onChange,
  min = -100,
  max = 100,
  startFrom = 0,
  isWhatIf,
  isBlack,
  rowValue,
  children,
  withoutRange,
  onReset,
}: RangeCardProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) < min) {
      onChange([Number(event.target.value), startFrom]);
    } else {
      onChange([startFrom, Number(event.target.value)]);
    }
  };

  return (
    <div
      className={classNames(
        styles.container,
        !isWhatIf && styles.whatIf,
        isBlack && styles.black,
        rowValue && styles.rowValue,
      )}
    >
      {isWhatIf && !withoutRange ? (
        <>
          <div className={styles.value}>
            <h4>
              {title}
              {label && <span> {label}</span>}
            </h4>
            <input
              type="number"
              value={value}
              onChange={handleInputChange}
              className={styles.input}
            />
            <button className={styles.reset} onClick={onReset}>
              <SliderReset />
            </button>
          </div>
          <div className={styles.range__input}>
            <Slider
              min={min}
              max={max}
              step={1}
              value={value >= 0 ? [0, value] : [value, 0]}
              defaultValue={[0, 0]}
              onChange={onChange}
              className={classNames(
                "custom-range",
                value >= 0 ? "range__slider" : "range__sliderNegative",
              )}
              range
            />
          </div>
        </>
      ) : (
        <div className={styles.value}>
          <h4>{title}</h4>
          {!withoutRange && <span>{value}</span>}
        </div>
      )}
      {children}
    </div>
  );
};
