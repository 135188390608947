import { FC, useEffect } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { Spin } from "antd";

interface LoadingOverlayProps {
  isLoading: boolean;
  taskStatus?: string;
}

export const LoadingOverlay = ({
  isLoading,
  taskStatus,
}: LoadingOverlayProps) => {
  if (!isLoading) return null;

  // useEffect(() => {
  //   const preventInspect = (event: KeyboardEvent) => {
  //     if (
  //       event.code === "F12" ||
  //       (event.ctrlKey && event.shiftKey && event.code === "KeyI") ||
  //       (event.ctrlKey && event.shiftKey && event.code === "KeyJ") ||
  //       (event.ctrlKey && event.code === "KeyU")
  //     ) {
  //       event.preventDefault();
  //       event.stopPropagation();
  //       return false;
  //     }
  //   };

  //   const preventContextMenu = (event: MouseEvent) => {
  //     event.preventDefault();
  //   };

  //   document.addEventListener("keydown", preventInspect, true);
  //   document.addEventListener("contextmenu", preventContextMenu, true);

  //   return () => {
  //     document.removeEventListener("keydown", preventInspect, true);
  //     document.removeEventListener("contextmenu", preventContextMenu, true);
  //   };
  // }, [isLoading]);

  return (
    <div className={classNames(styles.overlay)}>
      <div className={styles.spin}>
        <h2>
          Generating: <span>{taskStatus ? `${taskStatus}` : ""}</span>
        </h2>
        <Spin size="large" />
      </div>
    </div>
  );
};
