import { useState } from "react";
import style from "./style.module.scss";
import chatbotAvatar from "@assets/karim-chatbot.jpg";
import Logo from "@assets/icons/logo";

export const ChatBotAvatar = () => {
  const [imageError, setImageError] = useState(false);

  return (
    <div className={style.avatar}>
      {!imageError ? (
        <img
          src={chatbotAvatar}
          alt="Chatbot Avatar"
          className={style.avatar__image}
          onError={() => setImageError(true)}
        />
      ) : (
        <Logo className={style.avatar__fallback} />
      )}
    </div>
  );
};
