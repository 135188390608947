export const financeColumns = [
  {
    title: "Channel",
    dataIndex: "channel",
    key: "channel",
    fixed: "left" as const,
    width: 150,
  },
  {
    title: "Units Sold",
    dataIndex: "unitsSold",
    key: "unitsSold",
    width: 100,
  },
  {
    title: "Total Sales",
    dataIndex: "totalSales",
    key: "totalSales",
    width: 110,
  },
  {
    title: "COGS",
    dataIndex: "cos",
    key: "cos",
    width: 80,
  },
  {
    title: "Base fulfillment fee",
    dataIndex: "baseRrp",
    key: "baseRrp",
    width: 150,
  },
  {
    title: "Base monthly storage fee",
    dataIndex: "baseRebate",
    key: "baseRebate",
    width: 150,
  },
  {
    title: "Deal fees",
    dataIndex: "disposalReferralFee",
    key: "disposalReferralFee",
    width: 100,
  },
  {
    title: "Disposal order fee",
    dataIndex: "disposalOrderFee",
    key: "disposalOrderFee",
    width: 140,
  },
  {
    title: "Referral fee",
    dataIndex: "netSales",
    key: "netSales",
    width: 110,
  },
  {
    title: "Brand Referral Bonus",
    dataIndex: "baseTierBonus",
    key: "baseTierBonus",
    width: 160,
  },
  {
    title: "Refund administration fee",
    dataIndex: "baseRetailAdminFee",
    key: "baseRetailAdminFee",
    width: 150,
  },
  {
    title: "Total Spend",
    dataIndex: "retailQs",
    key: "retailQs",
    width: 110,
  },
  {
    title: "Contribution Profit",
    dataIndex: "contributionCost",
    key: "contributionCost",
    width: 150,
  },
  {
    title: "Contribution Margin",
    dataIndex: "contributionCostWhatIf",
    key: "contributionCostWhatIf",
    width: 160,
  },
  {
    title: "ROI",
    dataIndex: "marginPercentage",
    key: "marginPercentage",
    width: 100,
  },
];

export const financeData = [
  {
    key: "amazon",
    channel: "Amazon",
    unitsSold: 10634,
    totalSales: "$120,000",
    cos: "30.12%",
    baseRrp: "$3,513",
    baseRebate: "$1,374",
    disposalReferralFee: "$543",
    disposalOrderFee: "$200",
    netSales: "$67,423",
    baseTierBonus: "$400",
    baseRetailAdminFee: "$45",
    retailQs: "$401",
    contributionCost: "$23,323",
    contributionCostWhatIf: "$30,323",
    marginPercentage: "47%",
    children: [
      {
        key: "B0B5HN65QQ",
        channel: "B0B5HN65QQ",
        unitsSold: 8723,
        totalSales: "$70,100",
        cos: "28.1%",
        baseRrp: "$3,000",
        baseRebate: "$1,000",
        disposalReferralFee: "$300",
        disposalOrderFee: "$100",
        netSales: "$55,000",
        baseTierBonus: "$300",
        baseRetailAdminFee: "$25",
        retailQs: "$301",
        contributionCost: "$20,000",
        contributionCostWhatIf: "$25,000",
        marginPercentage: "46%",
      },
      {
        key: "B0CXYSDC2H",
        channel: "B0CXYSDC2H",
        unitsSold: 23,
        totalSales: "$1,200",
        cos: "29.5%",
        baseRrp: "$200",
        baseRebate: "$50",
        disposalReferralFee: "$30",
        disposalOrderFee: "$15",
        netSales: "$800",
        baseTierBonus: "$10",
        baseRetailAdminFee: "$0",
        retailQs: "$10",
        contributionCost: "$500",
        contributionCostWhatIf: "$600",
        marginPercentage: "44%",
      },
      {
        key: "B0D3VHMR3Z",
        channel: "B0D3VHMR3Z",
        unitsSold: 815,
        totalSales: "$10,500",
        cos: "31%",
        baseRrp: "$400",
        baseRebate: "$150",
        disposalReferralFee: "$50",
        disposalOrderFee: "$20",
        netSales: "$8,000",
        baseTierBonus: "$50",
        baseRetailAdminFee: "$0",
        retailQs: "$30",
        contributionCost: "$5,000",
        contributionCostWhatIf: "$6,000",
        marginPercentage: "42%",
      },
      {
        key: "B0DP55J8ZG",
        channel: "B0DP55J8ZG",
        unitsSold: 1074,
        totalSales: "$4,328",
        cos: "27.2%",
        baseRrp: "$300",
        baseRebate: "$100",
        disposalReferralFee: "$20",
        disposalOrderFee: "$5",
        netSales: "$3,500",
        baseTierBonus: "$40",
        baseRetailAdminFee: "$0",
        retailQs: "$10",
        contributionCost: "$2,300",
        contributionCostWhatIf: "$2,900",
        marginPercentage: "45%",
      },
    ],
  },
  {
    key: "organicSocial",
    channel: "Organic Social",
    unitsSold: 23661,
    totalSales: "$80,500",
    cos: "22.1%",
    baseRrp: "$1,012",
    baseRebate: "$324",
    disposalReferralFee: "$23",
    disposalOrderFee: "$150",
    netSales: "$765",
    baseTierBonus: "$10",
    baseRetailAdminFee: "$0",
    retailQs: "$0",
    contributionCost: "$700",
    contributionCostWhatIf: "$730",
    marginPercentage: "46%",
    children: [
      {
        key: "B0B5HN65QQ-2",
        channel: "B0B5HN65QQ",
        unitsSold: 19408,
        totalSales: "$60,000",
        cos: "20%",
        baseRrp: "$800",
        baseRebate: "$300",
        disposalReferralFee: "$100",
        disposalOrderFee: "$50",
        netSales: "$600",
        baseTierBonus: "$5",
        baseRetailAdminFee: "$0",
        retailQs: "$0",
        contributionCost: "$500",
        contributionCostWhatIf: "$530",
        marginPercentage: "46%",
      },
      {
        key: "B0CXYSDC2H-2",
        channel: "B0CXYSDC2H",
        unitsSold: 50,
        totalSales: "$2,300",
        cos: "24.1%",
        baseRrp: "$312",
        baseRebate: "$74",
        disposalReferralFee: "$23",
        disposalOrderFee: "$10",
        netSales: "$290",
        baseTierBonus: "$10",
        baseRetailAdminFee: "$0",
        retailQs: "$0",
        contributionCost: "$200",
        contributionCostWhatIf: "$230",
        marginPercentage: "42%",
      },
      {
        key: "B0D3VHMR3Z-2",
        channel: "B0D3VHMR3Z",
        unitsSold: 1812,
        totalSales: "$8,000",
        cos: "21.1%",
        baseRrp: "$512",
        baseRebate: "$224",
        disposalReferralFee: "$13",
        disposalOrderFee: "$7",
        netSales: "$480",
        baseTierBonus: "$10",
        baseRetailAdminFee: "$0",
        retailQs: "$0",
        contributionCost: "$350",
        contributionCostWhatIf: "$400",
        marginPercentage: "45%",
      },
      {
        key: "B0DP55J8ZG-2",
        channel: "B0DP55J8ZG",
        unitsSold: 2390,
        totalSales: "$10,200",
        cos: "25.3%",
        baseRrp: "$150",
        baseRebate: "$60",
        disposalReferralFee: "$5",
        disposalOrderFee: "$3",
        netSales: "$200",
        baseTierBonus: "$2",
        baseRetailAdminFee: "$0",
        retailQs: "$0",
        contributionCost: "$100",
        contributionCostWhatIf: "$130",
        marginPercentage: "40%",
      },
    ],
  },
  {
    key: "grandTotal",
    channel: "Grand Total",
    unitsSold: 34295,
    totalSales: "$1,011,894",
    cos: "$91,309",
    baseRrp: "$111,185",
    baseRebate: "$443",
    disposalReferralFee: "$300",
    disposalOrderFee: "$100",
    netSales: "$231",
    baseTierBonus: "$97,686",
    baseRetailAdminFee: "$54,099",
    retailQs: "$592",
    contributionCost: "$84,267",
    contributionCostWhatIf: "$625,884",
    marginPercentage: "62%",
  },
];
