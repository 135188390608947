import React from "react";
import UserPopupArrow from "@assets/icons/userPopupArrow";
import { useMaintenance } from "src/store/maintenance.state";

export interface ProductItem {
  label: string;
  icon: React.ReactNode;
}

export const useProductItems = (): ProductItem[] => {
  const { maintenance } = useMaintenance();

  const productItems: ProductItem[] = [
    {
      label: "My Products",
      icon: <UserPopupArrow />,
    },
    {
      label: "CRO Module",
      icon: <UserPopupArrow />,
    },
    {
      label: "Ad Campaign Manager",
      icon: <UserPopupArrow />,
    },
  ];

  if (maintenance) {
    productItems.push({
      label: "Subscription Performance",
      icon: <UserPopupArrow />,
    });
  }

  return productItems;
};
