import React, { useEffect, useState, useMemo, useCallback } from "react";
import styles from "./styles.module.scss";
import { useCanvaStore } from "src/store/canva/canva.state";
import { v4 as uuid } from "uuid";
import { Input } from "@components/Input";
import Search from "@assets/icons/search";
import { Element, ElementsAccordion } from "./ElementsAccordion";
import {
  campaignElements,
  croElements,
  organicElements,
  financeElements,
  getWidgetSize,
  marketElements,
  reportsElements,
  summaryElements,
  supplyChainElements,
} from "./utils";
import ChevronDown from "@assets/icons/chevron-down";
import classNames from "classnames";
import { ELEMENT_ID } from "src/store/canva/element-id";
import { useBrandStore } from "src/store/brand";
import { CanvaElement } from "src/store/canva/canva.types";
import CarretLeft from "@assets/icons/CarretLeft";

interface AccordionState {
  summaryElements: boolean;
  revenue: boolean;
  finance: boolean;
  supplyChain: boolean;
  marketIntelegence: boolean;
  reportsIntelegence: boolean;
}

interface ElementsByCategory {
  summary: Element[];
  campaign: Element[];
  cro: Element[];
  organic: Element[];
  finance: Element[];
  marketIntelegence: Element[];
  reportsIntelegence: Element[];
  supplyChain: Element[];
}

const setActiveElement = (
  elementsList: Element[],
  canvaElements: CanvaElement[],
): Element[] => {
  const activeElements = canvaElements.filter((el) => el.active);
  return elementsList.map((mockElement) => ({
    ...mockElement,
    active: !!activeElements.find(
      (el) => el.elementId === mockElement.elementId,
    ),
  }));
};

export const Elements: React.FC = () => {
  const {
    elements,
    addElement,
    removeElement,
    isOpenElements,
    setIsOpenElements,
  } = useCanvaStore();
  const { brands } = useBrandStore();
  const [search, setSearch] = useState("");
  const [openedAccordion, setOpenedAccordion] = useState<AccordionState>({
    summaryElements: false,
    revenue: false,
    finance: false,
    supplyChain: false,
    marketIntelegence: false,
    reportsIntelegence: false,
  });
  const [elementsByCategory, setElementsByCategory] =
    useState<ElementsByCategory>({
      summary: setActiveElement(summaryElements, elements),
      campaign: setActiveElement(campaignElements, elements),
      cro: setActiveElement(croElements, elements),
      organic: setActiveElement(organicElements, elements),
      finance: setActiveElement(financeElements, elements),
      marketIntelegence: setActiveElement(marketElements, elements),
      reportsIntelegence: setActiveElement(reportsElements, elements),
      supplyChain: setActiveElement(supplyChainElements, elements),
    });

  useEffect(() => {
    setElementsByCategory({
      summary: setActiveElement(summaryElements, elements),
      campaign: setActiveElement(campaignElements, elements),
      cro: setActiveElement(croElements, elements),
      organic: setActiveElement(organicElements, elements),
      finance: setActiveElement(financeElements, elements),
      marketIntelegence: setActiveElement(marketElements, elements),
      reportsIntelegence: setActiveElement(reportsElements, elements),
      supplyChain: setActiveElement(supplyChainElements, elements),
    });
  }, [elements]);

  const searchResults = useMemo(() => {
    if (!search) return null;

    const results = Object.entries(elementsByCategory).flatMap(
      ([category, elements]) =>
        elements
          .filter((element) =>
            element.name.toLowerCase().includes(search.toLowerCase()),
          )
          .map((element) => ({ element, category })),
    );

    const nameCounts = results.reduce(
      (acc, { element }) => {
        acc[element.name] = (acc[element.name] || 0) + 1;
        return acc;
      },
      {} as Record<string, number>,
    );

    return results.map((result) => ({
      ...result,
      hasDuplicates: nameCounts[result.element.name] > 1,
    }));
  }, [search, elementsByCategory]);

  const toggleAccordion = useCallback((category: keyof AccordionState) => {
    setOpenedAccordion((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  }, []);

  const handleToggleElement = useCallback(
    (elementId: ELEMENT_ID, category: string) => {
      setElementsByCategory((prevState) => {
        const categoryElements =
          prevState[category as keyof ElementsByCategory] || [];
        const updatedCategoryElements = categoryElements.map((el) => {
          if (el.elementId !== elementId) return el;

          const updatedElement = { ...el, active: !el.active };

          if (updatedElement.active) {
            addNewElement(elementId);
          } else {
            removeExistingElement(elementId);
          }

          return updatedElement;
        });

        return {
          ...prevState,
          [category]: updatedCategoryElements,
        };
      });
    },
    [elements, addElement, removeElement],
  );

  const addNewElement = useCallback(
    (elementId: ELEMENT_ID) => {
      const newCanvaElement: CanvaElement = {
        id: uuid(),
        elementId,
        x: 1,
        y: 50,
        size: getWidgetSize(
          elementId,
          elementId === ELEMENT_ID.BRAND ? brands.length : 1,
        ),
        active: true,
      };

      addElement(newCanvaElement);

      setTimeout(() => {
        const newElement = document.getElementById(
          `grid-item-${newCanvaElement.id}`,
        );
        if (newElement) {
          newElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }, 100);
    },
    [brands, addElement],
  );

  const removeExistingElement = useCallback(
    (elementId: ELEMENT_ID) => {
      const elementToRemove = elements.find((el) => el.elementId === elementId);
      if (elementToRemove) {
        removeElement(elementToRemove.id);
      }
    },
    [elements, removeElement],
  );

  const formatCategoryName = (camelCase: string): string => {
    const withSpaces = camelCase.replace(/([A-Z])/g, " $1");

    return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).trim();
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.open]: isOpenElements,
      })}
    >
      <div className={styles.header}>
        <button
          className={styles.openButton}
          onClick={() => setIsOpenElements(!isOpenElements)}
        >
          <CarretLeft />
        </button>
        <h3 className={styles.elementsTitle}>Add elements</h3>
        <Input
          placeholder="Search elements"
          value={search}
          onChange={setSearch}
          icon={<Search />}
        />
      </div>

      <div className={styles.elements}>
        {search && searchResults ? (
          <ul className={styles.elementsListSearch}>
            {searchResults.map(
              ({ element, category, hasDuplicates }, index) => (
                <li key={`${element.id}-${index}`}>
                  <button
                    className={styles.element}
                    onClick={() =>
                      handleToggleElement(element.elementId, category)
                    }
                  >
                    <img src={element.icon} alt={element.name} />
                    <div className={styles.elementInfo}>
                      <div>{element.name}</div>
                      {hasDuplicates && (
                        <div className={styles.categoryLabel}>
                          {formatCategoryName(category)}
                        </div>
                      )}
                    </div>
                    <span
                      className={classNames(styles.status, {
                        [styles.active]: element.active,
                      })}
                    ></span>
                  </button>
                </li>
              ),
            )}
          </ul>
        ) : (
          <>
            <div className={styles.list}>
              <ElementsAccordion
                title="Summary"
                elements={elementsByCategory.summary}
                onElementClick={handleToggleElement}
                search={search}
                category="summary"
                variant="small"
              />
            </div>

            {/* Revenue section - this is the only one that needs the custom accordion with nested items */}
            <div
              className={classNames(styles.accordion, {
                [styles.open]: openedAccordion.revenue,
              })}
            >
              <button onClick={() => toggleAccordion("revenue")}>
                Revenue (CMO)
                <ChevronDown />
              </button>
              <ul className={styles.list}>
                <ElementsAccordion
                  title="CRO"
                  elements={elementsByCategory.cro}
                  onElementClick={handleToggleElement}
                  search={search}
                  category="cro"
                  variant="small"
                />
                <ElementsAccordion
                  title="Campaign Manager"
                  elements={elementsByCategory.campaign}
                  onElementClick={handleToggleElement}
                  search={search}
                  category="campaign"
                  variant="small"
                />
                <ElementsAccordion
                  title="Organic"
                  elements={elementsByCategory.organic}
                  onElementClick={handleToggleElement}
                  search={search}
                  category="organic"
                  variant="small"
                />
              </ul>
            </div>

            {/* All other sections use the standard accordion pattern */}

            <div className={styles.list}>
              <ElementsAccordion
                elements={elementsByCategory.finance}
                onElementClick={handleToggleElement}
                search={search}
                category="finance"
                variant="small"
                title="Finance (CFO)"
              />
            </div>

            <div className={styles.list}>
              <ElementsAccordion
                elements={elementsByCategory.supplyChain}
                onElementClick={handleToggleElement}
                search={search}
                category="supplyChain"
                variant="small"
                title="Supply Chain (COO)"
                showAnyway
              />
            </div>

            <div className={styles.list}>
              <ElementsAccordion
                elements={elementsByCategory.marketIntelegence}
                onElementClick={handleToggleElement}
                search={search}
                category="marketIntelegence"
                variant="small"
                title="Market Intelligence"
              />
            </div>

            <div className={styles.list}>
              <ElementsAccordion
                elements={elementsByCategory.reportsIntelegence}
                onElementClick={handleToggleElement}
                search={search}
                category="reportsIntelegence"
                variant="small"
                title="Custom Reports"
                showAnyway
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
