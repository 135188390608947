export const selectMock = [
  {
    id: "B07N48MTTH",
    title:
      "SugarbearPro Sleep Aid Gummies for Adults with Melatonin, Magnesium, L-Theanine, 5 HTP, B6, Valerian Root, Lemon Balm - Vegan Chewable Sleep Supplement, Sleep Vitamins (1 Month Supply)",
    asin: "B07N48MTTH",
  },
  {
    id: "B07N48MTTH_2",
    title:
      "WEEM Biotin Gummies for Hair, Skin and Nails - Vegan Vitamins for Men & Women, Supports Faster Hair Growth and Stronger Nails - Extra Strength 10,000mcg",
    asin: "B07N48MTTH",
  },
  {
    id: "B07N48MTTH_3",
    title:
      "Sugarbear Vegan Hair Gummy Vitamins with Biotin, Vitamin C, Vitamin B-12, Zinc for Hair Skin & Nails (1 Month Supply)",
    asin: "B07N48MTTH",
  },
];
