import Photo from "@assets/img/Photo.png";
import Photo2 from "@assets/img/Photo2.png";
import Photo3 from "@assets/img/Photo3.png";
import Photo4 from "@assets/img/Photo4.png";
import { ROUTES } from "src/router/routes";
import { useMaintenance } from "src/store/maintenance.state";

export interface User {
  name: string;
  title: string;
  photo: string;
}

export const users: User[] = [
  { name: "John Gold", title: "CEO", photo: Photo },
  { name: "John Gold", title: "CMO", photo: Photo2 },
  { name: "John Gold", title: "COO", photo: Photo3 },
  { name: "John Gold", title: "CFO", photo: Photo4 },
];

export const getMenuItems = () => {
  const { maintenance } = useMaintenance();

  const items = [
    { label: "Referral page", route: ROUTES.REFERRAL_PAGE },
    { label: "Settings", route: "/settings/account" },
    { label: "Plans & Billing", route: "/settings/plan" },
    { label: "Connections", route: ROUTES.CONNECT_AMAZON },
    { label: "Notification Settings", route: "/notification-settings" },
  ];

  if (!maintenance) {
    return items.filter(
      (item) =>
        item.label !== "Settings" &&
        item.label !== "Plans & Billing" &&
        item.label !== "Notification Settings",
    );
  }

  return items;
};
