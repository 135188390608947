import classNames from "classnames";
import { Upload } from "lucide-react";
import { useState } from "react";
import styles from "./styles.module.scss";

const InputFile = ({ onChange }: { onChange: (file: File | null) => void }) => {
  const [imageName, setImageName] = useState("");
  // const generateService = new GenerateService();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files;

    const hasImage = Boolean(file);

    if (!hasImage) {
      setImageName("");
      onChange(null);
      return;
    }

    setImageName(file.name);
    onChange(file);
  };

  return (
    <label
      className={classNames(
        "button button--sm button--m-0 button--gap button--outline button--m-0",
      )}
    >
      <input
        type="file"
        onChange={handleChange}
        accept="image/png, image/gif, image/jpeg"
        hidden
      />
      {imageName ? (
        <span className={styles.buttonText}>{imageName}</span>
      ) : (
        <>
          <Upload size={16} /> upload new
        </>
      )}
    </label>
  );
};

export default InputFile;
