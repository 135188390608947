import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { getDateRangeForPeriod } from "@pages/Overview/components/ReviewScore/ReviewScore/ratingUtils";

const PeriodSelector = () => {
  const [activePeriod, setActivePeriod] = useState("day");
  const { setDateRangeSelect } = useDashboardStore((state) => ({
    dateRangeSelect: state.dateRangeSelect,
    setDateRangeSelect: state.setDateRangeSelect,
  }));

  useEffect(() => {
    if (activePeriod === "day") {
      const today = new Date();
      setDateRangeSelect({
        startDate: today,
        endDate: today,
      });
    }
  }, [activePeriod, setDateRangeSelect]);

  const handlePeriodChange = (period) => {
    setActivePeriod(period);
    const { startDate, endDate } = getDateRangeForPeriod(period);
    setDateRangeSelect({
      startDate,
      endDate,
    });
  };

  return (
    <div className={styles.whatIf}>
      <button
        className={classNames(styles.whatIf__button, {
          [styles.whatIf__button_active]: activePeriod === "day",
        })}
        onClick={() => handlePeriodChange("day")}
      >
        Day
      </button>
      <button
        className={classNames(styles.whatIf__button, {
          [styles.whatIf__button_active]: activePeriod === "week",
        })}
        onClick={() => handlePeriodChange("week")}
      >
        Week
      </button>
      <button
        className={classNames(styles.whatIf__button, {
          [styles.whatIf__button_active]: activePeriod === "month",
        })}
        onClick={() => handlePeriodChange("month")}
      >
        Month
      </button>
    </div>
  );
};

export default PeriodSelector;
