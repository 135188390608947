import { v4 as uuidv4 } from "uuid";
import {
  Tool,
  Message,
  AgentApiResponse,
  ToolType,
  PREFIX_PERFORMANCE,
  PREFIX_METRIC,
} from "./types";

class AgentHandler {
  private addMessage: (message: Message) => void;

  constructor(messageHandler: (message: Message) => void) {
    this.addMessage = messageHandler;
  }

  private parseData(data: string | number | any): any {
    if (typeof data === "number" || Array.isArray(data)) return data;
    if (typeof data === "string") {
      try {
        const parsed = JSON.parse(data);
        return parsed;
      } catch (error) {
        console.error("Error parsing data:", error);
        return null;
      }
    }
    return null;
  }

  private createMessage(
    text: string,
    type: ToolType = ToolType.TEXT,
    data?: any,
  ): Message {
    return {
      id: uuidv4(),
      text,
      sender: "assistant",
      type,
      ...(data && { data }),
    };
  }

  private handleMetricTool(tool: Tool, finalResult: string): void {
    const { data, type } = tool;
    const isDataInvalid =
      data === "0" || !data || String(data).toLowerCase().includes("error");
    const isErrorMessage =
      finalResult.startsWith("I'm sorry") ||
      finalResult.includes("unable to fetch") ||
      finalResult.includes("there was an error fetching the data");

    if (isDataInvalid && isErrorMessage) {
      this.addMessage(this.createMessage(finalResult, ToolType.TEXT));
    } else {
      const value = typeof data === "number" ? data : parseFloat(String(data));
      this.addMessage(this.createMessage("", type as ToolType, value));
    }
  }

  private handlePerformanceTool(tool: Tool, finalResult: string): void {
    const { type } = tool;
    const rawData = this.parseData(tool.data);

    if (rawData && Array.isArray(rawData)) {
      this.addMessage({
        id: uuidv4(),
        text: "",
        sender: "assistant",
        type: type as ToolType,
        data: rawData,
      });

      this.addMessage({
        id: uuidv4(),
        text: finalResult,
        sender: "assistant",
        type: ToolType.TEXT,
      });
    } else {
      this.addMessage(this.createMessage(finalResult, ToolType.TEXT));
    }
  }

  private handleSpiderTool(tool: Tool, finalResult: string): void {
    const spiderData = tool.data;
    if (spiderData) {
      this.addMessage(
        this.createMessage("", ToolType.PRODUCT_SPIDER, spiderData),
      );
    }
    this.addMessage(this.createMessage(finalResult, ToolType.TEXT));
  }

  public handleToolResponse(tool: Tool, finalResult: string): void {
    const { type } = tool;

    if (type.includes(PREFIX_METRIC)) {
      this.handleMetricTool(tool, finalResult);
    } else if (type.startsWith(PREFIX_PERFORMANCE)) {
      this.handlePerformanceTool(tool, finalResult);
    } else if (type === ToolType.PRODUCT_SPIDER) {
      this.handleSpiderTool(tool, finalResult);
    } else {
      this.addMessage(this.createMessage(finalResult, ToolType.TEXT));
    }
  }
}

export async function getFinancialAgent({
  responseData,
  addMessage,
}: {
  responseData: AgentApiResponse;
  addMessage: (message: Message) => void;
}): Promise<void> {
  const agentHandler = new AgentHandler(addMessage);
  const tool = responseData.tools[0];

  if (tool) {
    agentHandler.handleToolResponse(tool, responseData.finalMessage);
  } else {
    addMessage({
      id: uuidv4(),
      text: responseData.finalMessage,
      sender: "assistant",
      type: ToolType.TEXT,
    });
  }
}
