import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";
import ImageSection from "./сomponents/ImageSection";
import FormSection from "./сomponents/FormSection";
import { VisibleDescriptions } from "../../../components/OptimisationInfoSection/types";
import OptimisationPageWrapper from "../../../components/OptimisationPageWrapper";
import InfoSection from "../../../components/OptimisationInfoSection";
import { useProductStore } from "src/store/overviewProduct.state";
import { OptimizationService } from "@services/optimization/optimization.services";

const PhotoOptimisationDetails: React.FC = () => {
  const [isHypothesisVisible, setIsHypothesisVisible] = useState(false);
  const [visibleDescriptions, setVisibleDescriptions] =
    useState<VisibleDescriptions>({
      description: false,
      critique: false,
      hypothesis: false,
      howToTest: false,
      percentageImpact: false,
    });
  const { selectedProduct } = useProductStore();
  const optimizationService = new OptimizationService();
  const [fetchPhoto, setFetchPhoto] = useState<boolean>(false);
  const [newPhoto, setNewPhoto] = useState<string | null>(null);

  const location = useLocation();

  const {
    imageUrl,
    imageDescription,
    imageCritique,
    hypothesis,
    howToTest,
    percentageImpact,
    fullVariant,
    variant,
  } = location.state || {};

  const toggleHypothesisVisibility = () => {
    setIsHypothesisVisible((prevState) => !prevState);
  };

  const toggleVisibility = (field: keyof VisibleDescriptions) => {
    setVisibleDescriptions((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  useEffect(() => {
    0;
    const fetchNewPhoto = async () => {
      if (fetchPhoto && selectedProduct?.asin && variant) {
        const res = await optimizationService.getNewPhoto(
          selectedProduct.asin,
          variant,
        );
        setNewPhoto(res);
      }
    };

    fetchNewPhoto();
  }, [fetchPhoto, selectedProduct?.asin, variant]);

  return (
    <OptimisationPageWrapper>
      <div className={styles.detailsContainer}>
        <ImageSection imageUrl={fullVariant} newPhoto={newPhoto?.image_link} />
        <FormSection
          id={variant}
          setFetchPhoto={setFetchPhoto}
          newPhoto={newPhoto}
        />
      </div>
      {isHypothesisVisible && (
        <InfoSection
          type="image"
          description={imageDescription}
          critique={imageCritique}
          hypothesis={hypothesis}
          howToTest={howToTest}
          percentageImpact={percentageImpact}
          visibleDescriptions={visibleDescriptions}
          toggleVisibility={toggleVisibility}
        />
      )}
    </OptimisationPageWrapper>
  );
};

export default PhotoOptimisationDetails;
