// src/components/AppHeader/ProductPopup/ProductPopup.tsx
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

import { ROUTES } from "src/router/routes";
import classNames from "classnames";
import { ProductItem, useProductItems } from "./mock";

interface ProductPopupProps {
  closePopup: () => void;
}

const getRouteLink = (label: string) => {
  switch (label) {
    case "My Products":
      return ROUTES.MYPRODUCT;
    case "CRO Module":
      return ROUTES.CRO;
    case "Ad Campaign Manager":
      return ROUTES.ADS_ANALYTICS + "/campaigns";
    case "Subscription Performance":
      return ROUTES.SUBSCRIPTION_PERFORMANCE;
    default:
      return ROUTES.ROOT;
  }
};

export const ProductPopup: React.FC<ProductPopupProps> = ({ closePopup }) => {
  const productItems: ProductItem[] = useProductItems();

  const handleLinkClick = () => {
    closePopup();
  };

  return (
    <div className={styles.popupMenu}>
      <div className={styles.popupContent}>
        <div className={styles.section}>
          {productItems.map((item) => {
            const link = getRouteLink(item.label);

            return (
              <NavLink
                key={item.label}
                to={link}
                className={({ isActive }) =>
                  classNames(styles.item, {
                    [styles.active]: isActive,
                  })
                }
                onClick={handleLinkClick}
              >
                {item.label}
                <span className="icon">{item.icon}</span>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};
