import classNames from "classnames";
import styles from "./styles.module.scss";
import { CSSProperties, useState } from "react";
import Arrow from "@assets/icons/Arrow";
import { useOutsideClick } from "src/utils/useOutsideClick";

export type CheckWidgetData = {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  color: string;
};

interface CheckWidgetProps {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  isChecked: boolean;
  onChange: (value: string) => void;
  color: string;
  options?: string[];
  onReplace?: (title: string, titleToDelete: string) => void;
  hideExtra?: boolean;
}

interface CustomCSSProperties extends CSSProperties {
  "--checkbox-color"?: string;
}

export const getAdsLabel = (title: string, skipAd?: boolean) => {
  if (skipAd) {
    return null;
  }

  if (title.toLowerCase().includes("organic")) {
    return "";
  }

  switch (title.toLowerCase()) {
    case "cpc":
      return null;
    case "acos":
      return null;
    case "roas":
      return null;
    default:
      return "Ad ";
  }
};

export const CheckWidget = ({
  title,
  value,
  increase,
  increaseDirection,
  isChecked,
  onChange,
  color,
  options,
  onReplace,
  hideExtra,
}: CheckWidgetProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const buttonRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  return (
    <div
      className={classNames(
        hideExtra ? styles.overlay : styles.container,
        isOpen ? styles.open : "",
      )}
    >
      <div className={styles.info}>
        <div className={styles.header}>
          <label
            className={classNames(styles.checkbox, isChecked && styles.checked)}
            style={
              {
                "--checkmark-color": isChecked ? color : "transparent",
              } as CustomCSSProperties
            }
          >
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => {
                if (title === "Orders") {
                  return;
                }
                onChange(title);
              }}
            />
            <span></span>
          </label>
          <h3>
            {getAdsLabel(title)}
            {title}
          </h3>
        </div>
        {!hideExtra && (
          <div className={styles.value}>
            <span>{value}</span>
          </div>
        )}
      </div>

      {!hideExtra && (
        <div
          className={classNames(
            styles.increase,
            increaseDirection === "up" ? styles.up : styles.down,
          )}
        >
          {increase}
          <button
            className={styles.dropdown__button}
            onClick={() => setIsOpen(!isOpen)}
            ref={buttonRef}
          >
            <Arrow />
            {isOpen && options && (
              <div className={styles.dropdown}>
                {options.map((option) => (
                  <button
                    key={option}
                    onClick={() => {
                      onReplace?.(option, title);
                      setIsOpen(false);
                    }}
                  >
                    {getAdsLabel(option) + option}
                  </button>
                ))}
              </div>
            )}
          </button>
        </div>
      )}
    </div>
  );
};
