import { Select } from "@components/Select";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  ResponsiveContainer,
} from "recharts";
import {
  CanvasApi,
  ISaturationCurveData,
} from "../../services/canvas/canvas.api";
import {
  formatDateRange,
  getMinMaxValues,
  getTicks,
  getYAxisTicks,
  yAxisTickFormatter,
  transformAndSortData,
} from "./utils";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { CustomTooltip } from "./CustomTooltip";

interface ISaturationCurveLine {
  name: string;
  data: ISaturationCurveData[];
  color: string;
}

const CustomDot = (props: any) => {
  const { cx, cy, payload, saturation } = props;

  if (!cx || !cy || !payload) return null;

  // Only show dot for point matching saturation
  if (payload.category !== saturation) return null;

  return (
    <circle
      cx={cx}
      cy={cy}
      r={17.676 / 2}
      stroke="#B89A4F"
      strokeWidth={2.156}
      fill="#FFF"
    />
  );
};

export const SaturationCurve = () => {
  const [choosedCurve, setChoosedCurve] = useState("Ad Sales");
  const [curveData, setCurveData] = useState<ISaturationCurveLine[]>([]);
  const { dateRange } = useDashboardStore();
  const { startDate, endDate } = dateRange;
  const { adSpend: saturation } = useAdsAnaliticsStore().adSlides;

  useEffect(() => {
    const fetchSaturationCurve = async () => {
      try {
        const { date_start, date_end } = formatDateRange(startDate, endDate);
        const data = await new CanvasApi().getSaturationCurve(
          date_start,
          date_end,
          saturation,
        );
        const transformedData = transformAndSortData(data);
        setCurveData(transformedData);
      } catch (error) {
        console.error("Error fetching saturation curve data:", error);
      }
    };

    fetchSaturationCurve();
  }, [startDate, endDate]);

  const { min: xMin, max: xMax } = getMinMaxValues(
    curveData.flatMap((d) => d.data),
    "category",
  );
  const { min: yMin, max: yMax } = getMinMaxValues(
    curveData.flatMap((d) => d.data),
    "value",
  );

  const xTicks = getTicks(xMin, xMax, 30);
  const yTicks = getYAxisTicks(yMax);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Saturation Curve</h3>
        <div className={styles.select}>
          <Select
            options={[
              "Ad Sales",
              "Ad Sales Contribution Profit",
              "Organic Sales",
              "Organic Sales Contribution Profit",
              "Total Sales",
              "Total Sales Contribution Profit",
            ]}
            value={choosedCurve}
            onChange={(value) => setChoosedCurve(value)}
            placeholder="Select a curve"
            className={styles.select__dropdown}
          />
        </div>
      </div>
      <div className={styles.chart}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={curveData}
            margin={{ top: 0, right: 0, bottom: 40, left: 0 }}
          >
            <CartesianGrid stroke="#1F1F20" />
            <XAxis
              dataKey="category"
              type="number"
              domain={[xMin, xMax]}
              ticks={xTicks}
            >
              <Label
                value="Spend"
                offset={-10}
                position="insideBottom"
                style={{ textAnchor: "middle", fill: "#fff", fontSize: 14 }}
              />
            </XAxis>
            <YAxis
              dataKey="value"
              domain={[yMin, yMax]}
              ticks={yTicks}
              tickFormatter={yAxisTickFormatter}
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ stroke: "#7C7C78" }}
            />
            {curveData.map((s) => (
              <Line
                key={s.name}
                dataKey="value"
                data={s.data}
                name={s.name}
                stroke={s.color}
                type="monotone"
                dot={<CustomDot saturation={saturation} />}
                connectNulls
                strokeWidth={3}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
