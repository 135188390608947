export const data = [
  { date: "Jan 1", p1: 0.2, p2: 0.1, p3: 0.1, p4: 0.1, p5: 0.1, p6: 0.05 },
  { date: "Jan 2", p1: 0.8, p2: 0.6, p3: 0.5, p4: 0.4, p5: 0.3, p6: 0.1 },
  { date: "Jan 3", p1: 1.5, p2: 1.2, p3: 1, p4: 0.8, p5: 0.5, p6: 0.2 },
  { date: "Jan 4", p1: 2.2, p2: 1.8, p3: 1.6, p4: 1.2, p5: 0.8, p6: 0.3 },
  { date: "Jan 5", p1: 2.5, p2: 2.2, p3: 1.9, p4: 1.5, p5: 1.1, p6: 0.5 },
  { date: "Jan 6", p1: 3, p2: 2.8, p3: 2.3, p4: 1.8, p5: 1.3, p6: 0.6 },
  { date: "Jan 7", p1: 3.5, p2: 3.1, p3: 2.7, p4: 2.1, p5: 1.5, p6: 0.8 },
];

export const colors = [
  "#80C67A",
  "#5295E0",
  "#8A6AE8",
  "#0F7173",
  "#A65353",
  "#E69732",
];

export const formatCurrency = (value: number) => `$${value}M`;

export const AsinsMock = [
  { name: "B07N48MTTH_1", color: "#5A86F2" },
  { name: "B07N48MTTH_2", color: "#4BC18E" },
  { name: "B07N48MTTH_3", color: "#8E5AE3" },
  { name: "B07N48MTTH_4", color: "#22AB6F" },
  { name: "B07N48MTTH_5", color: "#C8A857" },
  { name: "B07N48MTTH_6", color: "#4876C7" },
  { name: "B07N48MTTH_7", color: "#E84545" },
  { name: "B07N48MTTH_8", color: "#E98C50" },
  { name: "B07N48MTTH_9", color: "#5A86F2" },
  { name: "B07N48MTTH_10", color: "#4BC18E" },
  { name: "B07N48MTTH_11", color: "#8E5AE3" },
  { name: "B07N48MTTH_12", color: "#22AB6F" },
  { name: "B07N48MTTH_13", color: "#C8A857" },
  { name: "B07N48MTTH_14", color: "#4876C7" },
  { name: "B07N48MTTH_15", color: "#E84545" },
  { name: "B07N48MTTH_16", color: "#E98C50" },
];

export const sliderLabels = [
  "Jan 1",
  "Jan 2",
  "Jan 3",
  "Jan 4",
  "Jan 5",
  "Jan 6",
  "Jan 7",
];
