import classNames from "classnames";
import styles from "./styles.module.scss";
import { formatCurrency } from "@pages/MarketIntelligence/components/BrandPerformance/mock";

interface AccentValueProps {
  value: number;
  size?: "sm" | "md" | "lg";
}

export const AccentValue = ({ value, size }: AccentValueProps) => {
  const isPositive = value > 2000;

  return (
    <span
      className={classNames(
        styles.accentValue,
        { [styles.positive]: isPositive },
        { [styles.negative]: !isPositive },
        { [styles[size]]: size },
      )}
    >
      {isPositive ? "+" : "-"}
      {formatCurrency(Math.abs(value))}
    </span>
  );
};
