import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactECharts from "echarts-for-react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { getOption, seriesConfig } from "./utils";
import { SanKey } from "@pages/AdsAnalitics/components/SanKey";
import { ArrowLeft } from "lucide-react";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { FunnelService } from "./lib";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { Spinner } from "@components/ui-kit/Spinner";
import Settings from "@assets/img/settings";
import { useContainerSize } from "src/utils/useContainerSize";
import { preloadTooltipMetrics } from "./tooltip";

interface FunnelChartProps {
  title?: string;
  isWhatIf?: boolean;
  zoom?: number;
}

export const FunnelChart: React.FC<FunnelChartProps> = ({
  title,
  isWhatIf,
  zoom,
}) => {
  const { ref: containerRef, width: containerWidth } = useContainerSize({
    zoom,
  });
  const { dateRange } = useDashboardStore();
  const { adSlides } = useAdsAnaliticsStore((state) => ({
    adSlides: state.adSlides,
  }));
  const [openedDetails, setOpenedDetails] = useState("");
  const [activeSeries, setActiveSeries] = useState({
    "Amazon Ads": true,
    "Amazon Organic": true,
    "Organic Social": false,
    DSP: false,
  });
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [funnelData, setFunnelData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const loadData = useCallback(async () => {
    setLoading(true);
    const { startDate, endDate } = dateRange;
    const startDateString = startDate.toISOString().split("T")[0];
    const endDateString = endDate.toISOString().split("T")[0];

    try {
      const funnel = await FunnelService.getAdsData(
        startDateString,
        endDateString,
      );
      setFunnelData(funnel);

      await preloadTooltipMetrics(dateRange);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  }, [dateRange]);

  useEffect(() => {
    loadData();

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [dateRange, loadData]);

  useEffect(() => {
    pollingIntervalRef.current = setInterval(
      () => {
        loadData();
      },
      3 * 60 * 1000,
    );

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [loadData]);

  const toggleSeries = (seriesName: string) => {
    setActiveSeries((prev) => ({
      ...prev,
      [seriesName]: !prev[seriesName],
    }));
  };

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const { offsetX } = event.nativeEvent;
      const width = event.currentTarget.clientWidth;

      const relativeX = offsetX / width;

      let section = null;
      if (relativeX < 0.33) {
        section = "first";
      } else if (relativeX < 0.66) {
        section = "second";
      } else {
        section = "third";
      }

      if (section !== hoveredSection) {
        setHoveredSection(section);
      }
    },
    [hoveredSection],
  );

  const handleMouseLeave = () => {
    setHoveredSection(null);
  };

  const handleFunnelClick = (params: any) => {
    if (params.componentType === "series") {
      setOpenedDetails(params.name);
    }
  };

  const transformSeriesName = (name: string): string => {
    if (title) {
      if (name === "Amazon Ads") return "Market Total";
      if (name === "Amazon Organic") return "Brand";
    }
    return name;
  };

  const options = useMemo(() => {
    if (!funnelData) return null;

    const baseOptions = getOption(
      activeSeries,
      adSlides,
      funnelData,
      dateRange,
      isWhatIf,
    );

    if (title) {
      baseOptions.tooltip = { show: false, trigger: "none" };
      if (baseOptions.series && Array.isArray(baseOptions.series)) {
        baseOptions.series = baseOptions.series.map((s: any) => ({
          ...s,
          tooltip: {
            show: false,
            trigger: "none",
          },
        }));
      }
    }

    return baseOptions;
  }, [activeSeries, adSlides, funnelData, title, dateRange]);

  if (openedDetails) {
    return (
      <div className={styles.sankey}>
        <button
          className={styles.back}
          onClick={() => {
            setOpenedDetails("");
          }}
        >
          <ArrowLeft size={20} />
          <span>{openedDetails} Sankey</span>
        </button>
        <SanKey
          hideTree
          hideTitle
          hideAsin
          isFunnelChart
          defaultOpenTab="Funnel"
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        containerWidth && containerWidth < 350 && styles.small,
      )}
      ref={containerRef}
    >
      <div className={styles.header}>
        <h3>{title ? title : "Funnel Performance"}</h3>
        <button
          className={classNames(styles.settingsButton, {
            [styles.active]: settingsOpen,
          })}
          onClick={() => setSettingsOpen(!settingsOpen)}
        >
          <Settings />
        </button>
      </div>
      <div
        className={classNames(
          styles.toggleButtons,
          settingsOpen && styles.open,
        )}
      >
        {seriesConfig.map((series, index) => (
          <button
            key={series.name}
            className={classNames(styles.toggleButton, {
              [styles.active]: activeSeries[series.name],
            })}
            style={{ backgroundColor: series.color }}
            onClick={() => toggleSeries(series.name)}
            disabled={index > 1 && !activeSeries[series.name]}
          >
            <span className={styles.indicator} />
            {series.name}
          </button>
        ))}
      </div>
      <div
        className={styles.chart__container}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <span></span>
        <div className={styles.legend}>
          {seriesConfig.map((series, index) => {
            if (index < 2) {
              return (
                <div
                  key={series.name}
                  className={classNames(styles.legendItem)}
                  onClick={() => toggleSeries(series.name)}
                >
                  <span
                    className={styles.indicator}
                    style={{ backgroundColor: series.color }}
                  />
                  {transformSeriesName(series.name)}
                </div>
              );
            }
            return null;
          })}
        </div>

        {funnelData && !loading ? (
          <ReactECharts
            option={options}
            onEvents={{
              click: (params: any) => {
                handleFunnelClick(params);
              },
            }}
            style={{
              height: "100%",
              minHeight: "500px",
              width: "100%",
            }}
          />
        ) : (
          <div className={styles.loader}>
            <Spinner />
          </div>
        )}
        <div className={styles.overlay}>
          <div
            className={classNames(
              styles.section,
              styles.first,
              hoveredSection && hoveredSection !== "first" && styles.dimmed,
            )}
          ></div>
          <div
            className={classNames(
              styles.section,
              styles.second,
              hoveredSection && hoveredSection !== "second" && styles.dimmed,
            )}
          ></div>
          <div
            className={classNames(
              styles.section,
              styles.third,
              hoveredSection && hoveredSection !== "third" && styles.dimmed,
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};
