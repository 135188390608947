import React from "react";
import styles from "./styles.module.scss";
import BrandRevenueChart from "@pages/MarketIntelligence/components/BrandRevenueChart";
import { ActiveView, dataContribution, dataSales } from "./mock";
import { dataByASINsales, dataByASINcontribution } from "./mock";
import { AsinBarChart } from "../AsinBarChart";
import classNames from "classnames";

interface LegendItem {
  label: string;
  color: string;
}

interface PerformanceCardProps {
  title: string;
  legendItems?: LegendItem[];
  activeView: ActiveView;
  onByChannelClick: () => void;
  onByAsinClick: () => void;
}

export const PerformanceCard: React.FC<PerformanceCardProps> = ({
  title,
  legendItems = [],
  activeView,
  onByChannelClick,
  onByAsinClick,
}) => {
  let dataToUse = [];
  switch (title) {
    case "Total Sales":
      dataToUse = dataSales;
      break;
    case "Contribution Profit":
      dataToUse = dataContribution;
      break;
    default:
      dataToUse = [];
      break;
  }

  let dataAsin = [];

  switch (title) {
    case "Total Sales":
      dataAsin = dataByASINsales;
      break;
    case "Contribution Profit":
      dataAsin = dataByASINcontribution;
      break;
    default:
      dataAsin = [];
      break;
  }

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.switchers}>
          <button
            onClick={onByChannelClick}
            className={classNames(styles.switchBtn, {
              [styles.active]: activeView === "channel",
              [styles.inactive]: activeView !== "channel",
            })}
          >
            by Channel
          </button>
          <button
            onClick={onByAsinClick}
            className={
              activeView === "asin"
                ? `${styles.switchBtn} ${styles.active}`
                : `${styles.switchBtn} ${styles.inactive}`
            }
          >
            by ASIN
          </button>
        </div>
      </div>

      {legendItems.length > 0 && (
        <div
          className={classNames(styles.legend, {
            [styles.legendSales]: title,
          })}
        >
          {legendItems.map((item) => (
            <div key={item.label} className={styles.legendItem}>
              <span
                className={styles.colorDot}
                style={{ backgroundColor: item.color }}
              />
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      )}

      {activeView === "channel" ? (
        <BrandRevenueChart
          hidePercentage={true}
          hideTitle={true}
          dataSales={dataToUse}
        />
      ) : (
        <div className={styles.asinBlock}>
          <AsinBarChart data={dataAsin} />
        </div>
      )}
    </div>
  );
};
