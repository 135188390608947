export const columns = [
  {
    title: "Brand",
    dataIndex: "category",
    key: "category",
    fixed: "left" as const,
    width: 150,
  },
  {
    title: "Price",
    dataIndex: "searchVolume",
    key: "searchVolume",
    width: 70,
  },
  {
    title: "Units Sold",
    dataIndex: "organicRank",
    key: "organicRank",
    width: 95,
  },
  {
    title: "Total Sales",
    dataIndex: "organicImpressions",
    key: "organicImpressions",
    width: 100,
  },
  {
    title: "Total Sales What If",
    dataIndex: "TotalWhatIf",
    key: "TotalWhatIf",
    width: 150,
  },
  {
    title: "Delta",
    dataIndex: "delta",
    key: "delta",
    width: 70,
  },
  {
    title: "Contribution Profit",
    dataIndex: "organicClicks",
    key: "organicClicks",
    width: 150,
  },
  {
    title: "Contribution Profit What If",
    dataIndex: "contributionProfitWhatIf",
    key: "contributionProfitWhatIf",
    width: 200,
  },
  {
    title: "Delta",
    dataIndex: "delta",
    key: "delta",
    width: 70,
  },
  {
    title: "Contribution Margin",
    dataIndex: "organicUnitsSold",
    key: "organicUnitsSold",
    width: 160,
  },
  {
    title: "Contribution Margin What If",
    dataIndex: "contributionMarginWhatIf",
    key: "contributionMarginWhatIf",
    width: 210,
  },
  {
    title: "Delta",
    dataIndex: "delta",
    key: "delta",
    width: 70,
  },
  {
    title: "ROI",
    dataIndex: "roi",
    key: "roi",
    width: 60,
  },
];
