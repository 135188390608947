export const computeTimeRanges = (
  dateRange: { startDate: string; endDate: string },
  timeRange: "daily" | "week" | "month",
  isCash: boolean,
): any[] => {
  if (!isCash || !dateRange.startDate || !dateRange.endDate) return [];
  const start = new Date(dateRange.startDate);
  const end = new Date(dateRange.endDate);
  if (timeRange === "daily") {
    const days: number[] = [];
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      days.push(d.getDate());
    }
    return days;
  } else if (timeRange === "week") {
    const weeks: string[] = [];
    let current = new Date(start);
    while (current <= end) {
      const weekStart = new Date(current);
      let weekEnd = new Date(current);
      weekEnd.setDate(weekEnd.getDate() + 6);
      if (weekEnd > end) weekEnd = new Date(end);
      weeks.push(`${weekStart.getDate()}-${weekEnd.getDate()}`);
      current = new Date(weekEnd);
      current.setDate(current.getDate() + 1);
    }
    return weeks;
  } else if (timeRange === "month") {
    const monthName = start.toLocaleString("default", { month: "long" });
    return [`${monthName} (${start.getDate()}-${end.getDate()})`];
  }
  return [];
};
