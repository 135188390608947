import React, { Key, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Tabs } from "@components/TabsTable";
import { Input } from "@components/Input";
import classNames from "classnames";
import Search from "@assets/icons/search";
import { Select } from "@components/Select";
import { Table } from "antd";
import { columns as originalColumns, sb, sd } from "./mock";
import "./table.scss";
import { Check } from "lucide-react";
import CloseMenuIcon from "@assets/icons/closeMenu";

interface SelectCampaignProps {
  close: () => void;
  apply: (campaigns: string[]) => void;
}

const tabs = ["SB Campaigns", "SD Campaigns", "SP Campaigns"];

const getData = (tab: string, filteredData = null) => {
  let sourceData;
  switch (tab) {
    case "SB Campaigns":
      sourceData = sb;
      break;
    case "SD Campaigns":
      sourceData = sd;
      break;
    case "SP Campaigns":
      sourceData = sb;
      break;
    default:
      sourceData = [];
  }

  return filteredData || sourceData;
};

export const SelectCampaign: React.FC<SelectCampaignProps> = ({
  close,
  apply,
}) => {
  const [tab, setTab] = useState("SB Campaigns");
  const [search, setSearch] = useState("");
  const [types, setTypes] = useState<string[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Key[]>();
  const [selectedRecommendations, setSelectedRecommendations] = useState<
    string[]
  >([]);
  const [tableData, setTableData] = useState<any[]>(getData(tab));

  const handleApplySingle = (record: any) => {
    apply([record.keyword_phrase]);
  };

  const handleRemoveRow = (record: any) => {
    const newData = tableData.filter((item) => item.key !== record.key);
    setTableData(newData);

    if (selectedCampaign && selectedCampaign.includes(record.key)) {
      const updatedSelections = selectedCampaign.filter(
        (key) => key !== record.key,
      );
      setSelectedCampaign(updatedSelections);

      const updatedRecommendations = selectedRecommendations.filter(
        (name) => name !== record.campaign_name,
      );
      setSelectedRecommendations(updatedRecommendations);
    }
  };

  const columns = [
    ...originalColumns,
    {
      title: "",
      key: "actions",
      width: 100,
      render: (_, record) => (
        <div className={styles.actionButtons}>
          <button
            onClick={() => handleApplySingle(record)}
            className={styles.actionButton}
            title="Apply"
          >
            <Check width={24} height={24} style={{ color: "#80C67A" }} />
          </button>
          <button
            onClick={() => handleRemoveRow(record)}
            className={classNames(styles.actionButton, styles.close)}
            title="Remove"
          >
            <CloseMenuIcon />
          </button>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedCampaign,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedCampaign(selectedRowKeys);
      const campaignNames = selectedRows.map((row) => row.keyword_phrase);
      setSelectedRecommendations(campaignNames);
    },
  };

  useEffect(() => {
    setTableData(getData(tab));
  }, [tab]);

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalContentTitle}>
        <h2>Analysis Results</h2>
      </div>
      <div className={styles.summary}>
        <div className={styles.card}>
          <div className={styles.cardHeader}>Avg ACOS</div>
          <div className={styles.cardBody}>12.97%</div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardHeader}>Total Ad Spend</div>
          <div className={styles.cardBody}>$8,382.32</div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardHeader}>Total Ad Sales</div>
          <div className={styles.cardBody}>$9,299.54</div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardHeader}>Campaigns Status</div>
          <div className={classNames(styles.cardBody, styles.enabled)}>
            Enabled
          </div>
        </div>
      </div>
      <div className={styles.modalContentBody}>
        <Tabs activeTab={tab} setActiveTab={setTab} tabs={tabs} />
        <div className={styles.inputs}>
          <Input
            placeholder="Search campaign or keyword..."
            value={search}
            icon={<Search />}
            onChange={setSearch}
            className={styles.searchInput}
          />
          <Select
            placeholder="All recommendation types"
            options={types}
            value={types}
            onChange={(value: string[]) => setTypes(value)}
            className={styles.select}
          />
        </div>
        <div className={styles.campaigns}>
          <Table
            columns={columns}
            dataSource={tableData}
            className={"optimization-table"}
            scroll={{ x: 1000, y: 360 }}
            pagination={false}
            rowSelection={rowSelection}
            size="small"
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <button className={styles.cancel} onClick={close}>
          Cancel
        </button>
        <button
          disabled={!selectedCampaign || selectedCampaign.length === 0}
          className={styles.select}
          onClick={() => apply(selectedRecommendations)}
        >
          Apply {selectedCampaign && selectedCampaign.length} recommendations
        </button>
      </div>
    </div>
  );
};
