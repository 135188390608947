import classNames from "classnames";
import styles from "./styles.module.scss";
import BottomArrow from "@assets/icons/bottomArrow";

interface CollapseInfoProps {
  label: string;
  count?: number;
  rotateArrow?: boolean;
  onClick?: () => void;
}

export const CollapseInfo = ({
  label,
  count,
  rotateArrow,
  onClick,
}: CollapseInfoProps) => {
  return (
    <div className={styles.collapseWrapper}>
      <button
        className={classNames(styles.collapseButton, {
          [styles.open]: rotateArrow,
        })}
        onClick={onClick}
      >
        {label}
        {count && <span className={styles.count}>({count})</span>}
        <span className={styles.arrow}>
          <BottomArrow fill="#5295E0" />
        </span>
      </button>
    </div>
  );
};
