import { useState, useCallback, useMemo, FC } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import BottomArrow from "@assets/icons/bottomArrow";
import CopyIcon from "@assets/icons/copy";
import Flag from "@assets/img/flag.svg";

export type AsinOption = {
  id: string;
  title: string;
  asin: string;
};

interface AsinSelectProps {
  value: string;
  options: AsinOption[];
  onChange: (value: string) => void;
}

export const AsinSelect: FC<AsinSelectProps> = ({
  value,
  options,
  onChange,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleToggleSelect = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleOptionClick = useCallback(
    (optionValue: string) => {
      onChange(optionValue);
      setOpen(false);
    },
    [onChange],
  );

  const displayValue = useMemo(() => {
    const selectedOption = options.find((opt) => opt.id === value);
    return selectedOption ? selectedOption.title : "Select ASIN";
  }, [value, options]);

  return (
    <div className={classNames(styles.selector, { [styles.open]: open })}>
      <button onClick={handleToggleSelect} className={styles.value}>
        <input value={displayValue} readOnly />
        <span className={styles.arrow}>
          <BottomArrow />
        </span>
      </button>

      {open && (
        <div className={styles.options}>
          {options.map((option) => (
            <button
              key={option.id}
              onClick={() => handleOptionClick(option.id)}
              className={styles.option}
            >
              <div className={styles.option__text}>
                <span>{option.title}</span>
                <div className={styles.productCode}>
                  <img
                    src={Flag}
                    alt="USA Flag"
                    className={styles.flag}
                    width={13}
                    height={11}
                  />
                  <span className={styles.code}>{option.asin}</span>
                  <CopyIcon />
                </div>
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
