import React from "react";
import styles from "./styles.module.scss";
import { ChartData } from "@services/widget.service";
import { ResponsiveContainer, XAxis, YAxis, AreaChart, Area } from "recharts";

interface WidgetChartProps {
  chartData: ChartData[];
}

export const WidgetChart: React.FC<WidgetChartProps> = ({ chartData }) => {
  return (
    <ResponsiveContainer width={"100%"} height={56}>
      <AreaChart data={chartData}>
        <XAxis dataKey="date" hide />
        <YAxis hide />
        <defs>
          <linearGradient
            id="colorUv"
            x1="98"
            y1="1"
            x2="98"
            y2="57"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#5295E0" />
            <stop offset="1" stop-color="#5295E0" stop-opacity="0" />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          stroke="#5295E0"
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
