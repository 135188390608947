import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useEffect, useState, useRef, useCallback } from "react";
import MetricBuilder from "@pages/CROModule/components/CROPage/components/Sections/MetricBuilder";
import { useCanvaStore } from "src/store/canva/canva.state";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { WidgetClass } from "@services/widget.service";
import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { WidgetMetricKey } from "@services/math/math.service";
import { Spin } from "antd";
import { Widget } from "@pages/Dashboard/features/Widgets/Widget";
import styles from "./styles.module.scss";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { useMarketStore } from "src/store/market.state";
import classNames from "classnames";
import WidgetIcon from "@assets/icons/widgetsDNDBtn";

interface IDefaultViewProps {
  choosenWidgets: WidgetMetricKey[];
  customTitles?: string[];
  showWhatIf: boolean;
  isCRO?: boolean;
  alwaysDefault?: boolean;
  showHistorical?: boolean;
}

export const DefaultView: React.FC<IDefaultViewProps> = ({
  choosenWidgets,
  customTitles,
  showWhatIf,
  isCRO,
  alwaysDefault,
  showHistorical,
}) => {
  const { dateRange, compareType } = useDashboardStore();
  const { compareWith } = useAdsAnaliticsStore();
  const { startDate, endDate } = dateRange;
  const [widgets, setWidgets] = useState<IWidget[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isWhatIf } = useCanvaStore();
  const { isWhatIf: isWhatIfMarket } = useMarketStore();

  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [targetIndex, setTargetIndex] = useState<number | null>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const ghostRef = useRef<HTMLDivElement | null>(null);
  const emptyImageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    emptyImageRef.current = img;
  }, []);

  const formatDifference = (difference: string): string => {
    if (difference.startsWith("+") || difference.startsWith("-")) {
      return difference;
    }
    return `+${difference}`;
  };

  useEffect(() => {
    const fetchWidgets = async () => {
      setIsLoading(true);
      const widgetClass = new WidgetClass();
      const startDateString = startDate.toISOString().split("T")[0];
      const endDateString = endDate.toISOString().split("T")[0];
      const { startDate: compareStartDate, endDate: compareEndDate } =
        compareWith;
      const compareStartDateString = startOfWeek(subWeeks(compareStartDate, 1))
        .toISOString()
        .split("T")[0];
      const compareEndDateString = endOfWeek(subWeeks(compareEndDate, 1))
        .toISOString()
        .split("T")[0];

      const response = await widgetClass.getWidgetsData(
        choosenWidgets,
        startDateString,
        endDateString,
        compareStartDateString,
        compareEndDateString,
        compareType,
        showHistorical,
      );

      let updatedWidgets = response;

      if (customTitles && customTitles.length > 0) {
        updatedWidgets = choosenWidgets
          .map((key, index) => {
            const widget = response.find((w) => w.id === key);
            if (!widget) return null;
            const title =
              customTitles[index] || WIDGET_METRICS_CONFIG[key]?.label || key;
            return {
              ...widget,
              title: title.trim(),
              name: title.trim(),
              difference: formatDifference(widget.difference),
            };
          })
          .filter(Boolean)
          .slice(0, 10);
      } else {
        updatedWidgets = response
          .map((widget) => ({
            ...widget,
            difference: formatDifference(widget.difference),
          }))
          .slice(0, 10);
      }

      setWidgets(updatedWidgets);
      setIsLoading(false);
    };
    fetchWidgets();
  }, [
    choosenWidgets,
    dateRange,
    compareWith,
    compareType,
    customTitles,
    showHistorical,
  ]);

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, widgets.length);
  }, [widgets]);

  const handleDragStart = useCallback((e: React.DragEvent, index: number) => {
    e.dataTransfer.effectAllowed = "move";

    if (emptyImageRef.current) {
      e.dataTransfer.setDragImage(emptyImageRef.current, 0, 0);
    }

    const ghostElement = document.createElement("div");
    ghostElement.className = styles.ghostElement;
    document.body.appendChild(ghostElement);
    ghostRef.current = ghostElement;

    setDraggedIndex(index);

    const draggedItem = itemRefs.current[index];
    if (draggedItem) {
      draggedItem.classList.add(styles.isDragging);

      const rect = draggedItem.getBoundingClientRect();

      ghostElement.style.width = `${rect.width}px`;
      ghostElement.style.height = `${rect.height}px`;
      ghostElement.style.position = "fixed";
      ghostElement.style.top = "0";
      ghostElement.style.left = "0";
      ghostElement.style.pointerEvents = "none";
      ghostElement.style.zIndex = "9999";
      ghostElement.style.transform = `translate(${e.clientX - 100}px, ${e.clientY - 50}px)`;
      ghostElement.innerHTML = draggedItem.innerHTML;

      const ghostHandles = ghostElement.querySelectorAll(
        `.${styles.dragHandle}`,
      );
      ghostHandles.forEach((handle) => handle.remove());
    }
  }, []);

  const handleDrag = useCallback((e: React.DragEvent) => {
    if (ghostRef.current && e.clientX > 0 && e.clientY > 0) {
      ghostRef.current.style.transform = `translate(${e.clientX - 100}px, ${e.clientY - 50}px)`;
    }
  }, []);

  const handleDragOver = useCallback(
    (e: React.DragEvent, index: number) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";

      if (index !== draggedIndex) {
        setTargetIndex(index);
      }
    },
    [draggedIndex],
  );

  const handleDragEnter = useCallback(
    (e: React.DragEvent, index: number) => {
      e.preventDefault();
      if (index !== draggedIndex) {
        setTargetIndex(index);
      }
    },
    [draggedIndex],
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent, index: number) => {
      if (e.currentTarget.contains(e.relatedTarget as Node)) {
        return;
      }

      if (index === targetIndex) {
        setTargetIndex(null);
      }
    },
    [targetIndex],
  );

  const handleDragEnd = useCallback(
    (e: React.DragEvent) => {
      // Remove ghost element
      if (ghostRef.current) {
        document.body.removeChild(ghostRef.current);
        ghostRef.current = null;
      }

      if (
        draggedIndex !== null &&
        targetIndex !== null &&
        draggedIndex !== targetIndex
      ) {
        const newWidgets = [...widgets];
        const [draggedWidget] = newWidgets.splice(draggedIndex, 1);
        newWidgets.splice(targetIndex, 0, draggedWidget);
        setWidgets(newWidgets);
      }

      const draggedItem =
        draggedIndex !== null ? itemRefs.current[draggedIndex] : null;
      if (draggedItem) {
        draggedItem.classList.remove(styles.isDragging);
      }

      setDraggedIndex(null);
      setTargetIndex(null);
    },
    [draggedIndex, targetIndex, widgets],
  );

  const showWhatIfTrue = showWhatIf ? isWhatIfMarket : isWhatIf;

  const defaultView = (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>
          <Spin />
        </div>
      ) : (
        <div
          ref={containerRef}
          className={classNames(styles.widgets, { [styles.cro]: isCRO })}
        >
          {widgets.map((widget, index) => (
            <div
              key={widget.id || index}
              ref={(el) => (itemRefs.current[index] = el)}
              className={classNames(styles.widgetWrapper, {
                [styles.isTarget]: index === targetIndex,
              })}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDrag={handleDrag}
              onDragOver={(e) => handleDragOver(e, index)}
              onDragEnter={(e) => handleDragEnter(e, index)}
              onDragLeave={(e) => handleDragLeave(e, index)}
              onDragEnd={handleDragEnd}
            >
              <div className={styles.dragHandle}>
                {typeof WidgetIcon === "function" ? <WidgetIcon /> : "≡"}
              </div>
              <Widget widget={widget} additionalClass={styles.widget} />
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const metricBuilderView = (
    <div className={styles.optimisation}>
      <MetricBuilder
        metricPerRow={5}
        useConfigButton
        hideSettings
        hideLine
        customTitles={customTitles}
      />
    </div>
  );

  if (alwaysDefault) {
    return defaultView;
  }

  return showWhatIfTrue ? metricBuilderView : defaultView;
};
