import { create } from "zustand";

interface ForecastState {
  showForecast: boolean;
  setShowForecast: (value: boolean) => void;
}

export const useForecastStore = create<ForecastState>((set) => ({
  showForecast: false,
  setShowForecast: (value) => set({ showForecast: value }),
}));
