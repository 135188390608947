import mockIMG from "@assets/img/product1.png";
import mockIMG2 from "@assets/img/product2.png";

export interface ItemData {
  title: string;
  img: string;
  asin: string;
  rating: number;
  value: number;
  variations: boolean;
  status: "active" | "inactive" | "search" | "out" | "closed";
  variants?: ItemData[];
  isVariants?: boolean;
  setIsVariants?: (value: boolean) => void;
  category?: string;
  isVariant?: boolean;
  variantsCount?: number;
}

export interface DataProps {
  data: ItemData[];
}

export const mock: DataProps = {
  data: [
    {
      title: "SugarbearPro Sleep Aid Gummies for Adults with",
      img: mockIMG,
      asin: "B004OOMYHK",
      rating: 4.5,
      value: 2070.59,
      variations: true,
      status: "active",
      variants: [
        {
          title: "SugarbearPro Sleep Aid Gummies for Adults with",
          img: mockIMG2,
          asin: "B004OOMYHK",
          rating: 4.5,
          value: 2070.59,
          variations: true,
          status: "active",
        },
        {
          title: "SugarbearPro Sleep Aid Gummies for Adults with",
          img: mockIMG2,
          asin: "B004OOMYHK",
          rating: 4.5,
          value: 2070.59,
          variations: true,
          status: "active",
        },
        {
          title: "SugarbearPro Sleep Aid Gummies for Adults with",
          img: mockIMG2,
          asin: "B004OOMYHK",
          rating: 4.5,
          value: 2070.59,
          variations: true,
          status: "active",
        },
      ],
    },
    {
      title: "SugarbearPro Sleep Aid Gummies for Adults with",
      img: mockIMG,
      asin: "B004O1MYHK",
      rating: 3.5,
      value: 1070.59,
      variations: false,
      status: "inactive",
    },
    {
      title: "SugarbearPro Sleep Aid Gummies for Adults with",
      img: mockIMG,
      asin: "B004O2MYHK",
      rating: 4.5,
      value: 3000.19,
      variations: false,
      status: "active",
    },
  ],
};
