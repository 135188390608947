import React from "react";
import styles from "../../styles.module.scss";

interface ZoomControlsProps {
  zoom: number;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
}

export const ZoomControls: React.FC<ZoomControlsProps> = ({
  zoom,
  handleZoomIn,
  handleZoomOut,
}) => {
  return (
    <div className={styles.zoom}>
      <button className={styles.zoom__button} onClick={handleZoomOut}>
        -
      </button>
      <span>{Math.round(zoom * 100)}%</span>
      <button className={styles.zoom__button} onClick={handleZoomIn}>
        +
      </button>
    </div>
  );
};
