import { useState } from "react";
import styles from "./styles.module.scss";
import { AsinBtn } from "../asinBtn";
import BottomArrow from "@assets/icons/bottomArrow";
import { Rating } from "@pages/Dashboard/features/Products/Rating";
import classNames from "classnames";
import { AccentValue } from "../AccentValue";
import React from "react";
import { formatCurrency } from "@pages/MarketIntelligence/components/BrandPerformance/mock";
import { ProductActions } from "../ProductActions";
import Setting from "@assets/icons/setting";

export const Table = ({ data }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [detailsState, setDetailsState] = useState({});

  const toggleDetails = (asin) => {
    setDetailsState((prevState) => ({
      ...prevState,
      [asin]: !prevState[asin],
    }));
  };

  const toggleRow = (asin) => {
    setExpandedRows((prev) => ({
      ...prev,
      [asin]: !prev[asin],
    }));
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.customTable}>
        <thead>
          <tr>
            <th>Product</th>
            <th>Total Sales</th>
            <th>Rating</th>
            <th>Category</th>
            <th>Price</th>
            <th>Product count</th>
            <th>Status</th>
            <th>
              <Setting />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((product) => {
            const isDetails = detailsState[product.asin] || false;
            return (
              <React.Fragment key={product.asin}>
                <tr className={styles.mainRow}>
                  <td className={styles.productInfo}>
                    <img
                      src={product.img}
                      alt={product.title}
                      className={styles.productImage}
                    />
                    <div>
                      <p className={styles.productName}>{product.title}</p>
                      <AsinBtn asin={product.asin} />
                    </div>
                  </td>
                  <td>
                    <AccentValue value={product.value} size="sm" />
                  </td>
                  <td>
                    <Rating rating={product.rating} starSize="sm" />
                  </td>
                  <td>{product.category || "Category"}</td>
                  <td>{formatCurrency(product.price || 31)}</td>
                  <td>{product.productCount || 3}</td>
                  <td>
                    <ProductActions
                      status={product.status}
                      isDetails={isDetails}
                      setIsDetails={() => toggleDetails(product.asin)}
                      isTable={true}
                    />
                  </td>
                  <td>
                    {product.variants?.length > 0 && (
                      <button
                        className={classNames(styles.toggleButton, {
                          [styles.expanded]: expandedRows[product.asin],
                        })}
                        onClick={() => toggleRow(product.asin)}
                      >
                        <BottomArrow />
                      </button>
                    )}
                  </td>
                </tr>

                {expandedRows[product.asin] &&
                  product.variants?.map((variant, index) => (
                    <tr
                      className={styles.expandedRow}
                      key={`${variant.asin}-${index}`}
                    >
                      <td className={styles.productInfo}>
                        <img
                          src={variant.img}
                          alt={variant.title}
                          className={styles.productImage}
                        />
                        <div>
                          <p className={styles.productName}>{variant.title}</p>
                          <AsinBtn asin={variant.asin} />
                        </div>
                      </td>
                      <td>
                        <AccentValue value={variant.value} size="sm" />
                      </td>
                      <td>
                        <Rating rating={variant.rating} starSize="sm" />
                      </td>
                      <td>{product.category || "Category"}</td>
                      <td>{formatCurrency(variant.price || 31)}</td>
                      <td>{variant.productCount || 5}</td>
                      <td>
                        <ProductActions
                          status={variant.status}
                          isDetails={isDetails}
                          setIsDetails={() => toggleDetails(variant.asin)}
                          isTable={true}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
