export type Checks = Record<string, boolean>;

export const initialChecks: Checks = {
  // impressions: false,
  // CVR: false,
  sales: true,
  adCTR: true,
  // adOrders: false,
  adSpend: true,
  // organicClicks: false,
  // organicUnitsSold: false,
  // subscriptionSales: false,
  // CTR: false,
  // orders: false,
  // spend: false,
  adClicks: true,
  adUnitsSold: true,
  // organicImpressions: false,
  // organicCVR: false,
  // organicSales: false,
  // ntbSales: false,
  // clicks: false,
  // unitSolds: false,
  ACOS: false,
  adImpressions: true,
  adCVR: false,
  adSales: false,
  ROAS: false,
  CPC: false,
  // organicCTR: false,
  // organicOrders: false,
  // organicSpend: false,
  // contributionProfit: false,
};

export const initialChecksForHideExtra: Checks = {
  sales: true,
  spend: false,
  impressions: false,
  ctr: false,
  cvr: true,
  cpc: false,
  acos: false,
  roas: false,
  orders: false,
  unitSolds: false,
  clicks: false,
  tacos: false,
  organicCVR: false,
  organicCTR: false,
  organicSales: false,
};

export const initialCheck: Checks = {
  sales: false,
  spend: false,
  impressions: false,
  ctr: false,
  cvr: false,
  cpc: false,
  acos: false,
  roas: false,
  orders: false,
  unitSolds: false,
  clicks: false,
  tacos: false,
  organicCVR: false,
  organicCTR: false,
  organicSales: false,
};
