import { stepConfig } from "./stepConfig";

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const urlToFile = async (
  url: string,
  filename: string,
  mimeType: string,
): Promise<File> => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  return new File([buffer], filename, { type: mimeType });
};

export const base64ToFile = (base64String: string, fileName: string): File => {
  try {
    const base64 = base64String.replace(/^data:image\/\w+;base64,/, "");

    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    return new File([blob], fileName, { type: "image/png" });
  } catch (error) {
    console.error("Error converting Base64 to File:", error);
    throw new Error("Invalid Base64 format");
  }
};

export const convertImageUrlToBase64 = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject("Canvas context not found");
        return;
      }

      ctx.drawImage(img, 0, 0);
      const base64 = canvas
        .toDataURL("image/png")
        .replace(/^data:image\/png;base64,/, "");
      resolve(base64);
    };

    img.onerror = (err) => reject(err);
  });
};

export const FormatCanvasResult = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  width = 1024,
  height = 1024,
) => {
  if (!canvasRef.current) {
    console.error("Canvas not found");
    return;
  }

  const canvas = canvasRef.current;
  const resizedCanvas = document.createElement("canvas");
  const resizedContext = resizedCanvas.getContext("2d");

  resizedCanvas.height = width;
  resizedCanvas.width = height;

  resizedContext.drawImage(canvas, 0, 0, width, height);

  try {
    const jpegImage = resizedCanvas.toDataURL("image/jpeg", 0.9);

    if (!jpegImage.startsWith("data:image/jpeg")) {
      throw new Error("Failed to generate JPEG format");
    }

    return jpegImage;
  } catch (error) {
    console.error("Error generating JPEG image:", error);
    return null;
  }
};

// userGuide stepsHandler
export const handleStepChange = (
  targetStep,
  setCurrUserStepGuide,
  additionalHandlers = {},
) => {
  const step = stepConfig[targetStep];

  if (step) {
    if (step.action && additionalHandlers) {
      step.action(...Object.values(additionalHandlers));
    }
    setCurrUserStepGuide(targetStep);
  } else {
    console.log("Guide completed");
  }
};

export const checkFirstVisit = (
  key: string,
  setShowUserGuide: (value: boolean) => void,
) => {
  const isFirstVisit = localStorage.getItem(key) === null;

  if (isFirstVisit) {
    setShowUserGuide(true);
    localStorage.setItem(key, "false");
  } else {
    setShowUserGuide(false);
  }
};
