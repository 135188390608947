import React, { useState } from "react";
import styles from "./styles.module.scss";
import CloseEye from "@assets/icons/iconCloseEye";
import OpenEye from "@assets/icons/iconOpenEye";

interface AuthInputProps {
  title: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  password?: boolean;
  error?: string;
}

const AuthInput: React.FC<AuthInputProps> = ({
  title,
  value,
  onChange,
  password,
  error,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={styles.authInputContainer}>
      <label className={styles.authInputLabel}>{title}</label>
      <div className={styles.inputContainer}>
        <input
          className={`${styles.authInputField} ${error ? styles.errorInput : ""}`}
          type={password && !isVisible ? "password" : "text"}
          value={value}
          onChange={onChange}
        />
        {password && (
          <button onClick={toggleVisibility} className={styles.eyeButton}>
            {isVisible ? <OpenEye /> : <CloseEye />}
          </button>
        )}
      </div>
    </div>
  );
};

export default AuthInput;
