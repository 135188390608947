import { Card } from "../Card";
import { ProductActions } from "../ProductActions";
import { ProductChart } from "../ProductChart";
import styles from "./styles.module.scss";

export const VariantsList = ({ data, isDetails }) => {
  return (
    <div className={styles.productsList}>
      {data.data.map((item) => (
        <div className={styles.productWrapper} key={item.asin}>
          {item.variants &&
            item.variants.map((variant) => (
              <div className={styles.product} key={variant.asin}>
                <div className={styles.cardWrapper}>
                  <Card
                    title={variant.title}
                    asin={variant.asin}
                    variations={variant.variations}
                    img={variant.img}
                    rating={variant.rating}
                    value={variant.value}
                    status={variant.status}
                    isVariant={true}
                  />
                </div>
                <div className={styles.variantChart}>
                  <ProductChart />
                </div>
                <ProductActions status={variant.status} isDetails={isDetails} />
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};
