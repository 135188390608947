import {
  CardChartData,
  CardWithChart,
  CardWithChartProps,
} from "@components/CardWithChart";
import {
  Message,
  PerformanceType,
  PREFIX_METRIC,
  PREFIX_PERFORMANCE,
  RadarData,
  ToolType,
} from "@components/ChatBot/types";
import { RadarChart } from "@components/Spider/RadarChart";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { CHART_DATASET_CONFIG, CHART_LABELS } from "./constants";
import { formatMetricValue, getWidgetName } from "./formatters";
import style from "./style.module.scss";

class MessageRenderer {
  static render(message: Message) {
    const { type, text, data } = message;

    try {
      if (type.includes(PREFIX_METRIC)) {
        return this.renderMetricValue(type, Number(data));
      }

      if (type.includes(PREFIX_PERFORMANCE)) {
        return this.renderPerformanceChart(type, data as CardChartData[]);
      }

      const renderers = {
        [ToolType.TEXT]: () => this.renderTextMessage(text),
        [ToolType.PRODUCT_SPIDER]: () =>
          this.renderSpiderChart(data as RadarData),
      };

      return renderers[type]?.() ?? this.renderUnsupported(type);
    } catch (error) {
      console.error("Error rendering message:", error);
      return <div className={style.message__text}>Error rendering message</div>;
    }
  }

  private static renderTextMessage(text: string) {
    const textWithRating = text.replace(
      /(\*\*Rating:\*\* )(\d+)/g,
      (_, prefix, rating) => `${prefix}${rating}⭐`,
    );

    return (
      <div className={style.message__text}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {textWithRating}
        </ReactMarkdown>
      </div>
    );
  }

  private static renderMetricValue(type: ToolType, data: number) {
    const metricName = getWidgetName(type);
    const formattedValue = formatMetricValue(type, data);

    return (
      <div className={style.bg}>
        <div className={style.metric_container}>
          <div className={style.metric_header}>
            <span className={style.metric_name}>{metricName}</span>
          </div>
          <div className={style.metric_value}>{formattedValue}</div>
        </div>
      </div>
    );
  }

  private static renderPerformanceChart(type: ToolType, data: CardChartData[]) {
    const chartProps: CardWithChartProps = {
      title: this.getPerformanceChartTitle(type),
      chartData: data,
      toolType: type,
    };

    return (
      <div className={style.card}>
        <CardWithChart {...chartProps} />
      </div>
    );
  }

  private static renderSpiderChart(data: RadarData) {
    if (!data) {
      return <div className={style.message__text}>No data available</div>;
    }
    const radarValues = Object.values(data);
    const dataset = [
      {
        label: "Your Product",
        data: radarValues,
        ...CHART_DATASET_CONFIG,
      },
    ];

    return (
      <div className={style.message__canva}>
        <RadarChart
          productOneTitles={CHART_LABELS}
          titles={CHART_LABELS}
          datasets={dataset}
          activeIndex={0}
          width={280}
          height={280}
          padding={0}
          productOneValues={radarValues}
          productTwoValues={[]}
          labelFontSize={12}
        />
      </div>
    );
  }

  private static getPerformanceChartTitle(type: string): string {
    const metric = type.replace("sales_performance_", "");
    const titles: Record<string, string> = {
      [PerformanceType.IMPRESSIONS]: "Impressions Over Time",
      [PerformanceType.CLICKS]: "Clicks Over Time",
      [PerformanceType.UNIT_SOLD]: "Units Sold Over Time",
      [PerformanceType.TOTAL_SALES]: "Total Sales Over Time",
      [PerformanceType.AD_SPEND]: "Ad Spend Over Time",
      [PerformanceType.ACOS]: "ACoS Over Time",
      [PerformanceType.ROAS]: "ROAS Over Time",
      [PerformanceType.CPC]: "CPC Over Time",
      [PerformanceType.CVR]: "Conversion Rate Over Time",
      [PerformanceType.CTR]: "Click-Through Rate Over Time",
      [PerformanceType.AD_SALES]: "Ad Sales Over Time",
    };

    return titles[metric] ?? "Performance Over Time";
  }

  private static renderUnsupported(type: string) {
    console.warn(`Unsupported message type: ${type}`);
    return <div className={style.message__text}>Type not supported</div>;
  }
}

export const renderMessage = MessageRenderer.render.bind(MessageRenderer);
