import { AgentApiResponse } from "@components/ChatBot/types";
import { Api } from "src/utils/api";
import { INSTATHINK_API_BASE_URL } from "./chat.constant";
import RefreshTokenService from "src/services/refreshToken";

interface ApiCall<T = unknown> {
  body: T;
}

export class ChatApi extends Api {
  constructor() {
    super(INSTATHINK_API_BASE_URL);
  }
  /**
   * Handles API call and parses the response.
   * @param payload - The payload to send with the API call.
   * @returns The parsed response as AgentApiResponse.
   */
  private async handleApiCall(
    payload: Record<string, unknown>,
  ): Promise<AgentApiResponse> {
    let token = localStorage.getItem("access_token");
    if (!token || !(await RefreshTokenService.isTokenActive(token))) {
      const refresh_token = localStorage.getItem("refresh_token");
      if (!refresh_token) {
        throw new Error("No refresh token available");
      }
      token = await RefreshTokenService.refresh(refresh_token);
    }

    this.axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;

    try {
      const response = await this.post<ApiCall<string>>("", payload);
      const data = response?.body;

      if (!data) {
        throw new Error("No data returned from API");
      }

      return JSON.parse(data) as AgentApiResponse;
    } catch (error) {
      const errorMessage = `Error from API call: ${error instanceof Error ? error.message : error}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  }

  /**
   * Sends a message to the API for processing.
   * @param question - The question/message to send.
   * @returns The response from the API.
   */
  public sendMessage = (question: string): Promise<AgentApiResponse> => {
    const payload = {
      message: question,
      workflowId: "nyle",
      taskId: "nyle_testing_id",
    };

    return this.handleApiCall(payload);
  };
}
