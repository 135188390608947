import { Select } from "@components/Select";
import styles from "./styles.module.scss";
import { useState } from "react";
import classNames from "classnames";
import { selectMock } from "./mock";
import { AsinSelect } from "./AsinSelect";

interface ActionsProps {
  setAddNewItem: (value: boolean) => void;
}

export const Actions = ({ setAddNewItem }: ActionsProps) => {
  const [asin, setAsin] = useState<string>("");
  const [selectedAsin, setSelectedAsin] = useState("");
  const [status, setStatus] = useState("All");
  const statusOptions = ["All", ...selectMock.map((item) => item.title)];

  return (
    <div className={styles.actions}>
      <div className={styles.select}>
        <span>product</span>
        <AsinSelect
          value={selectedAsin}
          options={selectMock}
          onChange={setSelectedAsin}
        />
      </div>
      <div className={styles.select}>
        <span>status</span>
        <Select
          options={["All", "Active", "Inactive"]}
          value={status}
          onChange={(value: string) => setStatus(value)}
          placeholder="All"
          className={classNames(styles.select__dropdown, styles.md)}
        />
      </div>
      <button
        className={classNames(
          "button button--sm button--gap button--m-0 button--spacing",
          styles.button,
        )}
        onClick={() => setAddNewItem(true)}
      >
        add new product
      </button>
    </div>
  );
};
