import { useState, useRef } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import ChevronDown from "@assets/icons/chevron-down";

interface DropdownSelectProps {
  options: string[];
  defaultLabel?: string;
  onSelect?: (value: string) => void;
  className?: string;
}

const DaysSelect = ({
  options,
  defaultLabel = "Select an option",
  onSelect,
  className,
}: DropdownSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultLabel);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false);
    if (onSelect) {
      onSelect(value);
    }
  };

  return (
    <div
      className={classNames(styles.dropdownContainer, className)}
      ref={dropdownRef}
    >
      <button
        className={classNames(styles.customSelect, { [styles.active]: isOpen })}
        onClick={toggleDropdown}
      >
        <span>{selectedValue}</span>
        <div className={styles.arrow}>
          <ChevronDown />
        </div>
      </button>

      {isOpen && (
        <div className={styles.dropdownMenu}>
          {options.map((option) => (
            <div
              key={option}
              className={styles.dropdownItem}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DaysSelect;
