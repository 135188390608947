import { FC } from "react";
import ModalBox from "@components/ModalBox";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/router/routes";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface ModalAmazonConnectProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ModalAmazonConnect: FC<ModalAmazonConnectProps> = ({
  isOpen,
  onClose,
}) => {
  const nav = useNavigate();
  const handleConnect = () => {
    onClose();
    nav(`/${ROUTES.CONNECT_AMAZON}`);
  };

  return (
    <ModalBox
      isOpen={isOpen}
      onClose={onClose}
      title="Connect Amazon Account"
      hideCloseButton={false}
    >
      <div className={styles.content}>
        <button
          className={classNames(
            "button button--sm button--gap button--m-0 button--spacing",
            styles.applyButton,
          )}
          onClick={handleConnect}
        >
          Connect
        </button>
      </div>
    </ModalBox>
  );
};
