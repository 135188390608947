import { productCache } from "@pages/MarketIntelligence/components/DotChart";
import "./typeTooltip.scss";

export function typeTooltipFormatter(params: any) {
  if (!params.data?.asin || !Array.isArray(params.data.value)) {
    return "";
  }

  const asin = params.data.asin;

  const cached = productCache[asin];
  let realPrice = "Loading...";
  let pictureUrl = "";

  if (cached) {
    realPrice = cached.price !== null ? String(cached.price) : "Null";
    pictureUrl = cached.picture_url ?? "";
  }

  return `
    <table class="typeTooltipTable">
      <thead>
        <tr>
          <th>ASIN</th>
          <th>Image URL</th>
          <th>Price, $</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>${asin}</td>
          <td>
            ${
              pictureUrl
                ? `<img src="${pictureUrl}" width="80" alt="product" />`
                : "Loading..."
            }
          </td>
          <td>
            <small>( ${realPrice})</small>
          </td>
        </tr>
      </tbody>
    </table>
  `;
}
