import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { Minus, Plus } from "lucide-react";
import classNames from "classnames";
import { renderToCanvas } from "./utils";
import { convertImageUrlToBase64, FormatCanvasResult } from "../../utils";
import UserGuidePopover from "../UserGuidePopover";
import AccentLight from "../AccentLight";
import { assetStatic } from "../../mock";
import { Spin } from "antd";

const addMimeType = (image: string) =>
  Boolean(image) === false
    ? ""
    : image.startsWith("data:image")
      ? image
      : "data:image/png;base64," + image;

const Canvas = ({
  activeImage,
  activeNavIndex,
  draggableEl,
  onMouseUpDraggableItems,
  activeBtn,
  generatedImage,
  isLoading,
  onApplyImg,
  onNewRender,
  setApplyImg,
  userGuide,
  setUserGuide,
  currStep,
  setCurrUserStepGuide,
  setActiveNavIndex,
  onGenerateImage,
  selectedPrompt,
  setSelectedPrompt,
}: {
  activeImage: {
    mask: string;
    image: string;
  };
  activeNavIndex: number;
  draggableEl: { image: string; style: CSSProperties }[];
  onMouseUpDraggableItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => void;
  activeBtn: HTMLElement;
  generatedImage: string;
  isLoading: boolean;
  setFormatCanvasResult: (value: string) => void;
  onApplyImg: (image: string) => void;
  onNewRender: () => void;
  setApplyImg: (value: boolean) => void;
  userGuide: boolean;
  setUserGuide: (value: boolean) => void;
  currStep: number;
  setCurrUserStepGuide: (value: number) => void;
  setActiveNavIndex: (value: number) => void;
  onGenerateImage: (prompt: string[]) => void;
  selectedPrompt?: string;
  setSelectedPrompt: (value: string) => void;
}) => {
  const isMobile = window.innerWidth < 768;
  const [zoom, setZoom] = useState(isMobile ? 0.55 : 1);
  const zoomRef = useRef(zoom);
  const canvasRef = useRef<HTMLDivElement>(null);
  const zIndexFront = 5;
  const zIndexBack = 3;

  const [generatedImageBase64, setGeneratedImageBase64] = useState<string>("");

  useEffect(() => {
    const convertGeneratedImage = async () => {
      if (generatedImage && !generatedImage.startsWith("data:image")) {
        const base64Image = await convertImageUrlToBase64(generatedImage);
        setGeneratedImageBase64(base64Image);
      } else {
        setGeneratedImageBase64(generatedImage);
      }
    };

    convertGeneratedImage();
  }, [generatedImage]);

  const changeZoom = (newZoom: number) => {
    if (!userGuide) {
      const clampedZoom = Math.min(
        Math.max(newZoom, 0.2),
        isMobile ? 0.55 : 1.2,
      );
      zoomRef.current = clampedZoom;
      return clampedZoom;
    }
    return zoomRef.current;
  };

  useEffect(() => {
    const canvasDom = canvasRef.current;

    if (canvasDom === null) {
      return;
    }

    Array.from(
      canvasDom.querySelectorAll('[data-default-position="center"]'),
    ).map((dom: HTMLElement | null) => {
      const offsetParentDom = dom.offsetParent as HTMLElement;

      const centerY = offsetParentDom.offsetHeight / 2 - dom.offsetHeight / 2;
      const centerX = offsetParentDom.offsetWidth / 2 - dom.offsetWidth / 2;

      dom.style.top = centerY + "px";
      dom.style.left = centerX + "px";
    });
  }, []);

  useEffect(() => {
    const canvasDom = canvasRef.current;

    if (canvasDom === null) {
      return;
    }

    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey === false) {
        return;
      }

      setZoom((prevZoom) => {
        return changeZoom(prevZoom + delta);
      });

      const delta = event.deltaY > 0 ? -0.05 : 0.05;
      setZoom((prevZoom) => changeZoom(prevZoom + delta));
    };

    const resizePointerDown = (event: PointerEvent) => {
      const target = event.target as HTMLElement | null;

      if (target === null) {
        return;
      }

      const draggableContainerDom = target.closest(
        '[data-draggable="container"]',
      ) as HTMLElement | null;
      const draggableDom = target.closest(
        '[data-draggable="draggable"]',
      ) as HTMLElement | null;
      const resizeButtonDom = target.closest(
        '[data-draggable="resize"]',
      ) as HTMLElement | null;

      if (
        draggableDom === null ||
        draggableContainerDom === null ||
        resizeButtonDom === null
      ) {
        return;
      }

      event.stopImmediatePropagation(); // stop movePointerDown fn

      const targetDom = draggableDom;
      targetDom.setPointerCapture(event.pointerId);

      const startX = event.clientX / zoom;
      const startY = event.clientY / zoom;
      const startTop = draggableDom.offsetTop;
      const startLeft = draggableDom.offsetLeft;
      const startWidth = draggableDom.offsetWidth;
      const startHeight = draggableDom.offsetHeight;

      const draggableRect = draggableDom.getBoundingClientRect();
      const buttonRect = resizeButtonDom.getBoundingClientRect();
      const shiftX = startX - buttonRect.left;
      const shiftY = startY - buttonRect.top;
      const offsetLeft = buttonRect.left - draggableRect.left;
      const offsetTop = buttonRect.top - draggableRect.top;

      const xDirection = Number(resizeButtonDom.dataset.x) || 1;
      const yDirection = Number(resizeButtonDom.dataset.y) || 1;

      const draggableContainerRect =
        draggableContainerDom.getBoundingClientRect();

      // offsetParent can be not draggable-container.
      const parent = targetDom.offsetParent as HTMLElement;
      const parentRect = parent.getBoundingClientRect();

      const shiftParentX = draggableContainerRect.left - parentRect.left;
      const shiftParentY = draggableContainerRect.top - parentRect.top;

      const pointerMove = (event: PointerEvent) => {
        const currentX = event.clientX / zoom;
        const currentY = event.clientY / zoom;

        const deltaX = (currentX - startX) * xDirection;
        const deltaY = (currentY - startY) * yDirection;

        const deltaWidth = startWidth + deltaX;
        const deltaHeight = startHeight + deltaY;

        const isReverseX = xDirection === -1;
        const isReverseY = yDirection === -1;

        const isStartX =
          currentX - offsetLeft - shiftX < draggableContainerRect.left;
        const isStartY =
          currentY - offsetTop - shiftY < draggableContainerRect.top;

        if (isReverseX === false) {
          const newWidth = Math.min(
            Math.max(0, deltaWidth),
            draggableContainerDom.offsetWidth - startLeft,
          );

          draggableDom.style.width = newWidth + "px";
        }

        if (isReverseY === false) {
          const newHeight = Math.min(
            Math.max(0, deltaHeight),
            draggableContainerDom.offsetHeight - startTop,
          );

          draggableDom.style.height = newHeight + "px";
        }

        if (isReverseX) {
          if (isStartX) {
            const oldLeft = draggableDom.offsetLeft - shiftParentX;
            draggableDom.style.width =
              draggableDom.offsetWidth + oldLeft + "px";
            draggableDom.style.left = shiftParentX + "px";
          } else {
            if (deltaWidth > 0) {
              const deltaLeft = startLeft - deltaX;
              draggableDom.style.width = deltaWidth + "px";
              draggableDom.style.left = deltaLeft + "px";
            } else {
              const oldWidth = draggableDom.offsetWidth;
              draggableDom.style.width = 0 + "px";
              draggableDom.style.left =
                draggableDom.offsetLeft + oldWidth + "px";
            }
          }
        }

        if (isReverseY) {
          if (isStartY) {
            const oldTop = draggableDom.offsetTop - shiftParentY;
            draggableDom.style.height =
              draggableDom.offsetHeight + oldTop + "px";
            draggableDom.style.top = shiftParentY + "px";
          } else {
            if (deltaHeight > 0) {
              const deltaTop = startTop - deltaY;
              draggableDom.style.height = deltaHeight + "px";
              draggableDom.style.top = deltaTop + "px";
            } else {
              const oldHeight = draggableDom.offsetHeight;
              draggableDom.style.height = 0 + "px";
              draggableDom.style.top =
                draggableDom.offsetTop + oldHeight + "px";
            }
          }
        }
      };

      const pointerUp = () => {
        targetDom.removeEventListener("pointermove", pointerMove);
        targetDom.removeEventListener("pointerup", pointerUp);

        renderToCanvas(canvasRef2, "imagesItemWorkspace");
        renderToCanvas(virtualCanvasRef, "imagesItemWorkspace");
        onNewRender();
      };

      targetDom.addEventListener("pointermove", pointerMove);
      targetDom.addEventListener("pointerup", pointerUp);
    };

    const movePointerDown = (event: PointerEvent) => {
      const target = event.target as HTMLElement | null;

      if (target === null) {
        return;
      }

      const draggableContainerDom = target.closest(
        '[data-draggable="container"]',
      ) as HTMLElement | null;
      const draggableDom = target.closest(
        '[data-draggable="draggable"]',
      ) as HTMLElement | null;

      if (draggableDom === null || draggableContainerDom === null) {
        return;
      }

      const targetDom = draggableDom;
      targetDom.setPointerCapture(event.pointerId);

      const zoom = zoomRef.current;
      const startX = event.clientX / zoom;
      const startY = event.clientY / zoom;
      const startTop = targetDom.offsetTop;
      const startLeft = targetDom.offsetLeft;

      const draggableContainerRect =
        draggableContainerDom.getBoundingClientRect();

      // offsetParent can be not draggable-container
      const parent = targetDom.offsetParent as HTMLElement;
      const parentRect = parent.getBoundingClientRect();

      const shiftParentX = draggableContainerRect.left - parentRect.left;
      const shiftParentY = draggableContainerRect.top - parentRect.top;

      const pointerMove = (event: PointerEvent) => {
        const currentX = event.clientX / zoom;
        const currentY = event.clientY / zoom;
        const deltaX = currentX - startX;
        const deltaY = currentY - startY;

        const deltaLeft = startLeft + deltaX;
        const deltaTop = startTop + deltaY;

        const maxLeft =
          draggableContainerDom.offsetWidth - draggableDom.offsetWidth;
        const maxTop =
          draggableContainerDom.offsetHeight - draggableDom.offsetHeight;

        const newLeft = Math.min(
          Math.max(shiftParentX, deltaLeft),
          maxLeft + shiftParentX,
        );
        const newTop = Math.min(
          Math.max(shiftParentY, deltaTop),
          maxTop + shiftParentY,
        );

        draggableDom.style.left = newLeft + "px";
        draggableDom.style.top = newTop + "px";
      };

      const pointerUp = (_: PointerEvent) => {
        targetDom.removeEventListener("pointermove", pointerMove);
        targetDom.removeEventListener("pointerup", pointerUp);
        handleRenderAndApply();

        renderToCanvas(canvasRef2, "imagesItemWorkspace");
        renderToCanvas(virtualCanvasRef, "imagesItemWorkspace");
        onNewRender();
      };

      targetDom.addEventListener("pointermove", pointerMove);
      targetDom.addEventListener("pointerup", pointerUp);
    };

    // TODO: add via property onClick
    const handleClick = (event: PointerEvent) => {
      const target = event.target as HTMLElement | null;

      if (!target) {
        return;
      }

      const draggableDom = target.closest(
        '[data-draggable="draggable"]',
      ) as HTMLElement | null;
      const buttonDom = target.closest(
        '[data-draggable="button"]',
      ) as HTMLElement | null;

      if (!draggableDom || !buttonDom) {
        return;
      }

      const position = buttonDom.dataset.position ?? "front";
      const toggleElements =
        document.querySelectorAll<HTMLElement>(".zIndexCopy");

      toggleElements.forEach((el) => {
        el.style.zIndex =
          position === "front" ? `${zIndexFront}` : `${zIndexBack}`;
      });
    };

    canvasDom.addEventListener("pointerdown", resizePointerDown);
    canvasDom.addEventListener("pointerdown", movePointerDown);
    canvasDom.addEventListener("wheel", handleWheel, { passive: false });
    canvasDom.addEventListener("mousedown", handleClick);

    return () => {
      canvasDom.removeEventListener("pointerdown", resizePointerDown);
      canvasDom.removeEventListener("pointerdown", movePointerDown);
      canvasDom.removeEventListener("wheel", handleWheel);
      canvasDom.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleZoomIn = () => setZoom(changeZoom(zoom + 0.05));
  const handleZoomOut = () => setZoom(changeZoom(zoom - 0.05));

  const handleChangePositions = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    onMouseUpDraggableItems(event, index);
    handleRenderAndApply();
  };

  const activeImageSrc = !userGuide
    ? addMimeType(activeImage?.image)
    : activeImage?.image;
  const generatedImageSrc = !userGuide
    ? addMimeType(generatedImageBase64)
    : generatedImageBase64;

  // canva
  const canvasRef2 = useRef<HTMLCanvasElement>(null);
  const virtualCanvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    renderToCanvas(canvasRef2, "imagesItemWorkspace");
    renderToCanvas(virtualCanvasRef, "imagesItemWorkspace");
  }, [activeImage, draggableEl]);

  const handleNewImage = () => {
    setApplyImg(false);
    setSelectedPrompt("");
    onNewRender();
  };

  const handleRenderAndApply = () => {
    renderToCanvas(canvasRef2, "imagesItemWorkspace").then(() => {
      renderToCanvas(virtualCanvasRef, "imagesItemWorkspace").then(() => {
        onApplyImg(FormatCanvasResult(virtualCanvasRef));
      });
    });
  };

  useEffect(() => {
    handleRenderAndApply();
  }, [activeImage, draggableEl]);

  return (
    <div ref={canvasRef} className={styles.canvas} data-draggable="container">
      <Zoom zoom={zoom} onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
      <div
        className={styles.canvasInner}
        style={{
          transform: `scale3D(${zoom}, ${zoom}, 1)`,
          transition: "transform 0.1s",
        }}
      >
        <div className={styles.images}>
          <div
            className={classNames(
              styles.imagesItem,
              styles.imagesItemWorkspace,
            )}
          >
            <div className={styles.imagesLabel}>
              Workspace · drop your images & assets here
            </div>
            {userGuide && (currStep === 2 || currStep === 7) ? (
              <UserGuidePopover
                placement="right"
                step={currStep === 2 ? 0 : 2}
                text={1}
                nextStep={currStep === 2 ? 3 : 8}
                setCurrUserStepGuide={setCurrUserStepGuide}
                additionalHandlers={
                  currStep !== 7 ? { setActiveNavIndex } : { setUserGuide }
                }
                currUserStepGuide={currStep}
                setUserGuide={setUserGuide}
              >
                <div
                  className={styles.imagesBox}
                  data-draggable="container"
                  id="imagesItemWorkspace"
                >
                  <div
                    className={classNames(
                      styles.draggable,
                      activeImage === null && styles.notHover,
                      activeImage &&
                        activeNavIndex === 0 &&
                        !isLoading &&
                        styles.active,
                    )}
                    style={{
                      pointerEvents:
                        activeNavIndex === 0 && !isLoading ? "auto" : "none",
                    }}
                    data-draggable="draggable"
                    data-type="workspace"
                    data-default-position="center"
                  >
                    {activeImage && (
                      <div
                        data-draggable="image"
                        className={classNames(styles.draggableImage)}
                        style={{
                          backgroundImage: `url("${activeImageSrc}")`,
                        }}
                      ></div>
                    )}
                    <DraggableResize />
                  </div>
                </div>
              </UserGuidePopover>
            ) : (
              <div
                className={styles.imagesBox}
                data-draggable="container"
                id="imagesItemWorkspace"
              >
                <div
                  className={classNames(
                    styles.draggable,
                    activeImage === null && styles.notHover,
                    activeImage &&
                      activeNavIndex === 0 &&
                      !isLoading &&
                      styles.active,
                  )}
                  style={{
                    pointerEvents:
                      activeNavIndex === 0 && !isLoading ? "auto" : "none",
                  }}
                  data-draggable="draggable"
                  data-type="workspace"
                  data-default-position="center"
                >
                  {activeImage && (
                    <div
                      data-draggable="image"
                      className={classNames(styles.draggableImage)}
                      style={{
                        backgroundImage: `url("${activeImageSrc}")`,
                      }}
                    ></div>
                  )}
                  <DraggableResize />
                </div>
              </div>
            )}
            <div className="zIndexCopy">
              <DraggableItems
                userGuide={userGuide}
                items={
                  userGuide && currStep === 7
                    ? [
                        {
                          image: assetStatic,
                          style: {
                            top: "35%",
                            left: "180px",
                            width: "150px",
                            height: "150px",
                          },
                        },
                      ]
                    : draggableEl
                }
                onPointerUp={handleChangePositions}
                activeBtn={activeBtn}
              />
            </div>
          </div>
          {userGuide && currStep === 5 ? (
            <UserGuidePopover
              placement="bottom"
              step={1}
              text={3}
              nextStep={6}
              setCurrUserStepGuide={setCurrUserStepGuide}
              additionalHandlers={{
                setActiveNavIndex,
              }}
              setUserGuide={setUserGuide}
            >
              <div
                className={classNames(
                  styles.imagesItem,
                  styles.imagesItemResult,
                )}
              >
                <div className={styles.imagesLabel}>Result</div>
                <div
                  className={classNames(
                    styles.imagesBox,
                    isLoading && styles.loading,
                  )}
                >
                  {isLoading && <Spin size="large" className={styles.loader} />}
                  <div
                    style={{
                      backgroundImage: `url("${generatedImageSrc}")`,
                      backgroundSize: "100% 100%",
                      position: "absolute",
                      inset: 0,
                      borderRadius: "8px",
                    }}
                  ></div>
                </div>
              </div>
            </UserGuidePopover>
          ) : (
            <div
              className={classNames(styles.imagesItem, styles.imagesItemResult)}
            >
              <div className={styles.imagesLabel}>Result</div>
              <div
                className={classNames(
                  styles.imagesBox,
                  isLoading && styles.loading,
                )}
              >
                {isLoading && <Spin size="large" className={styles.loader} />}
                {generatedImage && (
                  <div
                    style={{
                      backgroundImage: `url("${generatedImageSrc}")`,
                      backgroundSize: "100% 100%",
                      position: "absolute",
                      inset: 0,
                      borderRadius: "8px",
                    }}
                  ></div>
                )}
                <canvas
                  ref={canvasRef2}
                  width={338}
                  height={338}
                  style={{
                    position: "absolute",
                    inset: 0,
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <canvas
          ref={virtualCanvasRef}
          width={1024}
          height={1024}
          style={{ display: "none" }}
        />
        {activeImage && (
          <div className={styles.control}>
            {currStep === 4 ? (
              <button
                className={classNames("button button--m-0", styles.reset)}
                onClick={handleNewImage}
              >
                Reset
              </button>
            ) : (
              <button
                className={classNames("button button--m-0", styles.reset)}
                onClick={handleNewImage}
              >
                Reset
              </button>
            )}
            <button
              className={classNames(
                "button button--m-0",
                styles.generate,
                !selectedPrompt && styles.disabled,
              )}
              onClick={() => onGenerateImage([selectedPrompt || ""])}
              disabled={!selectedPrompt}
            >
              Generate image
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Canvas;

const Zoom = ({
  zoom,
  onZoomIn,
  onZoomOut,
}: {
  zoom: number;
  onZoomIn: () => void;
  onZoomOut: () => void;
}) => {
  return (
    <div className={styles.zoom}>
      <button className={styles.zoomButton} onClick={onZoomOut}>
        <Minus size={12} />
      </button>
      <span>{Math.round(zoom * 100)}%</span>
      <button className={styles.zoomButton} onClick={onZoomIn}>
        <Plus size={12} />
      </button>
    </div>
  );
};

const DraggableItems = ({
  items,
  onPointerUp,
  activeBtn,
  userGuide,
}: {
  items: { image: string; style: CSSProperties }[];
  onPointerUp: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => void;
  activeBtn: HTMLElement;
  userGuide?: boolean;
}) => {
  const handlePointerUp = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    onPointerUp(event, index);
  };

  return (
    <>
      {items.map((el, i) => {
        const isActiveEl = i === items.length - 1 && activeBtn;

        return (
          <div
            key={i}
            className={classNames(
              styles.draggable,
              isActiveEl && styles.active,
            )}
            style={el.style}
            data-draggable="draggable"
            onPointerUp={(event) => handlePointerUp(event, i)}
          >
            <div
              className={classNames(styles.draggableImage, styles.active)}
              data-draggable="image"
              style={{
                backgroundImage: !userGuide
                  ? `url('data:image/png;base64,${el.image}')`
                  : `url("${el.image}")`,
              }}
            ></div>
            <DraggableResize showButton />
          </div>
        );
      })}
    </>
  );
};

const DraggableResize = ({ showButton }: { showButton?: boolean }) => {
  return (
    <div className={classNames(styles.resize)}>
      <div
        className={styles.resizeDot}
        data-draggable="resize"
        data-x="-1"
        data-y="-1"
      ></div>
      <div
        className={styles.resizeDot}
        data-draggable="resize"
        data-x="1"
        data-y="-1"
      ></div>
      <div
        className={styles.resizeDot}
        data-draggable="resize"
        data-x="-1"
        data-y="1"
      ></div>
      <div
        className={styles.resizeDot}
        data-draggable="resize"
        data-x="1"
        data-y="1"
      ></div>
      {showButton && (
        <div className={styles.resizeButtons}>
          <button
            className={styles.resizeButton}
            data-draggable="button"
            data-position="front"
          >
            Move to front
          </button>
          <button
            className={styles.resizeButton}
            data-draggable="button"
            data-position="back"
          >
            Move to back
          </button>
        </div>
      )}
    </div>
  );
};
