import React from "react";
import styles from "./styles.module.scss";
import LoadMoreButton from "@components/LoadMoreButton";
import { Review } from "src/store/insights/types";
import { Spin } from "antd";

interface ReviewProps {
  reviews: Review[];
  loadMoreReviews: () => void;
  visibleReviews: number;
  isLoadingMore: boolean;
}

const Reviews: React.FC<ReviewProps> = ({
  reviews,
  visibleReviews,
  loadMoreReviews,
  isLoadingMore,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.reviewsList}>
        <h2>Reviews</h2>
        {reviews.slice(0, visibleReviews).map((review, index: number) => (
          <div key={index} className={styles.review}>
            <div className={styles.rating}>
              <span>⭐ {review.rating}</span>
              <div>
                <h3>TITLE:</h3>
                <p>{review.title}</p>
              </div>
            </div>
            <div className={styles.details}>
              <p>{review.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.loading}>
        {isLoadingMore ? (
          <Spin />
        ) : (
          visibleReviews < reviews.length + 1 && (
            <LoadMoreButton onClick={loadMoreReviews} />
          )
        )}
      </div>
    </div>
  );
};

export default Reviews;
