import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import Setting from "@assets/icons/setting";
import { Checks } from "@pages/Dashboard/features/SalesPerformance/types";
import { initialChecksBrand } from "@components/SalesPerformance/utils";
import BrandAsinPerformance from "../BrandAsinChart";
import { SettingsModal } from "@components/SalesPerformance/components/SettingsModal";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import classNames from "classnames";

const initialDataPoints = {};

interface PerformanceBlockProps {
  hideBorder?: boolean;
  onlyMarket?: boolean;
  onlyBrand?: boolean;
}

const PerformanceBlock: React.FC<PerformanceBlockProps> = ({
  hideBorder,
  onlyMarket,
  onlyBrand,
}) => {
  const [isOpenMarketModal, setIsOpenMarketModal] = useState(false);
  const [checksMarket, setChecksMarket] = useState<Checks>(initialChecksBrand);
  const [isAdvancedViewMarket, setIsAdvancedViewMarket] = useState(false);
  const [isForecastedMarket, setIsForecastedMarket] = useState(false);
  const [dataPointsMarket, setDataPointsMarket] = useState(initialDataPoints);
  const [dataMarket, setDataMarket] = useState<any[]>([]);

  const [isOpenBrandModal, setIsOpenBrandModal] = useState(false);
  const [checksBrand, setChecksBrand] = useState<Checks>(initialChecksBrand);
  const [isAdvancedViewBrand, setIsAdvancedViewBrand] = useState(false);
  const [isForecastedBrand, setIsForecastedBrand] = useState(false);
  const [dataPointsBrand, setDataPointsBrand] = useState(initialDataPoints);
  const [dataBrand, setDataBrand] = useState<any[]>([]);

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat("en", {
        day: "numeric",
        month: "short",
      }),
    [],
  );
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));

  useEffect(() => {
    const fetchDataMarket = async () => {
      try {
        const fetchedData = await getData(dateRange, checksMarket);
        const transformedData = transformDataToObject(fetchedData);
        setDataMarket(transformedData);
      } catch (error) {
        console.error("Error fetching Market data:", error);
      }
    };

    if (
      dateRange?.startDate &&
      dateRange?.endDate &&
      Object.values(checksMarket).some((value) => value)
    ) {
      fetchDataMarket();
    }
  }, [dateRange, checksMarket, dateFormatter]);

  useEffect(() => {
    const fetchDataBrand = async () => {
      try {
        const fetchedData = await getData(dateRange, checksBrand);
        const transformedData = transformDataToObject(fetchedData);
        setDataBrand(transformedData);
      } catch (error) {
        console.error("Error fetching Brand data:", error);
      }
    };

    if (
      dateRange?.startDate &&
      dateRange?.endDate &&
      Object.values(checksBrand).some((value) => value)
    ) {
      fetchDataBrand();
    }
  }, [dateRange, checksBrand, dateFormatter]);

  return (
    <>
      {!onlyBrand && (
        <div
          className={classNames(
            styles.perfomanceBlock,
            hideBorder ? styles.hideBorder : "",
          )}
        >
          <div className={styles.marketPerformanceWrapper}>
            <BrandAsinPerformance
              title="Market Sales Performance"
              checks={checksMarket}
              dataBrand={dataMarket}
              hideBorder={hideBorder}
            />
            <button
              className={styles.setting__button}
              onClick={() => setIsOpenMarketModal(true)}
            >
              <Setting />
            </button>
          </div>

          <SettingsModal
            open={isOpenMarketModal}
            onClose={() => setIsOpenMarketModal(false)}
            isAdvancedView={isAdvancedViewMarket}
            handleToggleView={setIsAdvancedViewMarket}
            checks={checksMarket}
            setChecks={setChecksMarket}
            isForecasted={isForecastedMarket}
            setIsForecasted={setIsForecastedMarket}
            choosedGroups={"all"}
            isBrand={true}
            dataPoints={dataPointsMarket}
            setDataPoints={setDataPointsMarket}
          />
        </div>
      )}

      {!onlyMarket && (
        <div className={styles.perfomanceBlock}>
          <div className={styles.marketPerformanceWrapper}>
            <BrandAsinPerformance
              title="Brand Sales Performance"
              line={true}
              checksBrand={checksBrand}
              dataBrand={dataBrand}
              hideBorder={hideBorder}
            />
            <button
              className={styles.setting__button}
              onClick={() => setIsOpenBrandModal(true)}
            >
              <Setting />
            </button>
          </div>

          <SettingsModal
            open={isOpenBrandModal}
            onClose={() => setIsOpenBrandModal(false)}
            isAdvancedView={isAdvancedViewBrand}
            handleToggleView={setIsAdvancedViewBrand}
            checks={checksBrand}
            setChecks={setChecksBrand}
            isForecasted={isForecastedBrand}
            setIsForecasted={setIsForecastedBrand}
            choosedGroups={"all"}
            isBrand={true}
            dataPoints={dataPointsBrand}
            setDataPoints={setDataPointsBrand}
          />
        </div>
      )}
    </>
  );
};

export default PerformanceBlock;
