import { Api } from "src/utils/api";
import { INVENTORY_API_BASE_URL } from "./inventory-performance.constant";

export type DatePeriod = "DAY" | "WEEK" | "MONTH";

export interface MetricData {
  name: string;
  units: number;
  revenue: {
    amount: number;
    currency_code: string;
  };
}

export interface DailyMetric {
  metric: MetricData[];
}

export interface InventoryHistoryAPIResponse {
  inventory: InventoryHistoryResponse;
}

export type InventoryHistoryResponse = {
  [date: string]: DailyMetric[];
};

export class InventoryPerformanceApi extends Api {
  constructor() {
    super(INVENTORY_API_BASE_URL);
  }

  public async getInventoryHistory(
    date_start: string,
    date_end: string,
    period?: DatePeriod,
    asin_filter?: string[],
    brand_filter?: string[],
  ): Promise<InventoryHistoryResponse> {
    const params = new URLSearchParams();
    params.append("date_start", date_start);
    params.append("date_end", date_end);
    if (period) params.append("period", period);

    asin_filter?.forEach((asin) => params.append("asin_filter[]", asin));
    brand_filter?.forEach((brand) => params.append("brand_filter[]", brand));

    const res = await this.get<InventoryHistoryAPIResponse>(
      `history?${params.toString()}`,
    );

    return res.inventory;
  }
}
