import { useEffect, useState } from "react";
import { normalizeTitle, Widgets } from "./components/Widgets";
import styles from "./styles.module.scss";
import { CampaignsChart } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart";
import classNames from "classnames";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import {
  ADDITIONAL_METRICS,
  AdsWidget,
  BASE_METRICS,
  formatMetricKey,
  getWidgetData,
  initialChecks,
  WidgetData,
} from "./utils";
import { generateWidgets } from "./utils";
import { CampaignsTable } from "./components/Tables";
import { SliderKey } from "./components/Slider";
import { Spin } from "antd";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { AdSpend } from "./components/AdSpend";
import { WhatIfCharts } from "./components/WhatIfCharts";
import { Checks } from "./components/CampaignChart/utils";
import { useMaintenance } from "src/store/maintenance.state";

export const METRICS_OPTIONS = [
  "TACOS",
  "Organic Rank",
  "Search Term Ad Impression Rank",
  "Time in Budget",
  "TOS Ad Impression Share",
];

interface CampaignsProps {
  canvas?: boolean;
}

export const Campaigns: React.FC<CampaignsProps> = ({ canvas = false }) => {
  const { dateRange, compareType } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    compareType: state.compareType,
  }));
  const { isWhatIfEnabled, compareWith } = useAdsAnaliticsStore((state) => ({
    isWhatIfEnabled: state.isWhatIfEnabled,
    compareWith: state.compareWith,
  }));
  const [data, setData] = useState<WidgetData>();
  const [availableWidgets, setAvailableWidgets] = useState(ADDITIONAL_METRICS);
  const [showedWidgets, setShowedWidgets] = useState<AdsWidget[]>([]);
  const { maintenance } = useMaintenance();

  const [checks, setChecks] = useState<Checks>(() => {
    const checks: Checks = initialChecks;
    [...BASE_METRICS, ...ADDITIONAL_METRICS].forEach((metric) => {
      const key = formatMetricKey(metric);
      checks[key] = BASE_METRICS.includes(metric);
    });
    return checks;
  });

  const [isChecksFixed, setIsChecksFixed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const activeChecks = Object.keys(checks).filter((key) => checks[key]).length;

  useEffect(() => {
    if (data) {
      const allWidgets = generateWidgets(data, compareType);

      if (showedWidgets.length === 0) {
        const initialShowedWidgets = allWidgets.filter(
          (widget) => !availableWidgets.includes(widget.title),
        );
        setShowedWidgets(initialShowedWidgets);
      } else {
        setShowedWidgets((prev) => {
          const updatedWidgetsMap = new Map();
          allWidgets.forEach((widget) => {
            updatedWidgetsMap.set(widget.title, widget);
          });

          return prev.map((widget) => {
            const updatedWidget = updatedWidgetsMap.get(widget.title);
            return updatedWidget || widget;
          });
        });
      }
    }
  }, [data, compareType]);

  const handleReplaceWidget = (newWidget: string, oldWidget: string) => {
    setAvailableWidgets((prev) =>
      [...prev, oldWidget].filter((widget) => widget !== newWidget),
    );

    setChecks((prev) => ({
      ...prev,
      [normalizeTitle(newWidget)]: true,
      [normalizeTitle(oldWidget)]: false,
    }));

    if (data) {
      setShowedWidgets((currentWidgets) => {
        const allWidgets = generateWidgets(data, compareType);
        const newWidgetData = allWidgets.find((w) => w.title === newWidget);

        if (newWidgetData) {
          return currentWidgets.map((widget) =>
            widget.title === oldWidget ? newWidgetData : widget,
          );
        }
        return currentWidgets;
      });
    }
  };

  const handleFixChecks = () => {
    if (isChecksFixed) {
      setIsChecksFixed(false);
      return;
    }

    if (activeChecks > 6) {
      setChecks({
        sales: true,
        spend: true,
        impressions: false,
        ctr: true,
        cvr: true,
        cpc: true,
        acos: false,
        roas: false,
        orders: false,
        unitSolds: true,
        clicks: false,
        tacos: false,
        organicRank: false,
        searchTermAdImpressionRank: false,
        timeInBudget: false,
        tosAdImpressionShare: false,
      });
      setIsChecksFixed(true);
    }
  };

  const handleCheck = (metric: string) => {
    const key = formatMetricKey(metric);

    setChecks((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await getWidgetData(dateRange, compareWith);
      setData(data);
      setIsLoading(false);
    };

    fetchData();
  }, [dateRange, compareWith, compareType]);

  return (
    <div className={styles.container}>
      <div className={classNames({ [styles.hidden]: canvas })}>
        {!isWhatIfEnabled && <SliderKey />}
      </div>
      <div className={styles.metrics}>
        {!isLoading ? (
          <Widgets
            checks={checks}
            setChecks={handleCheck}
            widgetsData={showedWidgets}
            options={availableWidgets}
            onReplaceWidget={handleReplaceWidget}
          />
        ) : (
          <div className={styles.loader}>
            <Spin size="large" />
          </div>
        )}
        <div className={classNames(styles.chart, styles.box)}>
          <CampaignsChart checks={checks} handleFixChecks={handleFixChecks} />
        </div>
      </div>
      <div className={classNames({ [styles.hidden]: canvas }, styles.products)}>
        {isWhatIfEnabled && (
          <>
            <SliderKey />
            <AdSpend />
          </>
        )}
        <CampaignsTable isWhatIf={isWhatIfEnabled} />
        {isWhatIfEnabled && maintenance && <WhatIfCharts />}
      </div>
    </div>
  );
};
