import { useEffect, useRef } from "react";
import { GridStack, GridStackNode } from "gridstack";
import { GridConfig } from "./canva.types";

const DEFAULT_GRID_CONFIG: GridConfig = {
  cellHeight: "10px",
  column: 48,
  draggable: {
    handle: ".drag-handle",
    appendTo: "body",
    scroll: true,
  },
  resizable: {
    handles: "e, se, s, sw, w",
  },
  margin: 0,
  float: true,
  disableOneColumnMode: true,
};

export const useGridStack = (
  onPositionChange: (id: string, x: number, y: number) => void,
  elements: any[],
) => {
  const gridRef = useRef<GridStack>(null);
  const gridContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (gridContainerRef.current) {
      const grid = GridStack.init(
        DEFAULT_GRID_CONFIG,
        gridContainerRef.current,
      );

      grid.on("change", function (_: Event, items: GridStackNode[]) {
        items.forEach((item) => {
          if (item.el) {
            const id = item.el.getAttribute("data-id") || "";
            const x = item.x || 0;
            const y = item.y || 0;
            onPositionChange(id, x, y);
          }
        });
      });

      gridRef.current = grid;

      return () => {
        grid.destroy();
        gridRef.current = null;
      };
    }
  }, []);

  useEffect(() => {
    const grid = gridRef.current;
    if (!grid) return;

    grid.enableResize(true);
    const gridItems = grid.engine.nodes.map((node) =>
      node.el?.getAttribute("data-id"),
    );

    elements.forEach((element) => {
      if (!gridItems.includes(element.id)) {
        const el = document.getElementById(`grid-item-${element.id}`);
        if (el) {
          grid.makeWidget(el);
        }
      }
    });
  }, [elements]);

  return { gridRef, gridContainerRef };
};
