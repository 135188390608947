import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "src/pages/Auth/Auth.module.scss";
import { AuthIntro } from "../AuthIntro";
import Logo from "@assets/img/logo";
import BackAuthIcon from "@assets/icons/backAuthIcon";
import AuthInput from "@components/AuthInput";
import AuthButtonArrow from "@assets/icons/authButtonArrow";
import { ConfirmNewPassword } from "@services/confirmNewPassword";

export const ConfirmPasswordReset = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== reNewPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await ConfirmNewPassword.resetPasswordConfirm(
        uid,
        token,
        newPassword,
        reNewPassword,
      );
      setSuccess(true);
    } catch (err) {
      setError(err.response?.data?.detail || "Something went wrong");
    }
  };

  if (success) {
    navigate("/password-confirmed");
  }

  return (
    <div className={styles.authPage}>
      <AuthIntro />
      <div className={styles.authPageMain}>
        <div>
          <Logo width="67" height="30" />
        </div>
        <div className={styles.navBar}>
          <Link to="/login" className={styles.backButton}>
            <BackAuthIcon /> back to log in
          </Link>
        </div>
        <div className={styles.passwordContainer}>
          <div className={styles.content}>
            <h1 className={styles.contentHeader}>Password reset</h1>
            <div className={styles.fullWidthInput}>
              <AuthInput
                title="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                password={true}
                error={error ? "error" : ""}
              />
            </div>
            <div className={styles.fullWidthInput}>
              <AuthInput
                title="Confirm New Password"
                type="password"
                value={reNewPassword}
                onChange={(e) => setReNewPassword(e.target.value)}
                password={true}
                error={error ? "error" : ""}
              />
            </div>
            {error && <p className={styles.errorMessage}>{error}</p>}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <button
                style={{ width: "100%", textAlign: "center" }}
                className={styles.button}
                onClick={handlePasswordReset}
              >
                send me a new password <AuthButtonArrow />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
