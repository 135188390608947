export const dataSales = [
  { name: "Amazon", value: 310000 },
  { name: "Organic Social", value: 690000 },
];

export const dataContribution = [
  { name: "Amazon", value: 150300 },
  { name: "Organic Social", value: 475600 },
];

export type ActiveView = "channel" | "asin";

export const dataByASINsales = [
  { asin: "B0B9HSSQ", amazon: 350000, organic: 202000 },
  { asin: "B0BPS6L2G", amazon: 100000, organic: 155000 },
  { asin: "B0D3HAVRZ", amazon: 31000, organic: 0 },
  { asin: "B0CKSY2CH", amazon: 10000, organic: 0 },
];

export const dataByASINcontribution = [
  { asin: "B0B9HSSQ", amazon: 35000, organic: 128000 },
  { asin: "B0BPS6L2G", amazon: 80000, organic: 155000 },
  { asin: "B0D3HAVRZ", amazon: 42000, organic: 0 },
  { asin: "B0CKSY2CH", amazon: 1000, organic: 0 },
];
