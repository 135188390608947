export const steps = [
  {
    title: "Step 1: Image Selection",
    subparagraphs: [
      "Select image or upload new to extract mask for editing.",
      "Drag, resize, and rotate your mask in the workspace",
    ],
  },
  {
    title: "Step 2: Image Generation",
    subparagraphs: [
      "Select Nyle AI template to generate image or use Prompt Builder",
      "You can upload reference image to generate prompt or write prompt manually",
      "Click “Generate Image”",
      "Terrific! Your image generated successfully",
    ],
  },
  {
    title: "Step 3: Adding Assets",
    subparagraphs: [
      "Drag asset to the workspace to add it to your image",
      "Drag, resize, and rotate your assets in the workspace",
    ],
  },
];
