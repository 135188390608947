import { create } from "zustand";
import { ELEMENT_ID } from "./canva/element-id";
import { defaultElements } from "./canva/canva.const";

export interface ICanvaState {
  isWhatIf: boolean;
}

export type CanvaElement = {
  id: string;
  elementId: ELEMENT_ID;
  x: number;
  y: number;
  size?: {
    width: number;
    height: number;
  };
  active: boolean;
};

interface CanvaStore extends ICanvaState {
  setIsWhatIf: (isWhatIf: boolean) => void;
}

export const useMarketStore = create<CanvaStore>((set) => ({
  isWhatIf: false,
  setIsWhatIf: (isWhatIf) => set({ isWhatIf }),
}));
