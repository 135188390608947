import { Line, Area, Bar, Cell } from "recharts";
import { areaStyle, lineStyle } from "./utils";
import React from "react";

const renderCells = (
  data,
  fill,
  stroke,
  opacity = 1,
  strokeWidth = null,
  strokeDasharray = null,
) =>
  data.map((entry: any, index: number) => (
    <Cell
      key={`cell-${index}`}
      fill={fill}
      stroke={stroke}
      opacity={entry.isForecast ? 0.5 : opacity}
      strokeWidth={strokeWidth}
      strokeDasharray={entry.isForecast ? "5 5" : strokeDasharray}
    />
  ));

const getBarSize = (isOnlyBar: boolean) => {
  return isOnlyBar ? 40 : undefined;
};

export const renderBar = (data, checks) => (
  <>
    {checks.orders && (
      <Bar
        yAxisId="left3"
        dataKey="orders"
        barSize={getBarSize(!checks.unitSolds)}
        xAxisId={!checks.unitSolds ? "centerAxis" : undefined}
      >
        {renderCells(data, "#5295E0", "#5295E0", 1, 2, "3 3")}
      </Bar>
    )}

    {checks.unitSolds && (
      <>
        <Bar
          yAxisId="left3"
          dataKey="forecastUnitSolds"
          stackId={"stackForecast"}
          barSize={getBarSize(!checks.orders)}
          xAxisId={!checks.orders ? "centerAxis" : undefined}
        >
          {renderCells(data, "#5295E040", "#5295E0", 0.5, 2, "3 3")}
        </Bar>
        <Bar
          yAxisId="left3"
          dataKey="unitSolds"
          stackId="stack"
          barSize={getBarSize(!checks.orders)}
          xAxisId={!checks.orders ? "centerAxis" : undefined}
        >
          {renderCells(data, "#255FA0", "#255FA0")}
        </Bar>
        <Bar
          yAxisId="left3"
          dataKey="pastUnitSolds"
          stackId="stack"
          barSize={getBarSize(!checks.orders)}
          xAxisId={!checks.orders ? "centerAxis" : undefined}
        >
          {renderCells(data, "#5295E040", "#5295E0", 0.5, 2, "3 3")}
        </Bar>
      </>
    )}
  </>
);

const renderGradient = (id, color) => (
  <linearGradient id={id} x1="0" y1="0" x2="0" y2="0.4">
    <stop offset="0%" stopColor={color} stopOpacity={1} />
    <stop offset="100%" stopColor={color} stopOpacity={0} />
  </linearGradient>
);

const gradients = [
  { key: "sales", id: "salesGradient", color: "#80C67A" },
  { key: "spend", id: "spendGradient", color: "#FE5858" },
  { key: "impressions", id: "impressionsGradient", color: "#6B4DBA" },
  { key: "ctr", id: "ctrGradient", color: "#1BB08D" },
  { key: "cvr", id: "cvrGradient", color: "#C8A857" },
  { key: "cpc", id: "cpcGradient", color: "#E6FF4B" },
  { key: "acos", id: "acosGradient", color: "#E667F1" },
  { key: "roas", id: "roasGradient", color: "#F19867" },
  { key: "organicctr", id: "organicCtrGradient", color: "#3F33EC" },
  { key: "organiccvr", id: "organicCvrGradient", color: "#FE5858" },
  { key: "organicsales", id: "organicSalesGradient", color: "#80B67A" },
];

const renderLine = (checks, data, key, yAxisId, name, isDashed = false) => {
  if (!checks[key]) return null;
  const gradient = gradients.find((g) => g.key === key.toLowerCase());
  if (!gradient) return null;

  return (
    <Line
      yAxisId={yAxisId}
      type="monotone"
      dataKey={key}
      stroke={gradient.color}
      {...lineStyle}
      data={data}
      name={name}
      strokeDasharray={isDashed ? "5 5" : undefined}
      connectNulls
    />
  );
};

const renderArea = (data, key, yAxisId, name) => {
  const gradient = gradients.find(
    (g) => g.key === key.replace("past", "").toLowerCase(),
  );
  if (!gradient) return null;
  return (
    <Area
      yAxisId={yAxisId}
      type="monotone"
      dataKey={key}
      fill={`url(#${gradient.id})`}
      stroke={gradient.color}
      {...lineStyle}
      {...areaStyle}
      data={data}
      name={name}
      connectNulls
    />
  );
};
export const renderLines = (linesData, checks, isDashed = false) => (
  <>
    <defs>{gradients.map(({ id, color }) => renderGradient(id, color))}</defs>

    {linesData.map((s) => {
      if (s.data.length === 0) return null;
      const { name, data } = s;

      return (
        <React.Fragment key={name}>
          {renderLine(checks, data, "sales", "left2", name, isDashed)}
          {renderLine(checks, data, "spend", "left2", name, isDashed)}
          {renderLine(checks, data, "impressions", "left1", name, isDashed)}
          {renderLine(checks, data, "ctr", "right1", name, isDashed)}
          {renderLine(checks, data, "cvr", "right1", name, isDashed)}
          {renderLine(checks, data, "cpc", "right2", name, isDashed)}
          {renderLine(checks, data, "acos", "right1", name, isDashed)}
          {renderLine(checks, data, "roas", "right3", name, isDashed)}
          {renderLine(checks, data, "organicCTR", "right1", name, isDashed)}
          {renderLine(checks, data, "organicCVR", "right1", name, isDashed)}
          {renderLine(checks, data, "organicSales", "left2", name, isDashed)}
          {renderArea(data, "pastSales", "left2", name)}
          {renderArea(data, "pastSpend", "left2", name)}
          {renderArea(data, "pastImpressions", "left1", name)}
          {renderArea(data, "pastCtr", "right1", name)}
          {renderArea(data, "pastCvr", "right1", name)}
          {renderArea(data, "pastCpc", "right2", name)}
          {renderArea(data, "pastAcos", "right1", name)}
          {renderArea(data, "pastRoas", "right3", name)}
        </React.Fragment>
      );
    })}
  </>
);
