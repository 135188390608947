import { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { CampaignsChart } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart";
import { Checks } from "@pages/AdsAnalitics/components/Campaigns/components/CampaignChart/utils";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import { brandLegendColorMapping } from "../BrandAsinChart/utils";

export const TotalSales = () => {
  const [isChecksFixed, setIsChecksFixed] = useState(false);
  const [checks, setChecks] = useState<Checks>({
    sales: true,
    spend: true,
    impressions: true,
    ctr: true,
    cvr: true,
    cpc: true,
    acos: true,
    roas: true,
    orders: true,
    unitSolds: true,
    clicks: false,
    tacos: false,
    organicRank: false,
    searchTermAdImpressionRank: false,
    timeInBudget: false,
    tosAdImpressionShare: false,
  });
  const activeChecks = Object.keys(checks).filter((key) => checks[key]).length;

  const handleFixChecks = () => {
    if (isChecksFixed) {
      setIsChecksFixed(false);
      return;
    }
    if (activeChecks > 6) {
      setChecks({
        sales: true,
        spend: true,
        impressions: false,
        ctr: true,
        cvr: true,
        cpc: true,
        acos: false,
        roas: false,
        orders: false,
        unitSolds: true,
        clicks: false,
        tacos: false,
        organicRank: false,
        searchTermAdImpressionRank: false,
        timeInBudget: false,
        tosAdImpressionShare: false,
      });
      setIsChecksFixed(true);
    }
  };

  const usedKeys = Object.keys(checks).filter((key) => checks[key]);

  return (
    <div className={classNames("chart", "box", styles.container)}>
      <span className={styles.title}>Total Sales</span>
      <div className={styles.chartContainer}>
        <div className={styles.legendContainer}>
          {usedKeys.map((key) => (
            <div className={styles.legendItem} key={key}>
              <span
                className={styles.colorDot}
                style={{
                  backgroundColor: brandLegendColorMapping[key] || "orange",
                }}
              />
              <span className={styles.legendText}>
                {WIDGET_METRICS_CONFIG[key]?.label || key}
              </span>
            </div>
          ))}
        </div>
        <CampaignsChart
          checks={checks}
          handleFixChecks={handleFixChecks}
          useDashed={true}
        />
      </div>
    </div>
  );
};
