import step1Video from "@assets/img/photoOptimisation/guide/Canvas-Step1.mp4";
import step2Video from "@assets/img/photoOptimisation/guide/Canvas-Step2.mp4";
import step3Video from "@assets/img/photoOptimisation/guide/Canvas-Step3.mp4";
import step4Video from "@assets/img/photoOptimisation/guide/Canvas-Step4.mp4";

export const steps = [
  {
    step: "Image selection",
    description: [
      "Select an image from your listing or upload a new one to extract the mask for editing.",
      "Choose the accurate mask from 3 options.",
      "Drag or resize your mask in the workspace.",
    ],
    video: step1Video,
  },
  {
    step: "Templates",
    description: ["Choose one of the pre-tested prompt templates."],
    video: step2Video,
  },
  {
    step: "Prompt Builder",
    description: [
      "Or use a prompt builder to create a unique prompt using a reference image and other settings.",
    ],
    video: step3Video,
  },
  {
    step: "Adding assets",
    description: [
      "Add assets from our library.",
      "Mix and match them with prompts to create stunning product images with desired elements.",
    ],
    video: step4Video,
  },
];
