import React, { useState } from "react";
import styles from "./styles.module.scss";
import "./modal.scss";
import { Modal } from "antd";
import { SelectCampaign } from "./SelectCampaign";
import { TriangleAlert } from "lucide-react";

interface RecomendationModalProps {
  visible: boolean;
  onCancel: () => void;
}

export const RecomendationModal: React.FC<RecomendationModalProps> = ({
  visible,
  onCancel,
}) => {
  const [step, setStep] = useState(1);
  const [campaigns, setCampaigns] = useState<string[]>();

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={`${step === 2 ? "440px" : "calc(100vw - 100px)"} `}
      className={`recomendation-modal ${step === 2 && "small"}`}
    >
      {step === 1 && (
        <SelectCampaign
          close={onCancel}
          apply={(campaigns: string[]) => {
            setCampaigns(campaigns);
            setStep(2);
          }}
        />
      )}
      {step === 2 && (
        <div className={styles.modalContent}>
          <div className={styles.modalContentTitle}>
            <h2>Confirm Optimization</h2>
          </div>
          <p>
            Please confirm optimization to{" "}
            <span>
              {campaigns.length > 1 ? campaigns.length : `"${campaigns[0]}"`}
            </span>{" "}
            campaign.
          </p>
          <div className={styles.warn}>
            <TriangleAlert size={34} />
            <span>
              This action applying straigh to amazon and can’t be undone.
            </span>
          </div>
          <div className={styles.buttons}>
            <button className={styles.cancel} onClick={() => setStep(1)}>
              Back
            </button>
            <button className={styles.select} onClick={onCancel}>
              Confirm
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};
