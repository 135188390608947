import { typeTooltipFormatter } from "@pages/AdsAnalitics/components/HoverAsin";
import {
  crestData,
  lumineuxData,
  inoProData,
  guruNandaData,
  keidocData,
  crestTypeDataOriginal,
  inoProTypeData,
  oldMin,
  oldMax,
  newMin,
  newMax,
} from "../DotChart/data";

export const brandNamesType = ["Crest", "InoPro"];

export function scaleX(
  x: number,
  oldMin: number,
  oldMax: number,
  newMin: number,
  newMax: number,
) {
  return newMin + ((x - oldMin) * (newMax - newMin)) / (oldMax - oldMin);
}

export const crestTypeDataScaled = crestTypeDataOriginal.map((item) => {
  const [x, y, r] = item.value;
  const scaledX = scaleX(x, oldMin, oldMax, newMin, newMax);
  return { ...item, value: [scaledX, y, r] };
});

export const optionAsin = {
  tooltip: {
    show: true,
    trigger: "item",
    formatter: typeTooltipFormatter,
    backgroundColor: "#222",
    borderWidth: 0,
  },

  legend: {
    top: 0,
    left: 10,
    textStyle: { color: "#fff" },
    data: ["Crest", "Lumineux", "InoPro", "GuruNanda", "Keidoc"],
    selectedMode: "multiple",
  },
  grid: { left: 80, right: 80, top: 40, bottom: 120 },
  dataZoom: [
    {
      type: "slider",
      xAxisIndex: 0,
      bottom: 60,
      left: 80,
      right: 80,
      height: 12,
    },
    { type: "slider", yAxisIndex: 0, left: 20, top: 40, width: 12 },
  ],
  xAxis: {
    name: "Price",
    nameLocation: "middle",
    nameGap: 80,
    min: 0,
    max: 80,
    axisLine: { lineStyle: { color: "#ccc" } },
    axisLabel: { color: "#ccc", formatter: (val: number) => `$${val}` },
    splitLine: { show: false },
  },
  yAxis: {
    name: "Sales",
    nameLocation: "middle",
    nameGap: 65,
    min: 0,
    max: 6,
    axisLine: { lineStyle: { color: "#ccc" } },
    axisLabel: { color: "#ccc", formatter: (val: number) => `$${val}M` },
    splitLine: { show: false },
  },
  series: [
    {
      name: "Segments",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderColor: "#555",
          borderWidth: 1,
          borderType: "dashed",
        },
        data: [
          [
            { xAxis: 0 },
            {
              xAxis: 20,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Fighting",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
          [
            { xAxis: 20 },
            {
              xAxis: 40,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Good",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
          [
            { xAxis: 40 },
            {
              xAxis: 60,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Better",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
          [
            { xAxis: 60 },
            {
              xAxis: 80,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Best",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
        ],
      },
    },
    {
      name: "Crest",
      data: crestData,
      type: "scatter",
      symbolSize: (val: number[]) => Math.sqrt(val[2]) * 2,
      label: { show: false },
      itemStyle: { color: "#5295E0" },
      emphasis: { focus: "series" },
      blur: { itemStyle: { opacity: 0.2 } },
    },
    {
      name: "Lumineux",
      data: lumineuxData,
      type: "scatter",
      symbolSize: (val: number[]) => Math.sqrt(val[2]) * 2,
      label: { show: false },
      itemStyle: { color: "#6B4DBA" },
      emphasis: { focus: "series" },
      blur: { itemStyle: { opacity: 0.2 } },
    },
    {
      name: "InoPro",
      data: inoProData,
      type: "scatter",
      symbolSize: (val: number[]) => Math.sqrt(val[2]) * 2,
      label: { show: false },
      itemStyle: { color: "#80C67A" },
      emphasis: { focus: "series" },
      blur: { itemStyle: { opacity: 0.2 } },
    },
    {
      name: "GuruNanda",
      data: guruNandaData,
      type: "scatter",
      symbolSize: (val: number[]) => Math.sqrt(val[2]) * 2,
      label: { show: false },
      itemStyle: { color: "#00F9F4" },
      emphasis: { focus: "series" },
      blur: { itemStyle: { opacity: 0.2 } },
    },
    {
      name: "Keidoc",
      data: keidocData,
      type: "scatter",
      symbolSize: (val: number[]) => Math.sqrt(val[2]) * 2,
      label: { show: false },
      itemStyle: { color: "#FE5858" },
      emphasis: { focus: "series" },
      blur: { itemStyle: { opacity: 0.2 } },
    },
  ],
};

export const optionType = {
  tooltip: {
    show: false,
  },
  legend: {
    top: 0,
    left: 10,
    textStyle: { color: "#fff" },
    data: brandNamesType,
    selectedMode: "multiple",
  },
  grid: { left: 80, right: 80, top: 40, bottom: 120 },
  dataZoom: [
    {
      type: "slider",
      xAxisIndex: 0,
      bottom: 60,
      left: 80,
      right: 80,
      height: 12,
    },
    { type: "slider", yAxisIndex: 0, left: 20, top: 40, width: 12 },
  ],
  xAxis: {
    name: "Price",
    nameLocation: "middle",
    nameGap: 80,
    min: 0,
    max: 80,
    axisLine: { lineStyle: { color: "#ccc" } },
    axisLabel: { color: "#ccc", formatter: (val: number) => `$${val}` },
    splitLine: { show: false },
  },
  yAxis: {
    name: "Sales",
    nameLocation: "middle",
    nameGap: 65,
    min: 0,
    max: 6,
    axisLine: { lineStyle: { color: "#ccc" } },
    axisLabel: { color: "#ccc", formatter: (val: number) => `$${val}M` },
    splitLine: { show: false },
  },
  series: [
    {
      name: "Segments",
      type: "scatter",
      data: [],
      markArea: {
        silent: true,
        itemStyle: {
          color: "transparent",
          borderColor: "#555",
          borderWidth: 1,
          borderType: "dashed",
        },
        data: [
          [
            { xAxis: 0 },
            {
              xAxis: 20,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Fighting",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
          [
            { xAxis: 20 },
            {
              xAxis: 40,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Good",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
          [
            { xAxis: 40 },
            {
              xAxis: 60,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Better",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
          [
            { xAxis: 60 },
            {
              xAxis: 80,
              label: {
                show: true,
                position: "insideTop",
                formatter: "Best",
                color: "#aaa",
                fontSize: 12,
              },
            },
          ],
        ],
      },
    },
    {
      name: "Crest",
      type: "scatter",
      data: crestTypeDataScaled,
      symbolSize: (val: number[]) => Math.sqrt(val[2]) * 2,
      label: {
        show: true,
        position: "top",
        formatter: (param: any) => param.data.name,
        color: "#fff",
        fontSize: 10,
      },
      itemStyle: { color: "#5295E0" },
      emphasis: { focus: "series" },
      blur: { itemStyle: { opacity: 0.2 } },
    },
    {
      name: "InoPro",
      type: "scatter",
      data: inoProTypeData,
      symbolSize: (val: number[]) => Math.sqrt(val[2]) * 2,
      label: {
        show: true,
        position: "top",
        formatter: (param: any) => param.data.name,
        color: "#fff",
        fontSize: 10,
      },
      itemStyle: { color: "#80C67A" },
      emphasis: { focus: "series" },
      blur: { itemStyle: { opacity: 0.2 } },
    },
  ],
};

export function handleLegendSelectChanged(
  params: any,
  chart: any,
  brands: string[],
) {
  brands.forEach((brand) => {
    chart.dispatchAction({ type: "legendSelect", name: brand });
  });
  const selectedBrands = Object.entries(params.selected)
    .filter(([_, selected]) => selected === true)
    .map(([brand]) => brand);
  if (selectedBrands.length === 0) {
    brands.forEach((brand) => {
      chart.dispatchAction({ type: "highlight", seriesName: brand });
    });
    return;
  }
  selectedBrands.forEach((brand) => {
    chart.dispatchAction({ type: "highlight", seriesName: brand });
  });
  brands
    .filter((brand) => !selectedBrands.includes(brand))
    .forEach((brand) => {
      chart.dispatchAction({ type: "downplay", seriesName: brand });
    });
}
