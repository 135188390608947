import classNames from "classnames";
import styles from "./styles.module.scss";

interface TabsProps<T extends string> {
  activeTab: T;
  setActiveTab: (tab: T) => void;
  tabs: readonly T[];
}

export const Tabs = <T extends string>({
  activeTab,
  setActiveTab,
  tabs,
}: TabsProps<T>) => {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <button
          key={tab}
          className={classNames(activeTab === tab && styles.active, styles.tab)}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};
