import styles from "./styles.module.scss";
import Flag from "@assets/img/flag.svg";
import CopyIcon from "@assets/icons/CopyIcon";

export const AsinBtn = ({ asin }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(asin);
  };

  return (
    <div className={styles.productCode} onClick={copyToClipboard}>
      <img
        src={Flag}
        alt="USA Flag"
        className={styles.flag}
        width={13}
        height={11}
      />
      <span className={styles.code}>{asin}</span>
      <CopyIcon fill={"#7C7C78"} />
    </div>
  );
};
