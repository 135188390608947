import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Product } from "../Product";
import SliderBtn from "@assets/icons/sliderBtn";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import { SpiderService } from "@services/spider/spider.services";
import { formattedKeys } from "src/utils/convertUtil";
import { Spin } from "antd";
import classNames from "classnames";

const Slider: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [current, setCurrent] = useState(0);
  const [products, setProducts] = useState<ProductWithImageLink[]>([]);
  const productsService = new ProductsService();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { selectedProduct, setSelectedProduct } = useProductStore();
  const { setProductOneSpiderTitles, setProductOneSpiderValues } =
    useInsightsStore();

  useEffect(() => {
    const fetchSpider = async () => {
      if (!selectedProduct) {
        setProductOneSpiderTitles([]);
        setProductOneSpiderValues([]);
        return;
      }

      try {
        const spider = new SpiderService();
        const spiderDots = await spider.getSpiderInfo(selectedProduct);

        if (spiderDots && Object.keys(spiderDots).length > 0) {
          setProductOneSpiderTitles(formattedKeys(spiderDots));
          setProductOneSpiderValues(
            Object.values(spiderDots).map((value) =>
              parseFloat(value.toFixed(1)),
            ),
          );
        } else {
          setProductOneSpiderTitles([]);
          setProductOneSpiderValues([]);
        }
      } catch (err) {
        console.error("Error fetching product:", err);
        setProductOneSpiderTitles([]);
        setProductOneSpiderValues([]);
      }
    };

    fetchSpider();
  }, [selectedProduct, current]);

  const fetchProducts = async (page: number, limit: number = 8) => {
    setIsLoading(true);
    try {
      const params = { limit, page };
      const response = await productsService.getOwnProducts(params);

      if (response.length > 0) {
        setProducts((prevProducts) => [...prevProducts, ...response]);

        if (response.length === limit && limit < 32) {
          fetchProducts(page, limit === 8 ? 16 : 32);
        } else {
          if (!selectedProduct && response.length > 0) {
            setSelectedProduct(response[0].asin);
          }
        }
      }
    } catch (err) {
      console.error("Error fetching products:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const nextSlide = () => {
    if (current < products.length - 1) {
      const newIndex = current + 1;
      setCurrent(newIndex);
      setSelectedProduct(products[newIndex].asin);
    } else {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevSlide = () => {
    if (current > 0) {
      const newIndex = current - 1;
      setCurrent(newIndex);
      setSelectedProduct(products[newIndex].asin);
    } else if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className={styles.slider}>
      {isLoading ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <button className={styles.leftArrow} onClick={prevSlide}>
            <div className={styles.iconWrapper}>
              <SliderBtn />
            </div>
          </button>
          <button className={styles.rightArrow} onClick={nextSlide}>
            <div className={styles.iconWrapper}>
              <SliderBtn />
            </div>
          </button>
          <div>
            {products.map((product, index) => (
              <div
                key={`${product.asin}-${index}`}
                className={classNames(styles.slide, {
                  [styles.slideActive]: index === current,
                })}
              >
                {index === current && <Product prod={product} />}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Slider;
