import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import styles from "./styles.module.scss";
import { optionAsin, optionType, handleLegendSelectChanged } from "./utils";
import { brandNamesAsin } from "./data";
import { brandNamesType } from "./utils";
import { AmazonService } from "@services/amazon/amazon.service";
import { useAsinStore } from "src/store/asin-hover";
import classNames from "classnames";

interface CacheProduct {
  price?: number | null;
  picture_url?: string | null;
}

export const productCache: Record<string, CacheProduct> = {};
let lastHoverKey: string | null = null;

interface MarketOverviewProps {
  fullSize?: boolean;
}

const MarketOverview: React.FC<MarketOverviewProps> = ({
  fullSize = false,
}) => {
  const [active, setActive] = useState<"ASIN" | "Type">("ASIN");
  const currentOption = active === "ASIN" ? optionAsin : optionType;
  const currentBrands = active === "ASIN" ? brandNamesAsin : brandNamesType;

  const { setAsins } = useAsinStore();

  const fetchProductOnce = async (asin: string) => {
    if (productCache[asin]) {
      return;
    }
    try {
      const prod = await AmazonService.products.getPublicProduct([asin]);
      productCache[asin] = {
        price: prod.price,
        picture_url: prod.picture_url,
      };
      setAsins(asin);
    } catch (error) {
      console.error("Error fetching public product:", error);
    }
  };

  const onMouseOver = (params: any) => {
    if (params.data?.asin) {
      const asin = params.data.asin;
      const key = `${asin}-${params.seriesIndex}-${params.dataIndex}`;
      if (key !== lastHoverKey) {
        lastHoverKey = key;
        fetchProductOnce(asin);
      }
    }
  };

  const onMouseOut = () => {
    lastHoverKey = null;
  };

  const onEvents = {
    legendselectchanged: (params: any, chart: any) => {
      handleLegendSelectChanged(params, chart, currentBrands);
    },
    mouseover: onMouseOver,
    mouseout: onMouseOut,
  };

  return (
    <div className={classNames(styles.container, fullSize && styles.fullSize)}>
      <div className={styles.headerBetween}>
        <h1 className={styles.marketShareTitle}>Market Overview</h1>
        <div className={styles.toggleContainer}>
          <button
            className={active === "ASIN" ? styles.active : ""}
            onClick={() => setActive("ASIN")}
          >
            ASIN
          </button>
          <button
            className={active === "Type" ? styles.active : ""}
            onClick={() => setActive("Type")}
          >
            Type
          </button>
        </div>
      </div>
      <ReactECharts
        option={currentOption}
        onEvents={onEvents}
        notMerge={true}
        style={{ width: "100%", height: "600px" }}
      />
    </div>
  );
};

export default MarketOverview;
