import React, { useMemo, useState } from "react";
import { Tooltip } from "antd";
import LineChart from "@components/SalesPerformance/components/LineChart";
import Setting from "@assets/icons/setting";
import { WidgetClass } from "@services/widget.service";
import { WIDGET_METRICS_CONFIG } from "@services/math/math.const";
import styles from "./styles.module.scss";
import { SettingsModal } from "@components/SalesPerformance/components/SettingsModal";
import { Checks } from "@pages/Dashboard/features/SalesPerformance/types";
import { useSalesPerformanceChart } from "@services/sales-performance/useSalesPerformance";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";

export const COLORSCHART = ["#fc6762", "#6B4DBA", "#5295E0"];

interface MarketSizeChartProps {
  data?: any;
  modalChecks: Checks;
  setModalChecks: (checks: Checks) => void;
}

const MarketSizeChart: React.FC<MarketSizeChartProps> = ({
  data,
  modalChecks,
  setModalChecks,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAdvancedView, setIsAdvancedView] = useState(false);
  const [isForecasted, setIsForecasted] = useState(false);
  const { dateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
  }));
  const { metricsColors } = useSalesPerformanceChart(dateRange, modalChecks);

  const selectedModalMetrics = useMemo(
    () => Object.keys(modalChecks).filter((key) => modalChecks[key]),
    [modalChecks],
  );

  const lineChartColors = useMemo(() => {
    const mapping: { [key: string]: string } = {};
    Object.keys(modalChecks).forEach((key, index) => {
      mapping[key] = COLORSCHART[index % COLORSCHART.length];
    });
    return mapping;
  }, [modalChecks]);

  return (
    <div className={styles.marketSizeBlock}>
      <div className={styles.settingsContainer}>
        <button
          className={styles.setting__button}
          onClick={() => setIsOpenModal(true)}
        >
          <Setting />
        </button>
        <SettingsModal
          open={isOpenModal}
          onClose={() => setIsOpenModal(false)}
          isAdvancedView={isAdvancedView}
          handleToggleView={setIsAdvancedView}
          checks={modalChecks}
          setChecks={setModalChecks}
          isForecasted={isForecasted}
          setIsForecasted={setIsForecasted}
          choosedGroups={"all"}
          isMarket={true}
        />
      </div>
      <h1 className={styles.marketTitle}>Market Size</h1>
      <div className={styles.legendContainer}>
        {Object.entries(modalChecks).map(([key, isChecked]) =>
          isChecked ? (
            <div key={key} className={styles.legendItem}>
              <span
                className={styles.colorDot}
                style={{ backgroundColor: metricsColors[key] || "#5295E0" }}
              />
              <span className={styles.legendText}>
                {WidgetClass.convertKeyToName(key)}
              </span>
              <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info}>
                <button>i</button>
              </Tooltip>
            </div>
          ) : null,
        )}
      </div>
      <LineChart
        data={data}
        metrics={selectedModalMetrics}
        metricsColors={lineChartColors}
        isForecasted={isForecasted}
        isMarket={selectedModalMetrics.length === 0}
        isMarketHeight={true}
      />
    </div>
  );
};

export default MarketSizeChart;
