import { AdsSalesApi } from "@services/ads-sales/ads-sales.api";
import { Checks } from "./components/CampaignChart/utils";
import { WidgetClass } from "@services/widget.service";

export type WidgetData = Record<string, number>;

export interface AdsWidget {
  title: string;
  value: string;
  increase: string;
  increaseDirection: "up" | "down";
  color: string;
}

export const initialChecks: Checks = {
  sales: false,
  spend: false,
  impressions: false,
  ctr: false,
  cvr: false,
  cpc: false,
  acos: false,
  roas: false,
  orders: false,
  unitSolds: false,
  clicks: false,
  tacos: false,
  organicRank: false,
  searchTermAdImpressionRank: false,
  timeInBudget: false,
  tosAdImpressionShare: false,
};

export const BASE_METRICS = [
  "Sales",
  "Spend",
  "Impressions",
  "CTR",
  "CVR",
  "CPC",
  "ACOS",
  "ROAS",
  "Orders",
  "Units Sold",
];

export const ADDITIONAL_METRICS = [
  "TACOS",
  "Organic Rank",
  "Search Term Ad Impression Rank",
  "Time in Budget",
  "TOS Ad Impression Share",
];

export const formatMetricKey = (metric: string): string => {
  switch (metric) {
    case "Units Sold":
      return "unitSolds";
    case "Search Term Ad Impression Rank":
      return "searchTermAdImpressionRank";
    case "Time in Budget":
      return "timeInBudget";
    case "TOS Ad Impression Share":
      return "tosAdImpressionShare";
    default:
      return metric.toLowerCase().replace(" ", "");
  }
};

export const calculateIncrease = (
  current: number,
  difference: number,
): { increase: string; increaseDirection: "up" | "down" } => {
  const previous = current - difference;
  const percentage = previous !== 0 ? (difference / previous) * 100 : 0;
  const isIncrease = percentage >= 0;
  return {
    increase: `${isIncrease ? "+" : ""}${percentage.toFixed(2)}%`,
    increaseDirection: isIncrease ? "up" : "down",
  };
};

export const formatNumber = (num: number): string =>
  num.toLocaleString("en-US");
export const formatCurrency = (num: number): string =>
  `$${Math.floor(num).toLocaleString("en-US")}`;
export const formatCurrencyDiff = (num: number): string =>
  `$${Math.floor(num).toLocaleString("en-US")}`;
export const formatPercentage = (num: number): string =>
  `${(num * 100).toFixed(2)}%`;

export const generateWidgets = (
  data: WidgetData,
  compareType: "raw" | "percent",
): AdsWidget[] => {
  const widgetClass = new WidgetClass();

  const widgets: AdsWidget[] = [
    {
      title: "Sales",
      value: formatCurrency(data.sales),
      ...(compareType === "raw"
        ? {
            increase: formatCurrencyDiff(data.salesCompare),
            increaseDirection: data.salesCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.sales, data.salesCompare),
          }),
      color: "#80C67A",
    },
    {
      title: "Spend",
      value: formatCurrency(data.spend),
      ...(compareType === "raw"
        ? {
            increase: formatCurrencyDiff(data.spendCompare),
            increaseDirection: data.spendCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.spend, data.spendCompare),
          }),
      color: "#BF4C46",
    },
    {
      title: "Impressions",
      value: formatNumber(data.impressions),
      ...(compareType === "raw"
        ? {
            increase: formatNumber(data.impressionsCompare),
            increaseDirection: data.impressionsCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.impressions, data.impressionsCompare),
          }),
      color: "#5C55D5",
    },
    {
      title: "CTR",
      value: formatPercentage(data.ctr),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.ctrCompare),
            increaseDirection: data.ctrCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.ctr, data.ctrCompare),
          }),
      color: "#67AC9B",
    },
    {
      title: "CVR",
      value: formatPercentage(data.cvr),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.cvrCompare),
            increaseDirection: data.cvrCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.cvr, data.cvrCompare),
          }),
      color: "#9C8437",
    },
    {
      title: "CPC",
      value: `$${data.cpc.toFixed(2)}`,
      ...(compareType === "raw"
        ? {
            increase: `$${data.cpcCompare.toFixed(2)}`,
            increaseDirection: data.cpcCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.cpc, data.cpcCompare),
          }),
      color: "#A8A03D",
    },
    {
      title: "ACOS",
      value: formatPercentage(data.acos),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.acosCompare),
            increaseDirection: data.acosCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.acos, data.acosCompare),
          }),
      color: "#9E44CC",
    },
    {
      title: "ROAS",
      value: data.roas.toFixed(2),
      ...(compareType === "raw"
        ? {
            increase: data.roasCompare.toFixed(2),
            increaseDirection: data.roasCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.roas, data.roasCompare),
          }),
      color: "#F19867",
    },
    {
      title: "Orders",
      value: formatNumber(data.orders),
      ...(compareType === "raw"
        ? {
            increase: formatNumber(data.ordersCompare),
            increaseDirection: data.ordersCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.orders, data.ordersCompare),
          }),
      color: "#5295E0",
    },
    {
      title: "Units Sold",
      value: formatNumber(data.unitSolds),
      ...(compareType === "raw"
        ? {
            increase: formatNumber(data.unitSoldsCompare),
            increaseDirection: data.unitSoldsCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.unitSolds, data.unitSoldsCompare),
          }),
      color: "#255FA0",
    },
    {
      title: "TACOS",
      // Format as percentage or however you want to show TACOS
      value: widgetClass.formatValue("TACOS", data.tacos),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.tacosCompare),
            increaseDirection: data.tacosCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.tacos, data.tacosCompare),
          }),
      color: "#DBA617", // choose your color
    },
    {
      title: "Organic Rank",
      // Might just display the numeric rank
      value: formatNumber(data.organicRank),
      ...(compareType === "raw"
        ? {
            increase: formatNumber(data.organicRankCompare),
            increaseDirection: data.organicRankCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.organicRank, data.organicRankCompare),
          }),
      color: "#87972B",
    },
    {
      title: "Search Term Ad Impression Rank",
      value: formatNumber(data.searchTermAdImpressionRank),
      ...(compareType === "raw"
        ? {
            increase: formatNumber(data.searchTermAdImpressionRankCompare),
            increaseDirection:
              data.searchTermAdImpressionRankCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(
              data.searchTermAdImpressionRank,
              data.searchTermAdImpressionRankCompare,
            ),
          }),
      color: "#A06BBF",
    },
    {
      title: "Time in Budget",
      // If it's a percentage of the day/hours, you might do formatPercentage
      value: formatPercentage(data.timeInBudget),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.timeInBudgetCompare),
            increaseDirection: data.timeInBudgetCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.timeInBudget, data.timeInBudgetCompare),
          }),
      color: "#33B3A4",
    },
    {
      title: "TOS Ad Impression Share",
      value: formatPercentage(data.tosAdImpressionShare),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.tosAdImpressionShareCompare),
            increaseDirection:
              data.tosAdImpressionShareCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(
              data.tosAdImpressionShare,
              data.tosAdImpressionShareCompare,
            ),
          }),
      color: "#CE3F3F",
    },
  ];

  return widgets;
};

export const generateSecondaryWidgets = (
  data: WidgetData,
  compareType: "raw" | "percent",
): AdsWidget[] => {
  return [
    {
      title: "Sales",
      value: formatCurrency(data.sales),
      ...(compareType === "raw"
        ? {
            increase: formatCurrencyDiff(data.salesCompare),
            increaseDirection: data.salesCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.sales, data.salesCompare),
          }),
      color: "#80C67A",
    },
    {
      title: "Spend",
      value: formatCurrency(data.spend),
      ...(compareType === "raw"
        ? {
            increase: formatCurrencyDiff(data.spendCompare),
            increaseDirection: data.spendCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.spend, data.spendCompare),
          }),
      color: "#BF4C46",
    },
    {
      title: "CTR",
      value: formatPercentage(data.ctr),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.ctrCompare),
            increaseDirection: data.ctrCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.ctr, data.ctrCompare),
          }),
      color: "#67AC9B",
    },
    {
      title: "CVR",
      value: formatPercentage(data.cvr),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.cvrCompare),
            increaseDirection: data.cvrCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(data.cvr, data.cvrCompare),
          }),
      color: "#9C8437",
    },
    {
      title: "Organic CVR",
      value: formatPercentage(data.organicCVRCompare),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.organicCVRCompare),
            increaseDirection: data.organicCVRCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(
              data.organicCVRCompare,
              data.organicCVRCompare,
            ),
          }),
      color: "#D98C12",
    },
    {
      title: "Organic CTR",
      value: formatPercentage(data.organicCTRCompare),
      ...(compareType === "raw"
        ? {
            increase: formatPercentage(data.organicCTRCompare),
            increaseDirection: data.organicCTRCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(
              data.organicCTRCompare,
              data.organicCTRCompare,
            ),
          }),
      color: "#D98C12",
    },
    {
      title: "Organic Sales",
      value: formatCurrency(data.organicSalesCompare),
      ...(compareType === "raw"
        ? {
            increase: formatCurrencyDiff(data.organicSalesCompare),
            increaseDirection: data.organicSalesCompare >= 0 ? "up" : "down",
          }
        : {
            ...calculateIncrease(
              data.organicSalesCompare,
              data.organicSalesCompare,
            ),
          }),
      color: "#80B67A",
    },
  ];
};

export const getWidgetData = async (
  dateRange: { startDate: Date; endDate: Date },
  compareWith: { startDate: Date; endDate: Date },
): Promise<WidgetData> => {
  const { startDate, endDate } = dateRange;
  const { startDate: compareStartDate, endDate: compareEndDate } = compareWith;
  const adsSalesApi = new AdsSalesApi();

  const startDateString = startDate.toISOString().split("T")[0];
  const endDateString = endDate.toISOString().split("T")[0];
  const compareStartDateString = compareStartDate.toISOString().split("T")[0];
  const compareEndDateString = compareEndDate.toISOString().split("T")[0];

  // Fetch main data
  const {
    sales,
    spend,
    impressions,
    ctr,
    cvr,
    cpc,
    acos,
    roas,
    orders,
    unitSolds,
    clicks,

    // NEW METRICS
    tacos,
    organicRank,
    searchTermAdImpressionRank,
    timeInBudget,
    tosAdImpressionShare,
  } = await adsSalesApi.getWidgetDataPromise(startDateString, endDateString);

  // Fetch compare data
  const {
    sales: salesCompare,
    spend: spendCompare,
    impressions: impressionsCompare,
    ctr: ctrCompare,
    cvr: cvrCompare,
    cpc: cpcCompare,
    acos: acosCompare,
    roas: roasCompare,
    orders: ordersCompare,
    unitSolds: unitSoldsCompare,
    clicks: clicksCompare,

    // NEW METRICS
    tacos: tacosCompare,
    organicRank: organicRankCompare,
    searchTermAdImpressionRank: searchTermAdImpressionRankCompare,
    timeInBudget: timeInBudgetCompare,
    tosAdImpressionShare: tosAdImpressionShareCompare,
  } = await adsSalesApi.getWidgetsComparePromise(
    startDateString,
    endDateString,
    compareStartDateString,
    compareEndDateString,
  );

  return {
    sales,
    spend,
    impressions,
    ctr,
    cvr,
    cpc,
    acos,
    roas,
    orders,
    unitSolds,
    clicks,
    tacos,
    organicRank,
    searchTermAdImpressionRank,
    timeInBudget,
    tosAdImpressionShare,
    salesCompare,
    spendCompare,
    impressionsCompare,
    ctrCompare,
    cvrCompare,
    cpcCompare,
    acosCompare,
    roasCompare,
    ordersCompare,
    unitSoldsCompare,
    clicksCompare,

    // Return the new fields
    tacosCompare,
    organicRankCompare,
    searchTermAdImpressionRankCompare,
    timeInBudgetCompare,
    tosAdImpressionShareCompare,
  };
};
