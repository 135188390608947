import { MathService } from "@services/math/math.service";

export interface FunnelAdsData {
  "Amazon Ads": number[];
  "Amazon Organic": number[];
  "Organic Social": number[];
  DSP: number[];
}

export class FunnelService extends MathService {
  static getAdsData = async (
    date_start: string,
    date_end: string,
  ): Promise<FunnelAdsData> => {
    const amazonAds = await Promise.all([
      MathService.getAdsImpressions(date_start, date_end),
      MathService.getAdsClicks(date_start, date_end),
      MathService.getAdsUnitsSold(date_start, date_end),
    ]);
    const amazonOrganic = await Promise.all([
      MathService.getOrganicImpressions(date_start, date_end),
      MathService.getOrganicClicks(date_start, date_end),
      MathService.getOrganicUnitsSold(date_start, date_end),
    ]);

    return {
      // return arrays of
      // [impressions, clicks, unit sold]
      "Amazon Ads": amazonAds,
      "Amazon Organic": amazonOrganic,
      "Organic Social": [50, 3, 2],
      DSP: [30, 2, 1],
    };
  };
}
