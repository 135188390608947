import Axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  onResponse,
  onRequest,
  onRequestError,
  onResponseError,
} from "./interceptor";

export class Api {
  protected axiosInstance: AxiosInstance;

  constructor(baseURL: string, headers?: Record<string, string>) {
    this.axiosInstance = Axios.create({
      baseURL: baseURL,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    });

    this.axiosInstance.interceptors.request.use(onRequest, onRequestError);
    this.axiosInstance.interceptors.response.use(onResponse, onResponseError);
  }

  protected async get<T>(
    endpoint: string,
    params: Record<string, string | number> = {},
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(endpoint, {
      params,
    });
    return response.data;
  }

  protected async post<T>(endpoint: string, data: any): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.post(
      endpoint,
      data,
    );
    return response.data;
  }

  protected async put<T>(endpoint: string, data: any): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(
      endpoint,
      data,
    );
    return response.data;
  }

  protected async delete<T>(
    endpoint: string,
    params: Record<string, string | number> = {},
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(
      endpoint,
      { params },
    );
    return response.data;
  }
}
