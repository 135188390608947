export const columns = [
  {
    title: "PNL Type",
    dataIndex: "pnlType",
    key: "pnlType",
    render: (value: any, record: any) => (
      <div
        className={
          record.pnlType === "Total Sales" ? "total-sales-cell-pnl" : undefined
        }
      >
        {value}
      </div>
    ),
  },
  {
    title: "As Is",
    dataIndex: "asIs",
    key: "asIs",
    render: (value: any, record: any) => {
      const redRows = [
        "Cost of Goods Sold",
        "COGS",
        "Shipping & Handling",
        "Amazon Fees",
        "Amazon Spend",
        "Organic Social Spend",
        "Overhead Cost",
        "OPEX",
      ];
      const isRedRow = redRows.includes(record.pnlType);

      const noDollarRows = ["DSP", "OPEX", "Contribution Margin"];

      const isNumeric = typeof value === "number";
      const formattedValue = isNumeric ? value.toLocaleString("en-US") : value;

      let finalValue = formattedValue;
      if (isNumeric && !noDollarRows.includes(record.pnlType)) {
        finalValue = `$${formattedValue}`;
      }

      if (isRedRow) {
        return <span className="redSpan">{finalValue}</span>;
      }

      return finalValue;
    },
  },
];

export const tableData = [
  {
    key: "1",
    pnlType: "Total Sales",
    asIs: 7531933,
    children: [
      {
        key: "1-1",
        pnlType: "Amazon Sales",
        asIs: 5711855,
        children: [
          {
            key: "1-1-1",
            pnlType: "Ad",
            asIs: 4982550,
          },
          {
            key: "1-1-2",
            pnlType: "Organic",
            asIs: 729304,
          },
        ],
      },
      {
        key: "1-2",
        pnlType: "Other Sales",
        asIs: 1820304,
        children: [
          {
            key: "1-2-1",
            pnlType: "Organic Social",
            asIs: 1820078,
          },
          {
            key: "1-2-2",
            pnlType: "DSP",
            asIs: 0,
          },
        ],
      },
    ],
  },
  {
    key: "2",
    pnlType: "Cost of Goods Sold",
    asIs: 1589844,
    children: [
      {
        key: "2-1",
        pnlType: "COGS",
        asIs: 1355748,
      },
      {
        key: "2-2",
        pnlType: "Shipping & Handling",
        asIs: 1355748,
      },
    ],
  },
  {
    key: "3",
    pnlType: "Overhead Cost",
    asIs: 4405719,
    children: [
      {
        key: "3-1",
        pnlType: "Amazon Fees",
        asIs: 2636176,
      },
      {
        key: "3-2",
        pnlType: "Amazon Spend",
        asIs: 1769543,
      },
      {
        key: "3-3",
        pnlType: "Organic Social Spend",
        asIs: 0,
      },
    ],
  },
  {
    key: "4",
    pnlType: "Contribution Profit",
    asIs: 1536370,
  },
  {
    key: "5",
    pnlType: "Contribution Margin",
    asIs: "20.4%",
  },
  {
    key: "6",
    pnlType: "OPEX",
    asIs: 225958,
  },
  {
    key: "7",
    pnlType: "EBITDA",
    asIs: 1310412,
  },
];
