import { Api } from "src/utils/api";
import { ADDS_SALES_API_BASE_URL } from "./ads-sales.constant";
import { WidgetData } from "@pages/AdsAnalitics/components/Campaigns/utils";

export class AdsSalesApi extends Api {
  constructor() {
    super(ADDS_SALES_API_BASE_URL);
  }

  public getImpressions = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("impressions", {
      date_start,
      date_end,
    });
  };

  public getClicks = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("clicks", {
      date_start,
      date_end,
    });
  };

  public getOrders = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("orders", {
      date_start,
      date_end,
    });
  };

  public getUnitsSold = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("units-sold", {
      date_start,
      date_end,
    });
  };

  public getSales = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("sales", {
      date_start,
      date_end,
    });
  };

  public getSpend = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("spend", {
      date_start,
      date_end,
    });
  };

  public getAcos = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("acos", {
      date_start,
      date_end,
    });
  };

  public getRoas = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("roas", {
      date_start,
      date_end,
    });
  };

  public getCpc = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("cpc", {
      date_start,
      date_end,
    });
  };

  public getCvr = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("cvr", {
      date_start,
      date_end,
    });
  };

  public getCtr = async (
    date_start: string,
    date_end: string,
  ): Promise<number> => {
    return await this.get("ctr", {
      date_start,
      date_end,
    });
  };

  public getImpressionCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("impressions/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getClicksCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("clicks/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getOrdersCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("orders/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getUnitsSoldCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("units-sold/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getSalesCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("sales/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getSpendCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("spend/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getAcosCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("acos/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getRoasCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("roas/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getCpcCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("cpc/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getCvrCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("cvr/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getCtrCompare = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<number> => {
    return await this.get("ctr/diff", {
      date_start,
      date_end,
      compare_date_start,
      compare_date_end,
    });
  };

  public getWidgetsComparePromise = async (
    date_start: string,
    date_end: string,
    compare_date_start: string,
    compare_date_end: string,
  ): Promise<Partial<WidgetData>> => {
    const [
      sales,
      spend,
      impressions,
      ctr,
      cvr,
      cpc,
      acos,
      roas,
      orders,
      unitSolds,
      clicks,
      tacos,
      organicRank,
      searchTermAdImpressionRank,
      timeInBudget,
      tosAdImpressionShare,
    ] = await Promise.all([
      this.getSalesCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getSpendCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getImpressionCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getCtrCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getCvrCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getCpcCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getAcosCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getRoasCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getOrdersCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getUnitsSoldCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getClicksCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      //TODO: Replace with actual routes
      this.getAcosCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getRoasCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getOrdersCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getUnitsSoldCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
      this.getClicksCompare(
        date_start,
        date_end,
        compare_date_start,
        compare_date_end,
      ).catch(() => 0),
    ]);

    return {
      sales,
      spend,
      impressions,
      ctr,
      cvr,
      cpc,
      acos,
      roas,
      orders,
      unitSolds,
      clicks,

      tacos,
      organicRank,
      searchTermAdImpressionRank,
      timeInBudget,
      tosAdImpressionShare,
    };
  };

  public getWidgetDataPromise = async (
    date_start: string,
    date_end: string,
  ): Promise<Partial<WidgetData>> => {
    const [
      sales,
      spend,
      impressions,
      ctr,
      cvr,
      cpc,
      acos,
      roas,
      orders,
      unitSolds,
      clicks,

      tacos,
      organicRank,
      searchTermAdImpressionRank,
      timeInBudget,
      tosAdImpressionShare,
    ] = await Promise.all([
      this.getSales(date_start, date_end),
      this.getSpend(date_start, date_end),
      this.getImpressions(date_start, date_end),
      this.getCtr(date_start, date_end),
      this.getCvr(date_start, date_end),
      this.getCpc(date_start, date_end),
      this.getAcos(date_start, date_end),
      this.getRoas(date_start, date_end),
      this.getOrders(date_start, date_end),
      this.getUnitsSold(date_start, date_end),
      this.getClicks(date_start, date_end),

      this.getSales(date_start, date_end),
      this.getSpend(date_start, date_end),
      this.getImpressions(date_start, date_end),
      this.getCtr(date_start, date_end),
      this.getCvr(date_start, date_end),
    ]);

    return {
      sales,
      spend,
      impressions,
      ctr,
      cvr,
      cpc,
      acos,
      roas,
      orders,
      unitSolds,
      clicks,

      tacos,
      organicRank,
      searchTermAdImpressionRank,
      timeInBudget,
      tosAdImpressionShare,
    };
  };
}
