import { RangeCard } from "@pages/AdsAnalitics/components/Campaigns/components/AdSpend/RangeCard";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useCanvaStore } from "src/store/canva/canva.state";
import { Slider } from "antd";
import { data } from "./mock";
import { ArrowLeft } from "lucide-react";

interface OrganicChartProps {
  setIsChartOpen: (value: boolean) => void;
  updateElementSize: () => void;
}

export const OrganicChart: React.FC<OrganicChartProps> = ({
  setIsChartOpen,
  updateElementSize,
}) => {
  const { isWhatIf } = useCanvaStore();
  const [estimate, setEstimate] = useState<number>(0);
  const [xRange, setXRange] = useState([0, 50]);
  const [yRangeLeft, setYRangeLeft] = useState<number[]>([0, 25]);
  const [yRangeRight, setYRangeRight] = useState<number[]>([0, 50]);
  const [filteredData, setFilteredData] = useState(data);

  const handleEstimateChange = (value: [number, number]) => {
    setEstimate(value[0] === 0 ? value[1] : value[0]);
  };

  const filterData = () => {
    return data.filter(
      (d) =>
        d.rank >= xRange[0] &&
        d.rank <= xRange[1] &&
        d.ctr >= yRangeLeft[0] &&
        d.ctr <= yRangeLeft[1] &&
        d.adsCVR >= yRangeRight[0] &&
        d.adsCVR <= yRangeRight[1],
    );
  };

  useEffect(() => {
    setFilteredData(filterData());
  }, [xRange, yRangeLeft, yRangeRight]);

  const handleRangeChange = (type: string, value: number[]) => {
    if (type === "x") setXRange(value);
    if (type === "leftY") setYRangeLeft(value);
    if (type === "rightY") setYRangeRight(value);
  };

  return (
    <>
      <div className={styles.chart__title}>
        <button
          onClick={() => {
            setIsChartOpen(false);
            updateElementSize();
          }}
        >
          <ArrowLeft width={20} height={20} />
        </button>
        <h3>CTR & CVR from organic rank</h3>
      </div>
      <div className={styles.container}>
        <RangeCard
          title="Estimated AVG Organic Rank"
          value={estimate}
          onChange={handleEstimateChange}
          min={0}
          max={50}
          isWhatIf={isWhatIf}
        />
        <div className={styles.chart}>
          <div className={styles.legend}>
            <div className={styles.legend__item}>
              <div
                className={styles.legend__color}
                style={{ background: "#5295E0" }}
              />
              <span>Organic CTR</span>
            </div>
            <div className={styles.legend__item}>
              <div
                className={styles.legend__color}
                style={{ background: "#F19867" }}
              />
              <span>Organic CVR</span>
            </div>
            <div className={styles.legend__item}>
              <div
                className={styles.legend__color}
                style={{ background: "#6B4EB9" }}
              />
              <span>Organic sales</span>
            </div>
            <div className={styles.legend__item}>
              <div
                className={styles.legend__color}
                style={{ background: "#E667F1" }}
              />
              <span>Ads CTR</span>
            </div>
            <div className={styles.legend__item}>
              <div
                className={styles.legend__color}
                style={{ background: "#3F33EC" }}
              />
              <span>Ads CVR</span>
            </div>
            <div className={styles.legend__item}>
              <div
                className={styles.legend__color}
                style={{ background: "#7fc67a" }}
              />
              <span>Ad Sales</span>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "0" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0",
              }}
            >
              <div style={{ maxHeight: "266px", marginTop: "52px" }}>
                <Slider
                  range
                  vertical
                  min={0}
                  max={25}
                  step={5}
                  defaultValue={[0, 25]}
                  onChange={(value) => handleRangeChange("leftY", value)}
                  style={{ margin: "0" }}
                  handleStyle={[{ border: "solid 2px #212121" }]}
                  railStyle={{ backgroundColor: "#2e2e2e" }}
                  trackStyle={[{ backgroundColor: "white" }]}
                />
              </div>
              <ResponsiveContainer width="100%" height={368}>
                <AreaChart
                  data={filteredData}
                  margin={{ right: 40, bottom: 20, left: 20 }}
                >
                  <CartesianGrid
                    stroke="#1F1F20"
                    vertical={false}
                    horizontal={true}
                  />
                  <XAxis
                    dataKey="rank"
                    tickLine={false}
                    axisLine={false}
                    style={{ fill: "#fff" }}
                    tickFormatter={(tick) => (tick % 5 === 0 ? tick : "")}
                  >
                    <Label
                      value="Organic Rank"
                      offset={-14}
                      position="insideBottom"
                      style={{
                        textAnchor: "middle",
                        fill: "#fff",
                        fontSize: 12,
                      }}
                    />
                  </XAxis>
                  <YAxis
                    yAxisId="left"
                    orientation="left"
                    stroke="#7C7C78"
                    strokeWidth={2}
                    tickLine={false}
                    style={{ fill: "#fff" }}
                    ticks={[0, 5, 10, 15, 20, 25]}
                    tickFormatter={(tick) => `${tick}%`}
                  >
                    <Label
                      value="Organic CTR & Organic CVR"
                      angle={-90}
                      position="insideLeft"
                      style={{
                        textAnchor: "middle",
                        fill: "#fff",
                        fontSize: 12,
                      }}
                    />
                  </YAxis>
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    stroke="#7C7C78"
                    strokeWidth={2}
                    tickLine={false}
                    ticks={[0, 10000, 20000, 30000, 40000, 50000]}
                    tickFormatter={(tick) => `$${tick / 1000}K`}
                    style={{ fill: "#fff", left: "-10px" }}
                  >
                    <Label
                      value="Organic Sales"
                      angle={90}
                      offset={-10}
                      position="insideRight"
                      style={{
                        textAnchor: "middle",
                        fill: "#fff",
                        fontSize: 12,
                      }}
                    />
                  </YAxis>
                  <Area
                    type="monotone"
                    dataKey="sales"
                    stroke="#6B4EB9"
                    strokeWidth={2}
                    fill="url(#colorSales)"
                    yAxisId="right"
                  />
                  <Area
                    type="monotone"
                    dataKey="ctr"
                    stroke="#5295E0"
                    fill="url(#ctr)"
                    yAxisId="left"
                  />
                  <Area
                    type="monotone"
                    dataKey="cvr"
                    stroke="#F19867"
                    fill="url(#cvr)"
                    yAxisId="left"
                  />
                  <Area
                    type="monotone"
                    dataKey="adsCTR"
                    stroke="#E667F1"
                    fill="url(#adsCTR)"
                    yAxisId="left"
                  />
                  <Area
                    type="monotone"
                    dataKey="adsCVR"
                    stroke="#3F33EC"
                    fill="url(#adsCVR)"
                    yAxisId="left"
                  />
                  <Area
                    type="monotone"
                    dataKey="adsSales"
                    stroke="#7fc67a"
                    fill="url(#adsSales)"
                    yAxisId="left"
                  />
                  <defs>
                    <linearGradient
                      id="colorSales"
                      x1="546.5"
                      y1="1"
                      x2="546.5"
                      y2="455"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#6B4EB9" />
                      <stop
                        offset="0.552554"
                        stop-color="#6B4EB9"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient
                      id="cvr"
                      x1="546.75"
                      y1="1"
                      x2="546.75"
                      y2="435.525"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#F19867" />
                      <stop
                        offset="0.519933"
                        stop-color="#F19867"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient
                      id="ctr"
                      x1="546.5"
                      y1="0.998047"
                      x2="546.5"
                      y2="341.998"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#5295E0" />
                      <stop
                        offset="0.443705"
                        stop-color="#5295E0"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient
                      id="adsCTR"
                      x1="546.5"
                      y1="0.998047"
                      x2="546.5"
                      y2="341.998"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#E667F1" />
                      <stop
                        offset="0.443705"
                        stop-color="#E667F1"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient
                      id="adsCVR"
                      x1="546.5"
                      y1="0.998047"
                      x2="546.5"
                      y2="341.998"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3F33EC" />
                      <stop
                        offset="0.443705"
                        stop-color="#3F33EC"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                  <defs>
                    <linearGradient
                      id="adsSales"
                      x1="546.5"
                      y1="0.998047"
                      x2="546.5"
                      y2="341.998"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#7fc67a" />
                      <stop
                        offset="0.443705"
                        stop-color="#7fc67a"
                        stop-opacity="0"
                      />
                    </linearGradient>
                  </defs>
                  <ReferenceLine
                    x={32}
                    stroke="#B5B5B5"
                    strokeDasharray="3 3"
                    yAxisId="left"
                    label={{
                      value: "Current Value",
                      position: "insideTop",
                      offset: 16,
                      style: {
                        fill: "#B5B5B5",
                        fontSize: 12,
                        textAnchor: "start",
                      },
                    }}
                  />
                  {isWhatIf && (
                    <ReferenceLine
                      x={estimate}
                      stroke="#80C67A"
                      strokeDasharray="3 3"
                      yAxisId="left"
                      label={{
                        value: "Nyle Optimize",
                        position: "insideTop",
                        offset: 16,
                        style: {
                          fill: "#80C67A",
                          fontSize: 12,
                          textAnchor: "start",
                        },
                      }}
                    />
                  )}
                </AreaChart>
              </ResponsiveContainer>
              <div style={{ maxHeight: "266px", marginTop: "52px" }}>
                <Slider
                  range
                  vertical
                  min={0}
                  max={50}
                  step={10}
                  defaultValue={[0, 50]}
                  onChange={(value) => handleRangeChange("rightY", value)}
                  style={{ margin: "0" }}
                  handleStyle={[{ border: "solid 2px #212121" }]}
                  railStyle={{ backgroundColor: "#2e2e2e" }}
                  trackStyle={[{ backgroundColor: "white" }]}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              maxWidth: "963px",
              alignSelf: "center",
              marginRight: "14px",
            }}
          >
            <Slider
              range
              min={0}
              max={50}
              step={5}
              defaultValue={[0, 50]}
              onChange={(value) => handleRangeChange("x", value)}
              style={{ margin: "0" }}
              handleStyle={[{ border: "solid 2px #212121" }]}
              railStyle={{ backgroundColor: "#2e2e2e" }}
              trackStyle={[{ backgroundColor: "white" }]}
            />
          </div>
        </div>
      </div>
    </>
  );
};
