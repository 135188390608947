import { Environment } from "../constants/auth.constants";

/** This is a temporary workaround */
export const getEnvironment = (): Environment => {
  const origin = window.location.origin;

  if (origin.includes("localhost") || origin.includes("127.0.0.1")) {
    return Environment.LOCAL;
  }

  if (origin.includes("dev")) {
    return Environment.DEV;
  }

  if (origin.includes("staging")) {
    return Environment.STAGING;
  }

  return Environment.PROD;
};
