import axios from "axios";
import { Image } from "@entities/Image";
import RefreshTokenService from "./refreshToken";
import { useNavigate } from "react-router-dom";

const IMAGE_URL = "/api/v1/images";
const getUserToken = async () => {
  let token = localStorage.getItem("access_token");
  const refreshToken = localStorage.getItem("refresh_token");

  if (!token || (await RefreshTokenService.isTokenActive(token)) === false) {
    if (refreshToken) {
      token = await RefreshTokenService.refresh(refreshToken);
    } else {
      const navigate = useNavigate();
      navigate("/auth/login");
    }
  }

  return token;
};

const getAllImages = () => {
  return axios.get(IMAGE_URL);
};

const getImageDetails = (id) => {
  return axios.get(`${IMAGE_URL}/${id}`);
};

const uploadImage = (data) => {
  return axios.post<Image>(IMAGE_URL, data);
};

const getImageTags = (id) => {
  return axios.post<{ features: string[] }>(`${IMAGE_URL}/${id}/features`);
};

const enhanceImage = async (id, { factor }: { factor: number }) => {
  const userToken = await getUserToken();
  return fetch(`${IMAGE_URL}/${id}/enhance?factor=${factor}`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "image/*, application/json",
      "Content-Type": "image/*",
      Authorization: `Bearer ${userToken}`,
    },
  });
};

const removeBackground = async (id) => {
  const userToken = await getUserToken();
  return fetch(`${IMAGE_URL}/${id}/remove-background`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "image/*, application/json",
      "Content-Type": "image/*",
      Authorization: `Bearer ${userToken}`,
    },
  });
};

const deleteImage = (id) => {
  return axios.delete(`${IMAGE_URL}/${id}`);
};

export const ImagesService = {
  enhanceImage,
  uploadImage,
  deleteImage,
  getAllImages,
  getImageTags,
  getImageDetails,
  removeBackground,
};
