import { PieChart, Pie, Tooltip } from "recharts";
import styles from "./styles.module.scss";
import {
  BRAND_DATA,
  BRAND_COLORS,
  brandLegendOrder,
  renderInnerDots,
  getLegendValue,
  getLegendColor,
} from "./constant";
import { calculateLabelPositions } from "./mock";
import CustomLegend from "@pages/SubscriptionPerformance/components/Month";
import React from "react";

interface BrandRevenueChartProps {
  hidePercentage?: boolean;
  hideTitle?: boolean;
  dataSales?: any[];
}

const BrandRevenueChart: React.FC<BrandRevenueChartProps> = ({
  hidePercentage,
  hideTitle,
  dataSales,
}) => {
  const data = dataSales ? dataSales : BRAND_DATA;
  const totalRevenue = data.reduce((acc, item) => acc + item.value, 0);
  const formattedTotalRevenue =
    totalRevenue >= 1000000
      ? `$${(totalRevenue / 1000000).toFixed(1)}M`
      : `$${(totalRevenue / 1000).toFixed(1)}K`;

  let cumulativeAngle = dataSales ? 195 : 100;
  const pieData = data.map((entry, index) => {
    const angle = (entry.value / totalRevenue) * 360;
    const startAngle = cumulativeAngle;
    const endAngle = cumulativeAngle - angle;
    cumulativeAngle = endAngle;
    let color = BRAND_COLORS[index];
    if (dataSales) {
      if (entry.name === "Organic Social") {
        color = "#5295E0";
      } else if (entry.name === "Amazon") {
        color = "#FE5858";
      }
    }
    return {
      ...entry,
      startAngle,
      endAngle,
      color,
    };
  });

  const centerX = 195;
  const centerY = 115;
  const radius = 115;
  const radialOffset = dataSales ? 45 : 55;
  const horizontalOffset = dataSales ? 25 : 65;

  const labelPositions = calculateLabelPositions(
    pieData,
    centerX,
    centerY,
    radius,
    radialOffset,
    horizontalOffset,
  );

  const chartOffsetX = 110;
  const chartOffsetY = hideTitle ? 20 : 150;
  const labelOffset = dataSales ? 75 : 45;

  return (
    <div className={styles.containerBrand}>
      {!hideTitle && <div className={styles.title}>Market Share</div>}
      <CustomLegend
        payload={data
          .map((item, index) => ({
            id: item.name,
            type: "circle",
            value: getLegendValue(item, hideTitle, hidePercentage, dataSales),
            color: getLegendColor(item, index, hideTitle, dataSales),
          }))
          .sort(
            (a, b) =>
              brandLegendOrder.indexOf(a.id) - brandLegendOrder.indexOf(b.id),
          )}
        hidePercentage={hidePercentage}
      />

      <PieChart
        width={472}
        height={430}
        style={{
          position: "relative",
          top: `${chartOffsetY}px`,
          left: `${chartOffsetX}px`,
        }}
      >
        {renderInnerDots(centerX + 5, centerY + 5, 75)}
        {pieData.map((entry, index) => (
          <Pie
            key={`slice-${index}`}
            data={[entry]}
            cx={centerX}
            cy={centerY}
            innerRadius={85}
            outerRadius={radius}
            startAngle={entry.startAngle}
            endAngle={entry.endAngle}
            dataKey="value"
            stroke="none"
            fill={entry.color}
          />
        ))}
        <Tooltip />
        <text
          x={centerX + 5}
          y={centerY + 5}
          textAnchor="middle"
          dominantBaseline="middle"
          fill="white"
          fontSize="21"
          fontWeight="bold"
        >
          {formattedTotalRevenue}
        </text>
      </PieChart>
      <svg
        className={styles.svgContainer}
        width={472}
        style={{ transform: `translate(${chartOffsetX}px, ${chartOffsetY}px)` }}
      >
        {labelPositions.map((pos, i) => (
          <g key={i}>
            <line
              x1={pos.startX}
              y1={pos.startY - labelOffset}
              x2={pos.midX}
              y2={pos.midY - labelOffset}
              stroke="#5C5C5A"
              strokeWidth="1.5"
            />
            <line
              x1={pos.midX}
              y1={pos.midY - labelOffset}
              x2={pos.endX}
              y2={pos.endY - labelOffset}
              stroke="#5C5C5A"
              strokeWidth="1.5"
            />
            <text
              x={pos.textX}
              y={pos.textY - labelOffset}
              textAnchor={pos.textAnchor}
              fill="#5C5C5A"
              fontSize="14"
              fontWeight="600"
            >
              <tspan
                x={pos.textX}
                dy="0em"
                style={{ fill: "white", fontWeight: 400 }}
              >
                {pos.name}
              </tspan>
              <tspan x={pos.textX} dy="1.2em" fontSize="12" fontWeight="400">
                $
                {dataSales
                  ? (pos.value / 1000)
                      .toFixed(1)
                      .replace(/\.0$/, "")
                      .replace(".", ",")
                  : Math.round(pos.value / 1000)}
                K (
                {((pos.value / totalRevenue) * 100)
                  .toFixed(1)
                  .replace(/\.0$/, "")
                  .replace(".", ",")}
                %)
              </tspan>
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
};

export default BrandRevenueChart;
