import styles from "./styles.module.scss";
import { useMemo, useState } from "react";
import { CalendarModal } from "@components/calendar-modal";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import classNames from "classnames";
import { formatDateRange } from "src/utils/formatDate";
import CalendarImage from "@assets/icons/calendar.svg";
import CustomSelect from "@pages/AdsAnalitics/components/Header/ui/CustomSelect";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { useBackground } from "@components/BackfroundContext";
import Setting from "@assets/icons/setting";
import { BrandAsinSelect } from "@components/brand-asin-select";
import { GlobalResetButton } from "@components/GlobalResetButton";
import { useGlobalResetStore } from "src/store/global-reset.store";

export const Header: React.FC = () => {
  const { setIsBlurred } = useBackground();
  const { hasChanges } = useGlobalResetStore();
  const { dateRange, setDateRange, compareType, setCompareType, brand, asin } =
    useDashboardStore((state) => ({
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      compareType: state.compareType,
      setCompareType: state.setCompareType,
      brand: state.brandSelected,
      asin: state.asinSelected,
    }));
  const {
    compareWith,
    setCompareWith,
    compareWithString,
    setCompareWithString,
  } = useAdsAnaliticsStore();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );
  const [isSettingOpen, setIsSettingOpen] = useState(false);

  const [initialFilters] = useState({
    compareWith,
    compareType,
    dateRange,
    compareWithString,
  });

  const activeFiltersCount = useMemo(() => {
    let count = 0;

    if (brand && brand.length > 0) {
      count++;
    }

    if (asin && asin.length > 0) {
      count++;
    }

    if (initialFilters.compareWithString && compareWithString !== "Custom") {
      count++;
    }

    if (compareType && compareType !== "raw") {
      count++;
    }

    if (initialFilters.dateRange && initialFilters.dateRange !== dateRange) {
      count++;
    }
    return count;
  }, [brand, asin, compareWith, compareType]);

  const handleDateChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
    setIsBlurred(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h2>Canvas</h2>
        </div>
        <div
          className={classNames(styles.setting, isSettingOpen && styles.active)}
        >
          <div className={styles.select__container}>
            <BrandAsinSelect />
          </div>
          <div className={styles.buttons}>
            <div className={styles.calendar}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCalendarOpen((prevState) => {
                    setIsBlurred(!prevState);
                    return !prevState;
                  });
                }}
                className={classNames(
                  styles.button,
                  isCalendarOpen && styles.openCalendar,
                )}
              >
                <img src={CalendarImage} alt="Calendar" />
                {selectedDateRange}
              </button>
              {isCalendarOpen && (
                <div className={styles.modal}>
                  <CalendarModal
                    buttonWidth={148}
                    closeModal={() => {
                      setIsCalendarOpen(false);
                      setIsBlurred(false);
                    }}
                    onDateChange={handleDateChange}
                    initialRange={dateRange}
                  />
                </div>
              )}
            </div>
            <CustomSelect
              dateRange={dateRange}
              className={styles.date__select}
              compareWith={compareWith}
              setCompareWith={setCompareWith}
              compareType={compareType}
              setCompareType={setCompareType}
              setCompareWithString={setCompareWithString}
            />
          </div>
          <button
            onClick={() => setIsSettingOpen((prevState) => !prevState)}
            className={styles.settingButton}
          >
            <Setting />
            {activeFiltersCount > 0 && (
              <div className={styles.count}>{activeFiltersCount}</div>
            )}
          </button>
          {hasChanges && <GlobalResetButton />}
        </div>
      </div>
    </div>
  );
};
