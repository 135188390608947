import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { CategoryData } from "src/types/spider.types";
import { ApiResponse } from "src/types/apiResponce";

export interface Brand {
  name: string;
  asins: string[];
}

export class CategoriesService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  public async getCategories(asin = ""): Promise<CategoryData> {
    return this.get("/categories", { asin });
  }
  public async getStats(asin = ""): Promise<CategoryData> {
    return this.get("/reviews/stats", { asin });
  }
  public async getBrands(): Promise<Brand[]> {
    return this.get("/products/own/brands");
  }
  public async getGraphsReview(
    asin = "",
    date_start: string,
    date_end: string,
  ): Promise<CategoryData> {
    return this.get("/graphs/review-score", { asin, date_start, date_end });
  }
  public async sendCogsData(
    payload: Record<string, any>,
  ): Promise<ApiResponse<any>> {
    return this.post(`/cogs/upload`, payload);
  }
}
