import { useState, useRef } from "react";
import styles from "./styles.module.scss";

interface CustomSliderProps {
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
  labels: string[];
}

export const CustomSlider = ({
  min,
  max,
  value,
  onChange,
  labels,
}: CustomSliderProps) => {
  const [sliderValue, setSliderValue] = useState(value);
  const sliderRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: MouseEvent) => {
    if (!sliderRef.current) return;

    const rect = sliderRef.current.getBoundingClientRect();
    const offsetX = event.clientX - rect.left;
    let newValue = Math.round((offsetX / rect.width) * (max - min) + min);

    if (newValue < min) newValue = min;
    if (newValue > max) newValue = max;

    setSliderValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={styles.sliderWrapper}>
      <div className={styles.sliderContainer}>
        <div
          ref={sliderRef}
          className={styles.sliderTrack}
          onMouseDown={(event) => {
            handleMouseMove(event);
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", () =>
              document.removeEventListener("mousemove", handleMouseMove),
            );
          }}
        >
          <div
            className={styles.sliderProgress}
            style={{ width: `${((sliderValue - min) / (max - min)) * 100}%` }}
          ></div>
          <div
            className={styles.sliderHandle}
            style={{ left: `${((sliderValue - min) / (max - min)) * 100}%` }}
          ></div>

          {labels.map((label, index) => {
            const position = (index / (labels.length - 1)) * 100;
            return (
              <div
                key={index}
                className={styles.sliderPoint}
                style={{ left: `${position}%` }}
              >
                <span className={styles.sliderLabel}>{label}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
