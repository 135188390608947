import { v4 as uuid } from "uuid";

export const performanceData = [
  {
    key: uuid(),
    category: "Search 1",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Organic performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
      {
        key: uuid(),
        category: "Ad Performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
    ],
  },
  {
    key: uuid(),
    category: "Search 2",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Organic performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
      {
        key: uuid(),
        category: "Ad Performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
    ],
  },
  {
    key: uuid(),
    category: "Search 3",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Organic performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
      {
        key: uuid(),
        category: "Ad Performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
    ],
  },
  {
    key: uuid(),
    category: "Search 1",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Organic performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
      {
        key: uuid(),
        category: "Ad Performance",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
    ],
  },
];

export const channelData = [
  {
    key: uuid(),
    category: "Organic performance",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Lorem Ipsum",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
      {
        key: uuid(),
        category: "Lorem Ipsum",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
    ],
  },
  {
    key: uuid(),
    category: "Ad Performance",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Search 2",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
      {
        key: uuid(),
        category: "Search 2",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
      },
    ],
  },
];

export const targetData = [
  {
    key: uuid(),
    category: "Current Target",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Lorem Ipsum",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
        children: [
          {
            key: uuid(),
            category: "Organic performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
          {
            key: uuid(),
            category: "Ad Performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
        ],
      },
      {
        key: uuid(),
        category: "Lorem Ipsum",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
        children: [
          {
            key: uuid(),
            category: "Organic performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
          {
            key: uuid(),
            category: "Ad Performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
        ],
      },
    ],
  },
  {
    key: uuid(),
    category: "Missing Target",
    searchVolume: 100,
    organicRank: 1,
    relevancyScore: 100,
    organicImpressions: 1000,
    impressionsShare: 10,
    organicClicks: 100,
    organicCTR: 10,
    spend: 100,
    spend_with_nyle: 100,
    cpc: 1,
    organicUnitsSold: 100,
    organicCVR: 10,
    organicSales: 1000,
    salesShare: 10,
    organicSales_with_nyle: 1000,
    acos: 10,
    acos_with_nyle: 10,
    tacos: 10,
    tacos_with_nyle: 10,
    svr: 10,
    children: [
      {
        key: uuid(),
        category: "Search 2",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
        children: [
          {
            key: uuid(),
            category: "Organic performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
          {
            key: uuid(),
            category: "Ad Performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
        ],
      },
      {
        key: uuid(),
        category: "Search 2",
        searchVolume: 100,
        organicRank: 1,
        relevancyScore: 100,
        organicImpressions: 1000,
        impressionsShare: 10,
        organicClicks: 100,
        organicCTR: 10,
        spend: 100,
        spend_with_nyle: 100,
        cpc: 1,
        organicUnitsSold: 100,
        organicCVR: 10,
        organicSales: 1000,
        salesShare: 10,
        organicSales_with_nyle: 1000,
        acos: 10,
        acos_with_nyle: 10,
        tacos: 10,
        tacos_with_nyle: 10,
        svr: 10,
        children: [
          {
            key: uuid(),
            category: "Organic performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
          {
            key: uuid(),
            category: "Ad Performance",
            searchVolume: 100,
            organicRank: 1,
            relevancyScore: 100,
            organicImpressions: 1000,
            impressionsShare: 10,
            organicClicks: 100,
            organicCTR: 10,
            spend: 100,
            spend_with_nyle: 100,
            cpc: 1,
            organicUnitsSold: 100,
            organicCVR: 10,
            organicSales: 1000,
            salesShare: 10,
            organicSales_with_nyle: 1000,
            acos: 10,
            acos_with_nyle: 10,
            tacos: 10,
            tacos_with_nyle: 10,
            svr: 10,
          },
        ],
      },
    ],
  },
];
