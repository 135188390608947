import { useEffect, useRef, useState } from "react";
import ProductDate from "./components/ProductDate";
import Sections from "./components/Sections";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useProductStore } from "src/store/overviewProduct.state";
import { SpiderService } from "@services/spider/spider.services";
import { useInsightsStore } from "src/store/insights/insights.state";
import { formattedKeys } from "src/utils/convertUtil";
import { CalendarModal } from "@components/calendar-modal";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { formatDateRange } from "src/utils/formatDate";
import CalendarImage from "@assets/icons/calendar.svg";
import CustomSelect from "./components/CustomSelect";
import { useSectionStore } from "src/store/croModule.state";
import MetricBuilder from "./components/Sections/MetricBuilder";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";
import { useBackground } from "@components/BackfroundContext";
import { ExecutiveSummary } from "@components/ExecutiveSummary";

function CROPage() {
  const { setIsBlurred } = useBackground();
  const { dateRange, setDateRange, compareType, setCompareType } =
    useDashboardStore((state) => ({
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
      compareType: state.compareType,
      setCompareType: state.setCompareType,
    }));
  const { compareWith, setCompareWith } = useAdsAnaliticsStore();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { isWhatIf, changeWhatIf } = useSectionStore((state) => ({
    isWhatIf: state.isWhatIf,
    changeWhatIf: state.changeWhatIf,
  }));
  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );
  // const [brand, setBrand] = useState<{ name: string }>();

  const handleDateChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
    setIsBlurred(false);
  };

  const [isActive, setIsActive] = useState(false);
  const sectionRefs = useRef({});
  const { selectedProduct } = useProductStore();
  const { setProductOneSpiderTitles, setProductOneSpiderValues } =
    useInsightsStore();

  useEffect(() => {
    const fetchProducts = async () => {
      if (!selectedProduct) return;

      try {
        const spider = new SpiderService();
        const spiderDots = await spider.getSpiderInfo(selectedProduct.asin);

        setProductOneSpiderTitles(formattedKeys(spiderDots));
        setProductOneSpiderValues(
          Object.values(spiderDots).map((value) =>
            parseFloat(value.toFixed(1)),
          ),
        );
      } catch (err) {
        console.error("Error fetching product:", err);
      }
    };

    fetchProducts();
  }, [selectedProduct]);

  // const fetchBrands = async () => {
  //   const spider = new CategoriesService();
  //   const spidersData = await spider.getBrands();
  //   if (spidersData.length > 0) {
  //     console.log(spidersData[5].name,"spidersData[5].name");

  //     setBrand(spidersData[5].name);
  //   }
  // };

  // useEffect(() => {
  //   fetchBrands();
  // }, [selectedProduct]);

  // const fetchPOS = async () => {
  //   if (!brand) return;
  //   const spider = new SpiderService();
  //   const spiderDots = await spider.getPos(brand);
  // };
  // useEffect(() => {
  //   fetchPOS();
  // }, [selectedProduct]);
  return (
    <>
      <div className={styles.header}>
        <div className={styles.title__container}>
          {/* <Tools
            scrollToSection={scrollToSection}
            isActive={isActive}
            setIsActive={setIsActive}
          /> */}
          <h1 className={styles.title}>CRO Module</h1>
        </div>
        <div className={styles.buttons}>
          <div className={styles.calendar}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsCalendarOpen((prevState) => {
                  setIsBlurred(!prevState);
                  return !prevState;
                });
              }}
              className={classNames(
                styles.button,
                isCalendarOpen && styles.active,
              )}
            >
              <img
                src={CalendarImage}
                alt="Calendar"
                className={styles.imgCalendar}
              />
              <p className={styles.btn}> {selectedDateRange}</p>
            </button>
            {isCalendarOpen && (
              <div className={styles.modal}>
                <CalendarModal
                  buttonWidth={148}
                  closeModal={() => {
                    setIsCalendarOpen(false);
                    setIsBlurred(false);
                  }}
                  onDateChange={handleDateChange}
                  initialRange={dateRange}
                />
              </div>
            )}
          </div>
          <CustomSelect
            dateRange={dateRange}
            compareWith={compareWith}
            setCompareWith={setCompareWith}
            compareType={compareType}
            setCompareType={setCompareType}
          />
        </div>
      </div>
      <div className={classNames(styles.content)}>
        <ProductDate setWhatIf={changeWhatIf} isWhatIf={isWhatIf} />
        <div className={styles.executive}>
          <ExecutiveSummary />
        </div>
        <Sections sectionRefs={sectionRefs} isWhatIf={isWhatIf} />
      </div>
      {isActive && <div className={styles.bg}></div>}
    </>
  );
}

export default CROPage;
