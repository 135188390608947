import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import Settings from "@assets/img/settings";
import { useModalStore } from "src/store/modal.state";
import { useWidgets } from "@hooks/useWidgets";
import { formatValue } from "src/utils/formatDate";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";
import classNames from "classnames";
import { ConfigModalProduct } from "@components/config-modal-product";
import { useDataBlockMetricStore } from "src/store/metric-block.state";
import { defaultMetrics } from "@components/config-modal-product/mock";
import { normalizeMetricName } from "./utils";
import { Tooltip } from "antd";
import InfoIcon from "@assets/icons/i.svg";

interface DataBlockProps {}

const DataBlock: React.FC<DataBlockProps> = () => {
  const { selectedProduct } = useProductStore();
  const [totalPosRounded, setTotalPosRounded] = useState<string>("N/A");
  const ongoingRequest = useRef<string | null>(null);
  const { handleApply, updatedWidgets } = useWidgets();
  const openModal = useModalStore((state) => state.openModal);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" ? window.innerWidth <= 768 : false,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });
  const { selectedMetric } = useDataBlockMetricStore();
  const fetchPos = async () => {
    const asin =
      typeof selectedProduct === "string"
        ? selectedProduct
        : selectedProduct?.asin;
    if (ongoingRequest.current === asin) return;
    ongoingRequest.current = asin;
    try {
      const spider = new SpiderService();
      const spiderDots = await spider.getCroInfoPos(asin);
      const roundedValue = Math.round(spiderDots.total_pos).toString();
      setTotalPosRounded(roundedValue);
    } catch {
      setTotalPosRounded("N/A");
    } finally {
      ongoingRequest.current = null;
    }
  };

  useEffect(() => {
    if (selectedProduct) fetchPos();
  }, [selectedProduct]);

  const mapApiDataToDataBlockProps = (apiData) => {
    const dataLookup = {};
    apiData.forEach((item) => {
      dataLookup[item.name] = item;
    });

    return {
      sessionTotal: dataLookup["Sales total"]?.value || "N/A",
      pageViewsTotal: dataLookup["Ad Impressions"]?.value || "N/A",
      featuredOffer: dataLookup["Organic Clicks"]?.value || "N/A",
      unitsOrdered: dataLookup["Ad Orders"]?.value || "N/A",
      unitsSessionPercentage: dataLookup["Ad CVR"]?.value || "N/A",
      orderedProductSales: dataLookup["Ad Sales"]?.value || "N/A",
      totalOrderItems: dataLookup["Ad Units Sold"]?.value || "N/A",
      totalCTR: dataLookup["Ad CTR"]?.value || "N/A",
      totalCVR: dataLookup["Ad CVR"]?.value || "N/A",
      totalPos: {
        value: totalPosRounded,
        icon: InfoIcon,
      },
    };
  };

  const dataBlockProps = useMemo(() => {
    return mapApiDataToDataBlockProps(updatedWidgets);
  }, [updatedWidgets]);
  const metricsToDisplay = useMemo(() => {
    return selectedMetric.length > 0 ? selectedMetric : defaultMetrics;
  }, [selectedMetric, defaultMetrics]);

  metricsToDisplay.forEach((metric) => {
    if (metric.name.toLowerCase() === "pos" && !metric.icon) {
      metric.icon = InfoIcon;
      metric.tooltip = "This is the POS metric.";
    }
  });

  const openConfigModal = (event: React.MouseEvent) => {
    const { right, bottom, width } = (
      event.currentTarget as HTMLButtonElement
    ).getBoundingClientRect();
    setModalPosition({ x: right, y: bottom, width });
    setIsConfigModalOpen(true);
  };

  const closeConfigModal = () => {
    setIsConfigModalOpen(false);
  };
  const groupMetricsInPairs = (metrics) => {
    const pairs = [];
    for (let i = 0; i < metrics.length; i += 2) {
      pairs.push(metrics.slice(i, i + 2));
    }
    return pairs;
  };

  return (
    <div className={styles.dataBlock}>
      {!isMobile && (
        <table className={styles.table}>
          <thead className={styles.headerRow}>
            <tr>
              {metricsToDisplay.map((metric) => {
                const correctedName = normalizeMetricName(metric.name);
                return (
                  <th key={metric.key} className={styles.headerCell}>
                    <div className={styles.metricHeader}>
                      {correctedName}
                      {metric.icon && (
                        <Tooltip
                          title={metric.tooltip || "No description available"}
                          overlayClassName={styles.customTooltip}
                        >
                          <img
                            src={metric.icon}
                            alt="Info"
                            className={styles.infoIcon}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </th>
                );
              })}

              <button className={styles.headerCell} onClick={openConfigModal}>
                <Settings />
              </button>
            </tr>
          </thead>
          <tbody className={styles.dataRow}>
            <tr>
              <td className={styles.dataCell}>
                {" "}
                {formatValue(dataBlockProps.sessionTotal)}
              </td>
              <td className={styles.dataCell}>
                {formatValue(dataBlockProps.totalCVR)}
              </td>
              <td className={styles.dataCell} style={{ color: "#80c67a" }}>
                {formatValue(dataBlockProps.totalCTR)}
              </td>
              <td className={styles.dataCell}>
                {" "}
                {formatValue(dataBlockProps.pageViewsTotal)}
              </td>
              <td className={styles.dataCell} style={{ color: "#fe5858" }}>
                {formatValue(dataBlockProps.featuredOffer)}
              </td>
              <td className={styles.dataCell}>
                {" "}
                {formatValue(dataBlockProps.orderedProductSales)}
              </td>

              <td className={styles.dataCell}>
                {formatValue(dataBlockProps.totalPos.value)}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {isMobile &&
        groupMetricsInPairs(metricsToDisplay).map((pair, index) => (
          <div key={index} className={styles.rowContainer}>
            {pair.map((metric) => {
              const metricKey = metric.key;

              const dataValue = dataBlockProps[metricKey];
              const value =
                typeof dataValue === "object" && dataValue?.value !== undefined
                  ? dataValue.value
                  : dataValue || "N/A";

              let customColor = "#FFFFFF";
              if (metricKey === "unitsSessionPercentage") {
                customColor = "#80c67a";
              } else if (metricKey === "pageViewsTotal") {
                customColor = "#FE5858";
              } else if (metricKey === "orderedProductSales") {
                customColor = "#5295E0";
              }

              return (
                <div key={metricKey} className={styles.metricContainer}>
                  <div className={styles.label}>{metric.name}</div>
                  <div
                    className={styles.value}
                    style={{ color: customColor || "inherit" }}
                  >
                    {formatValue(value)}
                  </div>
                </div>
              );
            })}
          </div>
        ))}

      {isConfigModalOpen && (
        <div className={styles.overlay}>
          <div
            className={classNames(styles.modalContainer)}
            style={{
              top: modalPosition.y,
              left: modalPosition.x + 20,
              width: modalPosition.width,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <ConfigModalProduct
              buttonWidth={modalPosition.width}
              closeModal={closeConfigModal}
              onApply={handleApply}
              storeType="dataBlock"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DataBlock;
