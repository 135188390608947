import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { RadarChart } from "@components/Spider/RadarChart";
import { datasets } from "./mock";
import SwiperNavigation from "@components/ProductSwiper";
import ProductCard from "@components/ProductCard";
import { Phrase } from "src/store/insights/types";
import TotalOptimization from "@pages/CROModule/components/CROPage/components/Sections/TotalOptimization";
import { CROInfoType } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { OptimizationService } from "@services/optimization/optimization.services";
import { SpiderService } from "@services/spider/spider.services";

interface AspectsAndPrasesProps {
  phrases: Phrase[];
  spiderTitles?: string[];
  spiderValues?: number[];
}

const AspectsAndPrases: React.FC<AspectsAndPrasesProps> = ({
  spiderTitles = [],
  spiderValues = [],
  phrases = [],
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [croInfo, setCroInfo] = useState<CROInfoType | undefined>(undefined);
  const [totalPOS, setTotalPOS] = useState<string | null>(null);
  const [categoryName, setCategoryName] = useState<string | null>(null);

  const { selectedProduct } = useProductStore();
  const optimizationService = new OptimizationService();

  const dynamicDataset = [{ ...datasets[0], data: spiderValues }];

  const dynamicRating = dynamicDataset[0]?.data[activeIndex] || 0;
  const improvementValue = (0.2 + activeIndex * 0.1).toFixed(1);

  useEffect(() => {
    const fetchScore = async () => {
      if (!selectedProduct?.asin) return;
      try {
        const res = await optimizationService.getCROInfo(selectedProduct.asin);
        setCroInfo(res);
      } catch (error) {
        console.error("Error fetching CRO Info:", error);
      }
    };
    fetchScore();
  }, [selectedProduct]);

  const fetchPOS = async () => {
    if (!categoryName) return;
    const spider = new SpiderService();

    try {
      const spiderDots = await spider.getPos(categoryName);
      if (spiderDots && typeof spiderDots.total_pos === "number") {
        const roundedPOS = Math.round(spiderDots.total_pos);
        setTotalPOS(String(roundedPOS));
      }
    } catch (error) {
      console.error("Error fetching POS:", error);
    }
  };

  useEffect(() => {
    if (selectedProduct && categoryName) {
      fetchPOS();
    }
  }, [selectedProduct, categoryName]);

  const hasSpiderData = spiderTitles.length > 0 && spiderValues.length > 0;

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex < spiderTitles.length - 1 ? prevIndex + 1 : 0,
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : spiderTitles.length - 1,
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.aspects}>
        <h3>Aspects</h3>

        {hasSpiderData ? (
          <div className={styles.aspectsContainer}>
            <div className={styles.radarBox}>
              <RadarChart
                productOneTitles={spiderTitles}
                datasets={dynamicDataset}
                activeIndex={activeIndex}
                blockChanging={true}
                titles={spiderTitles}
                productOneValues={[]}
                productTwoValues={[]}
              />
            </div>
            <div className={styles.infoBox}>
              <SwiperNavigation
                headerText={spiderTitles[activeIndex]}
                onNext={handleNext}
                onPrev={handlePrev}
              />
              <ProductCard
                rating={dynamicRating}
                headerText={spiderTitles[activeIndex]}
                improvementText={`Improving this aspect you will improve review score by ${improvementValue}`}
              />
            </div>
          </div>
        ) : (
          <div className={styles.noData}>No data</div>
        )}
      </div>

      <div className={styles.pharesContainer}>
        {croInfo ? (
          <TotalOptimization
            croInfo={croInfo}
            totalPOS={totalPOS}
            brand={categoryName}
          />
        ) : (
          <div className={styles.noData}>No data</div>
        )}
      </div>
    </div>
  );
};

export default AspectsAndPrases;
