import { Product } from "@services/amazon/products/types";
import { create } from "zustand";

interface IProductState {
  selectedProduct: Product | null;
  selectedProductToCompare: Product[];
  setSelectedProduct: (product: Product) => void;
  setSelectedProductToCompare: (products: Product[]) => void;
  selectedProductToCompareMultiselect: (product: Product) => void;
}

const localProduct = localStorage.getItem("selectedProduct");

export const useProductStore = create<IProductState>((set) => ({
  selectedProduct: localProduct ? JSON.parse(localProduct) : null,
  selectedProductToCompare: [],
  setSelectedProduct: (product) => {
    localStorage.setItem("selectedProduct", JSON.stringify(product));
    set({ selectedProduct: product });
  },
  setSelectedProductToCompare: (products) =>
    set((state) => ({
      selectedProductToCompare: Array.isArray(products) ? products : [products],
    })),

  selectedProductToCompareMultiselect: (product) =>
    set((state) => ({
      selectedProductToCompare: [
        ...state.selectedProductToCompare.filter(
          (p) => p.asin !== product.asin,
        ),
        product,
      ],
    })),
}));
