import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  LabelListProps,
} from "recharts";

interface AsinData {
  asin: string;
  amazon: number;
  organic: number;
}

interface AsinBarChartProps {
  data: AsinData[];
}

export const AsinBarChart: React.FC<AsinBarChartProps> = ({ data }) => {
  const formatLabel = (value: number) => {
    if (value >= 1_000_000) {
      return `$${(value / 1_000_000).toFixed(1)}M`;
    }
    if (value >= 1_000) {
      return `$${(value / 1_000).toFixed(0)}K`;
    }
    return `$${value}`;
  };

  const renderLabelIfWideEnough = (props: LabelListProps) => {
    const { x, y, width, height, value } = props;
    if (!width || !height) return null;
    if (width > 50) {
      const numericVal = Number(value);
      return (
        <text
          x={(x ?? 0) + (width ?? 0) / 2}
          y={(y ?? 0) + (height ?? 0) / 2}
          fill="#FFFFFF"
          textAnchor="middle"
          dominantBaseline="middle"
          style={{ fontSize: "14px" }}
        >
          {formatLabel(numericVal)}
        </text>
      );
    }
    return null;
  };

  return (
    <div style={{ width: "100%", height: 350 }}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 20, right: 40, left: 10, bottom: 20 }}
          barSize={60}
        >
          <XAxis type="number" hide />
          <YAxis
            dataKey="asin"
            type="category"
            width={80}
            tick={{ fill: "#ffffff" }}
            axisLine={false}
            tickLine={false}
          />
          <Tooltip
            formatter={(value: number) => formatLabel(value)}
            labelStyle={{ color: "#ffffff" }}
            cursor={{ fill: "transparent" }}
            contentStyle={{ backgroundColor: "#1B1B1B", borderRadius: "8px" }}
          />
          <Bar
            dataKey="organic"
            stackId="sales"
            fill="#5295E0"
            radius={[4, 0, 0, 4]}
          >
            <LabelList dataKey="organic" content={renderLabelIfWideEnough} />
          </Bar>
          <Bar
            dataKey="amazon"
            stackId="sales"
            fill="#FE5858"
            radius={[0, 4, 4, 0]}
          >
            <LabelList dataKey="amazon" content={renderLabelIfWideEnough} />
            <LabelList
              dataKey={(obj: AsinData) => obj.amazon + obj.organic}
              position="right"
              formatter={(val) => (val ? formatLabel(Number(val)) : "")}
              fill="#FFFFFF"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
