import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.scss";
import BottomArrow from "@assets/icons/bottomArrow";
import LoadMoreButton from "@components/LoadMoreButton";
import { Products } from "@pages/Dashboard/mocks/products";
import Settings from "@assets/img/settings";
import ProductCode from "@components/ProductCode";
import ProblemsBlock from "@components/ProblemsBlock";
import { useModalStore } from "src/store/modal.state";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import { formattedKeys } from "src/utils/convertUtil";
import { SpiderService } from "@services/spider/spider.services";
import classNames from "classnames";
import { ConfigModalProduct } from "@components/config-modal-product";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { useMetricStore } from "src/store/metrict.state";
import { defaultMetrics } from "@components/config-modal-product/mock";
import { normalizeMetricName } from "@components/DataBlock/utils";
import { useNavigate } from "react-router-dom";
import { Spin, Tooltip } from "antd";
import InfoIcon from "@assets/icons/i.svg";
import { useIsMobile } from "src/utils/isMobile";
import { AspectOptimization } from "@pages/CROModule/components/Product/Optimisation/components/DefaultOptimization/Aspects";
import { ProductMetrics } from "./productItem";

type MetricWithProps = {
  name: string;
  key: string;
  icon?: string;
  showButton?: boolean;
  tooltip?: string;
};
const Table: React.FC = () => {
  const [visibleProducts, setVisibleProducts] = useState(10);
  const [expandedProductId, setExpandedProductId] = useState<number | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [products, setProducts] = useState<ProductWithImageLink[]>([]);
  const { setSelectedProduct } = useProductStore();
  const [loadingMore, setLoadingMore] = useState(false);
  const productsService = new ProductsService();
  const [productPosData, setProductPosData] = useState<{
    [asin: string]: string;
  }>({});
  const [showAspects, setShowAspects] = useState(false);

  const isMobile = useIsMobile();
  const { widgets } = useDashboardStore((state) => ({
    setWidgets: state.setWidgets,
    widgets: state.widgets,
  }));
  const [randomMap, setRandomMap] = useState<{ [asin: string]: number }>({});
  const [orderedSalesMap, setOrderedSalesMap] = useState<{
    [asin: string]: number;
  }>({});
  const [randomPosMap, setRandomPosMap] = useState<{ [asin: string]: number }>(
    {},
  );

  const { selectedMetric, setSelectedMetric } = useMetricStore();
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
    width: number;
  }>({ x: 0, y: 0, width: 0 });
  const navigate = useNavigate();

  const metricsToDisplay =
    selectedMetric.length > 0 ? selectedMetric : defaultMetrics;

  const { setProductOneSpiderTitles, setProductOneSpiderValues } =
    useInsightsStore();
  const [selectedAsin, setSelectedAsin] = useState<string | null>(null);
  const fetchedAsins = useRef<Set<string>>(new Set());

  const handleLoadMore = () => {
    setLoadingMore(true);
    setTimeout(() => {
      setLoadingMore(false);
      const newVisibleProducts = visibleProducts + 10;
      setVisibleProducts(newVisibleProducts);
      fetchPosForProducts(products.slice(visibleProducts, newVisibleProducts));
      setShowAspects(true);
    }, 1000);
  };

  const toggleExpand = (productId: number, product: ProductWithImageLink) => {
    if (expandedProductId === productId) {
      setExpandedProductId(null);
      setSelectedProduct(null);
      setSelectedAsin(null);
    } else {
      setExpandedProductId(productId);
      setSelectedProduct(product.asin);
      setSelectedAsin(product.asin);
    }
  };

  const fetchSpider = async (asin: string) => {
    try {
      const spider = new SpiderService();
      const spiderDots = await spider.getSpiderInfo(asin);
      if (spiderDots && Object.keys(spiderDots).length > 0) {
        setProductOneSpiderTitles(formattedKeys(spiderDots));
        setProductOneSpiderValues(
          Object.values(spiderDots).map((value) =>
            parseFloat(value.toFixed(1)),
          ),
        );
      } else {
        setProductOneSpiderTitles([]);
        setProductOneSpiderValues([]);
      }
    } catch (err) {
      setProductOneSpiderTitles([]);
      setProductOneSpiderValues([]);
    }
  };

  useEffect(() => {
    if (selectedAsin) {
      fetchSpider(selectedAsin);
    } else {
      setProductOneSpiderTitles([]);
      setProductOneSpiderValues([]);
    }
  }, [selectedAsin]);

  const fetchProducts = async (page: number, limit: number = 10) => {
    try {
      const params = { limit, page: 1 };
      const response = await productsService.getOwnProducts(params);
      if (response.length > 0) {
        setProducts(response);

        if (response.length === limit && limit < 32) {
          fetchProducts(page, limit === 8 ? 16 : 32);
        }
      }
      fetchPosForProducts(response.slice(0, 10));
    } catch (err) {
      console.error("Error fetching products:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchPosForProducts = (productsBatch: ProductWithImageLink[]) => {
    const spider = new SpiderService();

    productsBatch.forEach(async (product) => {
      if (!fetchedAsins.current.has(product.asin)) {
        try {
          const spiderDots = await spider.getCroInfoPos(product.asin);
          const posValue = Math.round(spiderDots.total_pos).toString();

          setProductPosData((prevPosData) => ({
            ...prevPosData,
            [product.asin]: posValue,
          }));
          fetchedAsins.current.add(product.asin);
        } catch (err) {
          setProductPosData((prevPosData) => ({
            ...prevPosData,
            [product.asin]: "N/A",
          }));
        }
      }
    });
  };

  useEffect(() => {
    if (products.length > 0 && visibleProducts === 10) {
      setVisibleProducts(Math.min(10, products.length));
    }
  }, [products]);
  const openConfigModal = (event: React.MouseEvent) => {
    const { right, bottom, width } = (
      event.currentTarget as HTMLButtonElement
    ).getBoundingClientRect();
    setModalPosition({ x: right, y: bottom, width });
    setIsConfigModalOpen(true);
  };

  const closeConfigModal = () => {
    setIsConfigModalOpen(false);
  };
  const handleApplyMetrics = (selectedMetrics: IWidget[]) => {
    setSelectedMetric(selectedMetrics);
    setIsConfigModalOpen(false);
  };
  const handleTitleClick = async (product: ProductWithImageLink) => {
    setSelectedProduct(product);
    navigate("/my-product");
  };

  const filteredMetrics: MetricWithProps[] = metricsToDisplay.filter(
    (metric) => metric.name.toLowerCase() !== "pos",
  );

  const isPosSelected = metricsToDisplay.some(
    (metric) => metric.name.toLowerCase() === "pos",
  );

  useEffect(() => {
    if (products.length > 0) {
      const newRandomMap: { [asin: string]: number } = {};
      const newOrderedSalesMap: { [asin: string]: number } = {};
      const newRandomPosMap: { [asin: string]: number } = {};

      products.forEach((product) => {
        newRandomMap[product.asin] = Number(
          (Math.random() * (4 - 1) + 1).toFixed(2),
        );
        newOrderedSalesMap[product.asin] = Number(
          (Math.random() * (7 - 4) + 4).toFixed(2),
        );
        newRandomPosMap[product.asin] =
          Math.floor(Math.random() * (89 - 55 + 1)) + 55;
      });

      setRandomMap(newRandomMap);
      setOrderedSalesMap(newOrderedSalesMap);
      setRandomPosMap(newRandomPosMap);
    }
  }, [products]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <div className={styles.loading}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          {!isMobile ? (
            <table className={styles.table}>
              <thead>
                <tr>
                  <th className={styles.headerCell}>PRODUCT</th>
                  {filteredMetrics.map((metric) => {
                    const correctedName = normalizeMetricName(metric.name);
                    return (
                      <th key={metric.key} className={styles.headerCell}>
                        <div className={styles.metricHeader}>
                          {correctedName}
                          {metric.showButton && metric.icon && (
                            <Tooltip
                              title={
                                metric.tooltip || "No description available"
                              }
                              overlayClassName={styles.customTooltip}
                            >
                              <img
                                src={metric.icon}
                                alt="Info"
                                className={styles.infoIcon}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </th>
                    );
                  })}
                  {isPosSelected && (
                    <th className={styles.headerCell}>
                      <div className={styles.metricHeader}>
                        POS
                        <Tooltip title="Product Optimization Score">
                          <img
                            src={InfoIcon}
                            alt="Info"
                            className={styles.infoIcon}
                          />
                        </Tooltip>
                      </div>
                    </th>
                  )}
                  <button
                    className={styles.headerCell}
                    onClick={openConfigModal}
                  >
                    <Settings />
                  </button>
                </tr>
              </thead>
              <tbody>
                {products.slice(0, visibleProducts).map((product, index) => {
                  const mockData = Products[index % Products.length];
                  const posValue = productPosData[product.asin] || "Loading...";

                  return (
                    <React.Fragment key={index}>
                      <tr
                        className={`${styles.row} ${
                          expandedProductId === index ? styles.expanded : ""
                        }`}
                      >
                        <td className={styles.cell}>
                          <div className={styles.productInfo}>
                            <img
                              src={
                                product.image_link ||
                                "/src/assets/img/noImage.jpg"
                              }
                              alt="Product Image"
                              className={styles.productImage}
                            />
                            <div className={styles.productDetails}>
                              <p
                                onClick={() => handleTitleClick(product)}
                                style={{ cursor: "pointer" }}
                              >
                                {product.item_name || "No description"}
                              </p>
                              <ProductCode code={product.asin || "N/A"} />
                            </div>
                          </div>
                        </td>
                        <td className={styles.cell}>
                          <div>{`$${mockData.sessionTotal}`}</div>
                        </td>

                        <td className={styles.cell}>
                          <div>{Math.round(mockData.pageViewsTotal)}</div>
                        </td>

                        <td className={styles.cell}>
                          <div>{Math.round(mockData.featuredOffer)}</div>
                        </td>
                        <td className={styles.cell}>
                          <div>{`${randomMap[product.asin] || 1}%`}</div>
                        </td>

                        <td className={styles.cell}>
                          <div>
                            {Math.round(mockData.unitsSessionPercentage)}
                          </div>
                        </td>
                        <td className={styles.cell}>
                          <div>{`${orderedSalesMap[product.asin] || 4}%`}</div>
                        </td>
                        <td className={styles.cell}>
                          <div>{randomPosMap[product.asin] || 55}</div>
                        </td>
                        <td className={styles.cell}>
                          <button
                            className={`${styles.expandButton} ${
                              expandedProductId === index ? styles.rotated : ""
                            }`}
                            onClick={() => toggleExpand(index, product)}
                          >
                            <BottomArrow />
                          </button>
                        </td>
                      </tr>
                      {expandedProductId === index && (
                        <tr className={styles.expandedRow}>
                          <td colSpan={9} className={styles.expandedCell}>
                            <ProblemsBlock
                              topProblems={mockData.topProblems || []}
                            />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className={styles.mobileContainer}>
              {products.slice(0, visibleProducts).map((product, index) => (
                <div key={index} className={styles.card}>
                  <div className={styles.cardHeader}>
                    <div className={styles.img}>
                      <img
                        src={
                          product.image_link || "/src/assets/img/noImage.jpg"
                        }
                        alt={product.item_name || "Product image"}
                        className={styles.productImage}
                      />
                    </div>
                    <div className={styles.productDetails}>
                      <p
                        onClick={() => handleTitleClick(product)}
                        style={{ cursor: "pointer" }}
                      >
                        {product.item_name || "No description"}
                      </p>
                      <ProductCode code={product.asin} />
                    </div>
                    <button
                      className={`${styles.expandButton} ${
                        expandedProductId === index ? styles.rotated : ""
                      }`}
                      onClick={() => toggleExpand(index, product)}
                    >
                      <BottomArrow />
                    </button>
                  </div>

                  <div className={styles.cardBody}>
                    <ProductMetrics
                      key={index}
                      mockData={Products[index % Products.length]}
                      isMobile
                      metrics={defaultMetrics}
                    />
                  </div>

                  {expandedProductId === index && (
                    <div className={styles.aspects}>
                      <AspectOptimization isWhatIf={true} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {loadingMore && (
            <div className={styles.loading}>
              <Spin size="large" />
            </div>
          )}

          {visibleProducts < products.length && (
            <LoadMoreButton onClick={handleLoadMore} />
          )}
          {isConfigModalOpen && (
            <div className={styles.overlay}>
              <div
                className={classNames(styles.modalContainer)}
                style={{
                  top: modalPosition.y,
                  left: modalPosition.x,
                  width: modalPosition.width,
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <ConfigModalProduct
                  buttonWidth={300}
                  closeModal={() => setIsConfigModalOpen(false)}
                  initialWidgets={widgets}
                  setWidgets={() => {}}
                  onApply={handleApplyMetrics}
                  maxSelectedWidgets={12}
                  storeType="table"
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Table;
