import { Api } from "src/utils/api";
import { AMAZON_URL } from "../amazonUrl";
import { useProductsStore } from "src/store/product.state";
import {
  ProductParams,
  ProductWithImageLink,
  ProductWithPictureUrl,
  SearchParams,
  ProductDataByAsin,
  ProductMaterials,
  Brand,
} from "./types";
import yourProductImage from "@assets/img/product1.png";
import competitorProductImage from "@assets/img/product2.png";
import noPhoto from "@assets/img/noImage.jpg";

export class ProductsService extends Api {
  constructor() {
    super(AMAZON_URL);
  }

  private formatParams(
    params: ProductParams | SearchParams,
  ): Record<string, string | number> {
    return Object.fromEntries(
      Object.entries(params).map(([key, value]) => [key, String(value)]),
    );
  }

  public async getOwnProducts(
    params: ProductParams = {},
  ): Promise<ProductWithImageLink[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithImageLink[]>(
      "/products/own",
      formattedParams,
    );
    const isRootRoute = window.location.pathname === "/";

    response.map((product) => {
      if (product.image_link === null) {
        product.image_link = isRootRoute ? noPhoto : yourProductImage;
      }
    });
    useProductsStore.getState().setProductsOwn(response);
    return response;
  }

  public async getOwnProductsBrand(
    params: ProductParams = {},
  ): Promise<Brand[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<Brand[]>(
      "/products/own/brands",
      formattedParams,
    );
    return response;
  }

  public async searchOwnProducts(
    params: SearchParams,
  ): Promise<ProductWithImageLink[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithImageLink[]>(
      "/products/own/search",
      formattedParams,
    );
    response.map((product) => {
      if (product.image_link === null) {
        product.image_link = yourProductImage;
      }
    });
    useProductsStore.getState().setProductsOwn(response);
    return response;
  }

  public async getPublicProduct(
    asin: string[],
  ): Promise<ProductWithPictureUrl> {
    const product = await this.get<ProductWithPictureUrl>(
      `/products/public/${asin}`,
    );
    if (product.picture_url === null) {
      product.picture_url = competitorProductImage;
    }
    useProductsStore.getState().setProductPublic(product);
    return product;
  }
  public async postPublicProduct(
    ...asin: string[]
  ): Promise<ProductWithPictureUrl[]> {
    const path = "/products/public";

    const requestBody = asin.slice(0, 6);

    const products = await this.post<ProductWithPictureUrl[]>(
      path,
      requestBody,
    );
    products.forEach((product) => {
      if (product.picture_url === null) {
        product.picture_url = competitorProductImage;
      }
      useProductsStore.getState().setProductPublic(product);
    });

    return products;
  }

  public async getProductDataByAsin(
    asin: string,
  ): Promise<ProductDataByAsin | null> {
    try {
      const product = await this.get<ProductDataByAsin>(
        `/products/own/${asin}`,
      );
      if (product) {
        useProductsStore.getState().setProductDataByAsin(product);
      }

      return product;
    } catch (error) {
      console.error("Error fetching product data by ASIN:", error);
      return null;
    }
  }

  public async getProductDataByAsinImgs(
    asin: string[],
  ): Promise<ProductMaterials | null> {
    try {
      const product = await this.get<ProductMaterials>(
        `products/own/${asin}/images`,
      );
      if (product) {
        useProductsStore.getState().setProductMaterials(product);
      }

      return product;
    } catch (error) {
      console.error("Error fetching product data by ASIN:", error);
      return null;
    }
  }

  public async getProductDataByAsinVideos(
    asin: string[],
  ): Promise<ProductMaterials | null> {
    try {
      const product = await this.get<ProductMaterials>(
        `products/public/${asin}/videos`,
      );
      if (product) {
        useProductsStore.getState().setProductMaterials(product);
      }

      return product;
    } catch (error) {
      console.error("Error fetching product data by ASIN:", error);
      return null;
    }
  }

  public async getPublicProducts(
    params: ProductParams = {},
  ): Promise<ProductWithPictureUrl[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithPictureUrl[]>(
      "/products/public",
      formattedParams,
    );
    response.map((product) => {
      if (product.picture_url === null) {
        product.picture_url = competitorProductImage;
      }
    });
    useProductsStore.getState().setProductsPublic(response);
    return response;
  }

  public async searchPublicProducts(
    params: SearchParams,
  ): Promise<ProductWithPictureUrl[]> {
    const formattedParams = this.formatParams(params);
    const response = await this.get<ProductWithPictureUrl[]>(
      "/products/public/search",
      formattedParams,
    );
    response.map((product) => {
      if (product.picture_url === null) {
        product.picture_url = competitorProductImage;
      }
    });
    useProductsStore.getState().setProductsPublic(response);
    return response;
  }
}
