import React from "react";
import styles from "./styles.module.scss";
import { mockData } from "./mock";
import AddKeywords from "@assets/icons/addKeywords";

interface Image {
  link: string;
  variant: string;
  width: number;
  height: number;
}

interface GenerateProps {
  onGenerateClick: () => void;
  title: string | null;
  points: string[] | null;
  images: Image[] | null;
}

const Generate: React.FC<GenerateProps> = ({ title, points, images }) => {
  const pointsText = points ? points.join("\n") : "";

  return (
    <div className={styles.generate}>
      <div className={styles.title}>
        <label htmlFor="title">Title</label>
        <input type="text" placeholder="Title" name="title" value={title} />
      </div>
      <div className={styles.bulletPoints}>
        <label htmlFor="bulletPoints">Bullet Points</label>
        <textarea
          placeholder="Bullet Points"
          name="bulletPoints"
          value={!points ? mockData.EmptyBulletPoints : pointsText}
        />
      </div>
      <div className={styles.images}>
        <p>Images</p>
        <div className={styles.box}>
          {images
            ?.filter((image) => image.width <= 100 && image.height <= 100)
            .map((image, index) => (
              <img
                key={index}
                src={image.link}
                alt={image.variant || "Image"}
              />
            ))}
          <div className={styles.addImgIcon}>
            <AddKeywords />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Generate;
