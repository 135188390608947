import ModalBox from "@components/ModalBox";
import { DataProps, ItemData } from "../../mock";
import { useState } from "react";
import { FormProduct } from "./FormProduct";
import mockIMG from "@assets/img/mock3.png";

interface NewProductProps {
  isOpen: boolean;
  onClose: () => void;
  data: DataProps;
  setData: (value: DataProps) => void;
}

export const NewProduct = ({
  isOpen,
  onClose,
  data,
  setData,
}: NewProductProps) => {
  const [newItem, setNewItem] = useState<ItemData>({
    title: "",
    asin: "",
    variations: false,
    img: mockIMG,
    rating: 0,
    value: 0,
    status: "inactive",
  });

  const addNewItem = () => {
    setData({
      data: [newItem, ...data.data],
    });
    onClose();
  };

  return (
    <ModalBox isOpen={isOpen} onClose={onClose} title={"Add new product"}>
      <FormProduct
        setAddNewItem={addNewItem}
        newItem={newItem}
        setNewItem={setNewItem}
      />
    </ModalBox>
  );
};
