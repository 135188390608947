import React, { useState } from "react";
import styles from "./styles.module.scss";
import SliderReset from "@assets/icons/slider-reset";
import { useGlobalResetStore } from "src/store/global-reset.store";
import classNames from "classnames";

interface GlobalResetButtonProps {
  customReset?: () => void;
}

export const GlobalResetButton: React.FC<GlobalResetButtonProps> = ({
  customReset,
}) => {
  const { triggerReset } = useGlobalResetStore();
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    if (customReset) {
      customReset();
    } else {
      triggerReset();
    }
    setIsActive(true);

    setTimeout(() => {
      setIsActive(false);
    }, 500);
  };

  return (
    <button
      className={classNames(styles.resetButton, { [styles.active]: isActive })}
      onClick={handleClick}
      title="Reset all sliders to default"
    >
      <SliderReset />
    </button>
  );
};
