import { CardChartData } from "@components/CardWithChart";

export type RadarData = {
  design: number;
  brand: number;
  usability: number;
  quality: number;
  price: number;
  noise: number;
};
export type Sender = "user" | "assistant";

export interface Message {
  id: string;
  text: string;
  sender: Sender;
  type: ToolType;
  data?: RadarData | CardChartData[] | number;
}

export enum ToolType {
  TEXT = "text",
  METRIC_VALUE = "metric_value",
  PERFORMANCE_CHART = "performance_chart",
  PRODUCT_SPIDER = "get_product_spider",
  ADS_SALES_IMPRESSIONS = "ads_sales_impressions",
  ADS_SALES_CLICKS = "ads_sales_clicks",
  ADS_SALES_ORDERS = "ads_sales_orders",
  ADS_SALES_UNITS = "ads_sales_units",
  ADS_SALES_SALES = "ads_sales_sales",
  ADS_SALES_SPEND = "ads_sales_spend",
  ADS_SALES_ACOS = "ads_sales_acos",
  ADS_SALES_ROAS = "ads_sales_roas",
  ADS_SALES_CPC = "ads_sales_cpc",
  ADS_SALES_CVR = "ads_sales_cvr",
  ADS_SALES_CTR = "ads_sales_ctr",
  SALES_PERFORMANCE_IMPRESSIONS = "sales_performance_impressions",
  SALES_PERFORMANCE_CLICKS = "sales_performance_clicks",
  SALES_PERFORMANCE_ORDERS = "sales_performance_orders",
  SALES_PERFORMANCE_SALES = "sales_performance_sales",
  SALES_PERFORMANCE_SPEND = "sales_performance_spend",
  SALES_PERFORMANCE_ACOS = "sales_performance_acos",
  SALES_PERFORMANCE_ROAS = "sales_performance_roas",
  SALES_PERFORMANCE_CPC = "sales_performance_cpc",
  SALES_PERFORMANCE_CVR = "sales_performance_cvr",
  SALES_PERFORMANCE_CTR = "sales_performance_ctr",
  SALES_PERFORMANCE_TOTAL_SALES = "sales_performance_total-sales",
}

export enum PerformanceType {
  TOTAL_SALES = "total-sales",
  AD_SPEND = "ad-spend",
  AD_SALES = "ad-sales",
  CLICKS = "clicks",
  IMPRESSIONS = "impressions",
  UNIT_SOLD = "unit-sold",
  ACOS = "acos",
  ROAS = "roas",
  CPC = "cpc",
  CVR = "cvr",
  CTR = "ctr",
}

export enum ValueFormat {
  CURRENCY = "currency",
  CURRENCY_DECIMAL = "currency-decimal",
  PERCENT = "percent",
  PERCENT_DECIMAL = "percent-decimal",
  DECIMAL = "decimal",
  NUMBER = "number",
}

export type Tool = {
  type: ToolType;
  data: string;
};

export type AgentApiResponse = {
  success: boolean;
  tools: Tool[];
  finalMessage: string;
  role: "assistant";
  id: string;
  buttons: PromptButton[][] | null;
};

export type PromptButton = {
  id: string;
  question: string;
  label: string;
};

export const PREFIX_METRIC = "ads_sales_";
export const PREFIX_PERFORMANCE = "sales_performance_";
