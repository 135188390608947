import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import styles from "./new-styles.module.scss";
import { toDataURLByFile } from "src/utils/toDataURL";
import {
  generateImageByPromptAndAsset,
  generateImageByPrompt,
  getPlatforms,
  getTemplates,
  removeBackground,
  getImageProductDescription,
} from "./api";
import { Platform, PlatformGen, Template } from "./types";
import classNames from "classnames";
import Header from "./сomponents/Header";
import SettingsTab from "./сomponents/Settings";
import Nav from "./сomponents/Nav";
import AssetsTab from "./сomponents/Assets";
import AsinTab from "./сomponents/Asin";
import "./global.scss";
import Canvas from "./сomponents/Canvas";
import GenerateTab from "./сomponents/Generate";
import { useProductsStore } from "src/store/product.state";
import { AmazonService } from "@services/amazon/amazon.service";
import { useProductStore } from "src/store/overviewProduct.state";
import { navItems, SIZE_LARGE_IMAGE } from "./constants";
import MasksModal from "./сomponents/MasksModal";
import { listImages, staticMasks, generatedStatic } from "./mock";
import {
  checkFirstVisit,
  urlToFile,
  base64ToFile,
  convertImageUrlToBase64,
} from "./utils";
import UserGuideModal from "./сomponents/UserGuideModal";
import { useParams } from "react-router-dom";
import { useMaintenance } from "src/store/maintenance.state";
import { useUserStore } from "src/store/user.state";
import { GenerateService } from "@services/generate/generate.service";
import { LoadingOverlay } from "./сomponents/LoadingOverlay";
import { NotificationService } from "@services/generate/notification.service";

const PhotoOptimisationDetails: React.FC = () => {
  const { productMaterials, setProductMaterials, productDataByAsin } =
    useProductsStore();
  const { selectedProduct } = useProductStore();
  const [templates, setTemplates] = useState<{
    original: Template[];
    transformed: Template[];
  }>({ original: [], transformed: [] });
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [activeNavIndex, setActiveNavIndex] = useState(0);
  const [isLoadingTask, setIsLoadingTask] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingAssetImage, setIsLoadingAssetImage] = useState(false);
  const [isLoadingImageGeneration, setLoadingImageGeneration] = useState(false);
  const [activeImage, setActiveImage] = useState<{
    mask: string;
    image: string;
  } | null>(null);
  const [activeAssetImage, setActiveAssetImage] = useState<{
    mask: string;
    image: string;
  } | null>(null);
  const [generatedImage, setGeneratedImage] = useState<string>("");
  const [customPrompt, setCustomPrompt] = useState<string>("");
  const [applyImg, setApplyImg] = useState(false);
  const [draggableAssets, setDraggableAssets] = useState<
    {
      image: string;
      style: CSSProperties;
    }[]
  >([]);
  const [activeBtn, setActiveBtn] = useState<HTMLElement | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [masks, setMasks] = useState<{ image: string; mask: string }[]>([]);
  const [formatCanvasResult, setFormatCanvasResult] = useState<string>("");
  const [activeImageIndex, setActiveImageIndex] = useState(-1);
  // UserGuide
  const [showUserGuide, setShowUserGuide] = useState(true);
  const [currUserStepGuide, setCurrUserStepGuide] = useState(0);
  const [showUserGuideVideos, setShowUserGuideVideos] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [fileId, setFileId] = useState(localStorage.getItem("curr_file_id"));
  const [taskStatus, setTaskStatus] = useState("");
  const generateService = new GenerateService();
  const notificationService = new NotificationService();

  const asinImages = useMemo(() => {
    return (
      productMaterials?.images
        ?.filter(
          (img) =>
            img.width >= SIZE_LARGE_IMAGE && img.height >= SIZE_LARGE_IMAGE,
        )
        .map((img) => img.link) ?? []
    );
  }, [productMaterials?.images]);

  useEffect(() => {
    const fetchProductData = async () => {
      if (showUserGuide) return;

      const asin = selectedProduct?.asin || id;

      if (!asin) {
        return;
      }

      try {
        if (!productDataByAsin || productDataByAsin.asin !== asin) {
          await AmazonService.products.getProductDataByAsin([asin]);
        }

        if (!productMaterials || productMaterials.asin !== asin) {
          const [imagesData, videosData] = await Promise.all([
            AmazonService.products.getProductDataByAsinImgs([asin]),
            AmazonService.products.getProductDataByAsinVideos([asin]),
          ]);

          setProductMaterials({
            images: imagesData as any,
            videos: videosData as any,
            asin,
          });
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductData();
  }, [selectedProduct, showUserGuide]);

  const handleActiveTab = (index: number) => {
    if (isLoadingImage || isLoadingAssetImage || isLoadingImageGeneration) {
      return;
    }
    setActiveNavIndex(index);
  };

  const handleChangeImage = async (index: number) => {
    if (showUserGuide) return;

    const imageUrl = asinImages[index];
    const filename = imageUrl.split("/").pop() || "image.jpg";
    const mimeType = "image/jpeg";

    setIsLoadingTask(true);
    setTaskStatus("Processing...");
    resetGeneratedImage();

    try {
      const imageFile = await urlToFile(imageUrl, filename, mimeType);
      const { file_id } = await generateService.fileUpload(imageFile);
      setFileId(file_id);

      const removebgTask =
        await generateService.removeBackgroundByFileId(file_id);
      const descTask =
        await generateService.getProductDescriptionByFileId(file_id);

      const removebgTaskId = removebgTask.task_id;
      const descTaskId = descTask.task_id;

      console.log(
        `Started polling for tasks: removebg (${removebgTaskId}), desc (${descTaskId})`,
      );

      const completedTasks = new Set<string>();
      let statusUpdated = false;

      await notificationService.waitForTasks(
        [removebgTaskId, descTaskId],
        async (task) => {
          if (
            task.payload?.task_id === removebgTaskId &&
            !completedTasks.has(removebgTaskId)
          ) {
            console.log(`Remove BG task (${removebgTaskId}) completed!`);

            const results = task.payload.results?.[0]?.result;
            if (results?.length > 0) {
              const formattedMasks = await Promise.all(
                results.map(async (result: any) => ({
                  image: await convertImageUrlToBase64(result.image.url),
                  mask: await convertImageUrlToBase64(result.mask.url),
                })),
              );

              setMasks(formattedMasks);
              setShowModal(true);
              completedTasks.add(removebgTaskId);
            } else {
              console.error("No masks found in response!");
            }

            if (!statusUpdated) {
              setTaskStatus("Processing...");
              statusUpdated = true;
            }
          } else if (
            task.payload?.task_id === descTaskId &&
            !completedTasks.has(descTaskId)
          ) {
            console.log(`Description task (${descTaskId}) completed!`);

            setTemplates((prev) => ({
              ...prev,
              transformed: prev.original.map((t) => ({
                ...t,
                list: t.list.map((el) =>
                  el.replace(
                    "[product]",
                    task.payload.results?.[0]?.result || "",
                  ),
                ),
              })),
            }));
            completedTasks.add(descTaskId);

            if (!statusUpdated) {
              setTaskStatus("Processing...");
              statusUpdated = true;
            }
          }

          if (completedTasks.size < 2) {
            setTimeout(() => {
              if (!statusUpdated) setTaskStatus("Processing...");
            }, 1500);
          } else {
            console.log("All tasks completed successfully.");
            setIsLoadingTask(false);
          }
        },
      );
    } catch (error) {
      console.error("Error in handleChangeImage:", error);
      setTaskStatus("Task Failed");
      setTimeout(() => setIsLoadingTask(false), 3000);
    }
  };

  const handleUploadImage = async (file: File | null) => {
    if (showUserGuide || !file) return;

    setIsLoadingTask(true);
    setTaskStatus("Processing...");
    resetGeneratedImage();

    try {
      const { file_id } = await generateService.fileUpload(file);
      setFileId(file_id);

      const removebgTask =
        await generateService.removeBackgroundByFileId(file_id);
      const descTask =
        await generateService.getProductDescriptionByFileId(file_id);

      const removebgTaskId = removebgTask.task_id;
      const descTaskId = descTask.task_id;

      console.log(
        `Started polling for tasks: removebg (${removebgTaskId}), desc (${descTaskId})`,
      );

      const completedTasks = new Set<string>();
      let statusUpdated = false;

      await notificationService.waitForTasks(
        [removebgTaskId, descTaskId],
        async (task) => {
          if (
            task.payload?.task_id === removebgTaskId &&
            !completedTasks.has(removebgTaskId)
          ) {
            console.log(`Remove BG task (${removebgTaskId}) completed!`);

            const results = task.payload.results?.[0]?.result;
            if (results?.length > 0) {
              const formattedMasks = await Promise.all(
                results.map(async (result: any) => ({
                  image: await convertImageUrlToBase64(result.image.url),
                  mask: await convertImageUrlToBase64(result.mask.url),
                })),
              );

              setMasks(formattedMasks);
              setShowModal(true);
              completedTasks.add(removebgTaskId);
            } else {
              console.error("No masks found in response!");
            }

            if (!statusUpdated) {
              setTaskStatus("Processing...");
              statusUpdated = true;
            }
          } else if (
            task.payload?.task_id === descTaskId &&
            !completedTasks.has(descTaskId)
          ) {
            console.log(`Description task (${descTaskId}) completed!`);

            setTemplates((prev) => ({
              ...prev,
              transformed: prev.original.map((t) => ({
                ...t,
                list: t.list.map((el) =>
                  el.replace(
                    "[product]",
                    task.payload.results?.[0]?.result || "",
                  ),
                ),
              })),
            }));
            completedTasks.add(descTaskId);

            if (!statusUpdated) {
              setTaskStatus("Processing...");
              statusUpdated = true;
            }
          }

          if (completedTasks.size < 2) {
            setTimeout(() => {
              if (!statusUpdated) setTaskStatus("Processing...");
            }, 1500);
          } else {
            console.log("All tasks completed successfully.");
            setIsLoadingTask(false);
          }
        },
      );
    } catch (error) {
      console.error("Error in handleUploadImage:", error);
      setTaskStatus("Task Failed");
      setTimeout(() => setIsLoadingTask(false), 3000);
    }
  };

  const handleChangeAsset = async (newButton: HTMLElement) => {
    if (showUserGuide) return;

    const image = newButton.dataset.image;
    const mask = newButton.dataset.mask;

    setIsLoadingAssetImage(true);
    setActiveAssetImage({ image, mask });
    resetGeneratedImage();

    const result = { image };

    setIsLoadingAssetImage(false);

    newButton.style.borderColor = "#5295E0";
    setActiveBtn(newButton);

    const newImage = {
      image: result.image,
      style: {
        top: "100%",
        left: "180px",
        width: "150px",
        height: "150px",
      },
    };

    if (activeBtn) {
      activeBtn.style.borderColor = "";

      const lastIndex = draggableAssets.length - 1;
      setDraggableAssets(
        draggableAssets.map((image, i) => (i === lastIndex ? newImage : image)),
      );
      return;
    }

    setDraggableAssets([...draggableAssets, newImage]);
  };

  const handleMouseUpDraggableItems = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    const target = event.target as HTMLElement;
    draggableAssets[index].style = {
      top: target.style.top,
      left: target.style.left,
      width: target.style.width,
      height: target.style.height,
    };

    setDraggableAssets([...draggableAssets]);

    if (activeBtn === null) {
      return;
    }

    activeBtn.style.borderColor = "";
    setActiveBtn(null);
  };

  useEffect(() => {
    if (showUserGuide) return;

    getTemplates().then((templates) => {
      setTemplates({ original: templates, transformed: templates });
    });
    getPlatforms().then(setPlatforms);
  }, [showUserGuide]);

  const handleGenerateImage = async (prompt: string[]) => {
    if (showUserGuide) {
      setGeneratedImage(generatedStatic);
      return;
    }

    if (activeImage === null) {
      return;
    }

    setIsLoadingImage(true);
    setLoadingImageGeneration(true);
    setIsLoadingTask(true);
    setTaskStatus("Processing...");

    try {
      const imageFile = base64ToFile(formatCanvasResult, "generated-image.png");
      const { file_id } = await generateService.fileUpload(imageFile);
      console.log(`Uploaded image. File ID: ${file_id}`);
      let assetID = "";
      let assetMaskID = "";

      if (activeAssetImage?.image && activeAssetImage?.mask) {
        try {
          const [assetImageFile, assetMaskFile] = [
            base64ToFile(activeAssetImage.image, "asset-image.png"),
            base64ToFile(activeAssetImage.mask, "asset-mask.png"),
          ];

          const [assetImageResponse, assetMaskResponse] = await Promise.all([
            generateService.fileUpload(assetImageFile),
            generateService.fileUpload(assetMaskFile),
          ]);

          assetID = assetImageResponse.file_id;
          assetMaskID = assetMaskResponse.file_id;
        } catch (uploadError) {
          console.error("Error uploading asset images:", uploadError);
        }
      }

      const removeBgTask =
        await generateService.removeBackgroundByFileId(file_id);
      const removeBgTaskId = removeBgTask.task_id;
      console.log(
        `Background removal task created. Task ID: ${removeBgTaskId}`,
      );

      const file_ids = await new Promise<{ img: string; mask: string }>(
        (resolve, reject) => {
          notificationService.waitForTasks([removeBgTaskId], async (task) => {
            if (task.payload?.task_id === removeBgTaskId) {
              console.log(`Background removal completed!`);

              const results = task.payload?.results?.[0]?.result;
              if (results?.length > 0) {
                resolve({
                  img: results[0].image.file_id,
                  mask: results[0].mask.file_id,
                });
              } else {
                console.error("No mask found in response!");
                reject(new Error("Mask processing failed."));
              }

              try {
                await notificationService.setNotificationAck(task.uuid);
                console.log(
                  `Acknowledged notification for task ${removeBgTaskId}`,
                );
              } catch (ackError) {
                console.error(
                  `Failed to acknowledge notification for task ${removeBgTaskId}:`,
                  ackError,
                );
              }
            }
          });
        },
      );

      const deliveryPrompt = Array.isArray(prompt) ? prompt.join(" ") : prompt;
      const generateTask = await generateService.generateImageByMaskAndProps(
        file_ids.img,
        file_ids.mask,
        deliveryPrompt,
        "",
        assetID,
        assetMaskID,
        1,
      );

      if (!generateTask || !generateTask.task_id) {
        throw new Error("Failed to retrieve generateTaskId.");
      }

      const generateTaskId = generateTask.task_id;
      console.log(`Image generation task created. Task ID: ${generateTaskId}`);

      await notificationService.waitForTasks([generateTaskId], async (task) => {
        if (task.payload?.task_id === generateTaskId) {
          console.log(`Image generation completed!`);
          console.log("Generated image:", task.payload.results[0].result.url);
          setGeneratedImage(task.payload.results[0].result?.[0].url);

          try {
            await notificationService.setNotificationAck(task.uuid);
            console.log(`Acknowledged notification for task ${generateTaskId}`);
          } catch (ackError) {
            console.error(
              `Failed to acknowledge notification for task ${generateTaskId}:`,
              ackError,
            );
          }
          console.log(task.payload);
          setTaskStatus("Image Generation - Completed");
        }
      });

      console.log("All tasks completed successfully.");
    } catch (error) {
      console.error("Error in handleGenerateImage:", error);
      setTaskStatus("Image Generation Failed");
    } finally {
      setLoadingImageGeneration(false);
      setIsLoadingImage(false);
      setIsLoadingTask(false);
    }
  };

  const onSave = () => {
    if (generatedImage === "") {
      return;
    }

    const href = generatedImage;

    const link = document.createElement("a");
    link.href = href;
    link.download = "Download.png";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleApplyImage = (image: string) => {
    setFormatCanvasResult(image);
    image && setApplyImg(true);
    resetGeneratedImage();
  };

  const resetGeneratedImage = () => {
    setGeneratedImage("");
  };

  const handleNewRender = () => {
    resetGeneratedImage();
  };

  useEffect(() => {
    checkFirstVisit("photoOptimisationDetailsFirstVisit", setShowUserGuide);
  }, []);

  useEffect(() => {
    if (!showUserGuide) {
      setActiveNavIndex(0);
      setGeneratedImage("");
      setApplyImg(false);
      setActiveImage(null);
    }
  }, [showUserGuide]);

  const { maintenance } = useMaintenance();
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);
  const [platformsGen, setPlatformsGen] = useState<PlatformGen[]>([]);

  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const platforms: PlatformGen[] =
          (await generateService.getPlatforms()) as PlatformGen[];
        setPlatformsGen(platforms);
      } catch (error) {
        console.error("Error fetching platforms:", error);
      }
    };

    fetchPlatforms();
  }, []);

  return (
    <>
      <div className={classNames(styles.wrapper)}>
        <LoadingOverlay isLoading={isLoadingTask} taskStatus={taskStatus} />
        <Header onSave={onSave} setShow={setShowUserGuideVideos} />
        <div className={styles.main}>
          <div className={styles.sidebar}>
            <div
              className={classNames(styles.sidebar__primary, {
                [styles.darkened]: showUserGuide,
              })}
            >
              <Nav
                onChange={handleActiveTab}
                items={navItems}
                defaultIndex={activeNavIndex}
                UserGuide={showUserGuide}
                step={currUserStepGuide}
              />
            </div>
            <div
              className={classNames(styles.sidebar__secondary, {
                [styles.hidden]: activeNavIndex === 3,
              })}
            >
              {activeNavIndex === 0 && (
                <AsinTab
                  items={asinImages}
                  onChange={handleChangeImage}
                  onUploadImage={handleUploadImage}
                  setTemplates={setTemplates}
                  isLoading={isLoadingImage}
                  index={activeImageIndex}
                  setIndex={setActiveImageIndex}
                  UserGuide={showUserGuide}
                  staticData={showUserGuide ? listImages : undefined}
                  currStep={currUserStepGuide}
                  setCurrUserStepGuide={setCurrUserStepGuide}
                  setShowModal={setShowModal}
                  setUserGuide={setShowUserGuide}
                  setFileId={setFileId}
                />
              )}
              {activeNavIndex === 1 && (
                <GenerateTab
                  templates={templates.transformed}
                  onGenerateImage={handleGenerateImage}
                  customPrompt={customPrompt}
                  setCustomPrompt={setCustomPrompt}
                  applyImg={applyImg}
                  activeImage={asinImages[activeImageIndex]}
                  setUserGuide={setShowUserGuide}
                  userGuide={showUserGuide}
                  currStep={currUserStepGuide}
                  setCurrUserStepGuide={setCurrUserStepGuide}
                  selectedPrompt={selectedPrompt}
                  setSelectedPrompt={setSelectedPrompt}
                />
              )}
              {activeNavIndex === 2 && (
                <AssetsTab
                  platformsGen={platformsGen}
                  onChange={handleChangeAsset}
                  onUploadImage={() => {}}
                  isLoading={isLoadingAssetImage}
                  userGuide={showUserGuide}
                  setUserGuide={setShowUserGuide}
                  currStep={currUserStepGuide}
                  setCurrUserStepGuide={setCurrUserStepGuide}
                />
              )}
              {maintenance && activeNavIndex === 3 && <SettingsTab />}
            </div>
          </div>
          <Canvas
            activeImage={activeImage}
            activeNavIndex={activeNavIndex}
            draggableEl={draggableAssets}
            onMouseUpDraggableItems={handleMouseUpDraggableItems}
            activeBtn={activeBtn}
            generatedImage={generatedImage}
            isLoading={isLoadingImageGeneration}
            setFormatCanvasResult={setFormatCanvasResult}
            onApplyImg={handleApplyImage}
            onNewRender={handleNewRender}
            setApplyImg={setApplyImg}
            userGuide={showUserGuide}
            setUserGuide={setShowUserGuide}
            currStep={currUserStepGuide}
            setCurrUserStepGuide={setCurrUserStepGuide}
            setActiveNavIndex={setActiveNavIndex}
            setSelectedPrompt={setSelectedPrompt}
            selectedPrompt={selectedPrompt}
            onGenerateImage={handleGenerateImage}
          />
        </div>
      </div>
      <MasksModal
        show={showModal}
        setShow={setShowModal}
        setActiveMask={setActiveImage}
        imgs={masks}
        userGuide={showUserGuide}
        staticMasks={showUserGuide ? staticMasks : null}
        setCurrUserStepGuide={setCurrUserStepGuide}
      />
      <UserGuideModal
        show={showUserGuideVideos}
        setShow={setShowUserGuideVideos}
      />
    </>
  );
};

export default PhotoOptimisationDetails;
