import { Button } from "antd";
import styles from "./DataEntry.module.scss";
import DataIsEntry from "@assets/icons/dataEntry";
import Left from "@assets/icons/leftArrow";
import { useState } from "react";
import AddDataEntryModal from "../DataEntryModal";
import TableDataEntry from "../TableDataEntry";

const DataEntry = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const showTable = () => {
    setIsModalVisible(false);
    setIsTableVisible(true);
  };

  return (
    <div className={styles.wrapper}>
      {!isTableVisible ? (
        <>
          <div className={styles.header}>
            <h1 className={styles.title}>Data Entry</h1>
            <div className={styles.actions}>
              <Button type="default" className={styles.uploadButton}>
                <Left />
                Upload CSV
              </Button>
              <Button
                type="primary"
                className={styles.addButton}
                onClick={openModal}
              >
                Add Data Entry
              </Button>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.emptyState}>
              <DataIsEntry />
              <p className={styles.pEmpty}>No data entries added yet</p>
            </div>
          </div>
        </>
      ) : (
        <div>
          <TableDataEntry />
        </div>
      )}

      {isModalVisible && (
        <AddDataEntryModal
          visible={isModalVisible}
          onCancel={closeModal}
          onSwitch={showTable}
        />
      )}
    </div>
  );
};

export default DataEntry;
