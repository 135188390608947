import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Option, Select } from "@components/Select";
import { useEffect, useState } from "react";
import { Brand, ProductWithImageLink } from "@services/amazon/products/types";
import { Input } from "@components/Input";
import { ProductsService } from "@services/amazon/products/products.service";
import Search from "@assets/icons/search";

export const BrandAsinSelect: React.FC = () => {
  const productsService = new ProductsService();
  const [asinOptions, setAsinOptions] = useState<Option[]>();
  const [brandOptions, setBrandOptions] = useState<Option[]>();
  const [asinPage, setAsinPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [searchAsin, setSearchAsin] = useState("");

  const {
    isAsinSelect,
    asinSelected,
    brandSelected,
    setAsinBrandSelect,
    setBrandSelected,
    setAsinSelected,
    setBrandName,
    setAsinName,
  } = useDashboardStore();

  useEffect(() => {
    const fetchAsinOptions = async () => {
      try {
        const [productAsinData, productBrandData] = await Promise.all([
          productsService.getOwnProducts({
            limit: 32 * asinPage,
          }),
          productsService.getOwnProductsBrand({
            limit: 32 * brandPage,
          }),
        ]);

        const names = productBrandData.map((item) => item.name);
        const asinNames = productAsinData.map((item) => item.asin);
        setBrandName(names);
        setAsinName(asinNames);
        const asinOptions = productAsinData.map(
          (product: ProductWithImageLink) => ({
            id: product.asin,
            text: product.item_name,
            img: product.image_link,
            asin: product.asin,
          }),
        );
        const brandOptions = productBrandData.map((product: Brand) => ({
          id: product.name,
          text: product.asins.join(", "),
        }));
        setBrandOptions(brandOptions);
        setAsinOptions(asinOptions);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchAsinOptions();
  }, [isAsinSelect, asinPage, brandPage]);

  useEffect(() => {
    const fetchSearchData = async () => {
      try {
        const data = await productsService.searchOwnProducts({
          limit: 32,
          asin: searchAsin,
        });
        if (!data) return;
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchSearchData();
  }, [searchAsin]);

  const handleChange = (value: string[]) => {
    if (isAsinSelect) {
      setAsinSelected(value);
    } else {
      setBrandSelected(value);
    }
  };

  const handleReset = () => {
    if (isAsinSelect) {
      setAsinSelected([]);
    } else {
      setBrandSelected([]);
    }
  };

  const selectOptions = {
    value: isAsinSelect ? asinSelected : brandSelected,
    options: isAsinSelect ? asinOptions : brandOptions,
    onChange: handleChange,
    placeholder: isAsinSelect ? "Select ASIN" : "Select Brand",
    className: styles.select,
    isMultiSelect: true,
    isOptionsOutside: true,
  };

  return (
    <div className={styles.container}>
      <div className={styles.switch__container}>
        <button
          className={classNames(styles.switch, {
            [styles.active]: !isAsinSelect,
          })}
          onClick={() => setAsinBrandSelect(false)}
        >
          Brand
        </button>
        <button
          className={classNames(styles.switch, {
            [styles.active]: isAsinSelect,
          })}
          onClick={() => setAsinBrandSelect(true)}
        >
          ASIN
        </button>
      </div>
      <Select {...selectOptions}>
        <div className={styles.search}>
          {isAsinSelect && (
            <Input
              placeholder="Search"
              value={searchAsin}
              icon={<Search />}
              onChange={(value) => setSearchAsin(value)}
            />
          )}
          <button className={styles.reset} onClick={handleReset}>
            Reset
          </button>
        </div>
      </Select>
    </div>
  );
};
