import React, { useState } from "react";
import styles from "./styles.module.scss";
import { OrganicChart } from "./OrganicChart";
import { GridStack } from "gridstack";
import { updateElementSize } from "@pages/CanvasEditor/Canva";
import { OrganicTable } from "./OrganicTable";

interface OrganicSalesTableProps {
  gridRef?: React.RefObject<GridStack>;
  id?: string;
  isWhatIf?: boolean;
  autoHeight?: boolean;
}

export const OrganicSalesTable: React.FC<OrganicSalesTableProps> = ({
  gridRef,
  id,
  isWhatIf,
  autoHeight,
}) => {
  const [isChartOpen, setIsChartOpen] = useState(false);

  return (
    <div className={styles.container}>
      {isChartOpen ? (
        <OrganicChart
          updateElementSize={() =>
            updateElementSize({
              id: id || "",
              size: { height: 50 },
              gridRef: gridRef,
            })
          }
          setIsChartOpen={setIsChartOpen}
        />
      ) : (
        <OrganicTable isWhatIf={isWhatIf} autoHeight={autoHeight} />
      )}
    </div>
  );
};
