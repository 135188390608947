import { AuthGuard } from "@components/AuthGuard/AuthGuard";
import { Home } from "@pages/Home/Home";
import useAmazonRedirect from "src/utils/useAmazonRedirect";

interface PrivatePageProps {
  hideHeader?: boolean;
}

export const PrivatePage: React.FC<PrivatePageProps> = ({ hideHeader }) => {
  useAmazonRedirect();

  return (
    <AuthGuard>
      <Home hideHeader={hideHeader} />
    </AuthGuard>
  );
};
