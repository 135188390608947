import React from "react";
import { NavLink } from "react-router-dom";
import { ProductPopup } from "./ProductPopup";
import classNames from "classnames";

export type NavItem = {
  text: string;
  url?: string;
  onClick?: () => void;
};

export const createNavItems = (
  navItems: NavItem[],
  menuOpen: boolean,
  setMenuOpen: (open: boolean) => void,
  popupRef: React.RefObject<HTMLDivElement>,
  setMenuOpens: (open: boolean) => void,
  isAmazonConnectedOrSeller: boolean,
  setAmazonConnectModalOpen: (open: boolean) => void,
): JSX.Element[] => {
  return navItems.map((item) => (
    <React.Fragment key={item.text}>
      {isAmazonConnectedOrSeller && item.url ? (
        <NavLink
          to={item.url}
          className={({ isActive }) =>
            classNames("app-header__nav-item", "text-button", {
              active: isActive,
            })
          }
          onClick={() => {
            item.onClick?.();
            setMenuOpen(false);
            setMenuOpens(false);
          }}
        >
          {item.text}
        </NavLink>
      ) : (
        <div style={{ position: "relative" }} ref={popupRef}>
          <button
            className={classNames("app-header__nav-button", "text-button", {
              activeProduct: menuOpen,
            })}
            onClick={() => {
              if (!isAmazonConnectedOrSeller) {
                setAmazonConnectModalOpen(true);
              } else {
                item.onClick?.();
                const newState = !menuOpen;
                setMenuOpen(newState);
                if (!newState) {
                  setMenuOpens(false);
                }
              }
            }}
          >
            {item.text}
          </button>
          {menuOpen && (
            <ProductPopup
              closePopup={() => {
                setMenuOpen(false);
                setMenuOpens(false);
              }}
            />
          )}
        </div>
      )}
    </React.Fragment>
  ));
};
