import classNames from "classnames";
import styles from "./styles.module.scss";
import { useMaintenance } from "src/store/maintenance.state";

export type TypeTab = "Heatmap" | "Sankey" | "Ad Campaign Dotplot";

const typeTabs: TypeTab[] = ["Heatmap", "Sankey", "Ad Campaign Dotplot"];

export const TabButton = ({
  label,
  isSelected,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: React.ElementType;
}) => (
  <button
    className={classNames(styles.tab, isSelected && styles.active)}
    onClick={onClick}
  >
    {label}
  </button>
);

export const TabsTypeList = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: TypeTab;
  setSelectedTab: (tab: TypeTab) => void;
}) => {
  const { maintenance } = useMaintenance();

  const filteredTabs = !maintenance
    ? typeTabs.filter((tab) => tab !== "Heatmap")
    : typeTabs;

  return (
    <div className={styles.tabs}>
      {filteredTabs.map((tab) => (
        <TabButton
          key={tab}
          label={tab}
          isSelected={selectedTab === tab}
          onClick={() => setSelectedTab(tab)}
        />
      ))}
    </div>
  );
};
