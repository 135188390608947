import { useState } from "react";
import { DetailsChart } from "@pages/MarketIntelligence/components/DetailsChart";
import styles from "./styles.module.scss";
import classNames from "classnames";
import MarketOverview from "@pages/MarketIntelligence/components/DotChart";
import BrandRevenueChart from "@pages/MarketIntelligence/components/BrandRevenueChart";

interface DetailsProps {
  status: string;
}

export const Details = ({ status }: DetailsProps) => {
  const [productDetails, setProductDetails] = useState({
    category: "",
    price: 31,
    count: 21,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className={styles.detailsWrapper}>
      <div className={styles.productLaunch}>
        <h2>Product Launch</h2>
        <div className={styles.productInfo}>
          <label className="input">
            Category
            <input
              className="input__input"
              type="text"
              name="category"
              placeholder="Enter product type"
              value={productDetails.category}
              onChange={handleChange}
            />
          </label>
          <div className={styles.row}>
            <label className={classNames("input", styles.price)}>
              Price
              <input
                className="input__input"
                type="text"
                name="price"
                placeholder="Enter product price"
                value={productDetails.price}
                onChange={handleChange}
              />
            </label>
            <label className="input">
              Count
              <input
                className="input__input"
                type="text"
                name="count"
                placeholder="Enter product count"
                value={productDetails.count}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
      </div>
      <DetailsChart />
      {status === "inactive" && (
        <div className={styles.marketBetween}>
          <div className={styles.brandRevenue}>
            <MarketOverview />
          </div>
          <div className={styles.brandRevenue}>
            <BrandRevenueChart hidePercentage={true} />
          </div>
        </div>
      )}
    </div>
  );
};
