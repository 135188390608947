import ModalBox from "@components/ModalBox";
import { Checkbox, Switch, Tooltip } from "antd";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import { WidgetClass } from "@services/widget.service";
import { SalesChecks } from "../../utils";
import {
  WIDGET_METRIC_GROUPS,
  WIDGET_METRICS_CONFIG,
} from "@services/math/math.const";
import classNames from "classnames";
import { useForecastStore } from "src/store/forecast";
import DaysSelect from "@pages/AdsAnalitics/components/Header/ui/CustomDays";

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  isAdvancedView: boolean;
  handleToggleView: (checked: boolean) => void;
  checks: SalesChecks;
  setChecks: (checks: SalesChecks) => void;
  isForecasted: boolean;
  setIsForecasted: (checked: boolean) => void;
  choosedGroups: string[] | "all";
  isMarket?: boolean;
  isBrand?: boolean;
}

const MAX_METRICS = 6;
const MAX_METRICS_MARKET = 4;
export const SettingsModal = ({
  open,
  onClose,
  isAdvancedView,
  handleToggleView,
  checks,
  setChecks,
  isForecasted,
  setIsForecasted,
  isBrand,
  choosedGroups,
  isMarket,
}: SettingsModalProps) => {
  let selectedCount = Object.values(checks).filter(Boolean).length;
  const maxMetrics = isBrand ? 1 : isMarket ? MAX_METRICS_MARKET : MAX_METRICS;
  const [isTableView, setIsTableview] = useState(isAdvancedView);
  const [choosedDataPoints, setChoosedDataPoints] = useState(checks);
  const [isMaxMetrics, setIsMaxMetrics] = useState(selectedCount >= maxMetrics);

  useEffect(() => {
    const count = Object.values(choosedDataPoints).filter(Boolean).length;
    setIsMaxMetrics(count >= maxMetrics);
  }, [choosedDataPoints]);

  const { showForecast, setShowForecast } = useForecastStore();

  useEffect(() => {
    setShowForecast(isForecasted);
  }, [isForecasted]);

  const handleReset = () => {
    setChoosedDataPoints(checks);
    setIsTableview(isAdvancedView);
    setIsMaxMetrics(false);
  };

  const handleApply = () => {
    setChecks(choosedDataPoints);
    handleToggleView(isTableView);
    setIsForecasted(showForecast);
    onClose();
  };

  const filteredGroups =
    choosedGroups === "all"
      ? WIDGET_METRIC_GROUPS
      : WIDGET_METRIC_GROUPS.filter((group) =>
          choosedGroups.includes(group.title),
        );

  const handleSelect = (value: string) => {
    console.log("Selected:");
  };
  return (
    <ModalBox
      title="Sales Performance"
      isOpen={open}
      onClose={onClose}
      className={styles.modal}
    >
      <p>Choose view and metrics for «Sales Performance»</p>
      <div className={styles.switchRow}>
        <div className={styles.switchContainer}>
          <span className={styles.switchLabel}>Show lines</span>
          <Switch
            checked={isTableView}
            onChange={() => setIsTableview(!isTableView)}
          />
          <span className={styles.switchLabel}>Show bars</span>
        </div>
        <div className={styles.switchContainer}>
          <Switch
            checked={showForecast}
            onChange={(checked) => setShowForecast(checked)}
          />
          <span className={styles.switchLabel}>Show Forecast</span>
        </div>
        <div>
          {isBrand && (
            <DaysSelect
              options={["Days", "Week", "Month"]}
              defaultLabel="Group By"
              onSelect={handleSelect}
            />
          )}
        </div>
      </div>

      <div className={styles.points}>
        {filteredGroups.map((group) => (
          <div
            key={group.title}
            className={classNames(
              styles.group,
              isMaxMetrics && styles.maxMetrics,
            )}
          >
            <h3>{group.title}</h3>
            {group.keys.map((key) => {
              const isChecked = !!choosedDataPoints[key];
              const handleChange = (newChecked: boolean) => {
                if (!isChecked && newChecked) {
                  if (isMaxMetrics) return;
                  selectedCount++;
                  if (selectedCount >= MAX_METRICS) {
                    setIsMaxMetrics(true);
                  }
                }
                if (isChecked && !newChecked) {
                  selectedCount--;
                  if (selectedCount <= MAX_METRICS) {
                    setIsMaxMetrics(false);
                  }
                }
                setChoosedDataPoints({
                  ...choosedDataPoints,
                  [key]: newChecked,
                });
              };
              return (
                <div key={key} className={styles.row}>
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => handleChange(e.target.checked)}
                  />
                  <span className={styles.name}>
                    {WidgetClass.convertKeyToName(key)}
                  </span>
                  <Tooltip title={WIDGET_METRICS_CONFIG[key]?.info}>
                    <button>i</button>
                  </Tooltip>
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <button className={styles.reset} onClick={handleReset}>
          reset to default
        </button>
        <button className={styles.apply} onClick={handleApply}>
          Apply
        </button>
      </div>
    </ModalBox>
  );
};
