export const crestData = [
  { value: [10, 0.8, 100], asin: "B00AHAWW00" },
  { value: [15, 3.5, 300], asin: "B09M5PXJ86" },
  { value: [22, 2.1, 200], asin: "B077YCC84H" },
  { value: [28, 4.8, 550], asin: "B0BVT76MFH" },
  { value: [47, 2.0, 200], asin: "B09S8JPWMK" },
  { value: [52, 3.0, 300], asin: "B01M63SQTI" },
  { value: [70, 4.0, 400], asin: "B07XJZ1MSF" },
  { value: [75, 2.5, 250], asin: "B00336EUTK" },
];

export const lumineuxData = [
  { value: [5, 1.0, 120], asin: "B093TSRPQM" },
  { value: [12, 2.3, 200], asin: "B082TPDTM2" },
  { value: [25, 0.9, 150], asin: "B093TSRPQM" },
  { value: [35, 3.0, 250], asin: "B082TPDTM2" },
  { value: [44, 5.2, 580], asin: "B093TSRPQM" },
  { value: [50, 2.5, 220], asin: "B082TPDTM2" },
  { value: [62, 3.8, 350], asin: "B093TSRPQM" },
  { value: [78, 4.2, 400], asin: "B082TPDTM2" },
];

export const inoProData = [
  { value: [8, 1.5, 160], asin: "BODP55J8ZG" },
  { value: [18, 0.7, 110], asin: "BOB5HN65QQ" },
  { value: [26, 2.4, 260], asin: "BOD3VHMR3Z" },
  { value: [32, 3.9, 330], asin: "BOCXYSDC2H" },
  { value: [45, 1.2, 180], asin: "BODP55J8ZG" },
  { value: [55, 3.2, 320], asin: "B0B5HN65QQ" },
  { value: [65, 4.5, 450], asin: "BOD3VHMR3Z" },
  { value: [79, 5.3, 600], asin: "BOD3VHMR3Z" },
];

export const guruNandaData = [
  { value: [9, 5.0, 560], asin: "B0BK2SC18T" },
  { value: [14, 2.1, 220], asin: "B0DBQG28PV" },
  { value: [20, 1.0, 140], asin: "B0BK2SC18T" },
  { value: [29, 4.0, 380], asin: "B0DBQG28PV" },
  { value: [38, 3.3, 310], asin: "B0BK2SC18T" },
  { value: [46, 2.0, 200], asin: "B0DBQG28PV" },
  { value: [60, 4.6, 460], asin: "B0BK2SC18T" },
  { value: [72, 3.5, 360], asin: "B0DBQG28PV" },
];

export const keidocData = [
  { value: [7, 1.2, 150], asin: "B0D8S5N9FL" },
  { value: [16, 0.9, 120], asin: "B0D8S5N9FL" },
  { value: [24, 2.8, 260], asin: "B0D8S5N9FL" },
  { value: [33, 3.1, 300], asin: "B0D8S5N9FL" },
  { value: [48, 2.2, 210], asin: "B0D8S5N9FL" },
  { value: [55, 4.8, 570], asin: "B0D8S5N9FL" },
  { value: [66, 1.8, 180], asin: "B0D8S5N9FL" },
  { value: [76, 3.9, 420], asin: "B0D8S5N9FL" },
];

export const crestTypeDataOriginal = [
  {
    name: "Professional Effects",
    value: [5, 1.2, 100],
    asin: "B00AHAWW00",
  },
  {
    name: "Professional Effects Plus",
    value: [15, 3.0, 300],
    asin: "B09M5PXJ86",
  },
  {
    name: "No Slip Vivid Plus",
    value: [25, 2.5, 250],
    asin: "B077YCC84H",
  },
  {
    name: "Radiant Express + LED",
    value: [35, 1.8, 180],
    asin: "B0BVT76MFH",
  },
  {
    name: "3D Whitestrips + LED",
    value: [45, 2.2, 220],
    asin: "B09S8JPWMK",
  },
  {
    name: "Glamorous White",
    value: [55, 3.1, 310],
    asin: "B01M63SQTI",
  },
  {
    name: "Supreme Bright",
    value: [65, 4.0, 400],
    asin: "B07XJZ1MSF",
  },
  {
    name: "7 Treatments",
    value: [75, 5.0, 600],
    asin: "B00336EUTK",
  },
  {
    name: "Classic Vivid",
    value: [85, 2.5, 250],
    asin: "B01N1DUUN2",
  },
  {
    name: "Noticeably White",
    value: [95, 3.5, 350],
    asin: "B0CNQK2G75",
  },
  {
    name: "Professional White + LED",
    value: [105, 1.8, 180],
    asin: "B010OLLUVS",
  },
  {
    name: "Radiant Express",
    value: [115, 2.2, 220],
    asin: "B00YPKH2S5",
  },
  {
    name: "Professional Bright",
    value: [125, 3.1, 310],
    asin: "B01INDUT76A",
  },
  {
    name: "Professional White",
    value: [135, 4.0, 400],
    asin: "B01INDUWWC",
  },
  {
    name: "1 Hour Express + LED",
    value: [145, 2.5, 250],
    asin: "B0N1NWUP3Q",
  },
  {
    name: "Brilliance White",
    value: [155, 3.5, 350],
    asin: "B0993NXZ5B",
  },
  {
    name: "1 Hour Express",
    value: [165, 1.8, 180],
    asin: "B014X67UEA",
  },
  {
    name: "Luminous",
    value: [175, 2.2, 220],
    asin: "B09LWBWXGF",
  },
  {
    name: "Supreme Bright Boost",
    value: [185, 3.1, 310],
    asin: "B0BHRW1F8Z",
  },
  {
    name: "Classic White",
    value: [190, 4.0, 400],
    asin: "B07SRL8W7P",
  },
  {
    name: "No slip",
    value: [195, 2.5, 250],
    asin: "B0B4UJSD56",
  },
];

export const inoProTypeData = [
  { name: "Sensitive", value: [10, 0.5, 100], asin: "BOB5HN65QQ" },
  { name: "Product 1", value: [40, 1.5, 150], asin: "BODP55J8ZG" },
  { name: "Sensitive + LED", value: [65, 2.2, 220], asin: "BOCXYSDC2H" },
];

export const oldMin = 5;
export const oldMax = 195;
export const newMin = 0;
export const newMax = 80;

export const brandNamesAsin = [
  "Crest",
  "Lumineux",
  "InoPro",
  "GuruNanda",
  "Keidoc",
];
export const brandNamesType = ["Crest", "InoPro"];
