import {
  ToolType,
  PREFIX_METRIC,
  PREFIX_PERFORMANCE,
  ValueFormat,
} from "../../types";
import { METRIC_ICONS } from "./constants";

export const formatValue = (value: number, format: ValueFormat): string => {
  if (typeof value !== "number" || isNaN(value)) return "--";

  switch (format) {
    case ValueFormat.CURRENCY:
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);

    case ValueFormat.CURRENCY_DECIMAL:
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

    case ValueFormat.PERCENT:
      return new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);

    case ValueFormat.PERCENT_DECIMAL:
      return new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

    case ValueFormat.DECIMAL:
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);

    case ValueFormat.NUMBER:
    default:
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
  }
};

const normalizeMetricType = (type: ToolType | string): string => {
  const stringType = type.toString();
  if (
    stringType === "sales_performance_total-sales" ||
    stringType === ToolType.SALES_PERFORMANCE_TOTAL_SALES
  ) {
    return ToolType.SALES_PERFORMANCE_TOTAL_SALES;
  }
  return stringType.replace(PREFIX_PERFORMANCE, PREFIX_METRIC);
};

export const formatMetricValue = (
  type: ToolType | string,
  value: number,
): string => {
  if (typeof value !== "number" || isNaN(value)) return "--";

  const normalizedType = normalizeMetricType(type);

  const formatMap = {
    [ToolType.ADS_SALES_SALES]: ValueFormat.CURRENCY,
    [ToolType.ADS_SALES_SPEND]: ValueFormat.CURRENCY,
    [ToolType.ADS_SALES_CPC]: ValueFormat.CURRENCY_DECIMAL,
    [ToolType.ADS_SALES_ACOS]: ValueFormat.PERCENT,
    [ToolType.ADS_SALES_ROAS]: ValueFormat.DECIMAL,
    [ToolType.ADS_SALES_CVR]: ValueFormat.PERCENT_DECIMAL,
    [ToolType.ADS_SALES_CTR]: ValueFormat.PERCENT_DECIMAL,
    [ToolType.SALES_PERFORMANCE_SALES]: ValueFormat.CURRENCY,
    [ToolType.SALES_PERFORMANCE_SPEND]: ValueFormat.CURRENCY,
    [ToolType.SALES_PERFORMANCE_CPC]: ValueFormat.CURRENCY_DECIMAL,
    [ToolType.SALES_PERFORMANCE_ACOS]: ValueFormat.PERCENT,
    [ToolType.SALES_PERFORMANCE_ROAS]: ValueFormat.DECIMAL,
    [ToolType.SALES_PERFORMANCE_CVR]: ValueFormat.PERCENT_DECIMAL,
    [ToolType.SALES_PERFORMANCE_CTR]: ValueFormat.PERCENT_DECIMAL,
    [ToolType.SALES_PERFORMANCE_TOTAL_SALES]: ValueFormat.CURRENCY,
  };

  return formatValue(value, formatMap[normalizedType] || ValueFormat.NUMBER);
};

export const getMetricIcon = (type: string): string => {
  const metric = type.toLowerCase();
  return (
    Object.entries(METRIC_ICONS).find(([key]) => metric.includes(key))?.[1] ??
    METRIC_ICONS.default
  );
};

export const getWidgetName = (type: ToolType): string => {
  const name = type
    .replace(PREFIX_METRIC, "")
    .replace(PREFIX_PERFORMANCE, "")
    .toUpperCase();

  if (name === "SALES" || name.endsWith("_SALES")) {
    return "Total Sales";
  }

  return name;
};
